import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService, CLToastType } from '@clavisco/alerts';
import { OverlayService } from '@clavisco/overlay';
import {finalize, Observable, tap} from 'rxjs';
import {IUsers} from 'src/app/models/interfaces/i-users';
import { UserService } from 'src/app/services/user.service';
import {IResponse} from "../../../../models/i-response";

@Component({
  selector: 'app-modal-add-user',
  templateUrl: './modal-add-user.component.html',
  styleUrls: ['./modal-add-user.component.sass']
})
export class ModalAddUserComponent implements OnInit {

  inputPasswordIcon: string = 'visibility'
  inputPasswordType: string = 'password'

  Id!: string
  Email!: string
  UserName!: string
  Password!: string
  Active: boolean = false;

  userForm!: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<ModalAddUserComponent>,
    private alertService: AlertsService,
    @Inject(MAT_DIALOG_DATA) public userData: IUsers,
    private clBlockUI: OverlayService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.onLoad();
  }

  onLoad() {
    this.userForm = this.createUserForm();
    if (this.userData) {
      this.patchUserData(this.userData);
      this.userForm.controls['Password'].clearValidators()
      this.userForm.controls['Password'].updateValueAndValidity()
      this.userForm.controls['Password'].setValidators([Validators.minLength(8)])
    }
  }

  public patchUserData(userData: IUsers): void {
    this.userForm.patchValue({
      Email: userData.Email,
      Name: userData.Name,
      LastName: userData.LastName,
      IsActive: userData.IsActive,
      Id: userData.Id,
    });
  }

  ToggleShowPassword(): void {
    this.inputPasswordIcon = this.inputPasswordIcon === "visibility" ? "visibility_off" : "visibility";
    this.inputPasswordType = this.inputPasswordIcon === "visibility" ? "password" : "text";
  }

  CloseDialog(result: boolean): void {
    this.dialogRef.close(result)
  }

  private createUserForm(): FormGroup {
    return new FormGroup({
      Name: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(30)])),
      LastName: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(30)])),
      Email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      Password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
      IsActive: new FormControl(this.Active),
      Id: new FormControl(0)
    })
  }

  getErrorMessageRequired(nameControl: string, text: string): string {
    if (this.userForm.controls[nameControl].hasError('required')) {
      return text;
    }else if (this.userForm.controls['Password'].hasError('minlength')) {
      const minLength = this.userForm.controls[nameControl].getError('minlength').requiredLength;
      return `La contraseña debe tener mínimo ${minLength} caracteres`;
    }
    return this.userForm.controls['Email'].hasError('email') ? 'No es un correo electrónico válido' : '';
  }

  onSubmit(): void {
    if (!this.userForm.valid) {
      return this.alertService.Toast({ message: 'Los datos son requeridos', type: CLToastType.ERROR })
    }
    if(this.userData){
      this.userService.Patch(this.userForm.value).pipe(
        tap(
          value => {
            if(value.Data){
              this.alertService.Toast({ message: 'Actualizado Correctamente', type: CLToastType.SUCCESS });
              this.CloseDialog(true);
              return;
            }
            this.alertService.Toast({ message: value.Error.Message, type: CLToastType.ERROR })
          }
        )
      ).subscribe()
      return;
    }

    this.clBlockUI.OnPost('Guardando Usuario...')
    this.userService.SaveUser(this.userForm.value).pipe(finalize(() => { this.clBlockUI.Drop(); })).subscribe({
      next: callback => {
        if (callback.Data) {
          this.alertService.Toast({ message: 'Guardado Correctamente', type: CLToastType.SUCCESS });
          this.CloseDialog(true);
        } else {
          this.alertService.Toast({ message: callback.Error.Message, type: CLToastType.ERROR })
        }
      },
    })
  }

}
