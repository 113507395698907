import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/app-material.module';
import { CheckListComponent } from './check-list.component';
import { HttpClientModule } from '@angular/common/http';
import { MatExpansionModule } from '@angular/material/expansion';
import { ModalCheckListDetailsComponent } from './modal-check-list-details/modal-check-list-details.component';
import { ModalCheckListHeaderComponent } from './modal-check-list-header/modal-check-list-header.component';



@NgModule({
  declarations: [
    CheckListComponent,
    ModalCheckListDetailsComponent,
    ModalCheckListDetailsComponent,
    ModalCheckListHeaderComponent
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    HttpClientModule,
    MatExpansionModule,
  ],
  exports: [
    CheckListComponent
  ]
})
export class CheckListModule { }
