import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppConstants, PermissionCode } from 'src/app/common/constants';
import { SessionService } from 'src/app/services/session.service';
import { faBitbucket, IconDefinition } from '@fortawesome/free-brands-svg-icons'
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { IMenuPath } from 'src/app/models/interfaces/i-menu-paths';
import { PermissionsService } from 'src/app/services/permissions.service';
import { CLPrint, GetError, Structures } from '@clavisco/core';
import { AlertsService, CLModalType, ModalService } from '@clavisco/alerts';
import { LogService } from 'src/app/services/log.service';
import { MenuService } from 'src/app/services/menu.service';
import {OverlayService} from "@clavisco/overlay";
import {catchError, finalize, tap} from "rxjs";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {IPermission} from "../../models/interfaces/i-permissions";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass'],
})
export class HomeComponent implements OnInit {
  showHomeMenu: boolean = false;
  logedUserName!: string
  faBitbucket: IconDefinition = faBitbucket
  faServer: IconDefinition = faServer

  sidenavMenuItems: IMenuPath[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sessionService: SessionService,
    private permissionService: PermissionsService,
    private clBlockUI: OverlayService,
    private clAlertService: ModalService,
    private logsService: LogService,
    private menuService: MenuService
  ) {
    this.router.events.subscribe({
      next: (callback) => {
        if (callback instanceof NavigationEnd) {
          if (callback.url === '/index') {
            this.showHomeMenu = true;
          } else {
            this.showHomeMenu = false;
          }
        }
      },
    });
  }

  ngOnInit(): void {
    this.logedUserName = this.sessionService.GetUserName();
    this.GetUserPermissions();
    //this.getMenu();
  }


  GetUserPermissions(): void {
    try {
      if(this.sessionService.GetUserId()){
        this.clBlockUI.OnGet();
        this.permissionService.GetPermissionsByUser(this.sessionService.GetUserId()).pipe(finalize ( () => { this.clBlockUI.Drop() }),
          tap((data: ICLResponse<IPermission[]>)=>{
            if(data.Data){
              this.sessionService.SetPermissions(data.Data)
            }
          }),catchError((err) => {
            return [];
          })).subscribe();
      }
    } catch (error) {

      CLPrint(GetError(error));
    }
  }


  getMenu() {
   // this.sidenavMenuItems = menu;
    this.menuService.getMenu()
     .subscribe({
        next: (data) => {
          this.menuService.Data.menu = data;
        this.menuService.Menu.next(data)
        },
        error: (error: string) => {
          console.log(error)
        },
      });
  }

  HabilitedOption(code: PermissionCode[]): boolean {
    let condition: boolean = false;
    code.forEach((element) => {
      if (this.GetUserPermissons().includes(element)) {
        condition = true;
      }
    });
    return true;
  }

  LogOut(): void {
    this.sessionService.RemoveSession();
    this.router.navigateByUrl('/login');
  }

  ViewProyects(): void {
    this.router.navigate(['proyects'], { relativeTo: this.route });
  }

  ViewPermissions(): void {
    this.router.navigate(['permissions'], { relativeTo: this.route });
  }

  ViewUsers(): void {
    this.router.navigate(['users'], { relativeTo: this.route });
  }

  ViewDevelopers(): void {
    this.router.navigate(['developers'], { relativeTo: this.route });
  }

  ViewServers(): void {
    this.router.navigate(['servers'], { relativeTo: this.route });
  }

  ViewBitbucketIntegration() {
    this.router.navigate(['bitbucket'], { relativeTo: this.route })
  }

  ViewWindowsServios() {
    this.router.navigate(['services'], { relativeTo: this.route })
  }

  GetUserPermissons(): string[] {
    const permissions: string[] = [];
    this.sessionService.GetCompleteToken().Permissions.forEach((element) => {
      permissions.push(element.Name);
    });
    return permissions;
  }
}
