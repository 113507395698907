<div class="container">
  <router-outlet>
    <div style="display: flex; justify-content: flex-end;">
      <button mat-stroked-button color="primary" (click)="OpenSettingDialog()"><mat-icon>add</mat-icon>Agregar</button>
    </div>
    <div>
      <mat-card class="mb-1 mat-elevation-z0">
        <cl-table [Id]="tableId"
                  [MappedColumns]="displayedColumns"
                  [Records]="settings"
                  [PageSizeOptions]="pageSizeOptions"
                  [ItemsPeerPage]="itemsPeerPage"
                  [RecordsCount]="recordsCount"
                  [HasPaginator]="hasPaginator"
                  [ScrollHeight]="scrollHeight"
                  [Buttons]="Buttons">
        </cl-table>
      </mat-card>
    </div>
  </router-outlet>
</div>
