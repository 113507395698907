export interface IServersRecollectionHistories {
  MaxQtyRowsFetch: number;
  CreatedDate: string;
  JsonObject: string;
  Server: string;
}

export interface ILogs {
  Id: number;
  MaxQtyRowsFetch: number;
  CreatedBy: string;
  CreatedDate: string;
  UserName?: string
}
