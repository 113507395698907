import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayService } from '@clavisco/overlay';
import { ModalService, ModalModule } from '@clavisco/alerts';
import { RptmngDeskModule } from '@clavisco/rptmng-desk';
import { ReportsComponent } from './reports.component';
import { AppMaterialModule } from 'src/app/app-material.module';

@NgModule({
  declarations: [
    ReportsComponent
  ],
  imports: [
    AppMaterialModule,
    ModalModule,
    RptmngDeskModule
  ],
  exports: [
    ReportsComponent,
  ],
  providers:[
    {
      provide: 'OverlayService',
      useClass: OverlayService
    },
    {
      provide: 'ModalService',
      useClass: ModalService
    },
  ]

})
export class ReportsModule { }
