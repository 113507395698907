<div>
    <div id="filter-container">
        <form [formGroup]="formFilter">
            <div class="flex">
                <mat-form-field appearance="outline" class="w-input input-date align-items " >
                    <mat-label>Usuario</mat-label>
                    <input matInput [matAutocomplete]="auto" formControlName="UserId" [formControl]="formUser"
                        (click)="getFilteredUsers()">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let user of filteredUsers | async" (click)="selectedUser(user)"
                            [value]="user.Name">
                            <span>{{user.Name}}</span>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field class="align-items " appearance="outline" >
                    <mat-label>Rango de fechas</mat-label>
                    <mat-date-range-input [formGroup]="datesControl" [rangePicker]="campaignOnePicker">
                        <input matStartDate placeholder="Fecha Inicial" formControlName="initDate">
                        <input matEndDate placeholder="Fecha Final" formControlName="endDate">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
                </mat-form-field>

                <button mat-stroked-button color="primary" (click)="GetLogs()" class="mb-1" style="margin: 10px;">
                    <mat-icon>search</mat-icon>Buscar</button>
            </div>

        </form>
    </div>
    <mat-card class="mat-elevation-z0">
        <div id="table-container" class="mat-elevation-z0">
            <cl-table [Id]="tableId" [MappedColumns]="displayedColumns" [Records]="logs"
                [PageSizeOptions]="pageSizeOptions" [ItemsPeerPage]="itemsPeerPage" [RecordsCount]="recordsCount"
                [HasPaginator]="hasPaginator" [ShouldPaginateRequest]="shouldPaginateRequest"
                [ScrollHeight]="scrollHeight">
            </cl-table>
        </div>
    </mat-card>

</div>