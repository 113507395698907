import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppMaterialModule } from '../../../app-material.module';
import { ServerComponent } from './server.component';
import { ModalServerComponent } from './modal-server/modal-server.component';
import { LinkerService } from '@clavisco/linker';
import { TableModule } from '@clavisco/table';


@NgModule({
  declarations: [
    ServerComponent,
    ModalServerComponent
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    TableModule
  ],
  exports: [
    ServerComponent
  ],
  providers: [
    { provide: 'LinkerService', useClass: LinkerService }
  ],
})
export class ServerModule { }
