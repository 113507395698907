import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { ToastService, AngularToastifyModule } from 'angular-toastify';
import { AppMaterialModule } from './app-material.module';
import { HomeModule } from './pages/home/home.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SessionService } from './services/session.service';
import { StorageService } from './services/storage.service';
import { PermissionsService } from './services/permissions.service';
import { DevelopersService } from './services/developers.service';
import { AuthService } from './services/auth.service';
import { SharedModule } from './shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TokenInterceptor } from './theme/utils/token.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { LoginModule } from '@clavisco/login';
import { OverlayModule, OverlayService } from '@clavisco/overlay';
import { AlertsService } from '@clavisco/alerts';
import { LinkerService } from '@clavisco/linker';
import { TableModule } from '@clavisco/table';
import { MatDialog } from '@angular/material/dialog';
import {RequestInterceptor} from "./theme/utils/request-interceptor";
import {ErrorInterceptor} from "./theme/utils/error-interceptor";
import {RECAPTCHA_V3_SITE_KEY} from "ng-recaptcha";
import {environment} from "../environments/environment";
import {MatBadgeModule} from "@angular/material/badge";
import {Miscellaneous} from "@clavisco/core";
import PagedRequestInterceptor = Miscellaneous.Interceptors.PagedRequestInterceptor;
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppMaterialModule,
    AngularToastifyModule,
    HomeModule,
    SharedModule,
    FontAwesomeModule,
    LoginModule,
    OverlayModule,
    TableModule,
    MatBadgeModule
  ],
  providers: [
    AuthService,
    SessionService,
    StorageService,
    PermissionsService,
    DevelopersService,
    MatDialog,
    ToastService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PagedRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    { provide: 'OverlayService', useClass: OverlayService },
    {
      provide: 'AlertsService',
      useClass: AlertsService,
    },
    {
      provide: 'LinkerService',
      useClass: LinkerService
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recatpchaSiteKey
    }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
