import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleComponent } from './role.component';
import { ModalAddRoleComponent } from './modal-add-role/modal-add-role.component';
import { AppMaterialModule } from 'src/app/app-material.module';
import {SharedModule} from "../../../../shared/shared.module";



@NgModule({
  declarations: [
    RoleComponent,
    ModalAddRoleComponent
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    SharedModule,
  ],
  exports: [
    RoleComponent
  ]
})
export class RoleModule { }
