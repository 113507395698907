import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastService } from 'angular-toastify';
import { IDeveloper } from 'src/app/models/interfaces/i-developers';
import { PermissionCode } from 'src/app/common/constants';
import { DevelopersService } from 'src/app/services/developers.service';
import { SessionService } from 'src/app/services/session.service';
import { ModalAddDevelopersComponent } from './modal-add-developers/modal-add-developers.component';


@Component({
  selector: 'app-developers',
  templateUrl: './developers.component.html',
  styleUrls: ['./developers.component.sass']
})
export class DevelopersComponent implements OnInit {
  displayedColumns: string[] = ['Id', 'Email', 'Name','Active','action'];
  developers: IDeveloper[] = [

  ]
  dataSource: MatTableDataSource<IDeveloper>;
  filter!: FormControl
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private dialog:           MatDialog,
    private _developersService:  DevelopersService,
    private toastService:     ToastService,
    private sessionService:   SessionService,
  ) {this.dataSource = new MatTableDataSource(); }

  ngOnInit(): void {
    this.filter = new FormControl('')
    this.GetDevelopers();
  }

  GetDevelopers(): void{

      this._developersService.GetDevelopers().subscribe({
        next : callback => {
          if(callback.Result){
            this.developers = callback.Data;
            this.dataSource = new MatTableDataSource(this.developers);
            this.dataSource.paginator = this.paginator;
          } else {
            this.toastService.error(callback.Error.Message)
          }
        },
        error: error => console.log(error)
      })

  }

  OpenDevelopersAddDialog(user?:IDeveloper): void {

      const dialogRef: MatDialogRef<ModalAddDevelopersComponent> = this.dialog.open(ModalAddDevelopersComponent, {
        width: '650px',
        maxHeight: `85vh`,
        data: user
      });
      dialogRef.afterClosed().subscribe( result => {
        this.GetDevelopers()
      })

  }

  // OpenDevelopersEditDialog( user:IDeveloper): void {
  //   const dialogRef: MatDialogRef<ModalEditDevelopersComponent> = this.dialog.open(ModalEditDevelopersComponent, {
  //     width: '650px',
  //     data:
  //       user

  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.GetDevelopers();
  //   });
  // }

  Filter(): void {

      this._developersService.GetDevelopersByEmailOrName(this.filter.value).subscribe({
        next: callback => {
          if(callback.Result){
            if(!callback.Data.length) this.toastService.info(`No existe registro que coincida con el criterio ingresado`)
            this.dataSource = new MatTableDataSource(callback.Data)
            this.dataSource.paginator = this.paginator;
          } else {
            this.toastService.error(`${callback.Error.Message}`)
          }
        },
        error: error => {
          this.toastService.error(`${(error as HttpErrorResponse).error["error_description"]}`)
        }
      })

  }

}
