import { IMenuNode } from "@clavisco/menu/public-api";

const menu: IMenuNode[] = [
  {
    Key: 'home',
    Description: 'Inicio',
    Icon: 'home',
    Visible: true,
    Nodes: [],
    Route: 'index',
  },
  {
    Key: 'files',
    Description: 'Descargas',
    Icon: 'cloud_download',
    Visible: true,
    Nodes: [],
    Route: 'index/files',
  },
  {
    Key: 'reports',
    Description: 'Reportes',
    Icon: 'content_paste ',
    Visible: true,
    Nodes: [],
    Route: 'index/reports',
  },
  {
    Key: 'documents',
    Description: 'Documentos',
    Icon: 'folder',
    Visible: false,
    Nodes: [],
    Route: 'index/documents',
  },
  {
    Key: 'project',
    Description: 'Proyectos',
    Icon: 'folder',
    Visible: false,
    Nodes: [
      {
        Key: 'projects',
        Description: 'Proyectos',
        Icon: 'folder',
        Visible: true,
        Nodes: [],
        Route: 'index/proyects',
      },
      {
        Key: 'projDeliverable',
        Description: 'Entregas Proyectos',
        Icon: 'fact_check',
        Visible: false,
        Nodes: [],
        Route: 'index/projectDeliveries',
      },
    ],
    Route: '',
  },
  {
    Key: 'applications',
    Description: 'Aplicaciones',
    Icon: 'apps',
    Visible: true,
    Nodes: [],
    Route: 'index/applications',
  },
  {
    Key: 'newSapObject',
    Description: "Objetos definidos por el usuario",
    Icon: 'folder',
    Visible: true,
    Nodes: [
      {
        Key: 'CreateUDOs',
        Description: 'Creacion',
        Icon: 'create_new_folder',
        Visible: true,
        Nodes: [],
        Route: 'index/userDefinedObjects'
      },
      {
        Key: 'Replicate',
        Description: 'Replicar',
        Icon: 'reply_all',
        Visible: true,
        Nodes: [],
        Route: 'index/replicate'
      },
      {
        Key: 'history',
        Description: 'Historial',
        Icon: 'fact_check',
        Visible: true,
        Nodes: [],
        Route: 'index/historyUserDefinedObjects'
      }
    ],
    Route: ''
  },
  {
    Key: 'wservices',
    Description: 'Consultas',
    Icon: 'assignmentInd',
    Visible: true,
    Nodes: [
      {
        Key: 'services',
        Description: 'Servicios',
        Icon: 'storage',
        Visible: true,
        Nodes: [],
        Route: 'index/services',
      },
      {
        Key: 'servers',
        Description: 'Servidores',
        Icon: 'storage',
        Visible: true,
        Nodes: [],
        Route: 'index/servers',
      },
      {
        Key: 'logs',
        Description: 'Logs',
        Icon: 'description',
        Visible: true,
        Nodes: [],
        Route: 'index/logs',
      },
    ],
    Route: '',
  },
  {
    Key: 'settings',
    Description: 'Administración',
    Icon: 'settings',
    Visible: true,
    Nodes: [
      {
        Key: 'users',
        Description: 'Usuarios',
        Icon: 'person',
        Visible: true,
        Nodes: [],
        Route: 'index/users',
      },
      {
        Key: 'permissions',
        Description: 'Permisos',
        Icon: 'security',
        Visible: true,
        Nodes: [],
        Route: 'index/permissions',
      },
      {
        Key: 'checklist',
        Description: 'Datos de Liberaciones',
        Icon: 'description',
        Visible: false,
        Nodes: [],
        Route: 'index/checklist',
      },
      {
        Key: 'projectStatus',
        Description: 'Estados Proyecto',
        Icon: 'dns',
        Visible: true,
        Nodes: [],
        Route: 'index/projectStatus',
      },
      {
        Key: 'setting',
        Description: 'Configuraciones',
        Icon: 'build',
        Nodes: [],
        Visible: true,
        Route: 'index/setting'
      },
    ],
    Route: '',
  },

];


export default menu;
