export interface IError {
  Code: number;
  Message: string;
}

export interface IBaseResponse {
  Result: boolean;
  Error: IError;
}

export interface IResponse<Type> extends IBaseResponse {
  Data: Type;
}
