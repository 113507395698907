import {Component, OnInit, Inject, ViewChild, OnDestroy} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService, CLToastType } from '@clavisco/alerts';
import { CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown } from '@clavisco/linker';
import { MapDisplayColumns, MappedColumns } from '@clavisco/table';
import { Subscription} from 'rxjs';
import { DataBaseByServerModel } from 'src/app/models/interfaces/i-database';
import { DatabaseService } from 'src/app/services/database.service';

@Component({
  selector: 'app-db-server-detail',
  templateUrl: './db-server-detail.component.html',
  styleUrls: ['./db-server-detail.component.sass']
})
export class DbServerDetailComponent implements OnInit, OnDestroy {
  allSuscription: Subscription= new Subscription()
  dataBases: DataBaseByServerModel[];

  tableId = 'tableID'
  displayedColumns!: MappedColumns;
  scrollHeight: string = '300px';
  pageSizeOptions: number[] = [5, 10];
  itemsPeerPage: number = 5;
  recordsCount: number = 0;
  hasPaginator: boolean = true;
  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DbServerDetailComponent>,
    private databaseService: DatabaseService,
    @Inject('LinkerService') private linkerService: LinkerService,
    private alertService: AlertsService
  ) {
    this.displayedColumns = MapDisplayColumns<DataBaseByServerModel,null>({
      dataSource: [],
      renameColumns: {
        NameDB: 'Nombre',
        ProjectName: 'Proyecto',
      },
      ignoreColumns: ['MaxQtyRowsFetch', 'Id', 'Server']
    });
  }

  ngOnDestroy(): void{
    this.allSuscription.unsubscribe();
  }

  ngOnInit(): void {

    Register<CL_CHANNEL>(this.tableId, CL_CHANNEL.INPUT, this.GetElementsRecords, this.callbacks);
    this.allSuscription.add(this.linkerService.Flow()
      ?.pipe(StepDown<CL_CHANNEL>(this.callbacks))
      .subscribe({
        next: (callback) =>
          Run(callback.Target, callback, this.callbacks.Callbacks),
        error: (error) => console.log(`mi error`, error),
      }));

    this.GetDatabaseByServer();
  }

  GetElementsRecords = (_event: ICLEvent): void => {
    if (_event) {
      const INCOMMING_TABLE_STATE = JSON.parse(_event.Data);
      this.itemsPeerPage = INCOMMING_TABLE_STATE.ItemsPeerPage;
      this.recordsCount = INCOMMING_TABLE_STATE.CurrentPage;
      this.GetDatabaseByServer()
    }
  };


  GetDatabaseByServer(): void {
    this.databaseService.GetDatabaseByServer(
      this.recordsCount,
      this.itemsPeerPage,
      this.data.id
    ).subscribe(
      {
        next: response => {
          if (response.Result) {
            if (response.Data.length > 0) {
              this.dataBases = response.Data
              this.recordsCount = response.Data[0].MaxQtyRowsFetch
            } else {
              this.recordsCount = 0
              this.alertService.Toast({ message: `No tiene bases de datos asociadas`, type: CLToastType.INFO })
            }
          }
          this.loadTableData();
        }, error: error => {
          console.log(error)
        }
      }
    )
  }

  loadTableData(): void {
    const CURRENT_TABLE_STATE = {
      CurrentPage: 1,
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.dataBases,
      RecordsCount: this.recordsCount
    };
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.tableId,
      Data: JSON.stringify(CURRENT_TABLE_STATE)
    } as ICLEvent);
  }

  CloseDialog(): void {
    this.dialogRef.close();
  }

}
