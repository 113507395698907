import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MapDisplayColumns, MappedColumns} from "@clavisco/table";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SessionService} from "../../../services/session.service";
import {AlertsService} from "@clavisco/alerts";
import {OverlayService} from "@clavisco/overlay";
import {CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown} from "@clavisco/linker";
import {FormControl} from "@angular/forms";
import {ISetting} from "../../../models/i-appsetting";
import {SettingService} from "../../../services/setting.service";
import {Subscription, tap} from "rxjs";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {ICLTableButton} from "@clavisco/table/lib/table.space";
import {SettingDialogComponent} from "./setting-dialog/setting-dialog.component";

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.sass']
})
export class SettingComponent implements OnInit, OnDestroy {

  // Configuracion de Tabla
  allSuscription: Subscription= new Subscription()
  tableId: string = 'AppSettingIdTable'
  displayedColumns!: MappedColumns
  settings: ISetting[] = []
  readonly pageSizeOptions: number[] = [5, 7, 10];
  itemsPeerPage: number = this.pageSizeOptions[0]
  recordsCount: number = 0;
  hasPaginator: boolean = false;
  scrollHeight: string = '400px'
  readonly columnsToIgnore: string[] = ["UpdatedBy", "UpdatedDate", "View", "Id", "Json", "CreatedDate"]
  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };
  Buttons: ICLTableButton[] = [{
    Title: 'Editar',
    Action: Structures.Enums.CL_ACTIONS.UPDATE,
    Icon: `edit`,
    Color: `primary`
  }]
  // Fin de Configuracion

  filter!: FormControl

  constructor(
    private dialog: MatDialog,
    private settingServices: SettingService,
    private sessionService: SessionService,
    private alertService: AlertsService,
    private clBlockUI: OverlayService,
    @Inject('LinkerService') private linkerService: LinkerService
  ) {
    this.OnLoadTable()
  }

  ngOnInit(): void {
    try{
      Register<CL_CHANNEL>(this.tableId, CL_CHANNEL.INPUT, this.GetElementsRecords, this.callbacks);
      Register<CL_CHANNEL>(this.tableId, CL_CHANNEL.OUTPUT, this.SelectedSetting, this.callbacks);
      this.allSuscription.add(this.linkerService.Flow()
        ?.pipe(StepDown<CL_CHANNEL>(this.callbacks))
        .subscribe({
          next: (callback) =>
            Run(callback.Target, callback, this.callbacks.Callbacks),
          error: (error) => console.log(`mi error`, error),
        }));
    }
    catch (e) {
      console.log(e)
    }
    this.OnLoad()
  }

  ngOnDestroy(): void{
    this.allSuscription.unsubscribe();
  }

  OnLoad(): void {
    this.settingServices.Get().pipe(
      tap((callback:ICLResponse<ISetting[]>)=>{
        if(callback.Data){
          this.settings = callback.Data.map(setting => {
            setting.IsActive = setting.IsActive? "Activo" : "Inactivo"
            return setting
          })

          this.recordsCount = callback.Data.length
          this.loadTableData()
        }
      })
    ).subscribe()
  }

  OnLoadTable(): void {
    this.displayedColumns = MapDisplayColumns<ISetting, null>({
      dataSource: this.settings,
      renameColumns:{
        "CreatedBy": "Creado Por",
        "Code": "Codigo",
        "Description": "Descripcion",
        "IsActive": "Activo"
      },
      ignoreColumns: ["UpdateDate", ...this.columnsToIgnore]
    })
  }

  private loadTableData(): void{
    const CURRENT_TABLE_STATE = {
      CurrentPage: 1,
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.settings,
      RecordsCount: this.recordsCount
    };
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.tableId,
      Data: JSON.stringify(CURRENT_TABLE_STATE)
    } as ICLEvent);
  }

  SelectedSetting = (_event: ICLEvent) => {
    const event = JSON.parse(_event.Data)
    const setting: ISetting = JSON.parse(event.Data)
    setting.IsActive = setting.IsActive === 'Activo'
    this.OpenSettingDialog(setting)
  }

  GetElementsRecords = (_event: ICLEvent): void => {
    if (_event) {
      const INCOMMING_TABLE_STATE = JSON.parse(_event.Data);
      this.OnLoad();
    }
  };

  OpenSettingDialog(setting?: ISetting): void{
    const dialogRef: MatDialogRef<SettingDialogComponent> = this.dialog.open(
      SettingDialogComponent,{
        width: '850px',
        maxHeight: `85vh`,
        data: setting
      }
    )
    dialogRef.afterClosed().pipe(
      tap(
        () => this.OnLoad()
      )
    ).subscribe()
  }

  protected readonly confirm = confirm;
}
