import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AlertsService, CLToastType } from '@clavisco/alerts';
import { CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown } from '@clavisco/linker';
import { OverlayService } from '@clavisco/overlay';
import { MapDisplayColumns, MappedColumns } from '@clavisco/table';
import {catchError, finalize, map, Observable, of, startWith, Subscription, tap} from 'rxjs';
import {IServersRecollectionHistories, ILogs} from 'src/app/models/interfaces/i-servers-recollection-histories';
import {IUsers} from 'src/app/models/interfaces/i-users';
import { LogService } from 'src/app/services/log.service';
import { UserService } from 'src/app/services/user.service';
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {LogTypes} from "../../../../common/constants";

const today: Date = new Date();
const firstDayFilter: Date = new Date(new Date().setDate(today.getDate() - 6));

@Component({
  selector: 'app-logs-login',
  templateUrl: './logs-login.component.html',
  styleUrls: ['./logs-login.component.sass']
})
export class LogsLoginComponent implements OnInit, OnDestroy {
  allSuscription: Subscription= new Subscription()
  logs: ILogs[];
  StartPos: number = 0;
  RowsReturned: number = 10;
  tableId = 'tableLogsLogin'
  displayedColumns!: MappedColumns;
  scrollHeight: string = '490px';
  pageSizeOptions: number[] = [10, 20];
  itemsPeerPage: number = 10;
  recordsCount: number = 0;
  hasPaginator: boolean = true;
  shouldPaginateRequest: boolean = false;
  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };

  datesControl = new FormGroup({
    initDate: new FormControl(firstDayFilter),
    endDate: new FormControl(today),
  });

  formUser: FormControl = new FormControl('');
  filteredUsers!: Observable<IUsers[]>;
  users: IUsers[] = [];
  formFilter!: FormGroup;
  userSelected: string;

  constructor(
    private logsService: LogService,
    private userService: UserService, private alertService: AlertsService,
    private clBlockUI: OverlayService,
    @Inject('LinkerService') private linkerService: LinkerService
  ) {
    console.log(today)
    this.displayedColumns = MapDisplayColumns<ILogs, null>({
      dataSource: [],
      renameColumns: {
        Id: 'Id',
        CreatedBy: 'Usuario',
        CreatedDate: 'Fecha de Registro'
      },
      ignoreColumns: ['MaxQtyRowsFetch', 'Type', 'JsonObject', 'UserName', 'UpdateDate', 'UpdatedBy', 'IsActive']
    });
  }

  ngOnDestroy(): void{
    this.allSuscription.unsubscribe();
  }

  ngOnInit(): void {
    this.formFilter = new FormGroup({
      UserId: new FormControl(''),
      RangeDate: new FormControl('')
    });

    Register<CL_CHANNEL>(this.tableId, CL_CHANNEL.INPUT, this.GetElementsRecords, this.callbacks);
    this.allSuscription.add(this.linkerService.Flow()
      ?.pipe(StepDown<CL_CHANNEL>(this.callbacks))
      .subscribe({
        next: (callback) =>
          Run(callback.Target, callback, this.callbacks.Callbacks),
        error: (error) => console.log(`mi error`, error),
      }));

    this.GetUsers();
    this.getFilteredUsers();
    this.GetLogs();

  }

  GetElementsRecords = (_event: ICLEvent): void => {
    if (_event) {
      const INCOMMING_TABLE_STATE = JSON.parse(_event.Data);
      this.RowsReturned = INCOMMING_TABLE_STATE.ItemsPeerPage;
      this.StartPos = INCOMMING_TABLE_STATE.CurrentPage;
      this.GetLogs();
    }
  };

  getFilteredUsers() {
    this.filteredUsers = this.formUser.valueChanges.pipe(
      startWith(''),
      map((x: string | null) => (x ? this._filterUsers(x) : this.users.slice())),
    );
  }

  GetUsers(): void {
    this.userService.Get().pipe(finalize(() => {}),
      tap((callback: ICLResponse<IUsers[]>) => {
        this.users = callback.Data
      }),
      catchError((error: any) => {
        this.alertService.Toast({ message: error, type: CLToastType.ERROR });
        return [];
      })
      ).subscribe();
  }

  private _filterUsers(value: string): IUsers[] {
    const filterValue = value.toLowerCase();
    return this.users.filter(state => state.Name.toLowerCase().includes(filterValue));
  }

  GetLogs(): void {
    if (!this.datesControl.value.initDate || !this.datesControl.value.endDate) return this.alertService.Toast({ message: 'La fecha de inicio y de fecha final son obligatorias', type: CLToastType.WARNING })

    this.clBlockUI.OnGet('Cargando logs...')
    this.logsService.GetLogs(
      this.StartPos,
      this.RowsReturned,
      this.userSelected || '',
      this.datesControl.value.initDate!,
      this.datesControl.value.endDate!,
      LogTypes.LogIn
    ).pipe(finalize(() => { this.clBlockUI.Drop(); }),
      tap((response: ICLResponse<ILogs[]>)=>{
        if (response.Data) {
          this.logs = response.Data;
          this.logs.map(x => {
            x.UserName = this.getUserbyId(String(x.CreatedBy));
            x.CreatedDate = this.getFormatDate(new Date(x.CreatedDate))
          })
          this.recordsCount = response.Data.length > 0 ? response.Data[0].MaxQtyRowsFetch : 0;
          this.loadTableData();
        }
      }),catchError((err) => {
        return [];
      })      ).subscribe();
  }

  getFormatDate(date: Date):string{
    const time = ` - ${date.toTimeString().split(('GMT'))[0]}`;
    return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${time}`
  }

  loadTableData(): void {
    const CURRENT_TABLE_STATE = {
      CurrentPage: 1,
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.logs,
      RecordsCount: this.recordsCount
    };
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.tableId,
      Data: JSON.stringify(CURRENT_TABLE_STATE)
    } as ICLEvent);
  }

  getUserbyId(email: string): string {
    return this.users.filter(x => x.Email == email).map(m => m.Name).toString();
  }

  selectedUser(user: IUsers) {
    this.userSelected = user.Email;
  }

}
