import { ComponentType, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal, ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({ selector: '[customTooltip]' })
export class CustomTooltipDirective {
  /** Contenido que se va a renderizar dentro del tooltip */
  @Input('customTooltip')
  tooltipContent!: TemplateRef<any> | ComponentType<any>;
  /** Overlay que simula ser un tooltip */
  private _overlayRef!: OverlayRef;
  private static openedOverlay?: OverlayRef
  private static mouseClickListener?: Subscription
  private static firstClick?: boolean = true

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
  ) { }
  ngOnInit(): void {
    if (this.tooltipContent) {
      const position = this.overlayPositionBuilder
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          {
            originX: 'start',
            originY: 'center',
            overlayX: 'center',
            overlayY: 'center',
            offsetX: 120,
            offsetY: 5,
          },
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
            offsetX: 0,
            offsetY: -8,
          }
        ]);
      this._overlayRef = this.overlay.create({
        positionStrategy: position,
        scrollStrategy: this.overlay.scrollStrategies.close(),
        panelClass: 'custom-tooltip',
      });
    }
  }

  @HostListener('click')
  private OnActionClick(): void {
    if (CustomTooltipDirective.openedOverlay == null) {
      this.Show(this._overlayRef)
      CustomTooltipDirective.openedOverlay = this._overlayRef
    } else {
      if (CustomTooltipDirective.openedOverlay == this._overlayRef) {
        this.Hide(CustomTooltipDirective.openedOverlay)
        CustomTooltipDirective.openedOverlay = undefined
      } else {
        this.Hide(CustomTooltipDirective.openedOverlay)
        this.Show(this._overlayRef)
        CustomTooltipDirective.openedOverlay = this._overlayRef
      }
    }
  }

  private Show(_OverlayRef: OverlayRef): void {
    if (_OverlayRef) {
      let containerPortal: TemplatePortal<any> | ComponentPortal<any>;
      if (this.tooltipContent instanceof TemplateRef) {
        containerPortal = new TemplatePortal(this.tooltipContent, this.viewContainerRef);
      }
      else {
        containerPortal = new ComponentPortal(this.tooltipContent, this.viewContainerRef);
      }
      _OverlayRef.attach(containerPortal);
      this.InitialListenerMouseClick()
    }
  }

  private Hide(_OverlayRef: OverlayRef): void {
    if (_OverlayRef) {
      _OverlayRef.detach();
      this.DestroyListenerMouseClick()
    }
  }

  private InitialListenerMouseClick(): void {
    if (CustomTooltipDirective.mouseClickListener == undefined) {
      CustomTooltipDirective.mouseClickListener = fromEvent(document.body, 'click').subscribe({
        next: callback => {
          if (CustomTooltipDirective.firstClick) {
            callback.preventDefault
            CustomTooltipDirective.firstClick = false
          } else {
            //Condicion cierre con click automatico
            const position = this._overlayRef.overlayElement.getBoundingClientRect()
            if ((callback as PointerEvent).x < position.left || (callback as PointerEvent).x > position.right || ((callback as PointerEvent).y - window.scrollY) < position.top || ((callback as PointerEvent).y - window.scrollY) > position.bottom) {
              this.Hide(CustomTooltipDirective.openedOverlay!)
              CustomTooltipDirective.openedOverlay = undefined
            }
          }
        }
      })
    }
  }

  private DestroyListenerMouseClick(): void {
    CustomTooltipDirective.firstClick = true
    CustomTooltipDirective.mouseClickListener?.unsubscribe()
    CustomTooltipDirective.mouseClickListener = undefined;
  }
}
