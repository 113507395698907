<div>
    <div class="align-right"><mat-icon class="icon-close" (click)="CloseDialog()">close</mat-icon></div>
    <div class="content">
        <form [formGroup]="formHeader" (ngSubmit)="Save()">
            <div class="input-field">
                <mat-form-field appearance="outline">
                    <mat-label>Nombre</mat-label>
                    <input matInput placeholder="Nombre" formControlName="DisplayName" />
                </mat-form-field>
            </div>

            <div class="input-field">
                <mat-form-field appearance="outline">
                    <mat-label>Descripción</mat-label>
                    <input matInput placeholder="Descripción" formControlName="Description" />
                </mat-form-field>
            </div>

            <div class="input-field">
                <mat-form-field appearance="outline">
                    <mat-label>Nombre de Parámetro Crystal</mat-label>
                    <input matInput placeholder="Nombre de Parámetro Crystal" formControlName="CrystalParamName" />
                </mat-form-field>
            </div>

            <div class="input-field">
                <mat-checkbox formControlName="IsActive">Activar</mat-checkbox>
            </div>

            <div class="actionsBtn">
                <button mat-stroked-button color="primary" type="submit"><mat-icon>save</mat-icon> Guardar </button>
            </div>

        </form>
    </div>
</div>
