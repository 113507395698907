import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from 'angular-toastify';
import { Observable } from 'rxjs';
import { IProject } from 'src/app/models/interfaces/i-proyects';
import { IRepository, RepositoryType } from 'src/app/models/interfaces/i-repository';
import { ProyectService } from 'src/app/services/proyect.service';

@Component({
  selector: 'app-modal-add-repository',
  templateUrl: './modal-edit-repository.component.html',
  styleUrls: ['./modal-edit-repository.component.sass'],
})
export class ModalEditRepositoryComponent implements OnInit {

  uiAPIChecked: boolean = false
  uiUIChecked: boolean = true
  separatorKeysCodes: number[] = [ENTER, COMMA];
  projects!: IProject[]
  filteredProjects!: Observable<IProject[]>
  projectsControl!: FormControl
  types!: RepositoryType

  constructor(
    @Inject(MAT_DIALOG_DATA) public repository: IRepository,
    private dialogRef: MatDialogRef<ModalEditRepositoryComponent>,
    private toastService: ToastService,
    private _proyectService: ProyectService
  ) {}

  ngOnInit(): void {
  }

  ValidateType(): string | boolean {
    if(!this.uiAPIChecked && !this.uiUIChecked) return false
    return (this.uiAPIChecked && this.uiUIChecked) ? 'UI & API' : this.uiUIChecked ? 'UI' : 'API'
  }

  SaveRepository(): void {
    this
  }

  Cancel(): void {
    this.dialogRef.close()
  }

  GetProyect(): void {
    // this._proyectService.GetProyect().subscribe({
    //     next : callback => {
    //       if(callback.Result){
    //
    //       } else {
    //         this.toastService.error(callback.Error.Message)
    //       }
    //     },
    //     error: error => console.log(error)
    //   })
  }
}
