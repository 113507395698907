<div class="users-header">
    <mat-form-field >
      <mat-label>Busqueda de Desarrollador</mat-label>
      <input matInput [formControl]="filter" placeholder="Busqueda de Desarrollador" (keyup)="Filter()">
    </mat-form-field>
    <!-- <mat-checkbox class="example-margin">Activo</mat-checkbox> -->
    <button class="btn-developer" mat-stroked-button color="primary" (click)="OpenDevelopersAddDialog()" ><mat-icon>add</mat-icon> Agregar </button>
  </div>
  <div class="developers-content">
      <table mat-table [dataSource]="dataSource" id="table_developers" class="mat-elevation-z8">
          <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef> # </th>
            <td mat-cell *matCellDef="let element"> {{element.Id}} </td>
          </ng-container>
          <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef> Nombre </th>
            <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
          </ng-container>
          <ng-container matColumnDef="Email">
            <th mat-header-cell *matHeaderCellDef> Correo </th>
            <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
          </ng-container>
          <ng-container matColumnDef="Active">
            <th mat-header-cell *matHeaderCellDef> Activo </th>
            <td mat-cell *matCellDef="let element">
              <ng-template [ngIf]="element.Active" [ngIfElse]="inactive">
                <mat-icon>check</mat-icon>
              </ng-template>
              <ng-template #inactive>
                <mat-icon>close</mat-icon>
              </ng-template>
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Editar</th>
            <td mat-cell *matCellDef="let element"> 
              <div class="actions-buttons">
                <button class="btn-developer" mat-icon-button color="primary" (click)="OpenDevelopersAddDialog(element)">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                   showFirstLastButtons>
        </mat-paginator>
        
  </div>
