<form [formGroup]="formData">
  <div class="properties-Container" *ngFor="let control of formData.controls | keyvalue" >
    <div class="line-property">
      <button mat-icon-button class="delete" (click)="Remover(control.key, $event)"><mat-icon>delete</mat-icon></button>
      <mat-form-field appearance="outline">
        <input [id]="control.key" matInput [placeholder]="control.key" [value]="control.key"  />
        <button  matSuffix mat-icon-button (click)="UpdateKey(control.key)">
          <mat-icon class="delete">save</mat-icon>
        </button>
      </mat-form-field >
      <mat-form-field class="input-select" appearance="outline" disabled="true">
        <mat-select class="options" id="type-{{control.key}}" [value]="GetTypeOfValue(control.value.value)">
          <mat-option *ngFor="let type of valueTypes" [value]="type" (click)="SetDefaultValue(control.key, type, $event)">
            {{type}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <div *ngIf="GetTypeOfValue(control.value.value) != 'Object' else elseBlock">
          <input matInput [type]="GetTypeOfValue(control.value.value) == 'Object' ? 'text' : 'textarea'" placeholder="Valor" formControlName="{{control.key}}" (keyup)="EmitJsonString()"/>
        </div>
        <ng-template #elseBlock>
          <textarea matInput placeholder="Valor" formControlName="{{control.key}}" (click)="BindJson(control.key, control.value.value)" [jsonViewer]="GetTypeOfValue(control.value.value)" [templateContent]="templateJsonView"  (keyup)="EmitJsonString()"></textarea>
          <ng-template #templateJsonView>
            <pre #templatJsonContent class="template" contenteditable="true" (blur)="UpdateInfoFromTemplate()" >{{ShowTemplateJson(control.value.value)}}</pre>
          </ng-template>
        </ng-template>
      </mat-form-field>
    </div>
  </div>
  <div class="align-button">
    <button mat-flat-button color="primary" (click)="AgregarPropiedad()">Agregar<mat-icon>add</mat-icon></button>
  </div>
</form>
<mat-expansion-panel class="expansion-style" hideToggle>
  <mat-expansion-panel-header>
    Preview de JSON
  </mat-expansion-panel-header>
  <pre class="overflow">{{jsonFormatted}}</pre>
  <button mat-icon-button color="primary" (click)="CopyJson()">
    <mat-icon>file_copy</mat-icon>Copiar JSON
  </button>
</mat-expansion-panel>
