import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionComponent } from './permission.component';
import { ModalAddPermissionsComponent } from './modal-add-permissions/modal-add-permissions.component';
import { AppMaterialModule } from 'src/app/app-material.module';
import { LinkerService } from '@clavisco/linker';
import { TableModule } from '@clavisco/table';



@NgModule({
  declarations: [
    PermissionComponent,
    ModalAddPermissionsComponent,
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    TableModule
  ],
  exports: [
    PermissionComponent
  ],
  providers: [
    { provide: 'LinkerService', useClass: LinkerService }
  ],
})
export class PermissionModule { }
