import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-physical-address-detail',
  templateUrl: './physical-address-detail.component.html',
  styleUrls: ['./physical-address-detail.component.sass']
})
export class PhysicalAddressDetailComponent {


  placeholder: string = this.data.type == 'Password' ? 'Contraseña' :
                        this.data.type == 'PhysicalAddress' ? 'Dirección física' : 
                        this.data.type == 'Description' ? 'Descripción' : 'Ruta de logs';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PhysicalAddressDetailComponent>,

  ) { }

  CloseDialog(): void {
    this.dialogRef.close();
  }

}
