import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {AlertsService, CLToastType, ModalService} from "@clavisco/alerts";
import {Structures} from "@clavisco/core";
import {tap} from "rxjs";

@Component({
  selector: 'json-viewer',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['./json-viewer.component.sass']
})
export class JsonViewerComponent implements OnInit {

  valueTypes: string[] = ['String', 'Number', 'Boolean', 'Object']
  json: object = {}
  properties: string[] = []
  formData!: FormGroup
  lastKeyUpdated: Map<string, string> = new Map<string, string>()
  jsonFormatted: string = ''
  jsonTemplateAux: string = ''
  jsonActual: string = 'test'
  jsonBind: string | null = null
  @ViewChild('templatJsonContent') jsonContent: ElementRef

  @Input() jsonString: string = ""
  @Output() JsonStringModel: EventEmitter<string> = new EventEmitter<string>()
  constructor(
    private alertService: AlertsService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.LoadInitData()
  }

  LoadInitData(): void {
    this.json = JSON.parse(this.jsonString)??{}
    this.properties = Object.keys(this.json)
    this.formData = new FormGroup({});
    const obj: object = {}
    this.properties.map(prop => {
      const value = Object.getOwnPropertyDescriptor(this.json, prop)!.value
      Object.defineProperty(obj, prop, {
        value: value,
        writable: true,
        enumerable: true,
        configurable: true
      })
      this.formData.addControl(prop, new FormControl(typeof value == 'object' ? JSON.stringify(value): value,) )
    })
    this.jsonFormatted = JSON.stringify(obj, null, 4)
  }

  AgregarPropiedad(consecutivo: number = 0): void {
    if(!this.formData.controls[`Property-${consecutivo}`]){
      this.formData.addControl(`Property-${consecutivo}`, new FormControl(''))
      this.EmitJsonString()
    } else {
      this.AgregarPropiedad(consecutivo+=1)
    }
  }

  UpdateKey(oldValue: string): void {
    const value:string = this.formData.controls[oldValue].value
    const newVal: string = (<HTMLInputElement>document.getElementById(oldValue)!).value??''
    if(newVal.length == 0) {
      (<HTMLInputElement>document.getElementById(oldValue)!).value = oldValue
      this.alertService.Toast({message: `Campo no puede ir vacio`})
      return
    }
    if(oldValue != newVal){
      this.formData.removeControl(oldValue)
      this.formData.addControl(newVal, new FormControl(value))
      if(this.lastKeyUpdated.size!= 0) this.lastKeyUpdated.clear()
      this.lastKeyUpdated.set(newVal, oldValue)
      this.EmitJsonString()
    }
  }

  GetTypeOfValue(value: unknown): string{
    let result: string  = ''
    switch (typeof value) {
      case 'string':
          result = this.valueTypes[(value as string).startsWith('{') && (value as string).endsWith('}') ||  (value as string).startsWith('[') && (value as string).endsWith(']')? 3 :
            /^[0-9]+$/.test(value as string)  ? 1 :
              ['false', 'true'].includes(value as string) ? 2 : 0]
        break;
      case 'number':
        result = this.valueTypes[1]
        break;
      case 'boolean':
        result = this.valueTypes[2]
        break;
      case 'object':
        result = this.valueTypes[3]
        break;
      default:
        result = ''
    }
    return result
  }

  Remover(value: string, event: any): void {
    if(event.pointerId === 1){
      this.modalService.Open({
        title: "Remover Propiedad",
        subtitle: `Deseas remover la propiedad "${value}"`,
        disableClose: true,
        options: {
          ConfirmButton: {
            Action: Structures.Enums.CL_ACTIONS.CONTINUE,
            Color: 'primary',
            Title: 'Continuar', Data: '', Icon: 'done'
            },
          CancelButton: {
            Action: Structures.Enums.CL_ACTIONS.CANCEL,
            Color: 'warn',
            Icon: 'close',
            Title: 'Cancelar',
            Data: ''
          },
          YesNoQuestion: true }
        }).pipe(tap(
      (condition: boolean)=> {
          if(condition){
            this.formData.removeControl(value)
            this.EmitJsonString()
          }
        }
      )).subscribe()

    }
  }

  SetDefaultValue(key: string, type: string, event: any): void {
    switch (type){
      case this.valueTypes[0]:
        this.formData.controls[key].setValue('')
        break
      case this.valueTypes[1]:
        this.formData.controls[key].setValue(0)
        break
      case this.valueTypes[2]:
        this.formData.controls[key].setValue(true)
        break
      case this.valueTypes[3]:
        this.formData.controls[key].setValue("{}")
        break
      default:
        this.formData.controls[key].setValue('')
    }
  }
  SetTypeToValue(value: string, key: string): unknown {
    const type: string = (<HTMLInputElement>document.getElementById(`type-${key}`))?.innerText??''
    switch (type){
      case this.valueTypes[0]:
          return value.toString()
      case this.valueTypes[1]:
          return Number(value)??0
      case this.valueTypes[2]:
          return value === 'true' || value === '1'
      case this.valueTypes[3]:{
        if(this.IsValidJson(value)) return JSON.parse(value)
        return value
      }
      default:
        return ''
    }
  }

  IsValidJson(value: string): boolean {
    try{
      JSON.parse(value)
    }
    catch (e) {
      return false;
    }
    return true
  }

  EmitJsonString(): void {
    try{
      const object: Object = {}
      Object.keys(this.formData.controls).forEach((key:string) => {
          Object.defineProperty(object, key, {
            value: this.SetTypeToValue(this.formData.controls[key].value, this.lastKeyUpdated.get(key)??key,),
            writable: true,
            enumerable: true,
            configurable: true
          })
      })
      this.jsonFormatted = JSON.stringify(object, null, 4)
      this.JsonStringModel.emit(JSON.stringify(object))
    } catch (e) {
      if(e instanceof SyntaxError){
        this.alertService.Toast({message: (e as SyntaxError).message})
      }
      else {
        this.alertService.Toast({message: 'Hubo un error al tratar de actualizar JSON'})
      }
    }
  }

  ShowTemplateJson(jsonString: string): any {
    try{
      if(this.IsValidJson(jsonString)){
        return this.jsonTemplateAux = JSON.stringify(JSON.parse(jsonString), null, 4)
      }
      else {
        const obj: object = JSON.parse(this.jsonTemplateAux)
        Object.defineProperty(obj, 'Error', {
          value: 'Complete correctamente la propiedad',
          writable: true,
          enumerable: true,
          configurable: true
        })
        return JSON.stringify(obj, null, 4)
      }
    } catch(e){
      return JSON.stringify({
        "Error": e
      }, null, 4)
    }
  }
  CopyJson(): void {
    navigator.clipboard.writeText(this.jsonFormatted)
  }
  BindJson(formControlName: string, content: string): void {
    this.jsonBind = formControlName
    this.jsonActual = this.ShowTemplateJson(content)
  }

  UpdateInfoFromTemplate():void {
    this.formData.controls[this.jsonBind!].setValue(this.ShowTemplateJson(this.jsonContent.nativeElement.innerText))
    this.EmitJsonString()
    this.alertService.Toast({message: `El valor de la propiedad ${this.jsonBind} ha sido actualizada`, type: CLToastType.INFO})
  }
}
