<div class="align-right"><mat-icon class="icon-close" (click)="CloseDialog()">close</mat-icon></div>
<h3 mat-dialog-title>{{data.siteName}}</h3>
<div mat-dialog-content id="div-main">
    <mat-form-field>
        <mat-label>Web.config</mat-label>
        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" [value]="webConfig"
            cdkAutosizeMinRows="1" cdkAutosizeMaxRows="20" readonly="true"></textarea>
    </mat-form-field>
    <br>
    <button class="btn-developer" mat-stroked-button color="primary"
        (click)="DownloadFile()"><mat-icon>download</mat-icon>Descargar</button>
</div>