<div class="container">
  <div *ngIf="!filters" class="mb-1 mat-elevation-z0">
    <div class="filter-container flex">

      <mat-form-field class="filter-input w-22" appearance="outline" >
        <input type="text" matInput placeholder="Nombre" [(ngModel)]="this.filter.Name" (keydown.enter)="filterData()">
      </mat-form-field>

      <mat-form-field appearance="outline" class="filter-input w-22 ml-2">
        <mat-label>
          Servidor
        </mat-label>
        <input matInput [matAutocomplete]="auto" [formControl]="selectControl" (click)="getFilteredServers()" (keydown.enter)="filterData()">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let server of filteredServer | async" (click)="selectedServer(server)"
            [value]="server.Name">
            <span>{{server.Name}}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="filter-input w-22 ml-2" appearance="outline" >
        <mat-label>
          Ambiente
        </mat-label>
        <mat-select [(value)]="this.filter.Environment" (selectionChange)="filterData()">
          <mat-option value="">Todos</mat-option>
          <mat-option value="Produccion">Producción</mat-option>
          <mat-option value="Pruebas">Pruebas</mat-option>
          <mat-option value="Desarrollo">Desarrollo</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="filter-input w-22 ml-2" appearance="outline">
        <mat-label>
          Estado del Servicio
        </mat-label>
        <mat-select [(value)]="this.filter.Status" (selectionChange)="filterData()">
          <mat-option value="">Todos</mat-option>
          <mat-option value="Running">Running</mat-option>
          <mat-option value="Started">Started</mat-option>
          <mat-option value="Stopped">Stopped</mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-stroked-button color="primary" (click)="filterData()" class="btn-search w-10 search-button ml-2 ">
        <mat-icon>search</mat-icon>Buscar</button>
    </div>
  </div>

  <mat-card class="mat-elevation-z0">
    <mat-tab-group (selectedTabChange)="tabActive($event)" [selectedIndex]="selectedIndex">
      <mat-tab label="Tareas Programadas">
        <cl-table
          [Id]="tableTasksId"
          [MappedColumns]="displayedColumnsTasks"
          [Records]="tasks"
          [PageSizeOptions]="pageSizeOptions"
          [ItemsPeerPage]="itemsPeerPage"
          [RecordsCount]="recordsCountTasks"
          [HasPaginator]="hasPaginator"
          [ScrollHeight]="scrollHeight"
          [ShouldPaginateRequest]="shouldPaginateRequest"
          [Buttons]="ButtonsTask">
        </cl-table>
      </mat-tab>

      <mat-tab label="Servicios Windows">
        <cl-table
          [Id]="tableWServicesId"
          [MappedColumns]="displayedColumnsWServices"
          [Records]="wservices"
          [PageSizeOptions]="pageSizeOptions"
          [ItemsPeerPage]="itemsPeerPage"
          [RecordsCount]="recordsCountWServices"
          [HasPaginator]="hasPaginator"
          [ScrollHeight]="scrollHeight"
          [ShouldPaginateRequest]="shouldPaginateRequest"
          [Buttons]="ButtonsWServices">
        </cl-table>
      </mat-tab>

      <mat-tab label="Pools">
        <cl-table
          [Id]="tablePoolsId"
          [MappedColumns]="displayedColumnsPools"
          [Records]="pools"
          [PageSizeOptions]="pageSizeOptions"
          [ItemsPeerPage]="itemsPeerPage"
          [RecordsCount]="recordsCountPools"
          [HasPaginator]="hasPaginator"
          [ScrollHeight]="scrollHeight"
          [ShouldPaginateRequest]="shouldPaginateRequest"
          [Buttons]="ButtonsPools">
        </cl-table>
      </mat-tab>

      <mat-tab label="Sites">
        <cl-table
          [Id]="tableSitesId"
          [MappedColumns]="displayedColumnsSites"
          [Records]="sites"
          [PageSizeOptions]="pageSizeOptions"
          [ItemsPeerPage]="itemsPeerPage"
          [RecordsCount]="recordsCountSites"
          [HasPaginator]="hasPaginator"
          [ScrollHeight]="scrollHeight"
          [ShouldPaginateRequest]="shouldPaginateRequest"
          [Buttons]="ButtonsSites">
        </cl-table>
      </mat-tab>

    </mat-tab-group>
  </mat-card>

</div>
