<div>
    <div class="align-right"><mat-icon class="icon-close" (click)="CloseDialog()">close</mat-icon></div>
    <div class="content">
        <form [formGroup]="formDetails" (ngSubmit)="Save()">
            <div class="input-field">
                <mat-form-field appearance="outline">
                    <mat-label>Nombre</mat-label>
                    <input matInput placeholder="Nombre" formControlName="DisplayName" />
                </mat-form-field>
            </div>

            <div class="input-field">
                <mat-form-field appearance="outline" class="w-input">
                    <mat-label>Seleccione un tipo</mat-label>
                    <mat-select formControlName="Type" required>
                        <mat-option *ngFor="let status of StatusList" [value]="status">{{status}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="input-field">
                <mat-form-field appearance="outline">
                    <mat-label>Descripción</mat-label>
                    <input matInput placeholder="Descripción" formControlName="Description" />
                </mat-form-field>
            </div>

            <div class="input-field">
                <mat-form-field appearance="outline">
                    <mat-label>Nombre de Parámetro Crystal</mat-label>
                    <input matInput placeholder="Nombre de Parámetro Crystal" formControlName="CrystalParamName" />
                </mat-form-field>
            </div>

            <div class="input-field">
                <mat-checkbox formControlName="IsRequired" style="margin-right: 20px;">Requerido</mat-checkbox>
                <mat-checkbox formControlName="IsActive">Activar</mat-checkbox>
            </div>

            <div class="actionsBtn">
                <button mat-stroked-button color="primary" type="submit"><mat-icon>save</mat-icon> Guardar </button>
            </div>

        </form>
    </div>
</div>
