<div class="align-right"><mat-icon class="icon-close" (click)="CloseDialog()">close</mat-icon></div>
<h3 mat-dialog-title>{{data.siteName}}</h3>
<div mat-dialog-content class="row">
    <mat-form-field class="mat-form-field"
        *ngIf="data.type == 'Password' || data.type == 'PhysicalAddress' || data.type == 'LogPath'; else elseBlock">
        <input matInput [placeholder]="placeholder" [value]="data.physicalAddress" readonly="true">
    </mat-form-field>
    <ng-template #elseBlock>
        <mat-form-field>
            <mat-label>Descripción</mat-label>
            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" [value]="data.physicalAddress"
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10" readonly="true"></textarea>
        </mat-form-field>
    </ng-template>
</div>