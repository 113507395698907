import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AlertsService, CLToastType} from "@clavisco/alerts";
import {ISetting} from "../../../../models/i-appsetting";
import {OverlayService} from "@clavisco/overlay";
import {SettingService} from "../../../../services/setting.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {tap} from "rxjs";
import {SessionService} from "../../../../services/session.service";

@Component({
  selector: 'app-setting-dialog',
  templateUrl: './setting-dialog.component.html',
  styleUrls: ['./setting-dialog.component.sass']
})
export class SettingDialogComponent implements OnInit {

  settingForm: FormGroup
  isUpdated: boolean = false
  json: string

  constructor(
    @Inject(MAT_DIALOG_DATA)public settingData: ISetting,
    private dialogRef: MatDialogRef<SettingDialogComponent>,
    private alertService: AlertsService,
    private clBlockUI: OverlayService,
    private settingService: SettingService,
    private sessionService: SessionService
  ) { }

  ngOnInit(): void {
    if(this.settingData) this.isUpdated = true
    this.OnLoadSettingData(this.settingData)
  }
  OnLoadSettingData(data?: ISetting): void{
    this.settingForm = new FormGroup(
      {
        Id: new FormControl(data?.Id??0),
        Code: new FormControl({value: data?.Code ?? '', disabled: this.isUpdated}, Validators.compose([Validators.required]), ),
        Json: new FormControl(data?.Json??'', Validators.compose([Validators.required])),
        Description: new FormControl(data?.Description??'', Validators.compose([Validators.required])),
        IsActive: new FormControl(data?.IsActive??false)
      }
    )
  }

  GetErrorMessageRequired(nameControl: string, text: string): string{
    if (this.settingForm.controls[nameControl].hasError('required')) {
      return text;
    }
    return `Control ${nameControl} con error`
  }

  RefreshJson(event: string): void {
    this.settingForm.controls['Json'].setValue(event??'{}')
  }

  OnSubmit(): void{
    if (!this.settingForm.valid) {
      return this.alertService.Toast({ message: 'Los datos son requeridos', type: CLToastType.ERROR })
    }
    const setting: ISetting = this.settingForm.value as ISetting
    if(this.isUpdated)setting.Code = this.settingData.Code
    setting.View = 'N/A'
    setting.CreatedBy = setting.UpdatedBy = this.sessionService.GetUserName()
    if(this.isUpdated){
      this.settingService.Patch(setting).pipe(
        tap(
          (callback)=>{
            if(callback.Data) {
              this.alertService.Toast({ message: 'Actualizado Correctamente', type: CLToastType.SUCCESS });
              this.CloseDialog()
            }
          }
        )
      ).subscribe()
    }else{
      this.settingService.Post(setting).pipe(
        tap(callback=> {
          if(callback.Data) {
            this.CloseDialog()
            this.alertService.Toast({ message: 'Creado Correctamente', type: CLToastType.SUCCESS });
          }
        })
      ).subscribe()
    }
  }

  CloseDialog(): void {
    this.dialogRef.close()
  }

}
