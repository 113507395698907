import {Directive, ElementRef, HostListener, Input, OnInit, TemplateRef, ViewContainerRef} from "@angular/core";
import {ComponentType, Overlay, OverlayPositionBuilder, OverlayRef} from "@angular/cdk/overlay";
import {ComponentPortal, TemplatePortal} from "@angular/cdk/portal";
import {fromEvent, Observable, Subscription} from "rxjs";

@Directive({selector: '[jsonViewer]'})
export class JsonViewerDirective implements OnInit{
  @Input('jsonViewer') type!: string;
  @Input('templateContent')template!: TemplateRef<any> | ComponentType<any>;
  private _overlayRef!: OverlayRef;
  private static openedOverlay?: OverlayRef
  private static mouseClickListener?: Subscription
  private static firstClick?: boolean = true
  private IsFocused: Boolean = false;
  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
  ) {

  }

  ngOnInit(): void{
    const position = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'start',
          originY: 'center',
          overlayX: 'center',
          overlayY: 'center',
          offsetX: 375,
          offsetY: 5,
        },
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetX: 0,
          offsetY: -8,
        }
      ]);
    this._overlayRef = this.overlay.create({
      positionStrategy: position,
      scrollStrategy: this.overlay.scrollStrategies.close(),
      panelClass: 'custom-tooltip',
    });
  }

  // @HostListener('keyup')
  // private OnkeyUpAction(): void {
  //   if(JsonViewerDirective.openedOverlay != this._overlayRef && this.type == 'Object'){
  //     JsonViewerDirective.openedOverlay = this._overlayRef
  //     this.Show(this._overlayRef)
  //     return
  //   }
  //   if(this.IsFocused && this.type == 'Object' )this.Hide(JsonViewerDirective.openedOverlay)
  // }

  // @HostListener('focus')
  // private OnFocusAction(): void {
  //   if(this.type == 'Object'){
  //     this.Show(this._overlayRef)
  //     JsonViewerDirective.openedOverlay = this._overlayRef
  //     this.IsFocused = true
  //     this.InitialListenerMouseClick()
  //   }
  // }

  // @HostListener('blur')
  // private OnBlurAction(): void {
  //   this.Hide(JsonViewerDirective.openedOverlay)
  //   this.IsFocused = false
  // }

  @HostListener('click')
  private OnClickAction(): void {
    if(JsonViewerDirective.openedOverlay == null){
      this.Show(this._overlayRef)
      JsonViewerDirective.openedOverlay = this._overlayRef
    } else {
      if(JsonViewerDirective.openedOverlay == this._overlayRef){
        this.Hide(JsonViewerDirective.openedOverlay)
        JsonViewerDirective.openedOverlay = undefined
      } else {
        this.Hide(JsonViewerDirective.openedOverlay)
        this.Show(this._overlayRef)
        JsonViewerDirective.openedOverlay = this._overlayRef
      }
    }
  }

  private Hide(_OverlayRef?: OverlayRef): void {
    if (_OverlayRef) {
      _OverlayRef.detach();
      this.DestroyListenerMouseClick()
    }
  }

  private Show(_OverlayRef: OverlayRef): void {
    if (_OverlayRef) {
      let containerPortal: TemplatePortal<any> | ComponentPortal<any>;
      if (this.template instanceof TemplateRef) {
        containerPortal = new TemplatePortal(this.template, this.viewContainerRef);
      }
      else {
        containerPortal = new ComponentPortal(this.template, this.viewContainerRef);
      }
      _OverlayRef.attach(containerPortal);
      this.InitialListenerMouseClick()
    }
  }
  private DestroyListenerMouseClick(): void{
    JsonViewerDirective.firstClick = true
    JsonViewerDirective.mouseClickListener?.unsubscribe()
    JsonViewerDirective.mouseClickListener=undefined;
  }
  private InitialListenerMouseClick(): void {
    if(JsonViewerDirective.mouseClickListener == undefined){
      JsonViewerDirective.mouseClickListener = fromEvent(document.body, 'click').subscribe({
        next: callback => {
          if(JsonViewerDirective.firstClick) {
            callback.preventDefault
            JsonViewerDirective.firstClick = false
          } else {
            //Condicion cierre con click automatico
            const position = this._overlayRef.overlayElement.getBoundingClientRect()
            if((callback as PointerEvent).x < position.left || (callback as PointerEvent).x > position.right || ((callback as PointerEvent).y - window.scrollY) < position.top || ((callback as PointerEvent).y - window.scrollY) > position.bottom ){
              this.Hide(JsonViewerDirective.openedOverlay!)
              JsonViewerDirective.openedOverlay = undefined
            }
          }
        }
      })
    }
  }



}



