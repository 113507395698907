<div class="add-developer-header">
  <h1>Agregar Desarrollador</h1>
</div>
<mat-divider></mat-divider>
<form [formGroup]="developerForm">
  <div class="add-developer-content">
    <mat-form-field class="texBox" appearance="outline">
      <mat-label>Email</mat-label>
      <input
        type="email"
        matInput
        placeholder="Correo Electronico"
        formControlName="Email"
      />
    </mat-form-field>
    <mat-form-field class="texBox" appearance="outline">
      <mat-label>Nombre del desarrollador </mat-label>
      <input
        matInput
        placeholder="Nombre del desarrollador"
        formControlName="Name"
      />
    </mat-form-field>
 
      <mat-checkbox class="example-margin" formControlName="Active">Activo</mat-checkbox>
   

 

  </div>
</form>
<mat-divider></mat-divider>
<div class="add-developer-footer">
  <button mat-stroked-button color="warn" (click)="CloseDialog()">
    <mat-icon>close</mat-icon> Cerrar</button
  >&nbsp;
  <button mat-stroked-button color="primary" (click)="onSubmit()">
    <mat-icon>save</mat-icon> Guardar
  </button>
</div>
