import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersComponent } from './users.component';

import { AppMaterialModule } from '../../../app-material.module';
import { ModalAddUserComponent } from './modal-add-user/modal-add-user.component';
import { AssingRoleComponent } from './assing-role/assing-role.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { LinkerService } from '@clavisco/linker';
import { TableModule } from '@clavisco/table';
import {FlexModule} from "@angular/flex-layout";

@NgModule({
  declarations: [
    UsersComponent,
    ModalAddUserComponent,
    AssingRoleComponent,
  ],
    imports: [
        CommonModule,
        AppMaterialModule,
        AppRoutingModule,
        TableModule,
        FlexModule
    ],
  exports: [
    UsersComponent
  ],
  providers: [
    { provide: 'LinkerService', useClass: LinkerService }
  ],
})
export class UsersModule { }
