import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown } from '@clavisco/linker';
import { OverlayService } from '@clavisco/overlay';
import { MapDisplayColumns, MappedColumns } from '@clavisco/table';
import {catchError, finalize, Subscription, tap} from 'rxjs';
import { IProjectStatusTable } from 'src/app/models/interfaces/i-project-status';
import { FilteredServiceData } from 'src/app/models/interfaces/i-windowsservice';
import { ProjectStatusService } from 'src/app/services/projectStatus.service';
import { CreateEditProjectStatusComponent } from './create-edit-project-status/create-edit-project-status.component';
import {Structures} from "@clavisco/core";
import {IProjectStatus} from "../../../../core/interfaces/i-project-status";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Component({
  selector: 'app-project-status',
  templateUrl: './project-status.component.html',
  styleUrls: ['./project-status.component.sass']
})
export class ProjectStatusComponent implements OnInit, OnDestroy {
  allSuscription: Subscription= new Subscription()
  StartPos: number = 0;
  RowsReturned: number = 10;
  tableId = 'tablePStatus'
  displayedColumns!: MappedColumns;
  scrollHeight: string = '425px';
  pageSizeOptions: number[] = [10, 20];
  itemsPeerPage: number = 10;
  recordsCount: number = 0;
  hasPaginator: boolean = true;
  shouldPaginateRequest: boolean = false;
  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };
  Buttons: any = [
    {
      Title: `Acción`,
      Action: Structures.Enums.CL_ACTIONS.UPDATE,
      Icon: `edit`,
      Color: `primary`
    },
  ]

  projectStatus!: IProjectStatus[]

  filter: FilteredServiceData = {
    Name: '',
    Status: '',
    Server: '',
    Environment: 'true'
  }

  newProjectStatus: IProjectStatus = {
    Id: 0,
    NameStatus: '',
    Description: '',
    MaxQtyRowsFetch: 0,
    IsActive: false
  }

  constructor(
    private projectStatusService: ProjectStatusService,
    private modalService: MatDialog,
    private clBlockUI: OverlayService,
    @Inject('LinkerService') private linkerService: LinkerService
  ) {
    this.displayedColumns = MapDisplayColumns<IProjectStatusTable, null>({
      dataSource: [],
      renameColumns: {
        NameStatus: 'Nombre del Estado',
        Description: 'Descripción',
        Status: 'Estado'
      },
      ignoreColumns: ['MaxQtyRowsFetch', 'Id', 'IsActive', 'CreatedDate', 'UpdateDate', 'CreatedBy', 'UpdatedBy']
    });
  }

  ngOnDestroy(): void{
    this.allSuscription.unsubscribe();
  }

  ngOnInit(): void {
    Register<CL_CHANNEL>(this.tableId, CL_CHANNEL.INPUT, this.GetElementsRecords, this.callbacks);
    Register<CL_CHANNEL>(this.tableId, CL_CHANNEL.OUTPUT, this.SelectedProject, this.callbacks);

    this.allSuscription.add(this.linkerService.Flow()
      ?.pipe(StepDown<CL_CHANNEL>(this.callbacks))
      .subscribe({
        next: (callback) =>
          Run(callback.Target, callback, this.callbacks.Callbacks),
        error: (error) => console.log(`mi error`, error),
      }));
    this.GetProjectStatus();
  }

  GetElementsRecords = (_event: ICLEvent): void => {
    if (_event) {
      const INCOMMING_TABLE_STATE = JSON.parse(_event.Data);
      this.RowsReturned = INCOMMING_TABLE_STATE.ItemsPeerPage;
      this.StartPos = INCOMMING_TABLE_STATE.CurrentPage;
      this.GetProjectStatus();
    }
  };

  SelectedProject = (_event: ICLEvent) => {
    const event = JSON.parse(_event.Data)
    const status: IProjectStatus = JSON.parse(event.Data)
    this.ShowCreateEditProjectStatus(status)
  };


  GetProjectStatus(): void {
    this.clBlockUI.OnGet("Cargando estados de proyecto")
    this.projectStatusService.GetProyectStatusFilter(
      this.StartPos,
      this.RowsReturned,
      this.filter.Name,
      this.filter.Status?? '',
    ).pipe(
      tap((data:ICLResponse<IProjectStatus[]>) => {
        this.projectStatus = [...data.Data];
        this.projectStatus.map(x => {
          x.Status = x.IsActive ? 'Activo' : 'Inactivo'
        })
        this.recordsCount = data.Data.length > 0 ? data.Data.shift()!.MaxQtyRowsFetch : 0;
        this.loadTableData();
      }),
      catchError(() => {return []}),
      finalize(()=>{this.clBlockUI.Drop()})
    ).subscribe();
  }

  loadTableData(): void {
    const CURRENT_TABLE_STATE = {
      CurrentPage: 1,
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.projectStatus,
      RecordsCount: this.recordsCount
    };
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.tableId,
      Data: JSON.stringify(CURRENT_TABLE_STATE)
    } as ICLEvent);
  }

  ShowCreateEditProjectStatus(_projectStatus: IProjectStatus): void {
    const dialogRef: MatDialogRef<CreateEditProjectStatusComponent> = this.modalService.open(CreateEditProjectStatusComponent, {
      data: {
        projectStatus: _projectStatus,
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result) this.GetProjectStatus();
    });
  }

}
