import {Component, OnInit, Inject, ViewChild, Output} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PermissionCode } from 'src/app/common/constants';
import { SessionService } from 'src/app/services/session.service';
import { IISPool, IISSite, TaskSchedule, WindowServerService } from '../../../models/interfaces/i-windowsservice';
import { PhysicalAddressDetailComponent } from '../physical-address-detail/physical-address-detail.component';
import { XmlDetailComponent } from '../xml-detail/xml-detail.component';

@Component({
  selector: 'app-logs-detail',
  templateUrl: './logs-detail.component.html',
  styleUrls: ['./logs-detail.component.sass']
})
export class LogsDetailComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalService: MatDialog,
    private sessionService: SessionService,
    private dialogRef: MatDialogRef<LogsDetailComponent>,

  ) { }

  log = JSON.parse(this.data.jsonObject);
  taskList: TaskSchedule[] = this.log.TaskList;
  poolList: IISPool[] = this.log.PoolList;
  wServiceList: WindowServerService[] = this.log.ServiceList;
  siteList: IISSite[] = this.log.SiteList;
  serviceVersion: string = this.log.ServiceVersion;

  pageSizeOptions: number[] = [5, 10];
  itemsPeerPage: number = 5;


  CloseDialog(): void {
    this.dialogRef.close();
  }



}
