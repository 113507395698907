<section class="container-summary-modal">
  <div class="header-container">
    <mat-icon class="title-icon" [ngClass]="modalResponseStatus">{{ dataStructureModal.icon }}</mat-icon>
    <h1 class="title">{{ dataStructureModal.title }}</h1>
  </div>
  <div class="body-container">
    <cl-table
      [Id]="idResponseSummaryTable"
      [MappedColumns]="displayedColumnsSummary"
      [Records]="summaryInformationForTabla"
      [RecordsCount]="countSummaryInformationForTabla"
      ScrollHeight="405px"
    ></cl-table>
  </div>
  <div class="footer-container">
    <button mat-stroked-button matTooltip="Eliminar todas las configuraciones" (click)="HandleModalClose('clear')">
      <span class="text-btn"><mat-icon>delete_forever</mat-icon>Aceptar y Limpiar</span>
    </button>
    <button mat-stroked-button matTooltip="Conservar todas las configuraciones" (click)="HandleModalClose('save')">
      <span class="text-btn"><mat-icon>save_as</mat-icon>Aceptar y Mantener</span>
    </button>
  </div>
</section>
