import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { IResponse } from "../models/i-response";
import { IFiles } from "../models/interfaces/i-file";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;



@Injectable({
    providedIn: 'root'
})

export class FileService {
    private readonly CONTROLLER = '/api/Files';

    constructor(private http: HttpClient) { }

    Get(): Observable<ICLResponse<IFiles[]>> {
        return this.http.get<ICLResponse<IFiles[]>>(`${this.CONTROLLER}`);
    }

    DownloadFileBlob(file: string): Observable<ICLResponse<string>> {
      return this.http.get<ICLResponse<string>>(`${this.CONTROLLER}/DownloadFile`, {
            params: new HttpParams()
                .set('path', file.toString())
        })
    }

    UploadFile(files: IFiles[]): Observable<ICLResponse<string>> {
        return this.http.post<ICLResponse<string>>(`${this.CONTROLLER}`, files)
    }

    GetFileExtensions(): Observable<ICLResponse<string[]>> {
        return this.http.get<ICLResponse<string[]>>(`${this.CONTROLLER}/FileExtensions`)
    }

}
