<div class="permissions-content">
  <div class="permissions-header">
    <div class="filters-inputs w-100 flex">
      <mat-form-field class="w-30 align-items " appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label>Buscar Permiso</mat-label>
        <input matInput [formControl]="filter" autocomplete="off" (keyup.enter)="GetPermissionData()">
      </mat-form-field>
      <button mat-stroked-button color="primary" (click)="GetPermissionData()">
        <mat-icon>search</mat-icon>Buscar
      </button>
    </div>
  </div>
  <div class="permissions-body">
    <mat-card class="mat-elevation-z0">
      <cl-table [Id]="tableId"
                [MappedColumns]="displayedColumns"
                [Records]="dataSource"
                [PageSizeOptions]="pageSizeOptions"
                [ItemsPeerPage]="itemsPeerPage"
                [RecordsCount]="recordsCount"
                [HasPaginator]="hasPaginator"
                [ScrollHeight]="scrollHeight"
                [ShouldPaginateRequest]="shouldPaginateRequest"
                [Buttons]="Buttons">
      </cl-table>
    </mat-card>
  </div>
</div>
