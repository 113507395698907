<router-outlet>
  <div class="container">

    <div class="filter-container ">

        <div class="flex">
          <mat-form-field class="filter-input align-items" appearance="outline">
            <input type="text" matInput placeholder="Nombre" [(ngModel)]="this.filter.Name"
              (keyup.enter)="GetProjectStatus()">
          </mat-form-field>
          <mat-form-field class="filter-input align-items" appearance="outline">
            <mat-label>
              Estado
            </mat-label>
            <mat-select [(value)]="this.filter.Status" (selectionChange)="GetProjectStatus()">
              <mat-option value="">Ambos</mat-option>
              <mat-option value="true">Activo</mat-option>
              <mat-option value="false">Inactivo</mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-stroked-button color="primary" (click)="GetProjectStatus()" class="btn-search">
            <mat-icon>search</mat-icon>Buscar</button>
        </div>


      <div class="">
        <button mat-stroked-button color="primary" (click)="ShowCreateEditProjectStatus(newProjectStatus)"
          class="btn-edit">
          <mat-icon>add</mat-icon>Agregar</button>
      </div>
    </div>

    <mat-card class="mb-1 mat-elevation-z0">
      <div class="example-container mat-elevation-z0">
        <cl-table [Id]="tableId" [MappedColumns]="displayedColumns" [Records]="projectStatus"
          [PageSizeOptions]="pageSizeOptions" [ItemsPeerPage]="itemsPeerPage" [RecordsCount]="recordsCount"
          [HasPaginator]="hasPaginator" [ScrollHeight]="scrollHeight" [Buttons]="Buttons" [ShouldPaginateRequest]="shouldPaginateRequest">
        </cl-table>
      </div>
    </mat-card>
  </div>
</router-outlet>
