<div class="align-right"><mat-icon class="icon-close" (click)="CloseDialog(false)">close</mat-icon></div>
<div class="add-permissions-header">
    <h1>{{permissionData?.Id ? "Editar Permiso" : "Agregar Permiso"}}</h1>
</div>
<mat-divider></mat-divider>
<form [formGroup]="permissionForm">
    <div class="add-permissions-content">
        <mat-form-field appearance="outline" class="input-credential">
            <mat-label>Nombre</mat-label>
            <input type="text" matInput placeholder="Nombre" formControlName="Description">
        </mat-form-field>
        <mat-form-field appearance="outline" class="input-credential">
            <mat-label>Codigo</mat-label>
            <input type="text" matInput placeholder="Codigo" formControlName="Name">
        </mat-form-field>
<!--        <mat-form-field appearance="outline">-->
<!--            <mat-label>Tipo</mat-label>-->
<!--            <mat-select formControlName="PermissionType">-->
<!--                <mat-option *ngFor="let permission of getPermissionsType()" [value]='permission'>{{permission}}</mat-option>-->
<!--            </mat-select>-->
<!--        </mat-form-field>-->
        <mat-checkbox formControlName="IsActive">Activo</mat-checkbox>
    </div>
</form>
<mat-divider></mat-divider>
<div class="add-permissions-footer">
    <button mat-stroked-button (click)="CloseDialog(false)"><mat-icon>close</mat-icon> Cerrar </button>
    <button mat-stroked-button color="primary" (click)='SavePermission()'><mat-icon>save</mat-icon> Guardar </button>
</div>
