<div>
  <div class="header">
    <h1>Integracion Bitbucket</h1>
  </div>
  <div class="body">
    <div>
      <button mat-button color="primary" (click)="GenerateCode()">Generar Codigo</button>
      <button mat-button color="primary" (click)="GenerateAccessToken()">Generar Access Token</button>
      <button mat-button color="primary" (click)="RefreshAccessToken()">Refrescar Access Token</button>
    </div>
    <div>
      <cl-table [Id]="tableId"
                [MappedColumns]="displayedColumns"
                [Records]="repositoriesList"
                [PageSizeOptions]="pageSizeOptions"
                [ItemsPeerPage]="itemsPeerPage"
                [RecordsCount]="recordsCount"
                [HasPaginator]="hasPaginator"
                [ScrollHeight]="scrollHeight"
                [ShouldPaginateRequest]="true"
                [Buttons]="Buttons">
      </cl-table>
      <table mat-table [dataSource]="repositories" id="table_repositories" class="mat-elevation-z8">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Nombre del Repositorio </th>
          <td mat-cell *matCellDef="let element"> {{element.name || 'N/A'}} </td>
        </ng-container>
        <ng-container matColumnDef="language">
          <th mat-header-cell *matHeaderCellDef> Lenguaje </th>
          <td mat-cell *matCellDef="let element"> {{ element.language || 'N/A'}} </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button><fa-icon [icon]="['fas', 'cloud-arrow-up']"></fa-icon></button>
          </td>
        </ng-container>
        <!-- <ng-container matColumnDef="href">
          <th mat-header-cell *matHeaderCellDef> Enlace </th>
          <td mat-cell *matCellDef="let element"> {{ element.links.html.href }} </td>
        </ng-container> -->
        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>

</div>
