import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MapDisplayColumns, MappedColumns} from "@clavisco/table";
import {CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Run, StepDown} from "@clavisco/linker";
import {IProjectTemplate} from "../../../../models/interfaces/i-project-template";
import {ProjectTemplateService} from "../../../../services/project-template.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {finalize, Subscription} from "rxjs";
import {OverlayService} from "@clavisco/overlay";


@Component({
  selector: 'app-project-template-modal',
  templateUrl: './project-template-modal.html',
  styleUrls: ['./project-template-modal.sass']
})
export class ProjectTemplateModalComponent implements OnInit, OnDestroy {
  allSuscription: Subscription= new Subscription()
  tableId = 'tableIdPT'
  displayedColumns!: MappedColumns;
  scrollHeight: string = '250px';
  hasPaginator: boolean = false;
  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };

  projectTemplates:IProjectTemplate[] = []
  ignoreProperties: string[] = ['CreatedBy', 'UpdatedBy', 'CreatedDate', 'UpdatedDate', 'IsActive', 'UpdateDate']
  constructor(private templateService: ProjectTemplateService,
      private clBlockUI: OverlayService,
      private templatesService: ProjectTemplateService,
      @Inject('LinkerService') private linkerService: LinkerService,
      @Inject(MAT_DIALOG_DATA) public data:any,
  ) {
    this.displayedColumns = MapDisplayColumns<IProjectTemplate, null>({
      dataSource: [],
      renameColumns: {
        Project: 'Proyecto',
        ProjectCode: 'Codigo Proyecto',
        Client: 'Cliente',
        DevelopmentManager: 'Responsable',
        Communication: 'Comunicación',
        PO: 'PO',
        StartDate: 'Inicia Proyecto',
        EndDate: 'Entrega Final',
        NextDate: 'Siguiente Entrega',
        Type: 'Tipo Entrega',
        Comments: 'Obvs',
        State: 'Estado'
      },
      ignoreColumns: this.data.Type == 1 ? ['TemplateHeaderId', 'Id', ...this.ignoreProperties] : ['TemplateHeaderId', 'Id', ...this.ignoreProperties]
    });
  }

  ngOnDestroy(): void{
    this.allSuscription.unsubscribe();
  }

  ngOnInit() {
    this.allSuscription.add(this.linkerService.Flow()
      ?.pipe(StepDown<CL_CHANNEL>(this.callbacks))
      .subscribe({
        next: (callback) =>
          Run(callback.Target, callback, this.callbacks.Callbacks),
        error: (error) => console.log(`error`, error),
      }));

    this.GetTemplatesByHeader()
  }

  GetTemplatesByHeader(){
    if(this.data.Type == 1){
      this.projectTemplates = this.data.Data;
      this.loadTableData();
    }else{
      this.clBlockUI.OnGet('Cargando...')
      this.templatesService.GetTemplatesByHeader(this.data.Data).pipe(finalize(()=>this.clBlockUI.Drop())).subscribe({
        next:value => {
          this.projectTemplates = value.Data;
          this.projectTemplates.map(x=>{
            if(x.NextDate) x.NextDate = this.getFormatDate(new Date(x.NextDate))
            if(x.EndDate) x.EndDate = this.getFormatDate(new Date(x.EndDate))
            if(x.StartDate) x.StartDate = this.getFormatDate(new Date(x.StartDate))
            x.State = x.IsActive ? 'Activo' : 'Inactivo'
          })
          this.loadTableData();
        }
      });
    }
  }

  loadTableData(): void {
    const CURRENT_TABLE_STATE = {
      CurrentPage: 1,
      Records: this.projectTemplates,
    };
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.tableId,
      Data: JSON.stringify(CURRENT_TABLE_STATE)
    } as ICLEvent);
  }

  ValidTemplates(): boolean{
    if(this.projectTemplates.filter(x=>x.State == 'Código no válido').length > 0){
      return false;
    }
    return true;
  }

  getFormatDate(date: Date):string{
    return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`
  }

}
