import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataBaseByServerModel, IDataBaseProject } from '../models/interfaces/i-database';
import { SessionService } from './session.service';
import { IResponse } from '../models/i-response'
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  private readonly CONTROLLER = '/api/Database'

  constructor(private http: HttpClient,
    private sessionService: SessionService) { }


  GetDatabaseByServer(_startPos: number, _rowsReturned: number, _serverId: number): Observable<IResponse<DataBaseByServerModel[]>> {
    return this.http.get<IResponse<DataBaseByServerModel[]>>(`${this.CONTROLLER}/GetDatabaseByServer`, {
      params: new HttpParams()
        .set('StartPos', _startPos)
        .set('RowsReturned', _rowsReturned)
        .set('ServerId', _serverId)
    });
  }

  GetSAPDatabasesByProject(_projectId: number): Observable<ICLResponse<IDataBaseProject[]>> {
    return this.http.get<ICLResponse<IDataBaseProject[]>>(`${this.CONTROLLER}/GetSAPDatabasesByProjects`, {
      params: new HttpParams()
        .set('projectId', _projectId)
    });
  }

}
