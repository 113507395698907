import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/app-material.module';
import { WindowsServicesComponent } from './windows-services.component';
import { PasswordAsterisksPipe } from 'src/app/pipes/passwordAstericks';
import {TableModule} from "@clavisco/table";
@NgModule({
  declarations: [
    WindowsServicesComponent,
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    TableModule
  ],
  exports: [
    WindowsServicesComponent
  ]
})
export class WindowsServicesModule { }
