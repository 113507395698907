<div *ngIf="isloading">
    <div class="header mb-1" mat-dialog-title>
        <h1>
            Entregas de proyectos
        </h1>
        <mat-icon class="icon-close" (click)="CloseDialog()">close
        </mat-icon>
    </div>

    <mat-dialog-content class="mat-typography pt-1 pb-1">
        <mat-accordion multi *ngIf="headers">
            <mat-expansion-panel *ngFor="let header of headers; let i = index">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{header.Name}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <div *ngFor="let form of formDynamic">
                        <div *ngIf="header.Id == form.Id">
                            <form *ngIf="form.checklistD.length > 0" class="formControl" [formGroup]="form.FormGr">
                                <div *ngFor="let ff of form.checklistD" class="content-forms">
                                    <div *ngIf="ff.CrystalParamName === 'PType'; else Inputs">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>{{ff.DisplayName}}</mat-label>
                                            <mat-select placeholder="{{ff.DisplayName}}"
                                                [formControlName]="ff.CrystalParamName">
                                                <mat-option value="Pruebas">Pruebas</mat-option>
                                                <mat-option value="Producción">Producción</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <ng-template #Inputs>
                                        <mat-form-field appearance="outline" *ngIf="ff.IsActive" class="w-100">
                                            <mat-label>{{ff.DisplayName}}</mat-label>
                                            <input matInput [formControlName]="ff.CrystalParamName"
                                                placeholder="{{ff.DisplayName}}">
                                        </mat-form-field>
                                    </ng-template>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </mat-expansion-panel>
        </mat-accordion>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-stroked-button color="primary" (click)="SaveCheckListDetails()">
            <mat-icon>save</mat-icon> Guardar
        </button>
    </mat-dialog-actions>
</div>
