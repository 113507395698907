<div class="container">
  <router-outlet>

    <div class="primary-permissions-container">
      <mat-tab-group class="tab-group" (selectedTabChange)="tabChanged($event)">
        <mat-tab label="Permisos">
          
            <app-permission [ActualTabIndex]="tabIndex" (OpenAddModal)="SetFuntionOpenModal($event)"></app-permission>
        
        </mat-tab>
        <mat-tab label="Asignacion de permisos">
          <app-role [ActualTabIndex]="tabIndex" (OpenAddModal)="SetFuntionOpenModal($event)"></app-role>
        </mat-tab>
      </mat-tab-group>
      <button class="btn-add" mat-stroked-button color="primary" *ngIf='showAddButton' (click)="OpenModal()">
        <mat-icon>add</mat-icon>Agregar
      </button>
    </div>

  </router-outlet>
</div>