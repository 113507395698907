import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService, CLToastType } from '@clavisco/alerts';
import { OverlayService } from '@clavisco/overlay';
import {catchError, finalize, tap} from 'rxjs';
import { ProjectStatusService } from 'src/app/services/projectStatus.service';
import {IProjectStatus} from "../../../../../core/interfaces/i-project-status";

@Component({
  selector: 'app-create-edit-project-status',
  templateUrl: './create-edit-project-status.component.html',
  styleUrls: ['./create-edit-project-status.component.sass']
})
export class CreateEditProjectStatusComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CreateEditProjectStatusComponent>,
    private projectStatusService: ProjectStatusService,
    private alertService: AlertsService,
    private clBlockUI: OverlayService
  ) { }

  projectStatusForm = new FormGroup({
    Id: new FormControl(this.data.projectStatus.Id, Validators.required),
    NameStatus: new FormControl(this.data.projectStatus.NameStatus, [Validators.required]),
    Description: new FormControl(this.data.projectStatus.Description, [Validators.required]),
    IsActive: new FormControl(this.data.projectStatus.IsActive, [Validators.required]),
    CreatedBy: new FormControl(""),
    UpdatedBy: new FormControl("")
  });

  ActionProjectStatusSave(): void {
    if (this.projectStatusForm.invalid) {
      return this.alertService.Toast({ message: `Campos Requeridos`, type: CLToastType.ERROR })
    }
    this.clBlockUI.OnPost("Guardando...")
    this.projectStatusService.PostProjectStatus((this.projectStatusForm.value) as IProjectStatus)
      .pipe(finalize(() => { this.clBlockUI.Drop(); }),
        tap((data) => {
          this.CloseDialog(true);
          this.alertService.Toast({ message: `Estado de proyecto guardado`, type: CLToastType.SUCCESS })
        }),
        catchError((err) => {
          this.alertService.Toast({ message: (err as HttpErrorResponse).error['error_description'], type: CLToastType.ERROR })
          return [];}))
      .subscribe();
  }
  ActionProjectStatusEdit(): void {
    if (this.projectStatusForm.invalid) {
      return this.alertService.Toast({ message: `Campos Requeridos`, type: CLToastType.ERROR })
    }
    this.clBlockUI.OnPost("Actualizando...")
    this.projectStatusService.PatchProjectStatus((this.projectStatusForm.value) as IProjectStatus)
      .pipe(finalize(() => { this.clBlockUI.Drop(); }),
        tap((data) => {
          this.CloseDialog(true);
          this.alertService.Toast({ message: `Estado de proyecto  actualizado`, type: CLToastType.SUCCESS })
        }),
        catchError((err) => {
          this.alertService.Toast({ message: (err as HttpErrorResponse).error['error_description'], type: CLToastType.ERROR })
          return [];}))
      .subscribe();
  }

  CloseDialog(result: boolean): void {
    this.dialogRef.close(result);
  }

}
