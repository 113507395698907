<div class="container">
  <router-outlet>
    <mat-tab-group>
      <mat-tab label="Usuarios">
        <div class="users-header">
          <div class="filters-inputs w-100 flex">
            <mat-form-field class="w-30 align-items" appearance="outline">
              <mat-icon matPrefix>search</mat-icon>
              <mat-label>Buscar Usuario</mat-label>
              <input matInput [formControl]="filter" (keyup.enter)="GetUser()"
                     autocomplete="off">
            </mat-form-field>
            <button mat-stroked-button color="primary" (click)="GetUser()">
              <mat-icon>search</mat-icon>Buscar
            </button>
          </div>
          <div class="actions-buttons">
            <button mat-stroked-button color="primary" (click)="OpenUserAddDialog()"><mat-icon>add</mat-icon>
              Agregar </button>
          </div>
        </div>
        <mat-card class="mat-elevation-z0">
          <div class="users-content">
            <cl-table [Id]="tableId"
                      [MappedColumns]="displayedColumns"
                      [Records]="users"
                      [PageSizeOptions]="pageSizeOptions"
                      [ItemsPeerPage]="itemsPeerPage"
                      [RecordsCount]="recordsCount"
                      [HasPaginator]="hasPaginator"
                      [ScrollHeight]="scrollHeight"
                      [ShouldPaginateRequest]="shouldPaginateRequest"
                      [Buttons]="Buttons">
            </cl-table>
          </div>
        </mat-card>
      </mat-tab>
      <mat-tab label="Asignacion de roles" *ngIf="CanEditRole()">
        <app-assing-role></app-assing-role>
      </mat-tab>
    </mat-tab-group>
  </router-outlet>
</div>
