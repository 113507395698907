import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertsService, CLToastType } from '@clavisco/alerts';
import { IRole } from 'src/app/models/interfaces/i-role';
import { PermissionsService } from 'src/app/services/permissions.service';
import {catchError, finalize, tap} from "rxjs";
import {RolesService} from "../../../../../services/roles.service";

@Component({
  selector: 'app-modal-add-role',
  templateUrl: './modal-add-role.component.html',
  styleUrls: ['./modal-add-role.component.sass'],
})
export class ModalAddRoleComponent implements OnInit {
  roleForm!: FormGroup

  constructor(
    private dialogRef: MatDialogRef<ModalAddRoleComponent>,
    private permissionService: PermissionsService,
    private rolesService: RolesService,
    private alertService: AlertsService
  ) { }

  ngOnInit(): void {
    this.roleForm = new FormGroup({
      Name: new FormControl('', Validators.required),
      Description: new FormControl('', Validators.required),
      IsActive: new FormControl(true),
      CreatedBy: new FormControl(""),
      UpdatedBy: new FormControl(""),
    }, Validators.required);
  }

  SaveRole(): void {
    if (this.roleForm.invalid) {
      return this.alertService.Toast({ message: 'Campos requeridos', type: CLToastType.ERROR })
    }
    this.rolesService.Post((this.roleForm.value) as IRole)
      .pipe(finalize ( () => { }),
      tap((data)=>{
        this.CloseDialog();
        this.alertService.Toast({ message: 'Rol guardado correctamente', type: CLToastType.SUCCESS })
      }),catchError((err) => {
        this.alertService.Toast({ message: (err as HttpErrorResponse).error['error_description'], type: CLToastType.ERROR })
        return [];
      })).subscribe();
  }

  CloseDialog(): void {
    this.dialogRef.close();
  }
}
