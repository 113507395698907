import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import { ServersService } from '../../../services/servers.service';
import { IServer } from '../../../models/interfaces/i-server';
import { MatDialog } from '@angular/material/dialog';
import { ModalServerComponent } from './modal-server/modal-server.component';
import { FormControl } from '@angular/forms';
import { DbServerDetailComponent } from 'src/app/shared/components/db-server-detail/db-server-detail.component';
import { OverlayService } from '@clavisco/overlay';
import {catchError, finalize, Subscription, tap} from 'rxjs';
import { MapDisplayColumns, MappedColumns } from '@clavisco/table';
import { CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown } from '@clavisco/linker';
import { Structures } from '@clavisco/core';
import {PermissionCode} from "../../../common/constants";
import {SessionService} from "../../../services/session.service";
import {AlertsService, CLToastType} from "@clavisco/alerts";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Component({
  selector: 'app-server',
  templateUrl: './server.component.html',
  styleUrls: ['./server.component.sass']
})
export class ServerComponent implements OnInit, OnDestroy {
  allSuscription: Subscription= new Subscription()
  StartPos: number = 0;
  RowsReturned: number = 10;
  StartPosDB: number = 0;
  RowsReturnedDB: number = 10;
  tableId = 'tableServers'
  tableIdDB = 'tableServersDB'
  displayedColumns!: MappedColumns;
  displayedColumnsDB!: MappedColumns;
  scrollHeight: string = '425px';
  pageSizeOptions: number[] = [10, 20];
  itemsPeerPage: number = 10;
  recordsCount: number = 0;
  itemsPeerPageDB: number = 10;
  recordsCountDB: number = 0;
  hasPaginator: boolean = true;
  shouldPaginateRequest: boolean = false;
  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };
  Buttons: any = [
    {
      Title: `Acción`,
      Action: Structures.Enums.CL_ACTIONS.UPDATE,
      Icon: `edit`,
      Color: `primary`
    },
  ]

  serversApp: IServer[] = []
  serversDB: IServer[] = []

  filterDB: FormControl = new FormControl('');
  filterApp: FormControl = new FormControl('');

  selectedIndex: number = 0; //index del tab

  newServerDB: IServer = {
    Id: 0,
    MaxQtyRowsFetch: 0,
    Type: '',
    Name: '',
    IsActive: false,
    Description: '',
    ReadingType: '',
    CreatedBy: '',
    UpdateDate: new Date(),
    CreatedDate: new Date(),
    IpClient: '',
  }

  constructor(public serversService: ServersService,
    private dialog: MatDialog,
    private modalService: MatDialog,
    private alertService: AlertsService,

    private sessionService: SessionService,

    private clBlockUI: OverlayService,
    @Inject('LinkerService') private linkerService: LinkerService
  ) {
    this.displayedColumns = MapDisplayColumns<IServer, null>({
      dataSource: [],
      renameColumns: {
        Type: 'Tipo',
        Name: 'Nombre',
        Status: 'Estado',
        Description: 'Descripción',
        ReadingType: 'Tipo Lectura',
        CreatedBy: 'Usuario',
        DateFormat: 'Última Lectura',
        IpClient: 'IP del Servidor',
      },
      ignoreColumns: ['MaxQtyRowsFetch', 'Id', 'UpdateDate', 'CreatedDate', 'UpdatedBy', 'IsActive']
    });

    this.displayedColumnsDB = MapDisplayColumns<IServer, null>({
      dataSource: [],
      renameColumns: {
        Type: 'Tipo',
        Name: 'Nombre',
        Status: 'Estado',
        Description: 'Descripción',
        ReadingType: 'Tipo Lectura',
        CreatedBy: 'Usuario',
        DateFormat: 'Última verificación BDs',

      },
      ignoreColumns: ['MaxQtyRowsFetch', 'Id', 'IpClient', 'CreatedDate', 'UpdateDate', 'UpdatedBy', 'IsActive']
    });
  }

  ngOnInit(): void {
    this.InitializeTable();
    this.GetServers('','APP');
  }

  ngOnDestroy(): void{
    this.allSuscription.unsubscribe();
  }

  InitializeTable() {
    Register<CL_CHANNEL>(this.selectedIndex === 0 ? this.tableId : this.tableIdDB, CL_CHANNEL.INPUT, this.GetElementsRecords, this.callbacks);
    Register<CL_CHANNEL>(this.tableIdDB, CL_CHANNEL.OUTPUT, this.SelectedServer, this.callbacks);

    this.allSuscription.add(this.linkerService.Flow()
      ?.pipe(StepDown<CL_CHANNEL>(this.callbacks))
      .subscribe({
        next: (callback) =>
          Run(callback.Target, callback, this.callbacks.Callbacks),
        error: (error) => console.log(`mi error`, error),
      }));
  }

  GetElementsRecords = (_event: ICLEvent): void => {
    if (_event) {
      const INCOMMING_TABLE_STATE = JSON.parse(_event.Data);
      if (this.selectedIndex === 0) {
        this.RowsReturned = INCOMMING_TABLE_STATE.ItemsPeerPage;
        this.StartPos = INCOMMING_TABLE_STATE.CurrentPage;
        this.GetServers(this.filterApp.value, 'APP');
      } else {
        this.RowsReturnedDB = INCOMMING_TABLE_STATE.ItemsPeerPage;
        this.StartPosDB = INCOMMING_TABLE_STATE.CurrentPage;
        this.GetServers(this.filterDB.value, 'DB');
      }
    }
  };

  SelectedServer = (_event: ICLEvent) => {
    if(!this.CanEditServer()) {
      return this.alertService.Toast({ message: 'No tiene permisos para editar el servidor de base de datos', type: CLToastType.WARNING })
    }
    const event = JSON.parse(_event.Data)
    const server: IServer = JSON.parse(event.Data)
    this.openModal(server)
  };


  GetServers(_name: string, _type: string): void {
    this.clBlockUI.OnGet('Cargando Servidores...');
    this.serversService.GetServers(this.StartPos, this.RowsReturned, _name, _type).pipe(finalize(() => { this.clBlockUI.Drop(); }),
      tap((data: ICLResponse<IServer[]>)=>{
        if (data.Data) {
          data.Data.map(x=>{
            x.DateFormat = this.getFormatDate(new Date(x.UpdateDate??''));
            x.Status = x.IsActive ? 'Activo' : 'Inactivo';
          })
          switch (_type){
            case 'APP':
              this.serversApp = data.Data;
              this.recordsCount = data.Data.length > 0 ? data.Data[0].MaxQtyRowsFetch : 0;
              break;
            case  'DB':
              this.serversDB = data.Data;
              this.recordsCountDB = data.Data.length > 0 ? data.Data[0].MaxQtyRowsFetch : 0;
              break;
            default:
              this.serversDB = data.Data;
              this.recordsCountDB = data.Data.length > 0 ? data.Data[0].MaxQtyRowsFetch : 0;
              this.serversApp = data.Data;
              this.recordsCount = data.Data.length > 0 ? data.Data[0].MaxQtyRowsFetch : 0;
          }
          this.loadTableData();
        }
      }),catchError((err) => {

          return [];
        }
      )).subscribe();
  }

  getFormatDate(date: Date):string{
    const time = ` - ${date.toTimeString().split(('GMT'))[0]}`;
    return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${time}`
  }

  loadTableData(): void {
    const CURRENT_TABLE_STATE = {
      CurrentPage: 1,
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.selectedIndex === 0 ? this.serversApp : this.serversDB,
      RecordsCount: this.selectedIndex === 0 ? this.recordsCount : this.recordsCountDB
    };
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.selectedIndex === 0 ? this.tableId : this.tableIdDB,
      Data: JSON.stringify(CURRENT_TABLE_STATE)
    } as ICLEvent);
  }


  showDB(_id: number, _serverName: string): void {
    this.modalService.open(DbServerDetailComponent, {
      data: {
        id: _id,
        serverName: _serverName
      }
    });
  }


  tabActive(event: any) {
    this.selectedIndex = event.index;
    switch (this.selectedIndex) {
      case 0: {
        this.filterApp.setValue('');
        this.GetServers('','APP')
        break;
      }
      case 1: {
        this.filterDB.setValue('');
        this.GetServers('','DB');
        break;
      }
    }
  }

  openModal = (serverDB: IServer) => {
    const modal = this.dialog.open(
      ModalServerComponent,
      {
        width: '650px',
        maxHeight: `85vh`,
        data: serverDB,
      }
    );

    modal.afterClosed().subscribe(() => {
      this.GetServers(this.filterDB.value, 'DB');
    })
  }

  CanEditServer():boolean{
    return this.sessionService.GetPermissionCodeFromToken().includes(PermissionCode.EditServer)
  }

}
