import {
  AfterContentInit,
  Component, EventEmitter, Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {catchError, finalize, Subscription, tap} from "rxjs";
import {CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown} from "@clavisco/linker";
import {FormControl} from "@angular/forms";
import {Structures} from "@clavisco/core";
import {IApplication} from "../../../models/interfaces/i_applications";
import {MatDialog} from "@angular/material/dialog";
import {SessionService} from "../../../services/session.service";
import {AlertsService, CLToastType} from "@clavisco/alerts";
import {OverlayService} from "@clavisco/overlay";
import {ApplicationService} from "../../../services/application.service";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {PermissionCode} from "../../../common/constants";
import {ModalApplicationComponent} from "./modal-application/modal-application.component";
import {MatTableDataSource} from "@angular/material/table";
import {PageEvent} from "@angular/material/paginator";
import {ProyectService} from "../../../services/proyect.service";
import {MapDisplayColumns, MappedColumns} from "@clavisco/table";
import {ICLTableButton} from "@clavisco/table/lib/table.space";
import {ISetting} from "../../../models/i-appsetting";
import CL_ACTIONS = Structures.Enums.CL_ACTIONS;

@Component({
  selector: 'app-applications',
  templateUrl: './application.component.html',
  styleUrls: ['./applications.component.sass']
})
export class ApplicationComponent implements OnInit, OnDestroy {
  @Input() edit: boolean;
  @Input() checked: boolean;
  @Input() listApplications: IApplication[];
  @Input() filters: boolean;
  @Output() RemoveApplication: EventEmitter<IApplication[]> = new EventEmitter<IApplication[]>()

  // Configuracion Tabla Clavis
  allSuscription: Subscription= new Subscription()
  tableId: string = 'tableApplicationsId'
  displayedColumns!: MappedColumns
  applicationsList: IApplication[] = []
  readonly pageSizeOptions: number[] = [5, 7, 10]
  itemsPeerPage: number = this.pageSizeOptions[2];
  recordsCount: number = 0;
  hasPaginator: boolean = true;
  scrollHeight: string = '400px'
  readonly toIgnore: string[] = ["Id", "IsActive", "UpdatedBy", "UpdatedDate", "CreatedBy", "CreatedDate", "MaxQtyRowsFetch"]
  Buttons: ICLTableButton[] = [{
    Title: 'Editar',
    Action: Structures.Enums.CL_ACTIONS.UPDATE,
    Icon: `edit`,
    Color: `primary`
  }]
  //Fin de Configuracion

  filter!: FormControl
  filterStatus!: FormControl
  StartPos: number = 0;
  RowsReturned: number = 10;

  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };

  constructor(
    public dialog: MatDialog,
    private service: ApplicationService,
    private sessionService: SessionService,
    private alertService: AlertsService,
    private clBlockUI: OverlayService,
    private projectService: ProyectService,
    @Inject('LinkerService') private linkerService: LinkerService
  ) {
    this.OnLoadTable()
  }
  ngOnInit(): void {
    try{
      Register<CL_CHANNEL>(this.tableId, CL_CHANNEL.INPUT, this.GetElementsRecords, this.callbacks);
      Register<CL_CHANNEL>(this.tableId, CL_CHANNEL.OUTPUT, this.SelectedApplication, this.callbacks)
      this.allSuscription.add(this.linkerService.Flow()
        ?.pipe(StepDown<CL_CHANNEL>(this.callbacks))
        .subscribe({
          next: (callback) =>
            Run(callback.Target, callback, this.callbacks.Callbacks),
          error: (error) => console.log(`mi error`, error),
        }));
    }catch (e) {
      console.log(e)
    }
    this.filter = new FormControl('');
    this.filterStatus = new FormControl('');
    this.GetApplications();
  }

  GetElementsRecords = (_event: ICLEvent): void => {
    if (_event) {
      const INCOMMING_TABLE_STATE = JSON.parse(_event.Data);
      this.RowsReturned = INCOMMING_TABLE_STATE.ItemsPeerPage;
      this.StartPos = INCOMMING_TABLE_STATE.CurrentPage;
      this.GetApplications();
    }
  };

  OnLoadTable(): void {
    this.displayedColumns = MapDisplayColumns<IApplication, null>({
      dataSource: this.applicationsList,
      renameColumns: {
        "Code": "Codigo",
        "Name": "Nombre",
        "Description": "Descripción",
        "Status": "Estado",
      },
      ignoreColumns: [...this.toIgnore, "UpdateDate"]
    })
  }

  private loadTableData(): void{
    const CURRENT_TABLE_STATE = {
      CurrentPage: 1,
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.applicationsList,
      RecordsCount: this.recordsCount
    };
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.tableId,
      Data: JSON.stringify(CURRENT_TABLE_STATE)
    } as ICLEvent);
  }

  SelectedApplication = (_event: ICLEvent): void => {
    if(_event.Data){
      const event = JSON.parse(_event.Data)
      const application: IApplication = JSON.parse(event.Data)
      if(event.Action == CL_ACTIONS.UPDATE){
        this.EditApplication(application)
      }
    }
  }

  EditApplication = (application: IApplication) => {
    if(!this.CanEditApplication()) {
      return this.alertService.Toast({ message: 'No tiene permisos para editar la aplicación', type: CLToastType.WARNING })
    }
    this.openModal(true, application);
  }

  openModal = (edit: boolean, application?: IApplication) => {
    const modal = this.dialog.open(
      ModalApplicationComponent,
      {
        width: '650px',
        maxHeight: `85vh`,
        data: {Application: application, Edit: edit},
      }
    );

    modal.afterClosed().subscribe((): void => {
      this.GetApplications();
    })
  }

  GetApplications(): void {
    this.clBlockUI.OnGet('Cargando Aplicaciones...');
    this.service.GetApplications(this.filter.value, this.filterStatus.value?? '', this.StartPos, this.RowsReturned).pipe(finalize(() => { this.clBlockUI.Drop(); }),
      tap((response: ICLResponse<IApplication[]>)=>{
        if (response?.Data) {
          this.applicationsList.splice(0, this.applicationsList.length)
          this.applicationsList.push(...response.Data.map(item => {
              item.Status = item.IsActive ? "Activo" : "Inactivo"
              return item
            }
          ))
          this.recordsCount = this.applicationsList[0].MaxQtyRowsFetch
          this.loadTableData()
        }
      }),catchError((err) => {
          return [];
        }
      )).subscribe();
  }
  ngOnDestroy(): void {
    this.allSuscription.unsubscribe();
  }

  CanEditApplication():boolean{
    return this.sessionService.GetPermissionCodeFromToken().includes(PermissionCode.EditApplication)
  }
}
