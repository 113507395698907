import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'passwordAsterick'})
export class PasswordAsterisksPipe implements PipeTransform{
  transform(value: string, view: boolean): string {
    if(value!=null && value.length != 0){
        return view ? value : value = value.replace(value, value.split('').slice(0, value.length).map(v=> '*').join(''))
    }
    else{
        return value = ''
    }
  }
} 