
<div>
  <h2 *ngIf="this.data.Type==1" style="text-align: center;">Preview</h2>
  <h3 *ngIf="this.data.Type==1">{{data.NameHeader}}</h3>
  <div class="example-container mat-elevation-z0">
    <cl-table
      [Id]="tableId"
      [MappedColumns]="displayedColumns"
      [Records]="projectTemplates"
      [HasPaginator]="hasPaginator"
      [ScrollHeight]="scrollHeight"
    >
    </cl-table>
  </div>

  <div class="align-right mt-1">
    <button mat-stroked-button color="primary" [mat-dialog-close]="null" >Cerrar</button>
    <button *ngIf="this.data.Type==1" mat-stroked-button color="primary" [disabled]="!ValidTemplates()" [mat-dialog-close]="projectTemplates">Crear</button>
  </div>

</div>

