import {
  AfterContentInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator, MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {catchError, finalize, forkJoin, map, Observable, of, startWith, Subscription, tap} from 'rxjs';
import {WindowsService} from 'src/app/common/constants';
import {EncrDecrService} from 'src/app/common/encr-decr-service';
import {IServer} from 'src/app/models/interfaces/i-server';
import {
  FilteredServiceData,
  IISPool,
  IISSite,
  TaskSchedule,
  TypeServices,
  WindowServerService
} from 'src/app/models/interfaces/i-windowsservice';
import {ServersService} from 'src/app/services/servers.service';
import {WindowsServicesService} from 'src/app/services/windows-services.service';
import {
  PhysicalAddressDetailComponent
} from 'src/app/shared/components/physical-address-detail/physical-address-detail.component';
import {PoolDetailComponent} from 'src/app/shared/components/pool-detail/pool-detail.component';
import {XmlDetailComponent} from 'src/app/shared/components/xml-detail/xml-detail.component';
import {SessionService} from '../../../services/session.service';
import {ProyectService} from 'src/app/services/proyect.service'
import {OverlayService} from '@clavisco/overlay';
import {Structures} from "@clavisco/core";
import {AlertsService, CLToastType} from "@clavisco/alerts";
import {MapDisplayColumns, MappedColumns} from "@clavisco/table";
import {CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown} from "@clavisco/linker";
import ICLResponse = Structures.Interfaces.ICLResponse;
import CL_ACTIONS = Structures.Enums.CL_ACTIONS;

@Component({
  selector: 'app-windows-services',
  templateUrl: './windows-services.component.html',
  styleUrls: ['./windows-services.component.sass']
})
export class WindowsServicesComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input() edit: boolean;
  @Input() checked: boolean;
  @Input() listTask: TaskSchedule[];
  @Input() listServices: WindowServerService[];
  @Input() listSites: IISSite[];
  @Input() listPools: IISPool[];
  @Input() filters: boolean;
  @Input() pageSizeOptions: number[] = [10, 20];
  @Input() itemsPeerPage: number = 10;
  @Output() RemoveService?: EventEmitter<TypeServices> = new EventEmitter<TypeServices>()


  poolsList!: IISPool[]
  sitesList!: IISSite[]
  tasksList!: TaskSchedule[]
  wservicesList!: WindowServerService[]
  selectControl = new FormControl('');
  filteredServer!: Observable<IServer[]>;

  allSuscription: Subscription= new Subscription()

  //Tablas
  scrollHeight: string = '400px';

  hasPaginator: boolean = true;
  shouldPaginateRequest: boolean = false;
  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };


  //Tasks
  tasks: TaskSchedule[] = [];
  tableTasksId = 'tableTasks'
  displayedColumnsTasks!: MappedColumns;
  recordsCountTasks: number = 0;
  ButtonsTask: any = [];


  //Pools
  pools: IISPool[] = [];
  tablePoolsId = 'tablePools'
  displayedColumnsPools!: MappedColumns;
  recordsCountPools: number = 0;
  ButtonsPools: any = [];

  //Windows Services
  wservices: WindowServerService[] = [];
  tableWServicesId = 'tableWServices'
  displayedColumnsWServices!: MappedColumns;
  recordsCountWServices: number = 0;
  ButtonsWServices: any = [
    {
      Title: '',
      Action: CL_ACTIONS.READ,
      Icon: `info`,
      Color: `primary`,
      Options: [
        {
          Title: 'Ver Descripción',
          Action: CL_ACTIONS.OPTION_1,
          Icon: `visibility`,
          Color: `primary`,
        },
        {
          Title: 'Ver Direccion Física',
          Action: CL_ACTIONS.OPTION_2,
          Icon: `visibility`,
          Color: `primary`,
        },
        {
          Title: 'Ver Ruta de Logs',
          Action: CL_ACTIONS.OPTION_3,
          Icon: `visibility`,
          Color: `primary`,
        },
        {
          Title: 'Ver Web.config',
          Action: CL_ACTIONS.OPTION_4,
          Icon: `visibility`,
          Color: `primary`,
        }
      ],
      Data: "",
    },
  ];

  //Sites
  sites: IISSite[] = [];
  tableSitesId = 'tableSites'
  displayedColumnsSites!: MappedColumns;
  recordsCountSites: number = 0;
  ButtonsSites: any = [
    {
      Title: '',
      Action: CL_ACTIONS.READ,
      Icon: `info`,
      Color: `primary`,
      Options: [
        {
          Title: 'Abrir URL',
          Action: CL_ACTIONS.OPTION_1,
          Icon: `visibility`,
          Color: `primary`,
        },
        {
          Title: 'Ver Información de Pool',
          Action: CL_ACTIONS.OPTION_2,
          Icon: `visibility`,
          Color: `primary`,
        },{
          Title: 'Ver Direccion Física',
          Action: CL_ACTIONS.OPTION_3,
          Icon: `visibility`,
          Color: `primary`,
        },
        {
          Title: 'Ver Ruta de Logs',
          Action: CL_ACTIONS.OPTION_4,
          Icon: `visibility`,
          Color: `primary`,
        },
        {
          Title: 'Ver Web.config',
          Action: CL_ACTIONS.OPTION_5,
          Icon: `visibility`,
          Color: `primary`,
        }
      ],
      Data: "",
    },
  ];

  //ServiceSelectect
  service: IISPool[] | TaskSchedule[] | WindowServerService[] | IISSite[] = [];
  recordsCount: number = 0;


  serversApp: IServer[] = [];

  pageSizePools: number = 10;
  pageIndexPools: number = 0;

  pageSizeTasks: number = 10;
  pageIndexTasks: number = 0;

  pageSizeWServices: number = 10;
  pageIndexWServices: number = 0;

  pageSizeSites: number = 10;
  pageIndexSites: number = 0;
  // end pagination

  selectedIndex: number = 0; //index del tab

  filter: FilteredServiceData = {
    Name: '',
    Status: '',
    Server: '',
    Environment: ''
  }


  constructor(
    private serversService: ServersService,
    private windowsServiceService: WindowsServicesService,
    private sessionService: SessionService,
    private modalService: MatDialog,
    public matPaginatorIntl: MatPaginatorIntl,
    private encrDecrService: EncrDecrService,
    private projectService: ProyectService,
    private clBlockUI: OverlayService,
    private alertService: AlertsService,
    @Inject('LinkerService') private linkerService: LinkerService
  ) {
    this.displayedColumnsTasks = MapDisplayColumns<TaskSchedule, string>({
      dataSource: [],
      renameColumns: {
        Name: 'Nombre',
        Status: 'Activo',
        State: 'Estado',
        Author: 'Autor',
        Server: 'Servidor',
        Environment: 'Ambiente',
        UserAuth: 'Usuario ejecutor',
        UpdatedDate: 'Última lectura',
      },
      ignoreColumns: ['Id', 'Code','Path', 'ReadOnly', 'IsActive', 'Enabled', 'UpdateDate', 'Description','LastRunTime','NextRunTime','LastTimeResult', 'MaxQtyRowsFetch', 'CreatedBy', 'CreatedDate', 'UpdatedBy'],

    });

    this.displayedColumnsPools = MapDisplayColumns<IISPool, string>({
      dataSource: [],
      renameColumns: {
        PoolName: 'Nombre del Pool',
        Schema: 'Esquema',
        User: 'Usuario',
        IsAutoStart: 'AutoInicio',
        StartMode: 'Modo de Inicio',
        Server: 'Servidor',
        Environment: 'Ambiente',
        UpdatedDate: 'Última lectura'
      },
      ignoreColumns: ['Id', 'Code', 'IsActive', 'AutoStart', 'Password', 'Enable32Bit', 'UpdateDate', 'State','IsLocallyStored','Version', 'MaxQtyRowsFetch', 'CreateBy', 'CreatedBy', 'CreatedDate', 'UpdatedBy'],
    });

    this.displayedColumnsWServices = MapDisplayColumns<WindowServerService, string>({
      dataSource: [],
      renameColumns: {
        ServiceName: 'Nombre del Servicio',
        DisplayName: 'Nombre Interno',
        StartType: 'Tipo de Arranque',
        Status: 'Estado',
        Server: 'Servidor',
        Environment: 'Ambiente',
        UpdatedDate: 'Última lectura'
      },
      ignoreColumns: ['Id', 'Code', 'IsActive', 'Description', 'PhysicalPath', 'LogPath', 'XmlString', 'Password', 'UpdateDate', 'MaxQtyRowsFetch', 'CreateBy', 'CreatedBy', 'CreatedDate', 'UpdatedBy'],
    });

    this.displayedColumnsSites = MapDisplayColumns<IISSite, string>({
      dataSource: [],
      renameColumns: {
        SiteName: 'Nombre del sitio',
        Hostname: 'URL',
        PoolName: 'Nombre del pool',
        Schema: 'Esquema',
        State: 'Estado',
        IsServerAutoStart: 'Auto Inicio',
        Server: 'Servidor',
        Environment: 'Ambiente',
        UpdatedDate: 'Última lectura',
      },
      ignoreColumns: ['Id', 'Code', 'IsActive', 'LogFileDirectory', 'ServerAutoStart', 'PhysicalPath', 'LogPath', 'XmlString', 'UpdateDate', 'IdSite', 'MaxQtyRowsFetch', 'CreateBy', 'CreatedBy', 'CreatedDate', 'UpdatedBy'],
    });

  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void{
    this.allSuscription.unsubscribe();
  }

  ngAfterContentInit(): void {
    Register<CL_CHANNEL>(this.tableTasksId, CL_CHANNEL.INPUT, this.UpdateElementsRecordsOnNextTasks, this.callbacks);
    Register<CL_CHANNEL>(this.tablePoolsId, CL_CHANNEL.INPUT, this.UpdateElementsRecordsOnNextPools, this.callbacks);
    Register<CL_CHANNEL>(this.tableWServicesId, CL_CHANNEL.INPUT, this.UpdateElementsRecordsOnNextWServices, this.callbacks);
    Register<CL_CHANNEL>(this.tableWServicesId, CL_CHANNEL.OUTPUT, this.ButtonsEventWServices, this.callbacks);
    Register<CL_CHANNEL>(this.tableSitesId, CL_CHANNEL.INPUT, this.UpdateElementsRecordsOnNextSites, this.callbacks);
    Register<CL_CHANNEL>(this.tableSitesId, CL_CHANNEL.OUTPUT, this.ButtonsEventSites, this.callbacks);

    this.allSuscription.add(this.linkerService.Flow()
      ?.pipe(StepDown<CL_CHANNEL>(this.callbacks))
      .subscribe({
        next: (callback) =>
          Run(callback.Target, callback, this.callbacks.Callbacks),
        error: (error) => console.log(`mi error`, error),
      }));

    if (this.filters) {
      if (this.edit) {

        this.pageSizePools = this.itemsPeerPage;
        this.pageSizeTasks = this.itemsPeerPage;
        this.pageSizeWServices = this.itemsPeerPage;
        this.pageSizeSites = this.itemsPeerPage;

        this.tasks = this.listTask.map(x => {x.Status = x.IsActive ? 'Si' : 'No';
          x.UpdatedDate = this.getFormatDate(x.UpdateDate? new Date(x.UpdateDate): undefined);
          return x});
        this.pools = this.listPools.map(x => {
          x.IsAutoStart = x.AutoStart ? 'Si' : 'No';
          x.UpdatedDate = this.getFormatDate(x.UpdateDate? new Date(x.UpdateDate): undefined);
          return x
        });
        this.sites = this.listSites.map(x => {
          x.Hostname = x.Hostname == ''? 'N/A' : x.Hostname;
          x.IsServerAutoStart = x.ServerAutoStart ? 'Si' : 'No';
          x.UpdatedDate = this.getFormatDate(x.UpdateDate? new Date(x.UpdateDate): undefined);
          return x
        });
        this.wservices = this.listServices.map(x => {
          x.UpdatedDate = this.getFormatDate(x.UpdateDate? new Date(x.UpdateDate): undefined);
          return x
        })

        this.setService(0);
        this.setService(1);
        this.setService(2);
        this.setService(3);

      }

      //this.GetDataWindowsServices();

    } else {
      this.InitializeComponent();
      this.getFilteredServers();
    }
    this.matPaginatorIntl.itemsPerPageLabel = 'Items por página';
  }
  InitializeComponent(): void {
    this.loadInitialData();
  }

  UpdateElementsRecordsOnNextTasks = (_event: ICLEvent): void => {
    if (_event) {
      const INCOMMING_TABLE_STATE = JSON.parse(_event.Data);
      this.pageSizeTasks = INCOMMING_TABLE_STATE.ItemsPeerPage;
      this.pageIndexTasks = INCOMMING_TABLE_STATE.CurrentPage;
      if (this.filters) {
        this.setService(this.selectedIndex)
      }else{
        this.GetTaskSchedule(false);
      }
    }
  }
  UpdateElementsRecordsOnNextPools = (_event: ICLEvent): void => {
    if (_event) {
      const INCOMMING_TABLE_STATE = JSON.parse(_event.Data);
      this.pageSizePools = INCOMMING_TABLE_STATE.ItemsPeerPage;
      this.pageIndexPools= INCOMMING_TABLE_STATE.CurrentPage;
      if (this.filters) {
        this.setService(this.selectedIndex)
      }else{
        this.GetPoolIIS(false);
      }
    }
  }
  UpdateElementsRecordsOnNextWServices = (_event: ICLEvent): void => {
    if (_event) {
      const INCOMMING_TABLE_STATE = JSON.parse(_event.Data);
      this.pageSizeWServices = INCOMMING_TABLE_STATE.ItemsPeerPage;
      this.pageIndexWServices= INCOMMING_TABLE_STATE.CurrentPage;
      if (this.filters) {
        this.setService(this.selectedIndex)
      }else{
        this.GetWindowsServices(false);
      }
    }
  }
  UpdateElementsRecordsOnNextSites = (_event: ICLEvent): void => {
    if (_event) {
      const INCOMMING_TABLE_STATE = JSON.parse(_event.Data);
      this.pageSizeSites = INCOMMING_TABLE_STATE.ItemsPeerPage;
      this.pageIndexSites= INCOMMING_TABLE_STATE.CurrentPage;
      if (this.filters) {
        this.setService(this.selectedIndex)
      }else{
        this.GetSitesIIS(false);
      }
    }
  }

  ButtonsEventWServices = (_event: ICLEvent): void => {
    if (_event.Data) {
      const BUTTON_EVENT = JSON.parse(_event.Data);
      const ELEMENT = JSON.parse(BUTTON_EVENT.Data);

      switch (BUTTON_EVENT.Action) {
        case Structures.Enums.CL_ACTIONS.OPTION_1:
          if(ELEMENT.Description === 'No encontrado' || ELEMENT.Description == null || ELEMENT.Description == 'UNKNOWN' || ELEMENT.Description == ''){
            return this.alertService.Toast({ message: 'No se encontró una Descripción', type: CLToastType.INFO })
          }
          this.ShowPhysicalAddressOrPassword(ELEMENT.ServiceName, ELEMENT.Description, 'Description')
          break;
        case Structures.Enums.CL_ACTIONS.OPTION_2:
          if(ELEMENT.PhysicalPath === 'No encontrado' || ELEMENT.PhysicalPath == null || ELEMENT.PhysicalPath == 'UNKNOWN' || ELEMENT.PhysicalPath == ''){
            return this.alertService.Toast({ message: 'No se encontró una Dirección Física', type: CLToastType.INFO })
          }
          this.ShowPhysicalAddressOrPassword(ELEMENT.ServiceName, ELEMENT.PhysicalPath, 'PhysicalAddress')
          break;
        case Structures.Enums.CL_ACTIONS.OPTION_3:
          if(ELEMENT.LogPath === 'No encontrado' || ELEMENT.LogPath == null || ELEMENT.LogPath == 'UNKNOWN' || ELEMENT.LogPath == ''){
            return this.alertService.Toast({ message: 'No se encontró la Ruta de Logs', type: CLToastType.INFO })
          }
          this.ShowPhysicalAddressOrPassword(ELEMENT.ServiceName, ELEMENT.LogPath, 'LogPath')
          break;
        case Structures.Enums.CL_ACTIONS.OPTION_4:
          if(ELEMENT.XmlString === 'No encontrado' || ELEMENT.XmlString == null || ELEMENT.XmlString == 'UNKNOWN' || ELEMENT.XmlString == ''){
            return this.alertService.Toast({ message: 'No se encontró un Web config', type: CLToastType.INFO })
          }
          this.ShowXmlDetails(ELEMENT.XmlString)
          break;
      }
    }
  }

  ButtonsEventSites = (_event: ICLEvent): void => {
    if (_event.Data) {
      const BUTTON_EVENT = JSON.parse(_event.Data);
      const ELEMENT = JSON.parse(BUTTON_EVENT.Data);

      switch (BUTTON_EVENT.Action) {
        case Structures.Enums.CL_ACTIONS.OPTION_1:
          if(ELEMENT.Hostname === 'N/A'){
            return this.alertService.Toast({ message: 'No se encontró una URL', type: CLToastType.INFO })
          }
          window.open("//"+ELEMENT.Hostname, '_blank');
          break;
        case Structures.Enums.CL_ACTIONS.OPTION_2:
          if(ELEMENT.PoolName === 'No encontrado' || ELEMENT.PoolName == null || ELEMENT.PoolName == 'UNKNOWN' || ELEMENT.PoolName == ''){
            return this.alertService.Toast({ message: 'No hay un pool para mostrar', type: CLToastType.INFO })
          }
          this.ShowPoolDetails(ELEMENT.PoolName)
          break;
        case Structures.Enums.CL_ACTIONS.OPTION_3:
          if(ELEMENT.PhysicalPath === 'No encontrado' || ELEMENT.PhysicalPath == null || ELEMENT.PhysicalPath == 'UNKNOWN' || ELEMENT.PhysicalPath == ''){
            return this.alertService.Toast({ message: 'No se encontró una Dirección Física', type: CLToastType.INFO })
          }
          this.ShowPhysicalAddressOrPassword(ELEMENT.SiteName, ELEMENT.PhysicalPath, 'PhysicalAddress')
          break;
        case Structures.Enums.CL_ACTIONS.OPTION_4:
          if(ELEMENT.LogPath === 'No encontrado' || ELEMENT.LogPath == null || ELEMENT.LogPath == 'UNKNOWN' || ELEMENT.LogPath == ''){
            return this.alertService.Toast({ message: 'No se encontró la Ruta de Logs', type: CLToastType.INFO })
          }
          this.ShowPhysicalAddressOrPassword(ELEMENT.SiteName, ELEMENT.LogPath, 'LogPath')
          break;
        case Structures.Enums.CL_ACTIONS.OPTION_5:
          if(ELEMENT.XmlString === 'No encontrado' || ELEMENT.XmlString == null || ELEMENT.XmlString == 'UNKNOWN' || ELEMENT.XmlString == '' || !ELEMENT.SiteName.includes('API')){
            return this.alertService.Toast({ message: 'No se encontró un Web config', type: CLToastType.INFO })
          }
          this.ShowXmlDetails(ELEMENT.XmlString)
          break;
      }
    }
  }
  loadTableData(tableId: string, records: any, recordsCount: number, pageSize:number, pageIndex:number): void {
    let filteredRecords = records
    let filteredRecordsCount = recordsCount
    if (this.filters) {
      let init = pageSize * pageIndex;
      let end = pageSize * pageIndex + pageSize;
      filteredRecords = [...records.slice(init, end)]
      filteredRecordsCount = records.length > 0 ? records.length : 0;
    }
    this.service = filteredRecords;
    this.recordsCount = filteredRecordsCount;
    const CURRENT_TABLE_STATE = {
      CurrentPage: 1,
      ItemsPeerPage: this.itemsPeerPage,
      Records: filteredRecords,//.map(x => ({...x, CellsMessages: {Options: "Objeto definido por usuario"}})),
      RecordsCount: recordsCount
    };
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: tableId,
      Data: JSON.stringify(CURRENT_TABLE_STATE)
    } as ICLEvent);
  }

  setService(type: number): void {
    switch (type) {
      case 0:
        this.loadTableData(this.tableTasksId, this.tasks, this.recordsCountTasks, this.pageSizeTasks, this.pageIndexTasks);
        this.recordsCountTasks = this.recordsCount;
        break
      case 1:
        this.loadTableData(this.tableWServicesId, this.wservices, this.recordsCountWServices, this.pageSizeWServices, this.pageIndexWServices);
        this.recordsCountWServices = this.recordsCount;
        break
      case 2:
        this.loadTableData(this.tablePoolsId, this.pools, this.recordsCountPools, this.pageSizePools, this.pageIndexPools);
        this.recordsCountPools = this.recordsCount;
        break
      case 3:
        this.loadTableData(this.tableSitesId, this.sites, this.recordsCountSites, this.pageSizeSites, this.pageIndexSites);
        this.recordsCountSites = this.recordsCount;
        break
    }
  }
  loadInitialData(): void {
    this.clBlockUI.OnGet('Cargando servicios...')
    this.filter.Server = this.filter.Server == this.selectControl.value ? this.filter.Server : this.selectControl.value?? '';
    let server = this.serversService.Get();
    let task = this.windowsServiceService.GetWindowsInformation<TaskSchedule[]>(
      WindowsService.TaskSchedule,
      this.pageIndexTasks = false ? 0 : this.pageIndexTasks,
      this.pageSizeTasks,
      this.filter.Name,
      this.filter.Server,
      this.filter.Environment?? '',
      ''
    );
    let site = this.windowsServiceService.GetWindowsInformation<IISSite[]>(
      WindowsService.SitesIIS,
      this.pageIndexSites = false ? 0 : this.pageIndexSites,
      this.pageSizeSites,
      this.filter.Name,
      this.filter.Server,
      this.filter.Environment?? '',
      this.filter.Status ?? ''
    );
    let pool = this.windowsServiceService.GetWindowsInformation<IISPool[]>(
      WindowsService.PoolsIIS,
      this.pageIndexPools = false ? 0 : this.pageIndexPools,
      this.pageSizePools,
      this.filter.Name,
      this.filter.Server,
      this.filter.Environment?? '',
      ''
    )
    let service = this.windowsServiceService.GetWindowsInformation<WindowServerService[]>(
      WindowsService.Services,
      this.pageIndexWServices = false ? 0 : this.pageIndexWServices,
      this.pageSizeWServices,
      this.filter.Name,
      this.filter.Server,
      this.filter.Environment?? '',
      this.filter.Status ?? ''
    )

    forkJoin([
      server.pipe(catchError(()=> of(null))),
      task.pipe(catchError(()=> of(null))),
      site.pipe(catchError(()=> of(null))),
      pool.pipe(catchError(()=> of(null))),
      service.pipe(catchError(()=> of(null)))
    ]).pipe(
      tap(([respServer, respTask,respSite, respPool, respService])=>{
        if(respServer && respServer.Data){
          this.serversApp = respServer.Data.filter(x => x.Type == 'APP')
        }
        if(respTask){
          this.tasksList = [...respTask.Data]
          this.tasks = [...respTask.Data].map(x => {
            x.Status = x.IsActive ? 'Si' : 'No';
            x.UpdatedDate = this.getFormatDate(x.UpdateDate? new Date(x.UpdateDate): undefined);
            return x});

          this.recordsCountTasks = this.tasks.length > 0 ? this.tasks[0].MaxQtyRowsFetch : 0;
          this.loadTableData(this.tableTasksId, this.tasks, this.recordsCountTasks, this.pageSizeTasks, this.pageIndexTasks)
        }
        if(respSite){
          this.sitesList = [...respSite.Data]
          this.sites = this.sitesList.map(x => {
            x.Hostname = x.Hostname == ''? 'N/A' : x.Hostname;
            x.IsServerAutoStart = x.ServerAutoStart ? 'Si' : 'No';
            x.UpdatedDate = this.getFormatDate(x.UpdateDate? new Date(x.UpdateDate): undefined);
            return x
          });

          this.recordsCountSites = this.sites.length > 0 ? this.sites[0].MaxQtyRowsFetch : 0;
          this.loadTableData(this.tableSitesId, this.sites, this.recordsCountSites, this.pageSizeSites, this.pageIndexSites)
        }
        if(respPool){
          this.poolsList = [...respPool.Data];
          this.pools = [...respPool.Data].map(x => {
            x.IsAutoStart = x.AutoStart ? 'Si' : 'No';
            x.UpdatedDate = this.getFormatDate(x.UpdateDate? new Date(x.UpdateDate): undefined);
            return x});
          this.recordsCountPools = this.pools.length > 0 ? this.pools[0].MaxQtyRowsFetch : 0;
          this.loadTableData(this.tablePoolsId, this.pools, this.recordsCountPools, this.pageSizePools, this.pageIndexPools)
        }
        if(respService){
          this.wservicesList = [...respService.Data]
          this.wservices = this.wservicesList.map(x => {
            x.UpdatedDate = this.getFormatDate(x.UpdateDate? new Date(x.UpdateDate): undefined);
            return x
          });
          this.recordsCountWServices = this.wservices.length > 0 ? this.wservices[0].MaxQtyRowsFetch : 0;
          this.loadTableData(this.tableWServicesId, this.wservices, this.recordsCountWServices, this.pageSizeWServices, this.pageIndexWServices);
        }
      }),
      finalize(() => {
        this.clBlockUI.Drop();
      })
    ).subscribe();
  }

  getFilteredServers() {
    this.filteredServer = this.selectControl.valueChanges.pipe(
      startWith(''),
      map((x: string | null) => (x ? this._filterServer(x) : this.serversApp.slice())),
    );
  }

  private _filterServer(value: string): IServer[] {
    const filterValue = value.toLowerCase();
    return this.serversApp.filter(state => state.Name.toLowerCase().includes(filterValue));
  }

  selectedServer(server: IServer) {
    this.filter.Server = server.Name;
    this.filterData();
  }

  GetWindowsServices(isFilter: Boolean): void {
    this.windowsServiceService.GetWindowsInformation<WindowServerService[]>(
      WindowsService.Services,
      this.pageIndexWServices = isFilter ? 0 : this.pageIndexWServices,
      this.pageSizeWServices,
      this.filter.Name,
      this.filter.Server = this.filter.Server == this.selectControl.value ? this.filter.Server : this.selectControl.value?? '',
      this.filter.Environment?? '',
      this.filter.Status ?? ''
    ).pipe(finalize(() => { this.clBlockUI.Drop(); }),
      tap((response: ICLResponse<WindowServerService[]>)=>{
          this.wservicesList = [...(response?.Data || [])]
          this.wservices = this.wservicesList.map(x => {
            x.UpdatedDate = this.getFormatDate(x.UpdateDate? new Date(x.UpdateDate): undefined);
            return x
          });
        this.recordsCountWServices = this.wservices.length > 0 ? this.wservices[0].MaxQtyRowsFetch : 0;
        this.loadTableData(this.tableWServicesId, this.wservices, this.recordsCountWServices, this.pageSizeWServices, this.pageIndexWServices)
      }),catchError((err) => {
          console.log(err)

          return [];
        }
      )).subscribe();
  }

  GetPoolIIS(isFilter: Boolean): void {
    this.windowsServiceService.GetWindowsInformation<IISPool[]>(
      WindowsService.PoolsIIS,
      this.pageIndexPools = isFilter ? 0 : this.pageIndexPools,
      this.pageSizePools,
      this.filter.Name,
      this.filter.Server = this.filter.Server == this.selectControl.value ? this.filter.Server : this.selectControl.value?? '',
      this.filter.Environment?? '',
      ''
    ).pipe(finalize(() => { this.clBlockUI.Drop(); }),
      tap((response: ICLResponse<IISPool[]>)=>{
        this.poolsList = [...(response?.Data || [])];
        this.pools = [...(response?.Data || [])].map(x => {
          x.IsAutoStart = x.AutoStart ? 'Si' : 'No';
          x.UpdatedDate = this.getFormatDate(x.UpdateDate? new Date(x.UpdateDate): undefined);
          return x});
        this.recordsCountPools = this.pools.length > 0 ? this.pools[0].MaxQtyRowsFetch : 0;
        this.loadTableData(this.tablePoolsId, this.pools, this.recordsCountPools, this.pageSizePools, this.pageIndexPools)
      }),catchError((err) => {
          console.log(err)
          return [];
        }
      )).subscribe();
  }

  GetSitesIIS(isFilter: Boolean): void {
    this.windowsServiceService.GetWindowsInformation<IISSite[]>(
      WindowsService.SitesIIS,
      this.pageIndexSites = isFilter ? 0 : this.pageIndexSites,
      this.pageSizeSites,
      this.filter.Name,
      this.filter.Server = this.filter.Server == this.selectControl.value ? this.filter.Server : this.selectControl.value?? '',
      this.filter.Environment?? '',
      this.filter.Status ?? ''
    ).pipe(finalize(() => { this.clBlockUI.Drop(); }),
        tap((response: ICLResponse<IISSite[]>)=>{
            this.sitesList = [...(response?.Data || [])]
          this.sites = this.sitesList.map(x => {
            x.Hostname = x.Hostname == ''? 'N/A' : x.Hostname;
            x.IsServerAutoStart = x.ServerAutoStart ? 'Si' : 'No';
            x.UpdatedDate = this.getFormatDate(x.UpdateDate? new Date(x.UpdateDate): undefined);
            return x
          });

          this.recordsCountSites = this.sites.length > 0 ? this.sites[0].MaxQtyRowsFetch : 0;
          this.loadTableData(this.tableSitesId, this.sites, this.recordsCountSites, this.pageSizeSites, this.pageIndexSites)
        }),catchError((err) => {
            console.log(err)
            return [];
          }
        )).subscribe();
  }

  GetTaskSchedule(isFilter: Boolean): void {
    this.windowsServiceService.GetWindowsInformation<TaskSchedule[]>(
      WindowsService.TaskSchedule,
      this.pageIndexTasks = isFilter ? 0 : this.pageIndexTasks,
      this.pageSizeTasks,
      this.filter.Name,
      this.filter.Server = this.filter.Server == this.selectControl.value ? this.filter.Server : this.selectControl.value?? '',
      this.filter.Environment?? '',
      ''
    ).pipe(finalize(() => { this.clBlockUI.Drop(); }),
      tap((response: ICLResponse<TaskSchedule[]>)=>{
        this.tasksList = [...(response?.Data || [])]
          this.tasks = [...(response?.Data || [])].map(x => {
            x.Status = x.IsActive ? 'Si' : 'No';
            x.UpdatedDate = this.getFormatDate(x.UpdateDate? new Date(x.UpdateDate): undefined);
            return x});

        this.recordsCountTasks = this.tasks.length > 0 ? this.tasks[0].MaxQtyRowsFetch : 0;
        this.loadTableData(this.tableTasksId, this.tasks, this.recordsCountTasks, this.pageSizeTasks, this.pageIndexTasks)

      }),catchError((err) => {
          console.log(err)
          return [];
        }
      )).subscribe();
  }

  ShowPoolDetails(_poolName: string): void {
    this.windowsServiceService.GetPoolByName<IISPool>(_poolName).subscribe(
      {
        next: response => {
          this.modalService.open(PoolDetailComponent, {
            width: '850px',
            data: {
              poolInfo: response.Data
            },
          });
        }
      })
  }

  ShowPhysicalAddressOrPassword(_siteName: string, _physicalAddress: string, _type: string): void {
    this.modalService.open(PhysicalAddressDetailComponent, {
      data: {
        siteName: _siteName,
        physicalAddress: _physicalAddress,
        type: _type
      }
    });
  }

  ShowXmlDetails(_xml: string): void {
    this.modalService.open(XmlDetailComponent, {
      data: {
        xml: _xml,
      }
    });
  }

  tabActive(event: any) {
    this.selectedIndex = event.index;
    if (!this.filters) {
      switch (this.selectedIndex) {
        case 0: {
          this.GetTaskSchedule(false)
          break;
        }
        case 1: {
          this.GetWindowsServices(false);
          break;
        }
        case 2: {
          this.GetPoolIIS(false);
          break;
        }
        case 3: {
          this.GetSitesIIS(false);
          break;
        }
      }
    }
    else{
      this.setService(this.selectedIndex)
    }
  }

  filterData() {
    this.clBlockUI.OnPost('Buscando servicios....')
    switch (this.selectedIndex) {
      case 0: {
        this.GetTaskSchedule(true)
        break;
      }
      case 1: {
        this.GetWindowsServices(true);
        break;
      }
      case 2: {
        this.GetPoolIIS(true);
        break;
      }
      case 3: {
        this.GetSitesIIS(true);
        break;
      }
      default:
        this.clBlockUI.Drop();
        break;
    }
  }
  getFormatDate(date?: Date):string{
    if(date == null){return "N/A"}
    const time = ` - ${date.toTimeString().split(('GMT'))[0]}`;
    return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${time}`
  }

}
