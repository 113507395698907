<div>
    <div mat-dialog-title>
        <div class="header">
            {{this.data.Name ? "Editar Servidor": "Agregar Servidor"}}
            <mat-icon class="icon-close" (click)="CloseDialog()">close</mat-icon>
        </div>

    </div>

    <div class="content">
        <form (ngSubmit)="SaveServer()" [formGroup]="serverForm">
            <div class="input-field">
                <mat-form-field appearance="outline" class="w-input">
                    <mat-label>Nombre del servidor</mat-label>
                    <input matInput placeholder="Nombre del servidor" formControlName="Name">
                </mat-form-field>
            </div>
            <div class="input-field">
                <mat-form-field appearance="outline" class="w-input">
                    <mat-label>Seleccione un estado</mat-label>
                    <mat-select formControlName="Status" required>
                        <mat-option *ngFor="let status of StatusList" [value]="status">{{status}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="input-field">
                <mat-form-field appearance="outline" class="w-input">
                    <mat-label>Descripción</mat-label>
                    <textarea matInput placeholder="Descripción del servidor" formControlName="Description"></textarea>
                </mat-form-field>
            </div>
            <div class="actionsBtn">
                <button mat-stroked-button type="button" (click)="CloseDialog()"><mat-icon>close</mat-icon> Cerrar </button>
                <button mat-stroked-button color="primary" type="submit"><mat-icon>save</mat-icon> Guardar </button>
            </div>
        </form>
    </div>

</div>
