import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'angular-toastify';
import { DevelopersService } from 'src/app/services/developers.service';
import { IDeveloper } from 'src/app/models/interfaces/i-developers';

@Component({
  selector: 'app-modal-add-developers',
  templateUrl: './modal-add-developers.component.html'
 // styleUrls: ['./modal-add-developers.component.sass'],
})
export class ModalAddDevelopersComponent implements OnInit {
  accion!: string;
  developerForm!: FormGroup;
  Id!: FormControl;
  Name!: FormControl;
  Email!: FormControl;
  Active!: FormControl;
  StatusList: string[] = ['Activo', 'Inactivo'];

  constructor(
    private dialogRef: MatDialogRef<ModalAddDevelopersComponent>,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public devData: IDeveloper,
    private developerService: DevelopersService
  ) {}

  ngOnInit(): void {
    this.onLoad();
  }

  onLoad() {
    this.developerForm = this.createForm();
    if (this.devData) {
      this.accion = 'U'; //update
      this.patchUserData(this.devData);
    }
    this.accion = 'I'; //insert
  }

  private patchUserData(userData: IDeveloper): void {
    this.developerForm.patchValue({
      Id: userData.Id,
      Name: userData.Name,
      Email: userData.Email,
      Active: userData.Active,
    });
  }
  private createForm(): FormGroup {
    return new FormGroup({
      Id: new FormControl(0),
      Name: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.maxLength(30)])
      ),
      Email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.maxLength(15),
        ])
      ),
      Active: new FormControl('', Validators.required),
    });
  }

  CloseDialog(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (!this.developerForm.valid) {
      this.toastService.error('Por favor complete los datos');
      return;
    }
    if (this.accion == 'I') {
      this.developerService.SaveDeveloper(this.developerForm.value).subscribe({
        next: (callback) => {
          if (callback.Result) {
            this.toastService.success('Guardado Exitosamente');
          } else {
            this.toastService.error('Ocurrió un error al guardar'),
              console.log(callback.Error.Message);
          }
        },
      });
    } else {
      this.update;
    }
  }

  update(): void {
    this.developerService.SaveDeveloper(this.devData).subscribe({
      next: (callback) => {
        if (callback.Result) {
          this.toastService.success('Guardado Exitosamente');
          this.CloseDialog();
        } else {
          this.toastService.error('Ocurrió un error al guardar'),
            console.log(callback.Error.Message);
        }
      },
    });
  }
}
