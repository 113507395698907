<section class=history-udos-container>
  <mat-card class="mat-elevation-z0">
    <mat-card-content>
      <form id="udos-history-filters" [formGroup]="formHistoryUDOs" (ngSubmit)="handleUDOsHistoryFiltering()">
        <div class="hu-filters-grid">
          <mat-form-field appearance="outline">
            <mat-label>Aplicación</mat-label>
            <div class="container-application-input">
              <input type="text" matInput [matAutocomplete]="auto" [formControlName]="'Application'"
                     (click)="GetFilteredApplications()" />
              <mat-icon class="cancel-icon" (click)="ClearApplicationSelection('Application')"
                        matTooltip="Limpiar campo">close</mat-icon>
            </div>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let app of listFilteredApplications | async" [value]="app.Name"
                          [matTooltip]="app.Code + ':  ' + app.Description" [matTooltipPosition]="'right'">
                <span>{{ app.Name }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Tipo de Acción</mat-label>
            <mat-select [formControlName]="'TypeAction'">
              <mat-option [value]="'allActions'">Todas las acciones</mat-option>
              <mat-option [value]="typeActionsUDOsHistory.Create">Crear</mat-option>
              <mat-option [value]="typeActionsUDOsHistory.Replicate">Replicar</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Estado</mat-label>
            <mat-select [formControlName]="'State'">
              <mat-option [value]="'allStates'">Todos los estados</mat-option>
              <mat-option [value]="typeStatusUDOsHistory.Success">Exitoso</mat-option>
              <mat-option [value]="typeStatusUDOsHistory.Failed">Fallido</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Estado de las Transacciones</mat-label>
            <mat-select [formControlName]="'TransactionsStatus'">
              <mat-option [value]="'allTransactionsStatus'">Todas las transacciones</mat-option>
              <mat-option [value]="typeTransactionsStatusUDOsHistory.AllComplete">Todo completo</mat-option>
              <mat-option [value]="typeTransactionsStatusUDOsHistory.AllFailed">Todo fallido</mat-option>
              <mat-option [value]="typeTransactionsStatusUDOsHistory.PartialComplete">Parcial completo</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Creado Por</mat-label>
            <div class="container-application-input">
              <input type="text" matInput [matAutocomplete]="autoCreatedBy" [formControlName]="'CreatedBy'"
                     (click)="GetFilteredUDOsCreatorUsers()" />
              <mat-icon class="cancel-icon" (click)="ClearApplicationSelection('CreatedBy')"
                        matTooltip="Limpiar campo">close</mat-icon>
            </div>
            <mat-autocomplete #autoCreatedBy="matAutocomplete">
              <mat-option *ngFor="let creator of listFilteredUDOsCreatorUsers | async" [value]="creator.CreatedBy"
                          [matTooltip]="'Creado por: ' + creator.CreatedBy" matTooltipPosition="right">
                <span>{{ creator.CreatedBy }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Rango de Fechas</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate [formControlName]="'InitDate'" placeholder="Fecha Inicial..." />
              <input matEndDate [formControlName]="'EndDate'"  placeholder="Fecha Final..." />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>

        <div class="actions-container">
          <button type="button" (click)="ClearUDOsHistoryTable()" mat-stroked-button
                  matTooltip="Limpiar todos los campos">
            <mat-icon>close</mat-icon><span class="btn-text">Limpiar</span>
          </button>
          <button type="submit" class="btn-filter" mat-stroked-button
            matTooltip="Aplicar filtros a la búsqueda">
            <mat-icon>search</mat-icon><span class="btn-text">Buscar</span>
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
  <mat-card class="mat-elevation-z0">
    <mat-card-content>
      <cl-table [Id]="idUDOsHistoryTable"
                [MappedColumns]="displayedColumnsHistoryUDOs"
                [PageSizeOptions]="pageSizeOptions"
                [ItemsPeerPage]="itemsPeerPage"
                [Records]="sapUDOsHistory"
                [ShouldPaginateRequest]="shouldPaginateRequest"
                [PaginationType]="'dba'"
                [Buttons]="buttonsUDOsHistoryTable"
                [ScrollHeight]="scrollHeight">
      </cl-table>
    </mat-card-content>
  </mat-card>
</section>
