<div class="container">
    <mat-card class="mat-elevation-z0 mb-1">
        <div class="header">
            <span class="title">
                Datos de liberación
            </span>
            <div class="align-right">
                <button mat-stroked-button color="primary" (click)="openModalHeader(false)"><mat-icon>add</mat-icon>
                    Agregar Nuevo</button>
            </div>
        </div>
    </mat-card>

    <mat-accordion multi *ngIf="headers" class="example-headers-align mat-elevation-z0 mb-1">
        <mat-expansion-panel class="mat-elevation-z0" *ngFor="let header of headers; let i = index">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{header.DisplayName}}
                </mat-panel-title>
                <mat-panel-description>
                    {{header.Description}}
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div *ngFor="let form of formDynamic">
                <div *ngIf="header.Id == form.Id">
                    <table mat-table [dataSource]="form.Table" id="{{form.Id}}" class="mat-elevation-z0 w-100"
                        *ngIf="form.Table.data.length > 0">
                        <ng-container matColumnDef="DisplayName">
                            <th mat-header-cell *matHeaderCellDef> Nombre </th>
                            <td mat-cell *matCellDef="let element"> {{ element.DisplayName || 'N/A' }} </td>
                        </ng-container>
                        <ng-container matColumnDef="Type">
                            <th mat-header-cell *matHeaderCellDef> Tipo </th>
                            <td mat-cell *matCellDef="let element"> {{ element.Type || 'N/A' }} </td>
                        </ng-container>
                        <ng-container matColumnDef="IsRequired">
                            <th mat-header-cell *matHeaderCellDef> Requerido </th>
                            <td mat-cell *matCellDef="let element">
                                <ng-template [ngIf]="element.IsRequired" [ngIfElse]="inactive">
                                    <mat-icon>check</mat-icon>
                                </ng-template>
                                <ng-template #inactive>
                                    <mat-icon>close</mat-icon>
                                </ng-template>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Action">
                            <th mat-header-cell *matHeaderCellDef>Editar</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-stroked-button style="color: #323872;"
                                    (click)="OpenModalDetails(true, element.CheckListId, element)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-action-row>
                        <div class="button-row">
                            <button class="edit-button" mat-stroked-button
                                (click)="openModalHeader(true, header)"><mat-icon>edit</mat-icon> Editar</button>
                            <button mat-stroked-button color="primary"
                                (click)="OpenModalDetails(false, header.Id)"><mat-icon>add</mat-icon> Agregar
                                Nuevo</button>
                        </div>
                    </mat-action-row>

                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
