import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../app-material.module';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SidenavMenuComponent } from './sidenav-menu/sidenav-menu.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { PoolDetailComponent } from './components/pool-detail/pool-detail.component';
import { PasswordAsterisksPipe } from '../pipes/passwordAstericks';
import { PhysicalAddressDetailComponent } from './components/physical-address-detail/physical-address-detail.component';
import { PasswordDetailComponent } from './components/password-detail/password-detail.component';
import { LogsDetailComponent } from './components/logs-detail/logs-detail.component';
import { XmlDetailComponent } from './components/xml-detail/xml-detail.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DbServerDetailComponent } from './components/db-server-detail/db-server-detail.component';
import { LinkerService } from '@clavisco/linker';
import { TableModule } from '@clavisco/table';
import { MenuModule } from '@clavisco/menu';
import {JsonViewerComponent} from "./components/json-viewer/json-viewer.component";
import {JsonViewerDirective} from "../directives/json-viewer.directive";
import {WindowsServicesModule} from "../pages/home/windows-services/windows-services.module";

@NgModule({
    imports: [
        CommonModule,
        AppMaterialModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserModule,
        MatTooltipModule,
        TableModule,
        MenuModule,
        WindowsServicesModule,
      FlexLayoutModule
    ],
  declarations: [
    SidenavMenuComponent,
    PoolDetailComponent,
    PasswordAsterisksPipe,
    PhysicalAddressDetailComponent,
    PasswordDetailComponent,
    LogsDetailComponent,
    XmlDetailComponent,
    DbServerDetailComponent,
    JsonViewerComponent,
    JsonViewerDirective
  ],
  providers: [
    { provide: 'LinkerService', useClass: LinkerService }
  ],
  exports: [
    SidenavMenuComponent,
    JsonViewerComponent,
    FlexLayoutModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})
export class SharedModule { }
