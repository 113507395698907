import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../common/constants';
import { AppSettingModel } from '../models/appSettingModel';
import { IAppSetting } from '../models/i-appsetting';
import { IResponse } from '../models/i-response';
import { IBitbucketResponse } from '../models/interfaces/i-bitbucketrepository';
import { IBitbucketSetting } from '../models/interfaces/i-bitbucketsetting';
import { IBitbucketToken } from '../models/interfaces/i-bitbucketToken';
import { SessionService } from './session.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class BitbucketIntegrationService {

  constructor(
    private http:HttpClient,
    private storageService: StorageService,
    private sessionService: SessionService
  ) { }

  GetBitbucketSetting(): Observable<IResponse<AppSettingModel<IBitbucketSetting>>>{
    const headers = new HttpHeaders({
      'Content-Type' : 'application/json',
      'Authorization': `Bearer ${this.sessionService.GetToken()}`
    });
    const query = new HttpParams().set('nameKey', 'BitbucketSetting')
    return this.http.get<IResponse<AppSettingModel<IBitbucketSetting>>>(`${environment.ApiUrl}api/Permission/GetAppSetting`, { params: query, headers })
  }

  UpdateBitbucketSetting(appSetting: IAppSetting<IBitbucketSetting>): Observable<IResponse<AppSettingModel<IBitbucketSetting>>>{
    const headers = new HttpHeaders({
      'Content-Type' : 'application/json',
      'Authorization': `Bearer ${this.sessionService.GetToken()}`
    });
    return this.http.post<IResponse<AppSettingModel<IBitbucketSetting>>>(`${environment.ApiUrl}api/Permission/UpdateAppSettingByKey`, appSetting, {headers})
  }

  GenerateAccessToken(_client_key: string, _client_password : string,_code: string): Observable<IBitbucketToken>{
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Basic ${window.btoa(`${_client_key}:${_client_password}`)}`
    });
    const body = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('code', _code);
    return this.http.post<IBitbucketToken>(`${AppConstants.BitbucketAccessToken}`, body.toString(),{
      headers
    });
  }

  SaveAccessToken(token: IBitbucketToken): void{
    const time : number = new Date().setSeconds(token.expires_in)
    token.expires_date = new Date(time).toISOString()
    this.storageService.SetStorageData('bitbucket_session', JSON.stringify(token));
  }

  GetAccessToken(): IBitbucketToken{
    return (
      JSON.parse(this.storageService.GetStorageData('bitbucket_session'))
    );
  }

  RefreshAccessToken(_client_key:string, _client_password: string): Observable<IBitbucketToken>{
    const token: IBitbucketToken = JSON.parse(this.storageService.GetStorageData('bitbucket_session'))
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Basic ${window.btoa(`${_client_key}:${_client_password}`)}`
    });
    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('refresh_token', token.refresh_token);
    console.log(body.toString())
    return this.http.post<any>(`${AppConstants.BitbucketAccessToken}`, body.toString(),{
      headers
    });
  }

  GetRepositories(): Observable<IBitbucketResponse> {
    const token = this.GetAccessToken().access_token
    const headers = new HttpHeaders({
      'Accept' : 'application/json',
    })
    const params: HttpParams = new HttpParams().set("role", "owner")
    return this.http.get<IBitbucketResponse>(`${AppConstants.BitbucketRepositories}?access_token=${token}`, {params, headers})
  }
}
