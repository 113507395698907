import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/app-material.module';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { FilesComponent } from './files.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';



@NgModule({
  declarations: [
    FilesComponent,
    UploadFilesComponent
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserModule,
  ],
  exports: [
    FilesComponent
]
})
export class FilesModule { }
