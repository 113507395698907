<div>
  <div mat-dialog-title>
    <div class="header">
      {{this.data.Application?.Name ? "Editar Aplicación": "Agregar Aplicación"}}
      <mat-icon class="icon-close" (click)="CloseDialog()">close</mat-icon>
    </div>

  </div>

  <div class="content">
    <form (ngSubmit)="SaveApplication()" [formGroup]="applicationForm">
      <div class="input-field">
        <mat-form-field appearance="outline" class="w-input">
          <mat-label>Código de la Aplicación</mat-label>
          <input matInput placeholder="Código de la Aplicación" formControlName="Code">
        </mat-form-field>
      </div>
      <div class="input-field">
        <mat-form-field appearance="outline" class="w-input">
          <mat-label>Nombre de la Aplicación</mat-label>
          <input matInput placeholder="Nombre de la Aplicación" formControlName="Name">
        </mat-form-field>
      </div>
      <div class="input-field">
        <mat-form-field appearance="outline" class="w-input">
          <mat-label>Descripción</mat-label>
          <textarea matInput placeholder="Descripción de la Aplicacion" formControlName="Description"></textarea>
        </mat-form-field>
      </div>
      <mat-checkbox class="example-margin" formControlName="IsActive">Activo</mat-checkbox>
      <div class="actionsBtn">
        <button mat-stroked-button type="button" (click)="CloseDialog()"><mat-icon>close</mat-icon> Cerrar </button>
        <button mat-stroked-button color="primary" type="submit"><mat-icon>save</mat-icon> Guardar </button>
      </div>
    </form>
  </div>

</div>
