<section class="udf-modal-container">
  <div class="header-container" mat-dialog-title>
    <h1>Datos de Conexión</h1>
  </div>
  <div class="body-container" mat-dialog-content>
    <div class="connection-data-container">
      <div>
        <form [formGroup]="formConnectionServiceLayer" (ngSubmit)="OnConnectionServiceLayer()" class="form-create-connections">
          <mat-form-field appearance="outline">
            <mat-label>Base de Datos</mat-label>
            <input required type="text" placeholder="Base de datos..." aria-label="base de datos"
                   matInput [matAutocomplete]="auto" [formControlName]="'DataBaseCode'" (click)="GetFilteredDatabases()" />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let database of listFilteredDatabases | async" [value]="database">
                <span>{{ database }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Conexión a ServiceLayer</mat-label>
            <mat-select required [formControlName]="'ServerUrl'">
              <mat-option *ngFor="let connection of listConnections | async" [value]="connection.Value"
                          [matTooltip]="connection.Value" [matTooltipPosition]="'right'">
                <span>{{ connection.Name }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Licencia</mat-label>
            <input type="text" required matInput [formControlName]="'License'" placeholder="Usuario..." />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Contraseña</mat-label>
            <input [type]="hidePassword ? 'password' : 'text'" required matInput [formControlName]="'Password'"
                   placeholder="Contraseña..." />
            <button class="btn-icon" type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
                    [matTooltip]="hidePassword ? 'Mostrar contraseña' : 'Ocultar contraseña'">
              <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
          </mat-form-field>
          <div class="form-actions-container">
            <button type="submit" matTooltip="Agregar Conexión" mat-stroked-button>
              <span class="text-btn"><mat-icon>add_box</mat-icon> Agregar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="connections-list-container">
      <cl-table
        [Id]="ServiceLayerConnectionsTable"
        [MappedColumns]="displayedColumnsServiceLayer"
        [Records]="serviceLayerConnections"
        [PageSizeOptions]="pageSizeOptions"
        [ItemsPeerPage]="itemsPeerPage"
        [ShouldPaginateRequest]="shouldPaginateRequest"
        [Buttons]="connectionTableButtons"
        [ScrollHeight]="scrollHeight"
        PaginationType="dba"
      ></cl-table>
    </div>
  </div>
  <div class="footer-container" mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close matTooltip="Configuración terminada">
      <span class="text-btn"><mat-icon>done</mat-icon> Aceptar</span>
    </button>
  </div>
</section>
