import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CL_CHANNEL, ICLEvent, LinkerService } from "@clavisco/linker";
import { MapDisplayColumns, MappedColumns } from "@clavisco/table";
import { Component, Inject, OnInit } from '@angular/core';

import { ISapObjectResStructure } from "../../../../models/interfaces/sap-object/i-sap-object-res-structure";
import { IStructureSummaryModal } from "../../../../models/interfaces/sap-object/i-structure-summary-modal";
import { StateColors } from "../../../../common/constants";

@Component({
  selector: 'app-modal-summary-response',
  templateUrl: './modal-summary-response.component.html',
  styleUrls: ['./modal-summary-response.component.sass']
})
export class ModalSummaryResponseComponent implements OnInit {
  summaryInformationForTabla: ISapObjectResStructure [] = [];
  countSummaryInformationForTabla: number = 0;

  dataStructureModal: IStructureSummaryModal = { title: '', icon: '' };
  idResponseSummaryTable: string = "response-summary-table";
  displayedColumnsSummary: MappedColumns;
  currentPage: number = 1;
  itemsPeerPage: number = 4;

  modalResponseStatus: string = StateColors.Success;

  constructor(
    private dialogRef: MatDialogRef<ModalSummaryResponseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('LinkerService') private linkerService: LinkerService
  ) {
    this.summaryInformationForTabla = data.map((object: ISapObjectResStructure) => {
      return { ...object, UdoConnection: object.UdoConnection.DataBaseCode, TextStatus: object.Status ? "Creado" : "No Creado" }
    });
    this.displayedColumnsSummary = MapDisplayColumns({
      dataSource: [],
      renameColumns: { TextStatus: 'Estado', Summary: 'Resumen', UdoConnection: 'Base de Datos' },
      ignoreColumns: [ 'Status' ]
    });
  };

  ngOnInit(): void {
    this.LoadTableData();
    this.ValidateStatus();
  };

  LoadTableData(): void {
    this.countSummaryInformationForTabla = this.summaryInformationForTabla.length;
    const CURRENT_TABLE_STATE = {
      CurrentPage: this.currentPage,
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.summaryInformationForTabla,
      RecordsCount: this.countSummaryInformationForTabla
    };
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.idResponseSummaryTable,
      Data: JSON.stringify(CURRENT_TABLE_STATE)
    } as ICLEvent);
  };

  ValidateStatus(): void {
    const allTrue: boolean = this.summaryInformationForTabla.every(item => item.Status);

    switch (true) {
      case allTrue:
        this.dataStructureModal = { title: 'Solicitud procesada con éxito', icon: 'check_circle' };
        this.modalResponseStatus = StateColors.Success;
        break;
      default:
        this.dataStructureModal = { title: "Ocurrieron algunos errores", icon: "warning" };
        this.modalResponseStatus = StateColors.Warning;
        break;
    }
  };

  HandleModalClose(action: string): void {
    this.dialogRef.close(action);
  };
}
