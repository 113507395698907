<mat-sidenav-container>
  <mat-sidenav id="menuItem" #sidenav opened="true" mode="side">
    <app-sidenav-menu>
    </app-sidenav-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar id="tool">
      <mat-toolbar-row class="header-items">
        <div class="item-title">
          <ng-template [ngIf]="!sidenav.opened" [ngIfElse]="opened">
            <button mat-icon-button>
              <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
            </button>
          </ng-template>
          <ng-template #opened>
            <button mat-icon-button>
              <mat-icon (click)="sidenav.toggle()">chevron_left</mat-icon>
            </button>
          </ng-template>
          <h1>{{ 'Administrador de aplicaciones' | uppercase}}</h1>
        </div>
        <div class="item-user-logued">
          <h5 class="username"><mat-icon class="mr-1">person</mat-icon>{{logedUserName}}</h5>
          <a mat-list-item (click)="LogOut()">
            <button mat-icon-button class="exit-button ">
              <mat-icon>exit_to_app</mat-icon>
            </button>
          </a>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <div class="home-content">
      <div *ngIf="!showHomeMenu; else Menu">
        <router-outlet></router-outlet>
      </div>
    </div>
    <ng-template #Menu>
      <div id="home-menu">

        <div class="row-container">
          <div class="border welcome-card">
            <div class="w-100 flex-row">
              <div>
                <div class="welcome-title">
                  
                </div>
              </div>
              <div>
                <img src="../../../../assets/image/cl-manager.png" class="logo">
              </div>
            </div>
          </div>
        </div>

      </div>
    </ng-template>
  </mat-sidenav-content>
</mat-sidenav-container>