import { CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown } from "@clavisco/linker";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MapDisplayColumns, MappedColumns } from "@clavisco/table";
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { ICLTableButton } from "@clavisco/table/lib/table.space";
import { AlertsService, CLToastType } from "@clavisco/alerts";
import { Observable, of, Subscription } from "rxjs";
import { Structures } from "@clavisco/core";

import { ISapDefinitionSettings } from "../../../../models/interfaces/sap-object/i-sap-definition-settings";
import { IUserFieldValidValue } from "../../../../models/interfaces/sap-object/i-user-field-valid-value";
import { IUserDefinedField } from "../../../../models/interfaces/sap-object/i-user-defined-field";
import { IFieldTypes } from "../../../../models/interfaces/sap-object/i-field-types";
import { SettingService } from "../../../../services/setting.service";

@Component({
  selector: 'app-modal-add-udf',
  templateUrl: './modal-add-udf.component.html',
  styleUrls: ['./modal-add-udf.component.sass']
})
export class ModalAddUdfComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  listUDFs: IUserDefinedField[] = [];
  udfToEdit: IUserDefinedField;
  dbCodes: Set<string> = new Set<string>();

  listFieldTypes!: Observable<IFieldTypes[]>;
  listFieldSubTypes!: Observable<string[]>;
  sapDefinitions: ISapDefinitionSettings;

  isSelectedType: boolean = false;

  // #region Table Valid Values
  validValueTableId: string = 'valid-value-table';
  userFieldValidValues: IUserFieldValidValue[] = [];
  recordsCount: number = this.userFieldValidValues.length;
  displayedColumns!: MappedColumns;
  pageSizeOptions: number[] = [5, 10, 15];
  itemsPeerPage: number = this.pageSizeOptions[0];
  shouldPaginateRequest: boolean = false;
  scrollHeight: string = '515px';
  currentPage: number = 1;
  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };
  validValuesTableButtons: ICLTableButton[] = [
    {
      Title: "Eliminar",
      Action: Structures.Enums.CL_ACTIONS.DELETE,
      Icon: "delete",
      Color: "primary"
    }
  ];
  // #endregion

  constructor(
    private alertService: AlertsService,
    private settingService: SettingService,
    private dialogRef: MatDialogRef<ModalAddUdfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('LinkerService') private linkerService: LinkerService
  ) {
    this.listUDFs = data.listUDFS;
    this.dbCodes = data.dbCodes;
    this.udfToEdit = data.udfToEdit;
    this.displayedColumns = MapDisplayColumns<IUserFieldValidValue, null>({
      dataSource: [],
      renameColumns: { Value: 'Valor', Description: 'Descripción' },
      ignoreColumns: [],
      tooltipsPosition: { rows: 'left', cells: 'left' },
    });
  };

  ngOnInit(): void {
    Register<CL_CHANNEL>(this.validValueTableId, CL_CHANNEL.OUTPUT, this.ValidValueTableOptions, this.callbacks);
    this.subscription.add(this.linkerService.Flow()
      ?.pipe(StepDown<CL_CHANNEL>(this.callbacks))
      .subscribe({
        next: (callback) =>
          Run(callback.Target, callback, this.callbacks.Callbacks),
        error: (error) => this.alertService.ShowAlert({ HttpErrorResponse: error })
      })
    );
    this.loadInitialData((): void => {
      if (this.udfToEdit) {
        this.formToAddUDF.patchValue({
          ...this.udfToEdit,
          Mandatory: this.udfToEdit.Mandatory === 'tYES'
        });
        this.userFieldValidValues = this.udfToEdit.ValidValuesMD;
        this.SelectedType(this.udfToEdit.Type, this.udfToEdit.SubType);
        this.LoadTableData();
      }
    });
  };

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  };

  formToAddUDF: FormGroup = new FormGroup({
    Name: new FormControl('', Validators.required),
    DefaultValue: new FormControl(''),
    Type: new FormControl('', Validators.required),
    SubType: new FormControl(''),
    Size: new FormControl('', Validators.required),
    EditSize: new FormControl('', Validators.required),
    FieldID: new FormControl(0),
    TableName: new FormControl('', Validators.required),
    Description: new FormControl('', Validators.required),
    Mandatory: new FormControl(''),
    ValidValuesMD: new FormControl([]),
  });

  formUserFieldValidValue: FormGroup = new FormGroup({
    Value: new FormControl('', Validators.required),
    Description: new FormControl('', Validators.required)
  });

  loadInitialData(callback: () => void): void {
    let sapDefinitions = this.settingService.GetSAPDefinitions();
    sapDefinitions.subscribe({
      next: (resSapDefinitions): void => {
        if (resSapDefinitions?.Data) {
          this.sapDefinitions = resSapDefinitions.Data;
          this.listFieldTypes = of(this.sapDefinitions?.FieldTypes);
        }
      },
      error: (error): void => {
        this.alertService.ShowAlert({ HttpErrorResponse: error });
      },
      complete: (): void => {
       callback();
      }
    });
  };

  LoadTableData(): void {
    this.recordsCount = this.userFieldValidValues.length > 0 ? this.userFieldValidValues.length : 0;
    const CURRENT_TABLE_STATE = {
      CurrentPage: this.currentPage,
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.userFieldValidValues,
      RecordsCount: this.recordsCount
    };
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.validValueTableId,
      Data: JSON.stringify(CURRENT_TABLE_STATE)
    } as ICLEvent);
  };

  ValidValueTableOptions = (_event: ICLEvent) : void => {
    const event = JSON.parse(_event.Data);
    const userFieldValidValue: IUserFieldValidValue = JSON.parse(event.Data);

    switch (event.Action){
      case Structures.Enums.CL_ACTIONS.DELETE:
        const updatedValidValues: IUserFieldValidValue[] = this.userFieldValidValues.filter((option: IUserFieldValidValue) =>
          option.Value !== userFieldValidValue.Value && option.Description !== userFieldValidValue.Description
        );
        this.formToAddUDF.get('ValidValuesMD')?.setValue(updatedValidValues);
        this.userFieldValidValues = updatedValidValues;

        this.LoadTableData();
        this.alertService.Toast({
          type: CLToastType.SUCCESS,
          message: "Valor eliminado exitosamente"
        });
        break;
    }
  };

  SelectedType(selectedValue: string, subType?: string): void {
    this.isSelectedType = true;
    const fieldType = this.sapDefinitions?.FieldTypes.find(x => x.Type == selectedValue);
    this.listFieldSubTypes = of(fieldType!.SubTypes);
    this.formToAddUDF.get('SubType')?.setValue(subType || '');
  };

  AddUDF(): void {
    if (this.formToAddUDF.invalid) {
      this.formToAddUDF.markAllAsTouched();
      return this.alertService.Toast({ message: 'El formulario es inválido, los campos son requeridos', type: CLToastType.ERROR });
    }
    const userDefinedField: IUserDefinedField = this.formToAddUDF.value;
    userDefinedField.Mandatory = this.formToAddUDF.get('Mandatory')?.value ? 'tYES' : 'tNO';

    const existingUDF = this.listUDFs.find(UDF =>
      UDF.Name.toLowerCase() === userDefinedField.Name.toLowerCase() &&
      UDF.TableName.toLowerCase() === userDefinedField.TableName.toLowerCase()
    );

    if(this.udfToEdit){
      // Update UDF
      const indexUDF: number = this.listUDFs.findIndex((udf: IUserDefinedField): boolean => udf.Name === this.udfToEdit?.Name);
      if (this.listUDFs.some((udf: IUserDefinedField, idx: number) => idx !== indexUDF &&
        udf.Name.toLowerCase() === userDefinedField.Name.toLowerCase() &&
        udf.TableName.toLowerCase() === userDefinedField.TableName.toLowerCase())) {
        return this.alertService.Toast({ message: `Ya existe un UDF con el nombre ${userDefinedField.Name} en la tabla ${userDefinedField.TableName}`, type: CLToastType.ERROR });
      }
      this.listUDFs[indexUDF] = userDefinedField;
      this.alertService.Toast({ type: CLToastType.SUCCESS, message: 'El UDF se ha actualizado exitosamente' });
      this.HandleModalClose();
    } else {
      // Add UDF
      if( existingUDF ){
        return this.alertService.Toast({
          message: `'Ya existe un UDF con el nombre ${ userDefinedField.Name } en la tabla ${ userDefinedField.TableName }`,
          type: CLToastType.ERROR
        });
      }
      this.listUDFs.push(userDefinedField);
      this.dbCodes.add(userDefinedField.TableName);
      this.alertService.Toast({ message: 'El UDF se agrego correctamente', type: CLToastType.SUCCESS });
    }
  };

  AddFieldValidValues(): void {
    if (this.formUserFieldValidValue.invalid) {
      this.formUserFieldValidValue.markAllAsTouched();
      return this.alertService.Toast({ message: 'El formulario es inválido, los campos son requeridos', type: CLToastType.ERROR });
    }
    const formData: IUserFieldValidValue = this.formUserFieldValidValue.value;

    if(this.userFieldValidValues?.find(option => option.Value.toLowerCase() == formData.Value.toLowerCase())){
      return this.alertService.Toast({ message: 'El valor ya existe', type: CLToastType.ERROR });
    }
    if(this.userFieldValidValues?.find(option => option.Description.toLowerCase() == formData.Description.toLowerCase())){
      return this.alertService.Toast({ message: 'La descripción ya existe', type: CLToastType.ERROR });
    }

    const currentValues = this.formToAddUDF.get('ValidValuesMD')?.value || [];
    const updatedValidValues: IUserFieldValidValue[] = [...currentValues, formData];
    this.formToAddUDF.get('ValidValuesMD')?.setValue(updatedValidValues);
    this.userFieldValidValues = updatedValidValues;

    this.LoadTableData();
    this.formUserFieldValidValue.reset();
  };

  HandleModalClose(): void {
    this.dialogRef.close(this.listUDFs);
  };

  ClearTableValidValues(): void {
    this.userFieldValidValues = [];
    this.formUserFieldValidValue.reset();
    this.formToAddUDF.get('ValidValuesMD')?.setValue([]);
    this.LoadTableData();
  }
}
