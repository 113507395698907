<div class="container">
    <div class="header">
        <span class="title">
            Descargar Archivos
        </span>
        <div *ngIf="CanCreateFile()" class="content-right">
            <button mat-stroked-button (click)="OpenDialog()"><mat-icon class="icon-color">cloud_upload</mat-icon>
                Cargar Archivos
            </button>
        </div>
    </div>
    <mat-card class="mt-2 mat-elevation-z0">
        <div class="users-content">
            <mat-list *ngIf="listFiles.length > 0; else NotFiles" class="content">
                <mat-divider></mat-divider>
                <mat-list-item *ngFor="let file of listFiles; let i=index">

                    <mat-icon style="border-radius: 0%;" matListAvatar class="icon-color">description</mat-icon>
                    <button mat-stroked-button *ngIf="CanDownloadFile()">
                        <mat-icon class="pointer icon-color"
                            (click)="DownloadFileBlob(file)">download</mat-icon>
                    </button>
                    <h3 matLine class="text-file"> {{getNameFile(file.Name)}}
                        <div class="text-file">
                            <p class="p-file">{{file.CreatedDate | date: 'dd/MM/yyyy'}}</p>
                            <p class="p-file">{{getConvertBytes(file.Size || 0)}}</p>
                        </div>
                    </h3>
                    <mat-divider></mat-divider>
                </mat-list-item>

            </mat-list>
            <ng-template #NotFiles>
                <div class="dropzone" (click)="OpenDialog()">
                    <img id="file" mat-card-image src="../../../../assets/image/download-files.png"
                        alt="No hay archivos">
                    <h2>No existen archivos</h2>
                    <h2>Agregue nuevos archivos</h2>
                </div>
            </ng-template>
        </div>
    </mat-card>
</div>
