import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IResponse } from '../models/i-response';
import { IProjectStatusP, IProjectStatusTable } from '../models/interfaces/i-project-status';
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {IProjectStatus} from "../../core/interfaces/i-project-status";

@Injectable({
  providedIn: 'root'
})
export class ProjectStatusService {
  private readonly CONTROLLER = '/api/ProjectStatus';
  constructor(private http: HttpClient) { }

  GetProyectStatus(): Observable<ICLResponse<IProjectStatus[]>> {
    return this.http.get<ICLResponse<IProjectStatus[]>>(`${this.CONTROLLER}`);
  }

  GetProyectStatusFilter(_startPos: number, _rowsReturned: number, _nameStatus: string, _isActive: string): Observable<ICLResponse<IProjectStatus[]>> {
    return this.http.get<ICLResponse<IProjectStatus[]>>(`${this.CONTROLLER}/Filter`, {
      params: new HttpParams()
        .set('startPos', _startPos.toString())
        .set('rowsReturned', _rowsReturned.toString())
        .set('nameStatus', _nameStatus)
        .set('isActive', _isActive)
    });
  }

  PostProjectStatus(projectStatus: IProjectStatus): Observable<ICLResponse<IProjectStatus>> {
    return this.http.post<ICLResponse<IProjectStatus>>(
      `${this.CONTROLLER}`,
      projectStatus
    );
  }
  PatchProjectStatus(projectStatus: IProjectStatus): Observable<ICLResponse<IProjectStatus>> {
    return this.http.patch<ICLResponse<IProjectStatus>>(
      `${this.CONTROLLER}`,
      projectStatus
    );
  }

}

