import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.staging';
import { IToken } from '../models/interfaces/i-token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
  ) {}

  LogIn(username: string, password: string): Observable<IToken> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('userName', username)
      .set('password', password);
    return this.http.post<IToken>(
      `${environment.ApiUrl}token`,
      body.toString(),
      { headers }
    );
  }

  // LogOut(): void{
  //   this.sessionService.RemoveSession()
  // }
}
