import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SettingService } from "../../../../services/setting.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AlertsService, CLToastType } from "@clavisco/alerts";
import { Component, Inject, OnInit } from '@angular/core';
import { OverlayService } from "@clavisco/overlay";
import { filter, finalize, map } from "rxjs";

import { IUserDefinedTable } from "../../../../models/interfaces/sap-object/i-user-defined-table";

@Component({
  selector: 'app-modal-add-udt',
  templateUrl: './modal-add-udt.component.html',
  styleUrls: ['./modal-add-udt.component.sass']
})
export class ModalAddUdtComponent implements OnInit {
  listUDTs: IUserDefinedTable[] = [];
  udtToEdit?: IUserDefinedTable;
  listTableTypes: string[] = [];

  userDefinedTableForm: FormGroup = new FormGroup({
    TableName: new FormControl("", Validators.required),
    TableDescription: new FormControl("", Validators.required),
    TableType: new FormControl("", Validators.required),
    Archivable: new FormControl(false),
    ArchiveDateField: new FormControl(null),
  })

  constructor(
    private dialogRef: MatDialogRef<ModalAddUdtComponent>,
    private alertService: AlertsService,
    private settingService: SettingService,
    private overlayService: OverlayService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.listUDTs = data.listUDTS;
    this.udtToEdit = data.udtToEdit;
  }

  ngOnInit(): void {
    this.LoadDataRequired();
    if(this.udtToEdit){
      this.userDefinedTableForm.patchValue({
        ...this.udtToEdit,
        Archivable: this.udtToEdit.Archivable === 'tYES'
      });
    }
  }

  LoadDataRequired() {
    this.overlayService.OnGet();
    this.settingService.GetSAPDefinitions().pipe(
      finalize(() => {
        this.overlayService.Drop();
      }),
      filter(data => data != null && data.Data.TableTypes != null),
      map(data => data.Data.TableTypes.map(t => t.Name))
    ).subscribe(response => {
      this.listTableTypes = response;
    });
  }

  HandleModalClose() {
    this.dialogRef.close(this.listUDTs);
  }

  AddUdt() {
    if (this.userDefinedTableForm.invalid) {
      this.userDefinedTableForm.markAllAsTouched();
      return this.alertService.Toast({ message: 'El formulario es inválido, los campos son requeridos', type: CLToastType.ERROR });
    }

    let name: string = this.userDefinedTableForm.get('TableName')?.value;
    let tableType = this.userDefinedTableForm.get('TableType')?.value;
    let archivable: string = this.userDefinedTableForm.get('Archivable')?.value? 'tYES' : 'tNO';

    const udt: IUserDefinedTable = {
      TableName: name,
      TableDescription: this.userDefinedTableForm.get('TableDescription')?.value,
      TableType: tableType,
      Archivable: archivable,
      ArchiveDateField: this.userDefinedTableForm.get('ArchiveDateField')?.value,
    };

    if(this.udtToEdit){
      // Update UDT
      if (this.listUDTs.some((udt: IUserDefinedTable) => udt.TableName.toLowerCase() === name.toLowerCase() && udt.TableName !== this.udtToEdit?.TableName)) {
        return this.alertService.Toast({ message: 'Ya existe una tabla con ese nombre', type: CLToastType.ERROR });
      }
      const indexUDT: number = this.listUDTs.findIndex((udt: IUserDefinedTable): boolean => udt.TableName === this.udtToEdit?.TableName);
      this.listUDTs[indexUDT] = udt;
      this.alertService.Toast({ type: CLToastType.SUCCESS, message: 'El UDT se ha actualizado exitosamente' });
      this.HandleModalClose();
    } else {
      // Agg UDT
      if (this.listUDTs.some((udt: IUserDefinedTable): boolean => udt.TableName.toLowerCase() === name.toLowerCase())) {
        return this.alertService.Toast({message: 'Ya existe una tabla con ese nombre', type: CLToastType.ERROR});
      }
      if( !this.listTableTypes.includes(tableType) ){
        return this.alertService.Toast({message: 'El tipo de tabla no es válido', type: CLToastType.ERROR});
      }
      this.listUDTs.push(udt);
    }
  }
}
