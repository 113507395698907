<mat-card>
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title>
        {{ repository.Name }}
      </mat-card-title>
      <mat-card-subtitle>{{ repository.Slug }}</mat-card-subtitle>
    </mat-card-title-group>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div>
      <ul>
        <li><strong>Lenguaje: </strong>{{ repository.Lenguaje }}</li>
        <li><strong>Main Branch: </strong>{{ repository.MainBranchName }}</li>
        <li>
          <strong>Nombre del Proyecto: </strong>{{ repository.ProjectName }}
        </li>
        <li>
          <strong>Enlace: </strong>{{ repository.Url }}
        </li>
      </ul>
    </div>
    <div>
      <h3>Tipo de Proyecto</h3>
      <mat-form-field appearance="fill">
        <mat-label>Tipo de Repositorio</mat-label>
        <mat-select>
          <mat-option *ngFor="let type of types" [value]="type">
            {{type}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="Cancel()"><fa-icon [icon]="['fas', 'cancel']"></fa-icon> Cancelar</button>
    <button mat-button (click)="SaveRepository()"><fa-icon [icon]="['fas', 'save']"></fa-icon> Guardar</button>
  </mat-card-actions>
  <mat-card-footer>
    <mat-progress-bar></mat-progress-bar>
  </mat-card-footer>
</mat-card>
