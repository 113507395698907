import {Component, Inject, OnInit} from '@angular/core';
import { environment } from 'src/environments/environment';
import { LinkerService, } from "@clavisco/linker";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
  //styleUrls: ['./login.component.sass']
})

export class LoginComponent implements OnInit {

  LoginId: string = 'LoginId';
  ShouldResolve: boolean = true;
  ApiUrl: string = environment.ApiUrl + '/api/';
  PathToRedirect: string ='/index';
  SesionName: string ='session';
  LogoPath: string ='./assets/image/logo.png';
  DotNetApiType: 'CORE' = 'CORE';
  useReCaptcha: boolean = true

  constructor(@Inject('LinkerService') private linkerervice: LinkerService) {

  }

  ngOnInit(): void {
  }



}
