<div>
    <div class="align-right"><mat-icon class="icon-close" (click)="CloseDialog()">close</mat-icon></div>
    <h2 style="text-align: center;">{{data.serverName}}</h2>
        <div class="table-container mat-elevation-z0">
            <cl-table 
                [Id]="tableId"
                [MappedColumns]="displayedColumns"
                [Records]="dataBases"
                [PageSizeOptions]="pageSizeOptions"
                [ItemsPeerPage]="itemsPeerPage"
                [RecordsCount]="recordsCount"
                [HasPaginator]="hasPaginator"
                [ScrollHeight]="scrollHeight">
            </cl-table>
        </div>
</div>