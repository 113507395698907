import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.sass']
})
export class ReportsComponent implements OnInit {

  appKey: number = environment.ReportKey
  rptmngId ='rptmngId';

  constructor() { }

  ngOnInit(): void {
  }

}
