import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IResponse } from '../models/i-response';
import { IProjectDeliverables } from '../models/interfaces/i-proyects';
import { Observable } from 'rxjs';
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Injectable({
    providedIn: 'root'
})
export class ProyectDeliverableService {
    currentTime: Date = new Date();
    private readonly CONTROLLER = '/api/ProjectDeliverables'


    constructor(
        private http: HttpClient) { }

    getDeliverablesByProject(projectId: string | null, _initDate: Date, _endDate: Date): Observable<IResponse<IProjectDeliverables[]>> {
        let initDate = '';
        let endDate = '';
        if (_initDate && _endDate) {
            initDate = `${_initDate.getFullYear()}-${_initDate.getMonth() + 1}-${_initDate.getDate()} ${_initDate.getHours()}:${_initDate.getMinutes()}:${_initDate.getSeconds()}`;
            endDate = `${_endDate.getFullYear()}-${_endDate.getMonth() + 1}-${_endDate.getDate()} ${this.currentTime.getHours()}:${this.currentTime.getMinutes()}:${this.currentTime.getSeconds()}`;
        }

        return this.http.get<IResponse<IProjectDeliverables[]>>(`${environment.ApiUrl}/api/ProjectDeliverables/GetDeliverablesByProjectId`, {
            params: new HttpParams()
                .set('projectId', projectId ? projectId : '')
                 .set('initDate', initDate)
                 .set('endDate', endDate)
        })
    }

    getDeliverablesById(id: number) {
        return this.http.get<IResponse<IProjectDeliverables>>(`${environment.ApiUrl}api/ProjectDeliverables/GetDeliverablesById?id=${id}`)
    }

    SaveProyectDeliverable(data: IProjectDeliverables): Observable<ICLResponse<IProjectDeliverables>> {
      return this.http.post<ICLResponse<IProjectDeliverables>>(`${this.CONTROLLER}/StoreProjectDeliverables`, data);
    }
}
