import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { IAppLink } from 'src/app/models/interfaces/i-appLink';

@Component({
  selector: 'app-app-links-modal',
  templateUrl: './app-links-modal.component.html',
  styleUrls: ['./app-links-modal.component.sass']
})
export class AppLinksModalComponent implements OnInit {

  displayedColumns: String[] = ["UserName", "Link", "CreatedDate",]
  ListLinks!: MatTableDataSource<IAppLink>

  constructor(@Inject(MAT_DIALOG_DATA) public data: IAppLink[],
    private dialogRef: MatDialogRef<AppLinksModalComponent>) {
    this.ListLinks = new MatTableDataSource();

  }

  ngOnInit(): void {
    if (this.data) {
      this.ListLinks = new MatTableDataSource(this.data);
    }
  }

  OpenLink(link: string): void {
    window.open(link)
  }

  CloseDialog(): void {
    this.dialogRef.close();
  }

  deleteRow(row: any): void {
    // Implement your logic to delete the row from the dataSource (ListLinks)
    const index = this.ListLinks.data.findIndex(row);
    if (index !== -1) {
      this.ListLinks.data.splice(index, 1);
    }
  }

}
