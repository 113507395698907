import { CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown } from "@clavisco/linker";
import { catchError, forkJoin, map, Observable, of, startWith, Subscription } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MapDisplayColumns, MappedColumns } from "@clavisco/table";
import { ICLTableButton } from "@clavisco/table/lib/table.space";
import { AlertsService, CLToastType } from "@clavisco/alerts";
import { Component, Inject, OnInit } from '@angular/core';
import { Structures } from "@clavisco/core";

import { IServiceLayerConnection } from "../../../../models/interfaces/sap-object/i-service-layer-connection";
import { IUdoConnection } from "../../../../models/interfaces/sap-object/i-udo-connection";
import { ConnectionService } from "../../../../services/connection.service";
import { SAPObjectService } from "../../../../services/sap-object.service";
import { SettingService } from "../../../../services/setting.service";

@Component({
  selector: 'app-udo-connections-modal',
  templateUrl: './udo-connections-modal.component.html',
  styleUrls: ['./udf-connections-modal.component.sass']
})
export class UdoConnectionsModalComponent implements OnInit {
  subscription: Subscription= new Subscription()
  listFilteredDatabases!: Observable<string[]>;
  listConnections!: Observable<IServiceLayerConnection[]>;
  databases: string[] = [];
  hidePassword: boolean = true;

  // #region Table Connections
  ServiceLayerConnectionsTable: string = "service-layer-connections-table";
  displayedColumnsServiceLayer: MappedColumns;
  serviceLayerConnections: IUdoConnection[] = [];
  recordsCount: number = this.serviceLayerConnections.length;
  pageSizeOptions: number[] = [5, 10, 20];
  itemsPeerPage: number = this.pageSizeOptions[0];
  currentPage: number = 1;
  shouldPaginateRequest: boolean = false;
  scrollHeight: string = '515px';
  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };
  connectionTableButtons: ICLTableButton[] = [
    {
      Title: "Eliminar",
      Action: Structures.Enums.CL_ACTIONS.DELETE,
      Icon: "delete",
      Color: "primary"
    }
  ];
  // #endregion

  constructor(
    private alertService: AlertsService,
    private sapObjectService: SAPObjectService,
    private settingService: SettingService,
    private connectionService: ConnectionService,
    @Inject('LinkerService') private linkerService: LinkerService
  ) {
    this.displayedColumnsServiceLayer = MapDisplayColumns({
      dataSource: this.serviceLayerConnections,
      renameColumns: { DataBaseCode: 'Base de Datos', ServerUrl: 'Conexion', License: 'Licencia' },
      ignoreColumns: ['Password'],
    });
  };

  ConnectionTableOptions = (_event: ICLEvent) : void => {
    if (_event.Data) {
      const BUTTON_EVENT = JSON.parse(_event.Data);
      const ELEMENT = JSON.parse(BUTTON_EVENT.Data);

      switch (BUTTON_EVENT.Action) {
        case Structures.Enums.CL_ACTIONS.DELETE:
          this.connectionService.DeleteConnection(ELEMENT);
          this.UpdateServiceLayerConnections();
          this.alertService.Toast({
            type: CLToastType.SUCCESS,
            message: "Conexión eliminada exitosamente"
          });
          break;
      }
    }
  };

  ngOnInit(): void {
    Register<CL_CHANNEL>(this.ServiceLayerConnectionsTable, CL_CHANNEL.OUTPUT, this.ConnectionTableOptions, this.callbacks);
    this.subscription.add(this.linkerService.Flow()
      ?.pipe(StepDown<CL_CHANNEL>(this.callbacks))
      .subscribe({
        next: (callback) =>
          Run(callback.Target, callback, this.callbacks.Callbacks),
        error: (error) => this.alertService.ShowAlert({ HttpErrorResponse: error })
      })
    );
    this.LoadInitialData();
    this.UpdateServiceLayerConnections();
  };

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  };

  formConnectionServiceLayer: FormGroup = new FormGroup({
    DataBaseCode: new FormControl('', Validators.required),
    ServerUrl: new FormControl('', Validators.required),
    License: new FormControl('', Validators.required),
    Password: new FormControl('', Validators.required),
  });

  LoadInitialData(): void {
    let connection = this.settingService.GetServiceLayerConnections();
    forkJoin([
      connection.pipe(catchError(async (error)=> this.alertService.ShowAlert({ HttpErrorResponse: error }))),
    ]).subscribe(([respConnection]) => {
      if(respConnection?.Data){
        this.listConnections = of(respConnection.Data);
      }
    });
  };

  GetFilteredDatabases() {
    if(this.formConnectionServiceLayer.get('DataBaseCode')?.value !== null){
      this.listFilteredDatabases = this.formConnectionServiceLayer.get('DataBaseCode')!.valueChanges.pipe(
        startWith(''),
        map((x: string | null) => (x ? this.FilterDatabases(x) : this.databases.slice())),
      );
    }
  };

  private FilterDatabases(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.databases.filter(s => s.toLowerCase().includes(filterValue));
  };

  UpdateServiceLayerConnections = () => {
    this.serviceLayerConnections = this.connectionService.GetConnections();
    this.recordsCount = this.serviceLayerConnections.length > 0 ? this.serviceLayerConnections.length : 0;
    const NEXT_TABLE_STATE = {
      CurrentPage: this.currentPage,
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.serviceLayerConnections,
      RecordsCount: this.recordsCount,
    };
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.ServiceLayerConnectionsTable,
      Data: JSON.stringify(NEXT_TABLE_STATE)
    } as ICLEvent);
  };

  ClearFormConnectionServiceLayer = ()  => {
    this.formConnectionServiceLayer.reset();
    this.formConnectionServiceLayer.markAsPristine();
    this.formConnectionServiceLayer.markAsUntouched();
  };

  OnConnectionServiceLayer() {
    if(this.formConnectionServiceLayer.valid){
      const formData: IUdoConnection = this.formConnectionServiceLayer.value;
      const addedConnection = this.connectionService.AddConnection(formData);
      if(addedConnection){
        this.UpdateServiceLayerConnections();
        this.ClearFormConnectionServiceLayer();
        this.alertService.Toast({
          type: CLToastType.SUCCESS,
          message: "Conexión agregada exitosamente"
        });
      } else {
        this.alertService.Toast({
          type: CLToastType.ERROR,
          message: "Conexión ya existe"
        });
      }
    } else {
      this.alertService.Toast({
        type: CLToastType.ERROR,
        message: "Formulario no valido"
      });
    }
  };
}
