import { HttpClient, HttpParams } from '@angular/common/http';
import ICLResponse = Structures.Interfaces.ICLResponse;
import { Structures } from "@clavisco/core";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IUsersDefineObject } from "../models/interfaces/sap-object/i-users-define-object";
import { IUsers, IUserTable} from '../models/interfaces/i-users';
import { environment } from 'src/environments/environment';
import { SessionService } from './session.service';
import { IResponse } from '../models/i-response';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private sessionService: SessionService) { }

  Get(): Observable<ICLResponse<IUsers[]>>{
    return this.http.get<ICLResponse<IUsers[]>>(`${environment.ApiUrl}/api/Users`);
  }

  GetAllUsersDefineObjects(): Observable<ICLResponse<IUsersDefineObject[]>>{
    return this.http.get<ICLResponse<IUsersDefineObject[]>>(`${environment.ApiUrl}/api/Users/GetAllUsersDefineObjects`)
  }

  GetUsersP(_userName: string, _startPos: number, _rowsReturned: number): Observable<IResponse<IUserTable[]>> {
    return this.http.get<IResponse<IUserTable[]>>(`${environment.ApiUrl}/api/Users/Filter`, {
      params: new HttpParams()
        .set('name', _userName.toString())
        .set('startPos', _startPos.toString())
        .set('rowsReturned', _rowsReturned.toString())
    });
  }

  SaveUser(user: IUsers): Observable<IResponse<IUsers>> {
    user.UpdatedBy = user.CreatedBy = "sys"
    return this.http.post<IResponse<IUsers>>(
      `${environment.ApiUrl}/api/Users`,
      user
    );
  }

  Patch(user: IUsers): Observable<IResponse<IUsers>> {
    user.UpdatedBy = user.CreatedBy = "sys"
    return this.http.patch<IResponse<IUsers>>(
      `${environment.ApiUrl}/api/Users`,
      user
    )
  }

  GetUserById(userId: number): Observable<ICLResponse<IUsers>> {
    const query = new HttpParams().set('id', userId)
    return this.http.get<ICLResponse<IUsers>>(`${environment.ApiUrl}/api/Users/GetUserById`, { params: query })
  }

}
