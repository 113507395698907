import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  
  constructor() { }
  
  SetStorageData(key: string, value: string): void {
    localStorage.setItem(key, value)
  }
  
  GetStorageData(key: string): string {
    return localStorage.getItem(key) || ''
  }

  RemoveStorage(key: string) {
    localStorage.removeItem(key)
  }
}
