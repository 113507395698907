<div *ngIf="pool; else elseB">
  <div class="align-right"><mat-icon class="icon-close" (click)="CloseDialog()">close</mat-icon></div>
  <h1 mat-dialog-title>{{pool.PoolName}}</h1>
  <div mat-dialog-content class="row">
    <div class="col">
      <div class="dataPool">
        <mat-form-field>
          <input matInput placeholder="User" [value]="pool.User" readonly="true">
        </mat-form-field>
      </div>
      <div class="dataPool">
        <mat-form-field>
          <input matInput placeholder="Servidor" [value]="pool.Server" readonly="true">
        </mat-form-field>
      </div>
      <div class="dataPool">
        <mat-form-field>
          <input matInput placeholder="Estado" [value]="pool.State" readonly="true">
        </mat-form-field>
      </div>
      <div class="dataPool">
        <mat-form-field>
          <input matInput placeholder="Último Registro" [value]="pool.UpdateDate | date :'short'" readonly="true">
        </mat-form-field>
      </div>
      <div class="dataPool">
        <mat-form-field>
          <input matInput placeholder="Habilitado 32 bit" [value]="pool.Enable32Bit ? 'Sí' : 'No'"
                 readonly="true">
        </mat-form-field>
      </div>
    </div>
    <div class="col">
      <div class="dataPool">
        <mat-form-field>
          <input matInput placeholder="Esquema" [value]="pool.Schema" readonly="true">
        </mat-form-field>
      </div>
      <div class="dataPool">
        <mat-form-field>
          <input matInput placeholder="Ambiente" [value]="pool.Environment" readonly="true">
        </mat-form-field>
      </div>
      <div class="dataPool">
        <mat-form-field>
          <input matInput placeholder="Modo de Inicio" [value]="pool.StartMode" readonly="true">
        </mat-form-field>
      </div>
      <div class="dataPool">
        <mat-form-field>
          <input matInput placeholder="Auto Inicio" [value]="pool.AutoStart ? 'Sí' : 'No'" readonly="true">
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<ng-template #elseB>
  <h2>No existe informacion relacionada al pool</h2>
</ng-template>
