import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService, CLToastType } from '@clavisco/alerts';
import { OverlayService } from '@clavisco/overlay';
import {catchError, finalize, tap} from 'rxjs';
import { CheckListService } from 'src/app/services/checklist-details.service';
import {ICheckList, ICheckListRow} from "../../../../models/interfaces/i-checklist";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Component({
  selector: 'app-modal-check-list-details',
  templateUrl: './modal-check-list-details.component.html',
  styleUrls: ['./modal-check-list-details.component.sass']
})
export class ModalCheckListDetailsComponent implements OnInit {

  formDetails: FormGroup

  StatusList: string[] = [
    'TXT',
    'NUMBER',
  ];


  constructor(private dialogRef: MatDialogRef<ModalCheckListDetailsComponent>,
    private alertService: AlertsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private checkListService: CheckListService,
    private clBlockUI: OverlayService
  ) {
    this.formDetails = new FormGroup({
      Id: new FormControl(0, Validators.required),
      DisplayName: new FormControl('', Validators.required),
      Type: new FormControl('', Validators.required),
      IsRequired: new FormControl(false),
      Description: new FormControl(''),
      Name: new FormControl(''),
      CheckListId: new FormControl(this.data.idHeader),
      IsActive: new FormControl(true),
      CrystalParamName: new FormControl('P', [Validators.required]),
      CreatedBy: new FormControl(""),
      UpdatedBy: new FormControl("")
    })
  }

  ngOnInit(): void {
    if (this.data.edit) {
      this.formDetails.patchValue({
        Id: this.data.detail.Id,
        DisplayName: this.data.detail.DisplayName,
        Type: this.data.detail.Type,
        IsRequired: this.data.detail.IsRequired,
        Description: this.data.detail.Description,
        Name: this.data.detail.Name,
        IsActive: this.data.detail.IsActive,
        CrystalParamName: this.data.detail.CrystalParamName,
        CreatedBy: '',
        UpdatedBy: ''
      })
    }
  }

  CloseDialog(): void {
    this.dialogRef.close();
  }

  Save() {
    if (!this.formDetails.valid) {
      return this.alertService.Toast({ message: `El formulario es inválido, los campos son requeridos`, type: CLToastType.ERROR })
    }
    if (!this.data.edit) {
      const name = this.formDetails.get('DisplayName')?.value
      this.formDetails.get('Name')?.setValue(name)
    }
    this.clBlockUI.OnPost("Guardando...")
    if(this.data.detail.CheckListId > 0) {
      this.checkListService.PatchCheckListRow(this.formDetails.value).pipe(finalize(()=>{this.clBlockUI.Drop();}),
        tap((response: ICLResponse<ICheckListRow>) => {
          this.dialogRef.close();
          return this.alertService.Toast({ message: `Guardado Correctamente`, type: CLToastType.SUCCESS })
        })
        ,catchError((err) => {
          return [];
        })
      ).subscribe()
    }
    else{
      this.checkListService.PostCheckListRow(this.formDetails.value).pipe(finalize(()=>{this.clBlockUI.Drop();}),
        tap((response: ICLResponse<ICheckListRow>) => {
          this.dialogRef.close();
          return this.alertService.Toast({ message: `Guardado Correctamente`, type: CLToastType.SUCCESS })
        })
        ,catchError((err) => {
          return [];
        })
      ).subscribe()
    }
  }

}
