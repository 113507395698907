<div class="flex-container mt-3">

  <div cdkDropListGroup class="w-100" fxLayout="row wrap" fxLayoutAlign="space-around">

    <div fxFlex="100" fxFlex-xs="45" fxFlex.gt-xs="45" class="border">
      <div class="flex">
        <h2>Roles disponibles</h2>

      </div>
      <mat-divider></mat-divider>
      <mat-selection-list [multiple]="false" class="cdk-list">
        <mat-list-option *ngFor="let role of roleList" [value]="role.Name" class="cdk-role-box"
          [ngClass]="{ 'selected': role.Id == selectedRole?.Id}" (click)="ChargeRole(role)">
          {{ role.Description }}
        </mat-list-option>
      </mat-selection-list>
    </div>

    <div fxFlex="100" fxFlex-xs="45" fxFlex.gt-xs="45" class="border">
      <div class="flex-2">
        <h2>Permisos asignados y disponibles </h2>
      </div>

      <div class="bar-permission flex-2">

        <mat-form-field appearance="outline">
          <mat-label>Permiso</mat-label>
          <mat-icon matPrefix class="my-icon">search</mat-icon>
          <input matInput type="text" placeholder="Permiso" [formControl]="permissionFilter"
            (keydown.enter)="getPermissionsFilter()">
        </mat-form-field>
        <mat-slide-toggle [disabled]="!isSelected()" (click)="checkedRol()" [ngModel]="allSelected" (change)="setAllPermissions()">Seleccionar
          todos</mat-slide-toggle>
      </div>
      <mat-divider></mat-divider>
      <mat-selection-list [multiple]="false" class="cdk-list">
        <mat-list-option *ngFor="let perm of permissionsFiltered" [value]="perm.Name" class="cdk-role-box">
          <div class="bar-permission">
            {{perm.Description}}
            <mat-slide-toggle [disabled]="!isSelected()" (click)="checkedRol()" [ngModel]="perm.IsAssigned" (change)="SelectedPermission(perm)"></mat-slide-toggle>
          </div>
        </mat-list-option>
      </mat-selection-list>

    </div>
  </div>
</div>
