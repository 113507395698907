import { Injectable } from '@angular/core';

/**
 * Service responsible for handling and storing shared operations between components
 * */
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  /**
   * Transforms the received date into a format of YYYY-DD-MM HH-MM-SS
   * */
  FormatDate(fieldDate: Date): string {
    // Create a date with the received date value
    const date: Date = new Date(fieldDate);

    // Creation of each parameter to construct the date
    const year:    number = date.getFullYear();
    const day:     string = String(date.getDate()).padStart(2, '0');
    const month:   string = String(date.getMonth() + 1).padStart(2, '0');
    const hours:   string = String(date.getHours()).padStart(2, '0');
    const minutes: string = String(date.getMinutes()).padStart(2, '0');
    const seconds: string = String(date.getSeconds()).padStart(2, '0');

    // Returns the date structure according to the established format
    return `${year}-${day}-${month} ${hours}:${minutes}:${seconds}`;
  };

  /**
   * Validates if the URL received is indeed a URL
   * */
  isURL(url: string): boolean {
    // Constant to validate a URL
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    // Returns the comparison status with the URL validator
    return urlRegex.test(url)
  };

}
