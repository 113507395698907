import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IResponse } from '../models/i-response';
import { IInsertServer, IServer, IServersForProjects } from '../models/interfaces/i-server';
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Injectable({
  providedIn: 'root'
})
export class ServersService {
  private readonly CONTROLLER = '/api/Server';

  constructor(
    private http: HttpClient,
  ) { }

  Get(): Observable<ICLResponse<IServer[]>> {
    return this.http.get<ICLResponse<IServer[]>>(`${this.CONTROLLER}`,)
  }

  GetServers(_startPos: number, _rowsReturned: number, _name: string, _type: string): Observable<ICLResponse<IServer[]>> {
    return this.http.get<ICLResponse<IServer[]>>(`${this.CONTROLLER}/Filter`, {
      params: new HttpParams()
          .set('startPos', _startPos.toString())
          .set('rowsReturned', _rowsReturned.toString())
          .set('name', _name)
          .set('type', _type)
  })
  }

  StoreServer(data: IInsertServer): Observable<IResponse<number>> {
    return this.http.post<IResponse<number>>(`${this.CONTROLLER}`, data)
  }
  GetServersByRecollectionHistories(): Observable<ICLResponse<IServer[]>> {
    return this.http.get<ICLResponse<IServer[]>>(`${this.CONTROLLER}/ServersByRecollectionHistories`);
  }

}
