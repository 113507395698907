import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Structures } from '@clavisco/core';
import { CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown } from '@clavisco/linker';
import { MapDisplayColumns, MappedColumns } from '@clavisco/table';
import {finalize, Subscription} from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { IUser, IUserTable } from '../../../models/interfaces/i-users';
import { ModalAddUserComponent } from './modal-add-user/modal-add-user.component';
import {PermissionCode} from "../../../common/constants";
import {SessionService} from "../../../services/session.service";
import {AlertsService, CLToastType} from "@clavisco/alerts";
import {OverlayService} from "@clavisco/overlay";


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.sass'],
})
export class UsersComponent implements OnInit, OnDestroy {
  allSuscription: Subscription= new Subscription()
  users!: IUserTable[];
  filter!: FormControl
  StartPos: number = 0;
  RowsReturned: number = 10;
  tableId = 'tableUsers'
  displayedColumns!: MappedColumns;
  scrollHeight: string = '350px'
  pageSizeOptions: number[] = [10, 20];
  itemsPeerPage: number = 10;
  recordsCount: number = 0;
  hasPaginator: boolean = true;
  shouldPaginateRequest: boolean = false;
  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };
  Buttons: any = [
    {
      Title: `Acción`,
      Action: Structures.Enums.CL_ACTIONS.UPDATE,
      Icon: `edit`,
      Color: `primary`
    },
  ]
  constructor(
    public dialog: MatDialog,
    private service: UserService,
    private sessionService: SessionService,
    private alertService: AlertsService,
    private clBlockUI: OverlayService,
    @Inject('LinkerService') private linkerService: LinkerService
  ) {
    this.filter = new FormControl('')

    this.displayedColumns = MapDisplayColumns<IUser, null>({
      dataSource: [],
      renameColumns: {
        Email: 'Correo',
        Status: 'Estado',
        Name: 'Nombre',
        LastName: 'Apellido',
        CreatedBy: 'Creado Por',
        CreatedDate: 'Fecha Creacion'
      },
      ignoreColumns: ['MaxQtyRowsFetch', 'Id', 'Password', 'IsActive', 'UpdateDate', 'UpdatedBy']
    });
  }

  ngOnDestroy(): void{
    this.allSuscription.unsubscribe();
  }

  ngOnInit(): void {
    Register<CL_CHANNEL>(this.tableId, CL_CHANNEL.INPUT, this.GetElementsRecords, this.callbacks);
    Register<CL_CHANNEL>(this.tableId, CL_CHANNEL.OUTPUT, this.SelectedUser, this.callbacks);

    this.allSuscription.add(this.linkerService.Flow()
      ?.pipe(StepDown<CL_CHANNEL>(this.callbacks))
      .subscribe({
        next: (callback) =>
          Run(callback.Target, callback, this.callbacks.Callbacks),
        error: (error) => console.log(`mi error`, error),
      }));


    this.GetUser();
  }

  GetElementsRecords = (_event: ICLEvent): void => {
    if (_event) {
      const INCOMMING_TABLE_STATE = JSON.parse(_event.Data);
      this.RowsReturned = INCOMMING_TABLE_STATE.ItemsPeerPage;
      this.StartPos = INCOMMING_TABLE_STATE.CurrentPage;
      this.GetUser();
    }
  };

  SelectedUser = (_event: ICLEvent) => {
    const event = JSON.parse(_event.Data)
    const user: IUser = JSON.parse(event.Data)
    const id = this.sessionService.getUserId();
    if(this.CanEditUser() || id != user.Id){
      this.OpenUserAddDialog(user)
      return
    }
    return this.alertService.Toast({ message: `No tiene permisos para editar usuarios`, type: CLToastType.WARNING })
  };


  OpenUserAddDialog(user?: IUser): void {
    const dialogRef: MatDialogRef<ModalAddUserComponent> = this.dialog.open(
      ModalAddUserComponent,
      {
        width: '650px',
        maxHeight: `85vh`,
        data: user,
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if(result)  this.GetUser();
    });
  }

  GetUser(): void {
    this.clBlockUI.OnGet('Cargando usuarios...')
    this.service.GetUsersP(this.filter.value, this.StartPos, this.RowsReturned).pipe(finalize(() => { this.clBlockUI.Drop(); })).subscribe({
      next: (callback) => {
        if (callback.Data) {
          this.users = callback.Data;
          this.recordsCount = callback.Data.length > 0 ? callback.Data[0].MaxQtyRowsFetch : 0;
          this.users.map(x => {
            x.Status = x.IsActive ? 'Activo' : 'Inactivo'
          })
          this.loadTableData();
        }
      },
      error: (error) => console.log(error),
    });
  }

  loadTableData(): void {
    const CURRENT_TABLE_STATE = {
      CurrentPage: 1,
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.users,
      RecordsCount: this.recordsCount
    };
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.tableId,
      Data: JSON.stringify(CURRENT_TABLE_STATE)
    } as ICLEvent);
  }

  CanEditRole():boolean{
    return this.sessionService.GetPermissionCodeFromToken().includes(PermissionCode.EditRole)
  }

  CanEditUser():boolean{
    return this.sessionService.GetPermissionCodeFromToken().includes(PermissionCode.EditUser)
  }


}
