import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IProject, IProjectDeliverables } from 'src/app/models/interfaces/i-proyects';
import {catchError, finalize, map, Observable, startWith, tap} from 'rxjs';
import { ProyectService } from 'src/app/services/proyect.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ModalProjectDeliverablesComponent } from './modal-project-deliverables/modal-project-deliverables.component';
import { ProyectDeliverableService } from 'src/app/services/project-deliverables.service';
import { UserService } from 'src/app/services/user.service';
import { FileService } from 'src/app/services/file.service';
import { ActivatedRoute } from '@angular/router';
import { AlertsService, CLToastType } from '@clavisco/alerts';
import {PermissionCode} from "../../../common/constants";
import {SessionService} from "../../../services/session.service";
import {OverlayService} from "@clavisco/overlay";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Component({
  selector: 'app-project-deliveries',
  templateUrl: './project-deliveries.component.html',
  styleUrls: ['./project-deliveries.component.sass']
})
export class ProjectDeliveriesComponent implements OnInit {

  selectControl = new FormControl('');
  filteredProjects!: Observable<IProject[]>;
  projects: IProject[] = [];
  isSelected = false;
  projectSelected: IProject;

  projectDeliverables: MatTableDataSource<IProjectDeliverables> = new MatTableDataSource()
  displayColumns: string[] = ["User", "Type", "CreatedDate", "Action"]


  datesControl = new FormGroup({
    initDate: new FormControl(),
    endDate: new FormControl(),
  });

  constructor(private projectService: ProyectService,
    public dialog: MatDialog,
    private userService: UserService,
    private sessionService: SessionService,

    private deliverableService: ProyectDeliverableService,
    private fileService: FileService,
    private route: ActivatedRoute,
    private alertService: AlertsService,
    private clBlockUI: OverlayService
  ) { }

  ngOnInit(): void {
    this.GetProjects();
    if (this.route.snapshot.paramMap.get('id') != null) {
      const id = this.route.snapshot.paramMap.get('id')
      this.getDeliverable(Number(id))
    }
  }

  getDeliverable(id: number) {
    this.deliverableService.getDeliverablesById(Number(id)).subscribe({
      next: x => {
        if (x.Data == null) {
          return this.alertService.Toast({ message: `No se encontró la entrega del proyecto`, type: CLToastType.WARNING })
        } else {
          this.projectDeliverables.data.push(x.Data)
        }
      }
    })
  }

  getDeliverablesByProject() {
    console.log(this.datesControl.value.initDate,this.datesControl.value.endDate)
    if (this.datesControl.value.initDate && !this.datesControl.value.endDate) return this.alertService.Toast({ message: 'La fecha de inicio y de fecha final son obligatorias', type: CLToastType.WARNING })
    if (this.projectSelected == null) return this.alertService.Toast({ message: 'Debe seleccionar un proyecto', type: CLToastType.WARNING })
    this.clBlockUI.OnGet('Cargando entregas...')
    this.deliverableService.getDeliverablesByProject(this.projectSelected ? this.projectSelected.Id.toString() : '',
      this.datesControl.value.initDate,
      this.datesControl.value.endDate).pipe(finalize(() => { this.clBlockUI.Drop(); })).subscribe({
        next: x => {
          if (x.Data.length === 0) {
            this.projectSelected ? this.alertService.Toast({ message: `El proyecto ${this.projectSelected.Name} no tiene entregas asociadas`, type: CLToastType.INFO }) : this.alertService.Toast({ message: `No se encontraron entregas`, type: CLToastType.INFO })
          }
          this.projectDeliverables = new MatTableDataSource(x.Data)
        }
      })
  }

  GetProjects(): void {
    this.projectService.Get('', '', 0, 99).subscribe({
      next: callback => {
        if (callback.Data) {
          this.projects = callback.Data
        }
      },
    })
  }

  getProjectById(id: number): string {
    return this.projects.filter(x => x.Id === id).map(x => x.Name).toString();
  }

  getFilteredProject() {
    this.filteredProjects = this.selectControl.valueChanges.pipe(
      startWith(''),
      map((x: string | null) => (x ? this._filterProject(x) : this.projects.slice())),
    );
  }

  private _filterProject(value: string): IProject[] {
    const filterValue = value.toLowerCase();
    return this.projects.filter(p => p.Name.toLowerCase().includes(filterValue));
  }

  selectedProject(project: IProject) {
    this.isSelected = true;
    this.projectSelected = project;
    this.getDeliverablesByProject();
  }

  OpenDialogAddDeliverable() {
    const dialogRef = this.dialog.open(ModalProjectDeliverablesComponent, {
      minWidth: '80vw',
      height: '650px',
      maxHeight: `85vh`,
      data: this.projectSelected.Id
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getDeliverablesByProject();
    })
  }

  ViewCheckList(link: string) {
    this.fileService.DownloadFileBlob(link).pipe(finalize(() => {  }),
      tap((response:ICLResponse<string>)=>this.generatedDocument(response.Data, 'application/pdf')),
      catchError((error: any) => {
        console.log(error);
        return [];
      })
      ).subscribe();
  }

  public generatedDocument(data: any, applicationType: string): void {
    var fileBase64 = atob(data);
    var length = fileBase64.length;
    var arrayBuffer = new ArrayBuffer(length);
    var uintArray = new Uint8Array(arrayBuffer);
    for (var i = 0; i < length; i++) {
      uintArray[i] = fileBase64.charCodeAt(i);
    }
    var tab = window.open();
    var pdfFile = new Blob([uintArray], { type: `${applicationType}` });
    var fileUrl = URL.createObjectURL(pdfFile);
    tab?.open(fileUrl, 'about:blank')
  }

  OpenLink(link: string) {
    if (!link.includes('http')) {
      window.open('https://' + link)
    }
    window.open(link)
  }

  CopyLink(id: number) {
    let url = window.location.href.split('projectDeliveries');
    let URLatual = `${url[0]}projectDeliveries/${id}`;
    navigator.clipboard.writeText(URLatual);
    return this.alertService.Toast({ message: 'Link copiado', type: CLToastType.SUCCESS })
  }

  CanCreateDeliverables():boolean{
    return this.sessionService.GetPermissionCodeFromToken().includes(PermissionCode.CreateDeliverable)
  }

}
