import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-detail',
  templateUrl: './password-detail.component.html',
  styleUrls: ['./password-detail.component.sass']
})
export class PasswordDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
