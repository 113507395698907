import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {catchError, finalize, map, Observable, of, startWith, tap} from 'rxjs';
import {IProjectComplete} from 'src/app/models/interfaces/i-proyects';
import { ProyectService } from 'src/app/services/proyect.service';
import { MatChipInputEvent } from '@angular/material/chips';
import {COMMA, ENTER, F} from '@angular/cdk/keycodes';
import { IDataBaseProject } from 'src/app/models/interfaces/i-database';
import { ServersService } from 'src/app/services/servers.service';
import { UserService } from 'src/app/services/user.service';
import {IUsers} from 'src/app/models/interfaces/i-users';
import { IServer } from 'src/app/models/interfaces/i-server';
import { IISPool, IISSite, TaskSchedule, TypeServices, WindowServerService } from 'src/app/models/interfaces/i-windowsservice';
import { WindowsServicesService } from 'src/app/services/windows-services.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ModalAddUserComponent } from '../../users/modal-add-user/modal-add-user.component';
import { PermissionCode, WindowsService } from 'src/app/common/constants';
import { ProjectStatusService } from 'src/app/services/projectStatus.service';
import { SessionService } from 'src/app/services/session.service';
import { DatabaseService } from '../../../../services/database.service';
import { IAppLink } from 'src/app/models/interfaces/i-appLink';
import { AlertsService, CLToastType } from '@clavisco/alerts';
import { OverlayService } from '@clavisco/overlay';
import {IProjectStatus} from "../../../../../core/interfaces/i-project-status";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {ApplicationService} from "../../../../services/application.service";
import {IApplication} from "../../../../models/interfaces/i_applications";


@Component({
  selector: 'app-create-edit-project',
  templateUrl: './create-edit-project.component.html',
  styleUrls: ['./create-edit-project.component.sass']
})
export class CreateEditProyectComponent implements OnInit {
  edit = false;
  loading = true;
  projectForm!: FormGroup;
  formDBServer!: FormGroup;
  filteredUsers!: Observable<IUsers[]>;
  filteredOwner!: Observable<IUsers[]>;
  filteredDeveloperInv!: Observable<IUsers[]>;
  filteredServer!: Observable<IServer[]>;

  users: IUsers[] = [];
  allServer: IServer[] = [];
  task: string[] = [];
  taskSchedule: TaskSchedule[] = [];
  listTask: TaskSchedule[] = [];
  listDevelopersInv: IUsers[] = [];
  developersInv: string[] = [];
  services: string[] = [];
  windowsServices: WindowServerService[] = [];
  projectStatus: IProjectStatus[] = [];
  allApplications: IApplication[] = [];
  listServices: WindowServerService[] = [];
  pools: string[] = [];
  allPools: IISPool[] = [];
  listPools: IISPool[] = [];
  sites: string[] = [];
  allSites: IISSite[] = [];
  listSites: IISSite[] = [];
  listApplicacions: IApplication[] = [];

  formService!: FormControl;
  formManager !: FormControl;
  formServer!: FormControl;
  formDeveloper!: FormControl;
  formTask!: FormControl;
  formProductOwner!: FormControl;
  formApplicacion!: FormControl;

  DataBasesList: MatTableDataSource<IDataBaseProject> = new MatTableDataSource();
  displayedColumns: string[] = ['ServerId', 'Name', 'Type', 'IsActive'];

  AppLinkList: IAppLink[] = [];

  projectName: string = ''
  projectCode: string = ''
  managerId: number = 0;
  ownerId: number = 0;
  serverId: string;
  serverName: string;

  TypesList: string[] = [
    'Aplicación',
    'SAP'
  ]
  typesServices: string[] = ['Sitios', 'Tareas Programadas', 'Servicios de Windows', 'Pools']
  respList: Observable<any[]>
  listFilteredApp: Observable<IApplication[]>;
  objService: any;
  applicationSelected: IApplication;
  TypeService: FormControl = new FormControl('', Validators.required)

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  btnStyle: string;
  checked: boolean;

  @Output() checkedChange = new EventEmitter<boolean>();

  constructor(private route: ActivatedRoute,
    private proyectServices: ProyectService,
    private router: Router,
    private alertService: AlertsService,
    private userService: UserService,
    private serversService: ServersService,
    private windowsServiceService: WindowsServicesService,
    private projectStatusService: ProjectStatusService,
    private applicationService: ApplicationService,
    public dialog: MatDialog,
    private sessionService: SessionService,
    private databaseService: DatabaseService,
    private clBlockUI: OverlayService
  ) {
    this.formDeveloper = new FormControl('', [Validators.required]);
    this.formServer = new FormControl('');
    this.formManager = new FormControl('', [Validators.required]);
    this.formProductOwner = new FormControl('', [Validators.required]);
    this.formTask = new FormControl('', [Validators.required]);
    this.formService = new FormControl('', [Validators.required]);
    this.formApplicacion = new FormControl('', [Validators.required]);
    this.checked = this.edit ? true : false;
  }

  ngOnInit(): void {
    this.onLoad();
  }

  onLoad(): void {
    this.GetUsers();
    this.GetServers();
    this.GetProjectStatus();
    //Como aun no es necesario agregar aplicaciones a proyectos
    // solo se deja comentado por si a futuro se requiere utilizar
    //this.GetApplications();
    this.InitializeValue()
  }

  getIndex(type: string): number {
    switch (type) {
      case 'Sitios':
        return 3;
      case 'Tareas Programadas':
        return 0;
      case 'Servicios de Windows':
        return 1;
      case 'Pools':
        return 2;
    }
    return 0;
  }

  AddListApplication() {
    if (this.listApplicacions.filter(x => x.Name == this.applicationSelected.Name).length > 0) return this.alertService.Toast({ message: 'La Aplicación ya se encuentra agregado', type: CLToastType.WARNING });
    this.listApplicacions.push(this.applicationSelected);
    this.applicationService.event.emit(this.listApplicacions)
  }

  selectedApplication(application: IApplication): void {
    application.CreatedBy = '';
    application.UpdatedBy = '';
    this.applicationSelected = application
  }

  onModeEdition(event: any): void {
    if (this.CanEditProject()) {
      this.checked = !this.checked;

      this.checkedChange.emit(this.checked);
      this.btnStyle = this.checked ? "display: inherit" : "display: none"
      if (!this.checked) {
        this.onLoad();
        this.alertService.Toast({ message: 'Modo edición desactivado', type: CLToastType.INFO });
      } else {
        this.alertService.Toast({ message: 'Modo edición activado', type: CLToastType.INFO });
      }
    } else {
      event.checked = false
      this.alertService.Toast({ message: 'No tiene permiso para editar proyectos', type: CLToastType.WARNING });
    }
  }

  CanEditProject(): boolean {
    return this.sessionService.GetPermissionCodeFromToken().includes(PermissionCode.EditProjects)
  }

  getFilteredDeveloperInv() {
    this.filteredDeveloperInv = this.formDeveloper.valueChanges.pipe(
      startWith(null),
      map((x: string | null) => (x ? this._filterUsers(x) : this.users.slice())),
    );
  }

  getFilteredUsers() {
    this.filteredUsers = this.formManager.valueChanges.pipe(
      startWith(''),
      map(state => (state ? this._filterUsers(state) : this.users.slice())),
    );
  }

  getFilteredOwner() {
    this.filteredOwner = this.formProductOwner.valueChanges.pipe(
      startWith(''),
      map(state => (state ? this._filterUsers(state) : this.users.slice())),
    );
  }

  getFilteredApplications() {
    this.listFilteredApp = this.formApplicacion.valueChanges.pipe(
      startWith(''),
      map((x: string | null) => (x ? this._filterApplications(x) : this.allApplications.slice())),
    );
  }

  async InitializeValue(): Promise<void> {
    this.projectForm = new FormGroup({
      Name: new FormControl('', [Validators.required, Validators.minLength(1)]),
      Client: new FormControl('', [Validators.required, Validators.minLength(1)]),
      Code: new FormControl('', [Validators.required, Validators.minLength(1)]),
      CreatedDate: new FormControl(new Date, Validators.required),
      ModifiedDate: new FormControl(new Date, Validators.required),
      Status: new FormControl('', [Validators.required, Validators.minLength(1)]),
      StatusDisabled: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.minLength(1)]),
      JiraUrl: new FormControl('', [Validators.required, Validators.minLength(1)]),
      Description: new FormControl('', [Validators.required, Validators.minLength(1)]),
      UserManagerId: new FormControl('', [Validators.required]),
      ProductOwnerId: new FormControl('', [Validators.required, Validators.minLength(1)]),
      Id: new FormControl('0', [Validators.required]),
      CreatedBy: new FormControl(""),
      UpdatedBy: new FormControl(""),
    });

    this.formDBServer = new FormGroup({
      ProjectId: new FormControl(0),
      Type: new FormControl('', [Validators.required]),
      Name: new FormControl(''),
      ServerId: new FormControl('', [Validators.required]),
      IsActive: new FormControl(false, [Validators.required]),
      SubType: new FormControl('', [Validators.required]),
    })

    if (this.route.snapshot.paramMap.get('id') != null) {
      this.edit = true;
      this.btnStyle = this.edit ? "display: none" : "display: inherit";
      await this.GetProyectById(this.route.snapshot.paramMap.get('id')!);
    } else {
      this.loading = false;
    }
  }

  private _filterUsers(value: string): IUsers[] {
    const filterValue = value.toLowerCase();
    return this.users.filter(state => state.Name.toLowerCase().includes(filterValue));
  }

  private _filterApplications(value: string): IApplication[] {
    const filterValue = value.toLowerCase();
    return this.allApplications.filter(s => s.Name.toLowerCase().includes(filterValue));
  }


  GetUsers(): void {
    this.userService.Get().pipe(finalize(() => { }),
      tap((callback: ICLResponse<IUsers[]>) => {
        if (callback) {
          this.users = callback.Data;
          this.filteredUsers = of(this._filterUsers(''));
          this.filteredOwner = of(this._filterUsers(''));
          this.filteredDeveloperInv = of(this._filterUsers(''));
        }
        }),
      catchError((error: any) => {
        this.alertService.Toast({ message: error, type: CLToastType.ERROR });
        return [];
      })
    ).subscribe();
  }

  GetServers(): void{
    this.serversService.Get().pipe(finalize(() => {  }),
      tap((callback: ICLResponse<IServer[]>)=>{
        if (callback) {
          this.allServer = callback.Data.filter(x => x.Type == 'DB');
        }
      }),catchError((err) => {
          this.alertService.Toast({ message: err, type: CLToastType.ERROR });
          return [];
        }
      )).subscribe();
  }

  GetProjectStatus(): void {
    this.projectStatusService.GetProyectStatus().pipe(
      finalize(() => { }),
      tap((callback: ICLResponse<IProjectStatus[]>) => {
        if (callback) {
          this.projectStatus = [...callback.Data]
        }
      }), catchError((err) => {
        console.log(err);
        return [];
      })
    ).subscribe()
  }

  GetApplications(): void{
    this.applicationService.Get().pipe(finalize(() => { }),
      tap((callback: ICLResponse<IApplication[]>)=>{
        if (callback) {
          this.allApplications = callback.Data;
        }
      }),
      catchError((err) => {
        console.log(err);
        return [];
      })
    ).subscribe();
  }

  ChargeProyectData(projectData: IProjectComplete): void {
    this.projectForm.patchValue({
      Name: projectData.Name,
      Code: projectData.Code,
      Description: projectData.Description,
      JiraUrl: projectData.JiraUrl,
      Status: projectData.Status,
      StatusDisabled: projectData.Status,
      Client: projectData.Client,
      UserManagerId: this.users.filter(x => x.Id === projectData.UserManagerId).map(m => m.Name),
      ProductOwnerId: this.users.filter(x => x.Id === projectData.ProductOwnerId).map(m => m.Name),
      CreatedBy: '',
      UpdatedBy: '',
    })
    this.ownerId = projectData.ProductOwnerId;
    this.managerId = projectData.UserManagerId;

    this.developersInv = projectData.ListDevelopers.map(x => x.Name);
    this.listDevelopersInv = projectData.ListDevelopers;
    this.DataBasesList = new MatTableDataSource(projectData.ListDataBase??[]);
    if (projectData.ListSites) {
      this.sites = projectData.ListSites.map(x => x.SiteName);
      this.listSites = projectData.ListSites;
    }
    if (projectData.ListServices) {
      this.services = projectData.ListServices.map(x => x.ServiceName);
      this.listServices = projectData.ListServices;
    }
    if (projectData.ListPools) {
      this.pools = projectData.ListPools.map(x => x.PoolName);
      this.listPools = projectData.ListPools;
    }
    if (projectData.ListTasks) {
      this.task = projectData.ListTasks.map(x => x.Name);
      this.listTask = projectData.ListTasks;
    }
    if (projectData.ListLinks) {
      this.AppLinkList = projectData.ListLinks;
    }
    if (projectData.ListApplications) {
      this.listApplicacions = projectData.ListApplications;
    }
    this.loading = false;
  }

  GetProyectById(id: string): void {
    this.clBlockUI.OnGet('Cargando Proyecto...')
    this.proyectServices.GetProyectById(id).pipe(finalize(() => { this.clBlockUI.Drop(); }),
      tap((callback: ICLResponse<IProjectComplete>) => {
        this.projectName = callback.Data.Name;
        this.projectCode = callback.Data.Code
        this.ChargeProyectData(callback.Data);
      }), catchError((err) => {
        console.log(err);
        return [];
      })
      ).subscribe();
  }

  getServerId(id: number): string {
    return this.allServer.filter(x => x.Id == id).map(m => m.Name).toString();
  }

  GoBack(): void {
    this.edit ? this.router.navigate(['../../'], { relativeTo: this.route }) : this.router.navigate(['../'], { relativeTo: this.route });
  }

  getErrorMessageRequired(nameControl: string, text: string): string {
    if (this.projectForm.controls[nameControl].hasError('required')) {
      return text;
    }
    return '';
  }

  selectedDeveloper(dev: IUsers): void {
    dev.CreatedBy = '';
    dev.UpdatedBy = '';
    this.developersInv.push(dev.Name);
    this.formDeveloper.setValue('');
    this.listDevelopersInv.push(dev);
  }

  addDeveloper(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.developersInv.push(value);
    }
    event.chipInput!.clear();
    this.formDeveloper.setValue('');
  }

  removeDeveloper(developer: string): void {
    const index = this.developersInv.indexOf(developer);
    if (index >= 0) {
      this.developersInv.splice(index, 1);
      this.listDevelopersInv.splice(index, 1);
    }
  }

  selectedManager(manager: IUsers): void {
    this.managerId = manager.Id;
  }

  selectedOwner(owner: IUsers): void {
    this.ownerId = owner.Id;
  }

  selectedServer(server: IServer): void {
    this.serverId = server.Id.toString();
    this.serverName = server.Name;
  }

  validateDB(db: IDataBaseProject) {
    const res = this.DataBasesList.data.filter(x => x.Name == db.Name && x.ServerId == db.ServerId);
    if (res.length > 0) { return false; }
    return true;
  }

  RemoveServices(event: any) {
    const eventEmit: TypeServices = event;
    let obj: TypeServices = { Data: [], Type: eventEmit.Type };
    switch (eventEmit.Type) {
      case 0: //tareas
        this.listTask.splice(eventEmit.Data, 1)
        obj.Data = this.listTask;
        this.windowsServiceService.event.emit(obj)
        break;
      case 1: //servicios
        this.listServices.splice(eventEmit.Data, 1)
        obj.Data = this.listServices;
        this.windowsServiceService.event.emit(obj)
        break;
      case 2: //pools
        this.listPools.splice(eventEmit.Data, 1)
        obj.Data = this.listPools;
        this.windowsServiceService.event.emit(obj)
        break;
      case 3: //sitios
        this.listSites.splice(eventEmit.Data, 1)
        obj.Data = this.listSites;
        this.windowsServiceService.event.emit(obj)
        break;
    }
  }

  RemoveApplication(event: any) {
    const applications: IApplication[] = event;
    this.listApplicacions = applications;
  }

  createUser = () => {
    const dialog = this.dialog.open(
      ModalAddUserComponent,
      {
        width: '650px',
        maxHeight: `85vh`,
      }
    );
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.GetUsers();
        this.alertService.Toast({ message: 'Guardado Exitosamente', type: CLToastType.SUCCESS });
      }
    });
  }

  addProyect(): void {
    let projectComplete: IProjectComplete;
    this.projectForm.get('UserManagerId')?.setValue(this.managerId);
    this.projectForm.get('ProductOwnerId')?.setValue(this.ownerId);
    try {
      if (!this.projectForm.valid && !this.formDeveloper.valid) {
        return this.alertService.Toast({ message: 'Hay campos vacíos que son obligatorios o con formato incorrecto, verifique que los campos con * no se encuentren vacíos', type: CLToastType.ERROR });
      } else {
        this.clBlockUI.OnPost('Guardando Proyecto...')
        if (!this.edit) {
          projectComplete = this.projectForm.value;
          this.AppLinkList.map((link) => {
            link.CreatedBy = this.projectForm.value.CreatedBy;
            link.UpdatedBy = this.projectForm.value.UpdatedBy;
          });
          if(this.DataBasesList){
            this.DataBasesList.data.map((db) => {
              db.CreatedBy = this.projectForm.value.CreatedBy;
              db.UpdatedBy = this.projectForm.value.UpdatedBy;
            });
          }
          if(this.listApplicacions){
            this.listApplicacions.map((app) => {
              app.CreatedBy = this.projectForm.value.CreatedBy;
              app.UpdatedBy = this.projectForm.value.UpdatedBy;
            });
          }
          projectComplete.ListLinks = this.AppLinkList;
          projectComplete.ListDataBase = this.DataBasesList ? this.DataBasesList.data : [];
          projectComplete.ListDevelopers = this.listDevelopersInv;
          projectComplete.ListPools = this.listPools;
          projectComplete.ListSites = this.listSites;
          projectComplete.ListServices = this.listServices;
          projectComplete.ListTasks = this.listTask;
          projectComplete.ListApplications = this.listApplicacions;
          this.proyectServices.PostProyect(projectComplete).pipe(finalize(() => { this.clBlockUI.Drop(); }),
            tap((response) => {
              this.alertService.Toast({ message: 'El proyecto se ha guardado correctamente', type: CLToastType.SUCCESS });
              this.GoBack();
            }),
            catchError((err) => {
              this.alertService.Toast({ message: 'Ocurrió un problema al guardar el proyecto. ' + err, type: CLToastType.ERROR });
              return [];
            })
          ).subscribe()
        } else {
          const id = this.route.snapshot.paramMap.get('id')
          this.projectForm.get('Id')?.setValue(id);
          projectComplete = this.projectForm.value;
          this.AppLinkList.map((link) => {
            link.CreatedBy = this.projectForm.value.CreatedBy;
            link.UpdatedBy = this.projectForm.value.UpdatedBy;
          });
          if(this.DataBasesList){
            this.DataBasesList.data.map((db) => {
              db.CreatedBy = this.projectForm.value.CreatedBy;
              db.UpdatedBy = this.projectForm.value.UpdatedBy;
            });
          }
          if(this.listApplicacions){
            this.listApplicacions.map((app) => {
              app.CreatedBy = this.projectForm.value.CreatedBy;
              app.UpdatedBy = this.projectForm.value.UpdatedBy;
            });
          }
          if(this.listTask){
            this.listTask.map(task => {
              task.CreatedBy = this.projectForm.value.CreatedBy
              task.UpdatedBy = this.projectForm.value.UpdatedBy
            })
          }
          projectComplete.ListLinks = this.AppLinkList;
          projectComplete.ListDataBase = this.DataBasesList ? this.DataBasesList.data : [];
          projectComplete.ListDevelopers = this.listDevelopersInv;
          projectComplete.ListPools = this.listPools;
          projectComplete.ListSites = this.listSites;
          projectComplete.ListServices = this.listServices;
          projectComplete.ListTasks = this.listTask;
          projectComplete.ListApplications = this.listApplicacions;
          this.proyectServices.PatchProyect(projectComplete).pipe(finalize(() => { this.clBlockUI.Drop(); }),
            tap((response) => {
              this.alertService.Toast({ message: 'El proyecto se ha actualizado correctamente', type: CLToastType.SUCCESS });
              this.GoBack();
            }),
            catchError((err) => {
              this.alertService.Toast({ message: 'Ocurrió un problema al actualizar el proyecto. ' + err, type: CLToastType.ERROR });
              return [];
            })
          ).subscribe();
        }
      }
    } catch {
      this.alertService.Toast({ message: 'Nombre o código ya existen', type: CLToastType.ERROR });
    }
  }

  AddAppLink(event: any): void {
    const links: IAppLink[] = event;
    this.AppLinkList = links;
  }

}
