import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { ProyectsComponent } from './pages/home/proyects/proyects.component';
import { PermissionsComponent } from './pages/home/permissions/permissions.component';
import { UsersComponent } from './pages/home/users/users.component';
import { AuthGuard } from './guards/auth.guard';
import { PermissionsGuard } from './guards/permissions.guard';
import { DevelopersComponent } from './pages/home/developers/developers.component';
import { BitbucketIntegrationComponent } from './pages/home/bitbucket-integration/bitbucket-integration.component';
import { ServerComponent } from './pages/home/servers/server.component';
import { WindowsServicesComponent } from './pages/home/windows-services/windows-services.component';
import { CreateEditProyectComponent } from './pages/home/proyects/create-edit-project/create-edit-project.component';
import { LogsComponent } from './pages/home/logs/logs.component';
import { LogsLoginComponent } from './pages/home/logs/logs-login/logs-login.component';
import { ProjectStatusComponent } from './pages/home/project-status/project-status.component';
import { FilesComponent } from './pages/home/files/files.component';
import { ProjectDeliveriesComponent } from './pages/home/project-deliveries/project-deliveries.component';
import { CheckListComponent } from './pages/home/check-list/check-list.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { DocumentsComponent } from './pages/home/documents/documents.component';
import {ApplicationComponent} from "./pages/home/applications/application.component";
import {SettingComponent} from "./pages/home/setting/setting.component";
import { UserDefinedObjectsComponent } from "./pages/home/sap-object/user-defined-objects/user-defined-objects.component";
import { ReplicateObjectsComponent } from "./pages/home/sap-object/replicate-objects/replicate-objects.component";
import { HistoryUdosComponent } from "./pages/home/sap-object/history-udos/history-udos.component";

const routes: Routes = [
  {
    path: 'index',
    component: HomeComponent,
    outlet: 'primary',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'index',
        pathMatch: 'full',
      },
      {
        path: 'bitbucket',
        children: [
          { path: '?code=:code', component: BitbucketIntegrationComponent },
          { path: '', component: BitbucketIntegrationComponent }
        ],
      },
      {
        path: 'services',
        canActivate: [PermissionsGuard],
        component: WindowsServicesComponent
      },
      {
        path: 'logs',
        canActivate: [PermissionsGuard],
        component: LogsComponent
      },
      {
        path: 'logsLogin',
        component: LogsLoginComponent
      },
      {
        path: 'proyects',
        canActivate: [PermissionsGuard],
        children: [
          {
            path: 'new',
            component: CreateEditProyectComponent
          },
          {
            path: ':id/edit',
            component: CreateEditProyectComponent,
            pathMatch: 'full',
          },
          {
            path: '',
            component: ProyectsComponent,
          },
        ]
      },
      {
        path: 'applications',
        canActivate: [PermissionsGuard],
        component: ApplicationComponent,
        outlet: 'primary',
        pathMatch: 'full'
      },
      {
        path: 'userDefinedObjects',
        canActivate: [PermissionsGuard],
        component: UserDefinedObjectsComponent,
        outlet: 'primary',
        pathMatch: 'full'
      },
      {
        path: 'historyUserDefinedObjects',
        canActivate: [PermissionsGuard],
        component: HistoryUdosComponent,
        outlet: 'primary',
        pathMatch: 'full'
      },
      {
        path: 'servers',
        canActivate: [PermissionsGuard],
        component: ServerComponent,
        outlet: 'primary',
        pathMatch: 'full'
      },
      {
        path: 'servers',
        component: ServerComponent,
        outlet: 'primary',
        pathMatch: 'full'
      },
      {
        path: 'permissions',
        component: PermissionsComponent,
        canActivate: [PermissionsGuard],
      },
      {
        path: 'projectStatus',
        component: ProjectStatusComponent,
        canActivate: [PermissionsGuard],
      },
      {
        path: 'files',
        component: FilesComponent,
        canActivate: [PermissionsGuard],
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [PermissionsGuard],
      },
      {
        path: 'setting',
        component: SettingComponent,
        canActivate: [PermissionsGuard],
      },
      {
        path: 'developers',
        component: DevelopersComponent,
        canActivate: [PermissionsGuard],
      },
      {
        path: 'projectDeliveries',
        children: [
          { path: ':id', component: ProjectDeliveriesComponent },
          { path: '', component: ProjectDeliveriesComponent }
        ],
        canActivate: [PermissionsGuard],
      },
      {
        path: 'checklist',
        component: CheckListComponent,
        canActivate: [PermissionsGuard],
      },
      {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [PermissionsGuard],
      },
      {
        path: 'documents',
        component: DocumentsComponent,
        canActivate: [PermissionsGuard],
      },
      {
        path: 'replicate',
        component: ReplicateObjectsComponent,
        canActivate: [PermissionsGuard],
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    outlet: 'primary',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LoginComponent,
    outlet: 'primary',
    pathMatch: 'full',
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
