<div class="container">
    <mat-card class="mat-elevation-z0">
        <div class="flex">
            <mat-form-field appearance="outline" class="filter-input " style="margin-top: 17px;">
                <mat-label>
                    Proyecto
                </mat-label>
                <input matInput [matAutocomplete]="auto" [formControl]="selectControl" (click)="getFilteredProject()">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let project of filteredProjects | async" (click)="selectedProject(project)"
                        [value]="project.Name">
                        <span>{{project.Name}}</span>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field appearance="outline" class="filter-input  ml-2" style="margin-top: 17px;">
                <mat-label>Rango de fechas</mat-label>
                <mat-date-range-input [formGroup]="datesControl" [rangePicker]="campaignOnePicker">
                    <input matStartDate placeholder="Start date" formControlName="initDate">
                    <input matEndDate placeholder="End date" formControlName="endDate">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
            </mat-form-field>
            <button mat-stroked-button color="primary" (click)="getDeliverablesByProject()" class="search-button"
                style="margin: 10px;">
                <mat-icon>search</mat-icon>Buscar</button>
        </div>
    </mat-card>
</div>

<div class="mb-1">
    <button *ngIf="isSelected && CanCreateDeliverables()" mat-stroked-button color="primary" style="margin:12px;"
        (click)="OpenDialogAddDeliverable()">
        <mat-icon>add</mat-icon> Agregar Entrega
    </button>
</div>
<div *ngIf="this.projectDeliverables.data.length > 0; else notDeliverables">
    <mat-card class="mat-elevation-z0">
        <table mat-table [dataSource]="projectDeliverables" id="table" class="mat-elevation-z0">
            <ng-container matColumnDef="User">
                <th mat-header-cell *matHeaderCellDef>Usuario</th>
                <td mat-cell *matCellDef="let element">{{ element.User || 'N/A' }}</td>
            </ng-container>
            <ng-container matColumnDef="Type">
                <th mat-header-cell *matHeaderCellDef>Tipo</th>
                <td mat-cell *matCellDef="let element">{{element.Type || 'N/A' }}</td>
            </ng-container>
            <ng-container matColumnDef="CreatedDate">
                <th mat-header-cell *matHeaderCellDef>Fecha de Creación</th>
                <td mat-cell *matCellDef="let element">{{ element.CreatedDate | date: 'short' }}</td>
            </ng-container>
            <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef>Opciones</th>
                <td mat-cell *matCellDef="let element">
                    <div>
                        <button *ngIf="element.LinkChekList" class="table-button" mat-stroked-button
                            [customTooltip]="tooltipTemplate"><mat-icon>info</mat-icon></button>
                        <ng-template #tooltipTemplate>
                            <div class="tooltip-background">
                                <button *ngIf="element.LinkVideo" class="table-button" mat-stroked-button
                                    (click)="OpenLink(element.LinkVideo)"
                                    matTooltip="Ver Video"><mat-icon>ondemand_video</mat-icon></button>
                                <button *ngIf="element.LinkChekList" class="table-button" mat-stroked-button
                                    (click)="ViewCheckList(element.LinkChekList)"
                                    matTooltip="Ver Documento"><mat-icon>article</mat-icon></button>
                                <button *ngIf="element.LinkChekList" class="table-button" mat-stroked-button
                                    (click)="OpenLink(element.LinkChekList)"
                                    matTooltip="Descargar Documento"><mat-icon>cloud_download</mat-icon></button>
                                <button class="table-button" mat-stroked-button (click)="CopyLink(element.Id)"
                                    matTooltip="Copiar link de entrega"><mat-icon>content_copy</mat-icon></button>
                            </div>
                        </ng-template>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
        </table>
    </mat-card>
</div>

<ng-template #notDeliverables>
    <div *ngIf="!isSelected" class="dropzone">
        <img id="file" mat-card-image src="../../../../assets/image/download-files.png" alt="No hay archivos">
        <h3>Selecciona un proyecto para</h3>
        <h3>ver las entregas asociadas</h3>
    </div>
</ng-template>