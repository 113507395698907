<router-outlet>
  <div class="container">

    <mat-tab-group>
      <mat-tab label="Recolección de datos">
        <div>
          <div id="filter-container" class="flex">
            <mat-form-field appearance="outline" class="filterInput align-items ">
              <mat-label>
                Servidor
              </mat-label>
              <input matInput [matAutocomplete]="auto" [formControl]="selectControl" (click)="getFilteredServers()" (keydown.enter)="GetServersRecHistories()">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let server of filteredServer | async" (click)="selectedServer(server)"
                  [value]="server.Name">
                  <span>{{server.Name}}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field class="input-date align-items" appearance="outline" >
              <mat-label>Rango de fechas</mat-label>
              <mat-date-range-input [formGroup]="datesControl" [rangePicker]="campaignOnePicker">
                <input matStartDate placeholder="Start date" formControlName="initDate">
                <input matEndDate placeholder="End date" formControlName="endDate">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
              <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
            </mat-form-field>

            <button mat-stroked-button color="primary" (click)="GetServersRecHistories()" class="">
              <mat-icon>search</mat-icon>Buscar</button>
          </div>

          <div class="example-container mat-elevation-z0">
            <mat-card class="mat-elevation-z0">
              <cl-table [Id]="tableId" [MappedColumns]="displayedColumns" [Records]="logs"
                [PageSizeOptions]="pageSizeOptions" [ItemsPeerPage]="itemsPeerPage" [RecordsCount]="recordsCount"
                [HasPaginator]="hasPaginator" [ScrollHeight]="scrollHeight"
                [ShouldPaginateRequest]="shouldPaginateRequest" [Buttons]="Buttons">
              </cl-table>
            </mat-card>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Inicios de sesión">
        <app-logs-login *ngIf="!loading"></app-logs-login>
      </mat-tab>
    </mat-tab-group>


  </div>
</router-outlet>
