import { IMenuPath } from "../models/interfaces/i-menu-paths";

export const enum WindowsService {
  TaskSchedule=1,
  Services,
  SitesIIS,
  PoolsIIS
}

export class AppConstants {

  static BitbucketCode: string = "https://bitbucket.org/site/oauth2/authorize?client_id={client_id}&response_type=code"
  static BitbucketAccessToken: string = "https://bitbucket.org/site/oauth2/access_token"
  static BitbucketRepositories: string = "https://api.bitbucket.org/2.0/repositories"
}

export const enum PermissionCode {
  NoNecessary = '',
  Users = 'S_Users',
  Projects = 'S_Projects',
  Permission =  'S_Permission',
  Services = 'S_Services',
  EditProjects = 'P_EditProjects',
  Logs = 'S_Logs',
  Files = 'S_Files',
  Reports = 'S_Reports',
  EditUser = 'P_EditUser',
  CreateFile = 'P_CreateFile',
  DownloadFile = 'P_DownloadFile',
  DeliverableProject = 'S_DeliverableProject',
  CreateDeliverable = 'P_CreateDeliverable',
  Servers = 'S_Servers',
  EditServer = 'P_EditServer',
  StatusProject = 'S_StatusProject',
  EditRole = 'P_EditRole',
  CheckList = 'S_CheckList',
  Application = 'S_Application',
  EditApplication = 'P_EditApplication',
  SAPObjetcs = 'S_SAPObject',
  UserDefinedObjectHistory = 'S_UserDefinedObjectHistory',
  ShowSAPObjectCreation = 'F_ShowSAPObjectCreationModal',
  ShowSAPObjectReplication = 'F_ShowSAPObjectReplicationModal',
  Configuration = 'S_Configuration',
  ReplicateSapObj = 'S_ReplicateSapObj',
  UserDefinedObjects = 'S_UserDefinedObjects',
  HistorySapObj = 'S_HistorySapObj',
  AddUDT = 'F_AddUDT',
  AddUDF = 'F_AddUDF',
  ReplicateUDOs = 'F_ReplicateUDOs',
}
export enum PermissionType
{
  Create,
  Read,
  Update,
  Delete,
}

export enum LogTypes
{
  LogIn = 1,
}

/**
 * User-defined data types
 * */
export enum UserDefinedTypes
{
  UDF = 1,
  UDT = 2
}

/**
 * Represents the name of the class depending on the assigned status
 * */
export enum StateColors {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
}

/**
 * Represents the corresponding action to store in the UDOS history
 * */
export enum UdoHistoryAction {
  Create = 1,
  Update = 2,
  Delete = 3,
  Replicate = 4
}

/**
 * This enum represents the state of the history process which was carried out for a user-defined object.
 * */
export enum UdoHistoryStatus {
  Success = 1,
  Failed = 2,
}
/**
 * This enum represents the state of transactions performed by user-defined objects
 * */
export enum UdoHistoryTransactionsStatus {
  AllComplete = 1,
  AllFailed = 2,
  PartialComplete = 3
}
