import {Structures} from "@clavisco/core";
import IBaseEntity = Structures.Interfaces.IBaseEntity;

export interface IServer extends  IBaseEntity{
  Id: number;
  MaxQtyRowsFetch: number;
  Type: string;
  Name: string;
  Description: string;
  ReadingType: string;
  CreatedBy: string;
  IpClient: string;
  DateFormat?: string;
  Status?: string;
}

export interface IInsertServer {
  Id: number;
  Type: string;
  Name: string;
  IsActive: string;
  Description: string;
  CreatedBy: string;
}

export interface IServersForProjects {
  Id: number;
  ProjectId: number;
  ServerId: number;
}
