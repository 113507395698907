import {Component, Inject, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'angular-toastify';
import { AppConstants } from 'src/app/common/constants';
import { AppSettingModel } from 'src/app/models/appSettingModel';
import { IBitbucketRepository } from 'src/app/models/interfaces/i-bitbucketrepository';
import { IBitbucketSetting } from 'src/app/models/interfaces/i-bitbucketsetting';
import { BitbucketIntegrationService } from 'src/app/services/bitbucket-integration.service';
import {Subscription} from "rxjs";
import {MapDisplayColumns, MappedColumns} from "@clavisco/table";
import {ICLTableButton} from "@clavisco/table/lib/table.space";
import {Structures} from "@clavisco/core";
import {CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService} from "@clavisco/linker";
@Component({
  selector: 'app-bitbucket-integration',
  templateUrl: './bitbucket-integration.component.html',
  styleUrls: ['./bitbucket-integration.component.sass']
})

export class BitbucketIntegrationComponent {

  // Configuracion de Tabla
  allSuscription: Subscription= new Subscription()
  tableId: string = 'tableApplicationsId'
  displayedColumns!: MappedColumns
  repositoriesList: IBitbucketRepository[] = []
  readonly pageSizeOptions: number[] = [5, 7, 10]
  itemsPeerPage: number = this.pageSizeOptions[2];
  recordsCount: number = 0;
  hasPaginator: boolean = true;
  scrollHeight: string = '400px'
  readonly toIgnore: string[] = ["Id", "IsActive", "UpdatedBy", "UpdatedDate", "CreatedBy", "CreatedDate", "MaxQtyRowsFetch"]
  Buttons: ICLTableButton[] = [{
    Title: 'Editar',
    Action: Structures.Enums.CL_ACTIONS.UPDATE,
    Icon: `edit`,
    Color: `primary`
  }]
  StartPos: number = 0;
  RowsReturned: number = 10;
  // Fin de Configuracion
  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };

  bitbucketCode?: string
  bitbucketSetting!: AppSettingModel<IBitbucketSetting>

  displayColumns: String[] = ["language", "name", "action"]
  repositories!: MatTableDataSource<IBitbucketRepository>

  @ViewChild(MatPaginator) paginator!: MatPaginator

  constructor(
    private route: ActivatedRoute,
    private bitbucketIntegrationService : BitbucketIntegrationService,
    private toastService: ToastService,
    public dialog: MatDialog,
    @Inject('LinkerService') private linkerService: LinkerService
  ) {
    this.OnLoadTable()
    this.bitbucketCode = this.route.snapshot.queryParamMap.get('code') ?? ""
    this.bitbucketIntegrationService.GetBitbucketSetting().subscribe(
      {
        next: setting => {
          if(setting.Result) this.bitbucketSetting = new AppSettingModel(setting.Data)
        },
        error: error => {this.toastService.error(error)},
        complete: ()=>{
          this.GetRepositoryList()
        }
      }
    )
  }

  GetElementsRecords = (_event: ICLEvent): void => {
    if (_event) {
      const INCOMMING_TABLE_STATE = JSON.parse(_event.Data);
      this.RowsReturned = INCOMMING_TABLE_STATE.ItemsPeerPage;
      this.StartPos = INCOMMING_TABLE_STATE.CurrentPage;
      //this.GetApplications();
    }
  };

  OnLoadTable(): void {
    this.displayedColumns = MapDisplayColumns<IBitbucketRepository, null>({
      dataSource: this.repositoriesList,
      renameColumns: {
        "Code": "Codigo",
        "Name": "Nombre",
        "Description": "Descripción",
        "Status": "Estado",
      },
      ignoreColumns: [...this.toIgnore, "UpdateDate"]
    })
  }

  private loadTableData(): void{
    const CURRENT_TABLE_STATE = {
      CurrentPage: 1,
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.repositoriesList,
      RecordsCount: this.recordsCount
    };
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.tableId,
      Data: JSON.stringify(CURRENT_TABLE_STATE)
    } as ICLEvent);
  }

  GenerateCode(): void{
    window.location.href=AppConstants.BitbucketCode.replace("{client_id}", this.bitbucketSetting.Json.key);
  }

  GenerateAccessToken():void{
    this.bitbucketIntegrationService.GenerateAccessToken(this.bitbucketSetting.Json.key, this.bitbucketSetting.Json.secret, this.bitbucketCode!).subscribe(
      accessToken => {
        this.bitbucketIntegrationService.SaveAccessToken(accessToken)
        this.toastService.success(`Token Generado Exitosamente`)
      }
    )
  }

  RefreshAccessToken():void{
    this.bitbucketIntegrationService.RefreshAccessToken(this.bitbucketSetting.Json.key, this.bitbucketSetting.Json.secret).subscribe(
      {
        next: accessToken=>{
          this.bitbucketIntegrationService.SaveAccessToken(accessToken)
          this.toastService.success("Token actualizado")
        },
        error: error => {
          this.toastService.warn("No se pudo refresacar el token")
        }
      }
    );
  }

  GetRepositoryList(): void {
    this.bitbucketIntegrationService.GetRepositories().subscribe({
      next: response=>{
        this.repositories = new MatTableDataSource(response.values)
        this.repositories.paginator = this.paginator
      }, error: error => {
        console.log(error)
      }
    })
  }
}
