<div>
    <mat-card class="mb-1 mat-elevation-z0">
        <div *ngIf="edit; else elseBlock">
            <div class="header">
                <button mat-icon-button>
                    <mat-icon (click)="GoBack()">chevron_left</mat-icon>
                </button>

                <h1>Proyecto: {{projectName}}</h1>
            </div>
        </div>
        <ng-template #elseBlock>
            <div class="header">
                <button mat-icon-button>
                    <mat-icon (click)="GoBack()">chevron_left</mat-icon>
                </button>

                <h1>Creación de proyecto</h1>
            </div>
        </ng-template>

    </mat-card>

    <mat-card class="mb-1 mat-elevation-z0">
        <div class="action-buttons mb-1">
            <div id="buttons-container">
                <mat-slide-toggle class="example-margin" color="primary" [checked]="checked"
                    *ngIf="edit && CanEditProject()" (change)="onModeEdition($event)">
                    Edición
                </mat-slide-toggle>
                <mat-slide-toggle class="example-margin" color="primary" [checked]="checked" *ngIf="!CanEditProject()"
                    disabled (change)="onModeEdition($event)">
                    No tiene permiso para editar proyectos
                </mat-slide-toggle>
            </div>
            <div class="align-right mb-1">
                <button mat-button mat-stroked-button color="primary" (click)="addProyect()" class="form-control"
                    [style]="btnStyle">
                    <mat-icon>save_outline</mat-icon> Guardar
                </button>
            </div>
        </div>
        <div>



            <mat-accordion class="example-headers-align" multi>
                <mat-expansion-panel expanded="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Información del proyecto
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <form [formGroup]="projectForm">
                        <div class="form-input alignStart">
                            <mat-form-field appearance="legacy" class="w-input">
                                <mat-label>Nombre del proyecto </mat-label>
                                <input matInput placeholder="Nombre del proyecto" formControlName="Name"
                                    [readonly]="edit ? !checked : checked">
                                <mat-error
                                    *ngIf="projectForm.controls['Name'].invalid">{{getErrorMessageRequired("Name","El
                                    nombre es requerido")}}</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="legacy" class="w-input">
                                <mat-label>Código del proyecto </mat-label>
                                <input matInput placeholder="Código del proyecto" formControlName="Code"
                                    [readonly]="edit ? !checked : checked">
                                <mat-error
                                    *ngIf="projectForm.controls['Code'].invalid">{{getErrorMessageRequired("Code","El
                                    código
                                    es requerido")}}</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="form-input alignStart">
                            <mat-form-field appearance="legacy" class="w-input">
                                <mat-label>Nombre del Cliente </mat-label>
                                <input matInput placeholder="Nombre del Cliente" formControlName="Client"
                                    [readonly]="edit ? !checked : checked">
                                <mat-error
                                    *ngIf="projectForm.controls['Client'].invalid">{{getErrorMessageRequired("Name","El
                                    nombre del cliente es requerido")}}</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="legacy" class="w-input">
                                <mat-label>URL de Jira </mat-label>
                                <input matInput placeholder="URL de Jira" formControlName="JiraUrl"
                                    [readonly]="edit ? !checked : checked">
                                <mat-error
                                    *ngIf="projectForm.controls['JiraUrl'].invalid">{{getErrorMessageRequired("JiraUrl","El
                                    url de Jira es requerido")}}</mat-error>
                            </mat-form-field>

                        </div>

                        <div class="form-input alignStart">
                            <mat-form-field appearance="legacy" class="w-input">
                                <mat-label>Product Owner</mat-label>
                                <input matInput [matAutocomplete]="auto4" formControlName="ProductOwnerId"
                                    [formControl]="formProductOwner" placeholder="Ingrese un correo" required
                                    (click)="getFilteredOwner()" [readonly]="edit ? !checked : checked">
                                <mat-error *ngIf="this.formProductOwner?.errors?.['required']">El propitario del
                                    producto es
                                    requerido</mat-error>
                                <mat-autocomplete #auto4="matAutocomplete">
                                    <mat-option (click)="createUser()">
                                        <span class="color-span">Crear nuevo </span><mat-icon
                                            class="color-span">add</mat-icon>
                                    </mat-option>
                                    <mat-option *ngFor="let state of filteredOwner | async"
                                        (click)="selectedOwner(state)" [value]="state.Name">
                                        <span>{{state.Name}}</span>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <mat-form-field appearance="legacy" class="w-input">
                                <mat-label>Responsable</mat-label>
                                <input matInput [matAutocomplete]="auto" formControlName="UserManagerId"
                                    [formControl]="formManager" placeholder="Ingrese un usuario" required
                                    (click)="getFilteredUsers()" [readonly]="edit ? !checked : checked">
                                <mat-error *ngIf="this.formManager?.errors?.['required']">El responsable es
                                    requerido</mat-error>
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option (click)="createUser()">
                                        <span class="color-span">Crear nuevo </span><mat-icon
                                            class="color-span">add</mat-icon>
                                    </mat-option>
                                    <mat-option *ngFor="let state of filteredUsers | async"
                                        (click)="selectedManager(state)" [value]="state.Name">
                                        <span>{{state.Name}}</span>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>

                        <div class="form-input alignStart">
                            <mat-form-field appearance="legacy" class="w-input">
                                <mat-label>Desarrolladores Involucrados</mat-label>
                                <mat-chip-list #chipList5 aria-label="Desarrolladores">
                                    <mat-chip *ngFor="let dev of developersInv" (removed)="removeDeveloper(dev)">
                                        {{dev}}
                                        <button matChipRemove *ngIf="edit ? checked : !checked">
                                            <mat-icon>cancel</mat-icon>
                                        </button>
                                    </mat-chip>
                                    <input
                                        [placeholder]="(checked && edit) ||  (!checked && !edit) ? 'Nuevo desarrollador...' : ''"
                                        [formControl]="formDeveloper" [matAutocomplete]="auto3"
                                        [matChipInputFor]="chipList5"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        (matChipInputTokenEnd)="addDeveloper($event)"
                                        (click)="getFilteredDeveloperInv()" [readonly]="edit ? !checked : checked">
                                </mat-chip-list>
                                <mat-autocomplete #auto3="matAutocomplete">
                                    <mat-option (click)="createUser()">
                                        <span class="color-span">Crear nuevo </span><mat-icon
                                            class="color-span">add</mat-icon>
                                    </mat-option>
                                    <mat-option *ngFor="let dev of filteredDeveloperInv | async" [value]="dev.Name"
                                        (click)="selectedDeveloper(dev)">
                                        {{dev.Name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <mat-form-field appearance="legacy" class="w-input">
                                <mat-label>Descripcion del proyecto </mat-label>
                                <input matInput placeholder="Descripcion del proyecto" formControlName="Description"
                                    [readonly]="edit ? !checked : checked" />
                                <mat-error
                                    *ngIf="projectForm.controls['Description'].invalid">{{getErrorMessageRequired("Description","Descripcion
                                    requerida")}}</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="form-input alignStart">

                            <mat-form-field appearance="legacy" class="w-input"
                                *ngIf="edit && !checked; else elseStatus">
                                <mat-label>Estado</mat-label>
                                <mat-select formControlName="StatusDisabled">
                                    <mat-option *ngFor="let status of projectStatus"
                                        [value]="status.NameStatus">{{status.NameStatus}}</mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="projectForm.controls['Status'].invalid">{{getErrorMessageRequired("Status","Estado
                                    requerido")}}</mat-error>
                            </mat-form-field>

                            <ng-template #elseStatus>
                                <mat-form-field appearance="legacy" class="w-input">
                                    <mat-label>Estado</mat-label>
                                    <mat-select formControlName="Status">
                                        <mat-option *ngFor="let status of projectStatus"
                                            [value]="status.NameStatus">{{status.NameStatus}}</mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="projectForm.controls['Status'].invalid">{{getErrorMessageRequired("Status","Estado
                                        requerido")}}</mat-error>
                                </mat-form-field>
                            </ng-template>
                        </div>
                    </form>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Bases de datos
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div id="table-container" class="example-container">
                        <table mat-table [dataSource]="DataBasesList" id="tableServ">
                            <ng-container matColumnDef="ServerId">
                                <th mat-header-cell *matHeaderCellDef> Servidor </th>
                                <td mat-cell *matCellDef="let element"> {{ getServerId(element.ServerId) | uppercase }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Name">
                                <th mat-header-cell *matHeaderCellDef> Nombre de Base de datos </th>
                                <td mat-cell *matCellDef="let element"> {{ element.Name | uppercase }} </td>
                            </ng-container>
                            <ng-container matColumnDef="Type">
                                <th mat-header-cell *matHeaderCellDef> Tipo </th>
                                <td mat-cell *matCellDef="let element"> {{ element.Type | uppercase }} </td>
                            </ng-container>
                            <ng-container matColumnDef="IsActive">
                                <th mat-header-cell *matHeaderCellDef> Ambientes </th>
                                <td mat-cell *matCellDef="let element">
                                  <div *ngIf="element.Name.includes('DEV')">
                                    DESARROLLO
                                  </div>
                                  <div *ngIf="element.Name.includes('TST')">
                                    PRUEBAS
                                  </div>
                                  <div *ngIf="!element.Name.includes('DEV') && !element.Name.includes('TST')">
                                    PRODUCCIÓN
                                  </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                          Recursos asignados
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-windows-services *ngIf="!loading" [edit]="true" [checked]="false" [listTask]="listTask"
                        [listSites]="listSites" [listServices]="listServices" [listPools]="listPools" [filters]="true"></app-windows-services>
<!--                        (RemoveService)="RemoveServices($event)"-->

                </mat-expansion-panel>
                <mat-expansion-panel *ngIf="!loading">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Link de Intereses
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-app-links [isChecked]="checked" [edit]="edit" [checked]="checked" [AppLinkList]="AppLinkList"
                        (AppLinkEvent)="AddAppLink($event)">
                    </app-app-links>
                </mat-expansion-panel>
              <mat-expansion-panel *ngIf="false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Aplicaciones
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="edit ? checked : !checked">
                    <mat-form-field appearance="legacy" class="w-input">
                      <mat-label>
                        Nombre de la Aplicación
                      </mat-label>
                      <input matInput [matAutocomplete]="auto7" [formControl]="formApplicacion"
                             placeholder="Nombre del Aplicación" required
                             (click)="getFilteredApplications()"
                             [readonly]="edit ? !checked : checked">
                      <mat-autocomplete #auto7="matAutocomplete">
                        <mat-option *ngFor="let appl of listFilteredApp | async" (click)="selectedApplication(appl)"
                                    [value]="appl.Name">
                          <span>{{appl.Name}}</span>
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>

                    <button mat-icon-button color="primary" style="margin-left: 15px;"
                            (click)="AddListApplication()">Agregar
                      <mat-icon>add</mat-icon>
                    </button>
                </div>
<!--                <app-applications *ngIf="!loading" [edit]="edit" [checked]="checked" [listApplications]="listApplicacions"-->
<!--                                  [filters]="true" (RemoveApplication)="RemoveApplication($event)"></app-applications>-->

              </mat-expansion-panel>
            </mat-accordion>

        </div>
    </mat-card>
</div>
