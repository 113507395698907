import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { IBaseResponse, IResponse } from '../models/i-response';
import {
  IPermission,
  IPermissionsByRole
} from '../models/interfaces/i-permissions';
import {
  IRole,
} from '../models/interfaces/i-role';
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private readonly CONTROLLER = '/api/Permission'
  constructor(
    private http: HttpClient,
  ) { }

  GetAllPermissions(): Observable<ICLResponse<IPermission[]>> {
    return this.http.get<ICLResponse<IPermission[]>>(`${this.CONTROLLER}`);
  }
  GetPermissions(_value: string, _startPos: number, _rowsReturned: number): Observable<ICLResponse<IPermission[]>> {
    return this.http.get<ICLResponse<IPermission[]>>(
      `${this.CONTROLLER}/Filter`, {
      params: new HttpParams()
        .set('value', _value)
        .set('startPos', _startPos.toString())
        .set('rowsReturned', _rowsReturned.toString())
    });
  }
  PostPermission(permission: IPermission): Observable<ICLResponse<IPermission>> {
    return this.http.post<ICLResponse<IPermission>>(
      `${this.CONTROLLER}`,
      permission
    );
  }
  PatchPermission(permission: IPermission): Observable<ICLResponse<IPermission>> {
    return this.http.patch<ICLResponse<IPermission>>(
      `${this.CONTROLLER}`,
      permission
    );
  }


  GetPermissionsByRole(idRole: number): Observable<ICLResponse<IPermission[]>> {
    const query = new HttpParams().set('id', idRole);
    return this.http.get<ICLResponse<IPermission[]>>(
      `${this.CONTROLLER}/GetPermsByRole`,
      { params: query }
    );
  }

  PostPermissionByRole(roleId: number, permissionsIds: string
  ): Observable<ICLResponse<IPermissionsByRole>> {
    let params = {
      Data: permissionsIds,
      EntityId: roleId
    }
    return this.http.patch<ICLResponse<IPermissionsByRole>>(`${this.CONTROLLER}/PatchPermissionsByRole`, params);
  }




  GetPermissionsByUser(userId: number): Observable<ICLResponse<IPermission[]>> {
    const params = new HttpParams().set('id', userId)
    return this.http.get<ICLResponse<IPermission[]>>(`${this.CONTROLLER}/GetPermissionsByUser`, { params: params })
  }
}
