import { IDataBaseProject } from "./i-database";
import { IProject } from "./i-proyects";
import { IServer } from "./i-server";
import { IUsers } from "./i-users";
import { TaskSchedule, WindowServerService } from "./i-windowsservice";
import {Structures} from "@clavisco/core";
import IBaseEntity = Structures.Interfaces.IBaseEntity;


export interface ICheckList extends IBaseEntity{
    Id: number,
    Name: string,
    Description: string,
    DisplayName: string,
    CrystalParamName: string
}

export interface ICheckListRow extends IBaseEntity{

    Id: number,
    Name: string,
    Type: string,
    Description: string,
    CheckListId: number,
    IsRequired: boolean,
    DisplayName: string,
    CrystalParamName: string
}

export interface ICheckListProject {
    Project: IProject,
    ListDataBase: IDataBaseProject[],
    ListTasks: TaskSchedule[],
    ListServers: IServer[],
    ListDevelopers: IUsers[],
    ListServices: WindowServerService[];
}
