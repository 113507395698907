<section class="replicate-objects-container">
  <fieldset class="mat-elevation-z0">
    <legend>
      Conexión de Origen:
    </legend>
    <form [formGroup]="formConnexion" class="form-fields">

      <mat-form-field appearance="outline">
        <mat-label>Base de datos</mat-label>
        <div class="input-container-with-cleanliness">
          <input type="text" placeholder="Base de datos" matInput formControlName="DataBaseCode"
                 [matAutocomplete]="autoDB">
          <mat-icon matTooltip="Cancelar" class="cancel-icon" (click)="ClearSelectedField('DataBaseCode')">close
          </mat-icon>
        </div>
        <mat-autocomplete #autoDB="matAutocomplete">
          <mat-option *ngFor="let db of listFilteredDatabases" [value]="db" [matTooltip]="db"
                      matTooltipPosition="right">{{db}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Conexión ServiceLayer</mat-label>
        <mat-select required [formControlName]="'ServerUrl'">
          <mat-option *ngFor="let connection of listConnections | async" [value]="connection.Value"
                      [matTooltip]="connection.Value" [matTooltipPosition]="'right'">
            <span>{{ connection.Name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Licencia</mat-label>
        <input type="text" required matInput [formControlName]="'License'" placeholder="Usuario..."/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Contraseña</mat-label>
        <input [type]="hidePassword ? 'password' : 'text'" required matInput [formControlName]="'Password'"
               placeholder="Contraseña..."/>
        <button class="btn-icon" type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
                [matTooltip]="hidePassword ? 'Mostrar contraseña' : 'Ocultar contraseña'">
          <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
      </mat-form-field>
      <div>
        <button class="text-btn clear-btn" type="button" mat-stroked-button
                matTooltip="Limpiar todos los campos conexión"
                (click)="ClearConnectionFields()">
          <mat-icon>close</mat-icon>
          Limpiar
        </button>
      </div>
    </form>

  </fieldset>

  <mat-card class="mat-elevation-z0">
    <form [formGroup]="filterForm" class="form-fields filters-row " (ngSubmit)="FilterSapObject()">
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Filtrar por:</mat-label>
          <mat-select required formControlName="Type">
            <mat-option *ngFor="let type of filterTypes" [value]="type.Id" (click)="SelectType(type.Id)"
                        [matTooltip]="type.Value" [matTooltipPosition]="'right'">
              <span>{{ type.Value }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="selectedType ===1">
          <mat-label>Tabla SAP</mat-label>
          <div class="input-container-with-cleanliness">
            <input type="text" matInput formControlName="TableName" placeholder="Tabla SAP"/>
            <mat-icon matTooltip="Cancelar" class="cancel-icon" (click)="ClearSelectedField('TableName', 'filter')">
              close
            </mat-icon>
          </div>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="selectedType ===2">
          <mat-label>Applicación</mat-label>
          <div class="input-container-with-cleanliness">
            <input type="text" placeholder="Applicación" matInput formControlName="ApplicationName"
                   [matAutocomplete]="autoApp">
            <mat-icon matTooltip="Cancelar" class="cancel-icon" (click)="ClearSelectedField('ApplicationName', 'filter')">
              close
            </mat-icon>
          </div>
          <mat-autocomplete #autoApp="matAutocomplete">
            <mat-option *ngFor="let app of listFilteredApp" [value]="app.Name" [matTooltip]="app.Name"
                        (click)="SelectApplication(app)"
                        matTooltipPosition="right">{{app.Name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="thereDataUDOs">
          <mat-label>Nombre</mat-label>
          <div class="input-container-with-cleanliness">
            <input type="text" formControlName="NameUDO" (click)="GetFilteredUDONames()"
                   matInput [matAutocomplete]="autoNameUDO" placeholder="Filtrar por Nombre" />
            <mat-icon matTooltip="Limpiar" class="cancel-icon"
                      (click)="ClearSelectedField('NameUDO', 'filter')">close</mat-icon>
          </div>
          <mat-autocomplete #autoNameUDO="matAutocomplete">
            <mat-option *ngFor="let name of listFilteredUDONames | async" [value]="name">{{ name }}</mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="actions-container">
        <button type="button" mat-stroked-button matTooltip="Limpiar todos los filtros"
                (click)="ClearFiltersReplicateTable()">
          <mat-icon>close</mat-icon>
          <span class="btn-text">Limpiar</span>
        </button>
        <button type="submit" class="btn-filter" mat-stroked-button matTooltip="Aplicar filtros a la búsqueda">
          <mat-icon>search</mat-icon>
          Buscar
        </button>
      </div>
    </form>
  </mat-card>
  <mat-card class="udos-tab-container mat-elevation-z0">
    <mat-card-content>
      <mat-tab-group dynamicHeight [(selectedIndex)]="tabIndex" (selectedTabChange)="TabChanged($event)">
        <mat-tab label="UDTs">
          <cl-table [Id]="tableUdt"
                    [MappedColumns]="displayedColumnsUDTsTable"
                    [PageSizeOptions]="pageSizeOptions"
                    [ItemsPeerPage]="itemsPeerPage"
                    [Records]="listUdts"
                    [RecordsCount]="recordsCount"
                    [ShouldPaginateRequest]="shouldPaginateRequest"
                    [HasPaginator]="hasPaginator"
                    [HasItemsSelection]="true"
                    [ShouldEmitRows]="true"
                    [ScrollHeight]="scrollHeight"
          ></cl-table>
        </mat-tab>
        <mat-tab label="UDFs">
          <cl-table [Id]="tableUdf"
                    [MappedColumns]="displayedColumns"
                    [PageSizeOptions]="pageSizeOptions"
                    [ItemsPeerPage]="itemsPeerPage"
                    [Records]="listUdfs"
                    [RecordsCount]="recordsCount"
                    [ShouldPaginateRequest]="shouldPaginateRequest"
                    [HasItemsSelection]="true"
                    [ShouldEmitRows]="true"
                    [Buttons]="Buttons"
                    [ScrollHeight]="scrollHeight"
          ></cl-table>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
    <div class="container-action-btns">
      <button class="connections-btn" mat-stroked-button matBadge="{{selectedConnections.length}}"
              matTooltip="Conexiones agregadas {{selectedConnections.length}}" (click)="OpenModalConnections()">
        <span class="text-btn">Conexiones</span>
      </button>
      <button *ngIf="permissionReplicateUDOs" mat-stroked-button (click)="ReplicateSelectedUDOs()"
              matTooltip="Replicar UDOs seleccionados">
        <span class="text-btn">Replicar</span>
      </button>
    </div>
  </mat-card>
</section>
