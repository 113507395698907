import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppMaterialModule } from '../../app-material.module';
import { AppRoutingModule } from '../../app-routing.module';
import { SharedModule } from "../../shared/shared.module";
import { BitbucketIntegrationModule } from './bitbucket-integration/bitbucket-integration.module';
import { DevelopersModule } from './developers/developers.module';
import { HomeComponent } from './home.component';
import { LogsModule } from './logs/logs.module';
import { PermissionsModule } from './permissions/permissions.module';
import { ProyectsModule } from './proyects/proyects.module';
import { ServerModule } from './servers/server.module';
import { UsersModule } from './users/users.module';
import { WindowsServicesModule } from './windows-services/windows-services.module';
import { FilesModule } from './files/files.module';
import { ProjectDeliveriesModule } from './project-deliveries/project-deliveries.module';
import { CheckListModule } from './check-list/check-list.module';
import { ProjectStatusModule } from './project-status/project-status.module';
import { ReportsModule } from '../reports/reports.module';
import { DocumentsModule } from './documents/documents.module';
import {TableModule} from "@clavisco/table";
import {ApplicationModule} from "./applications/application.module";
import {SAPObjectModule} from "./sap-object/sap-object.module";
import { SettingComponent } from './setting/setting.component';
import { SettingDialogComponent } from './setting/setting-dialog/setting-dialog.component';


@NgModule({
    declarations: [
        HomeComponent,
        SettingComponent,
        SettingDialogComponent,
    ],
    exports: [
        HomeComponent
    ],
  imports: [
    CommonModule,
    AppMaterialModule,
    AppRoutingModule,
    ApplicationModule,
    UsersModule,
    PermissionsModule,
    DevelopersModule,
    ProyectsModule,
    ProjectDeliveriesModule,
    PermissionsModule,
    BitbucketIntegrationModule,
    WindowsServicesModule,
    SharedModule,
    ServerModule,
    LogsModule,
    FilesModule,
    CheckListModule,
    ProjectStatusModule,
    ReportsModule,
    DocumentsModule,
    TableModule,
    SAPObjectModule
  ]
})
export class HomeModule { }
