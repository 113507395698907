import {Structures} from "@clavisco/core";
import IBaseEntity = Structures.Interfaces.IBaseEntity;

export interface IWindowService {
  Id: number;
  ProjectId: number;
  Name: string;
  Description: string;
  Status: string;
}

export interface IISSite {
  Code: string;
  MaxQtyRowsFetch: number
  IdSite: number;
  SiteName: string;
  Schema: string;
  ServerAutoStart: Boolean;
  IsServerAutoStart: string;
  State: string;
  LogFileDirectory: string;
  Server: string;
  Hostname: string;
  PhysicalPath: string;
  XmlString: string;
  PoolName: string;
  Environment: string;
  UpdateDate: Date;
  UpdatedDate: string;
  LogPath: string;
}

export interface IISPool extends IBaseEntity{
  MaxQtyRowsFetch: number
  Code: string;
  AutoStart: Boolean;
  IsAutoStart?: string
  PoolName: string;
  State: string;
  Schema: string;
  IsLocallyStored: Boolean;
  Enable32Bit: Boolean;
  User: string;

  StartMode: string;
  Version: string;
  Server: string
  Environment: string
  CreatedBy: string
  UpdatedDate: string
}

export interface TaskSchedule {
  Code: string;
  MaxQtyRowsFetch: number;
  Name: string;
  Path: string;
  IsActive: Boolean;
  Status?: string;
  LastRunTime: Date;
  NextRunTime: Date;
  LastTimeResult: number;
  Enabled: Boolean;
  State: string;
  ReadOnly: Boolean;
  Description: string;
  Author: string;
  CreatedBy: string
  CreatedDate: Date;
  Server: string;
  Environment: string
  UpdatedBy: string
  UpdateDate: Date
  UpdatedDate: string
}

export interface WindowServerService {
  Code: string;
  MaxQtyRowsFetch: number;
  ServiceName: string;
  DisplayName: string;
  Description: string;
  StartType: string;
  Status: string;
  Server: string;
  Environment: string;
  UpdateDate: Date;
  UpdatedDate: string;
  XmlString: string;
  LogPath: string;
}

export interface FilteredServiceData {
  Name: string
  Status: string
  Server: string
  Environment: string

}

export interface TypeServices {
  Type: number;
  Data: any;
}
