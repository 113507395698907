import { Component, Inject, OnInit } from '@angular/core';
import { PermissionCode } from 'src/app/common/constants';
import { IISPool } from 'src/app/models/interfaces/i-windowsservice';
import { SessionService } from 'src/app/services/session.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService, CLToastType } from '@clavisco/alerts';

@Component({
  selector: 'app-pool-detail',
  templateUrl: './pool-detail.component.html',
  styleUrls: ['./pool-detail.component.sass']
})
export class PoolDetailComponent {

  pool: IISPool = {} as IISPool;
  showPw: boolean = true
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PoolDetailComponent>,
    private sessionService: SessionService,
    private alertService: AlertsService
  ) {
    this.pool = data.poolInfo
  }

  CloseDialog(): void {
    this.dialogRef.close()
  }
}
