<div class="align-right"><mat-icon class="icon-close" (click)="CloseDialog(false)">close</mat-icon></div>
<div class="user-add-header">
    <h1>{{this.userData ? "Editar Usuario": "Agregar Usuario"}}</h1>
</div>
<mat-divider></mat-divider>
<div class="user-add-content">
    <form [formGroup]="userForm">
        <div class="imput-field">
            <mat-form-field appearance="outline">
                <mat-label>Nombre De Usuario</mat-label>
                <input matInput placeholder="Nombre De Usuario" formControlName="Name">
                <mat-error *ngIf="userForm.controls['Name'].invalid">{{getErrorMessageRequired("Name","El nombre de usuario es requerido")}}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="imput-field">
            <mat-form-field appearance="outline">
                <mat-label>Apellido De Usuario</mat-label>
                <input matInput placeholder="Apellido De Usuario" formControlName="LastName">
                <mat-error *ngIf="userForm.controls['LastName'].invalid">{{getErrorMessageRequired("LastName","El apellido de usuario es requerido")}}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="imput-field">
            <mat-form-field appearance="outline">
                <mat-label>Correo Electronico</mat-label>
                <input matInput placeholder="Correo Electronico" formControlName="Email">
                <mat-error *ngIf="userForm.controls['Email'].invalid">{{getErrorMessageRequired("Email","El correo es requerido")}}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="user-add-password-field">
            <mat-form-field appearance="outline">
                <mat-label>Contraseña</mat-label>
                <input [type]="inputPasswordType" matInput placeholder="Contraseña" formControlName="Password">
                <mat-error *ngIf="userForm.controls['Password'].invalid">{{getErrorMessageRequired("Password","La contraseña es requerida")}}
                </mat-error>

            </mat-form-field>
            <button mat-icon-button matSuffix (click)="ToggleShowPassword()">
                <mat-icon class="f-size-4">{{inputPasswordIcon}}</mat-icon>
            </button>
        </div>
        <mat-checkbox class="example-margin" formControlName="IsActive">Activo</mat-checkbox>
    </form>
</div>
<mat-divider></mat-divider>
<div class="user-add-footer">
    <button mat-stroked-button  (click)="CloseDialog(false)"><mat-icon>close</mat-icon> Cerrar </button>
    <button mat-stroked-button color="primary" (click)="onSubmit()"><mat-icon>save</mat-icon> Guardar </button>
</div>
