import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IMenuPath } from '../models/interfaces/i-menu-paths';
import { IMenuNode } from '@clavisco/menu';


export class Data {

  constructor(public menu: IMenuNode[],
  ) { }
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  showDbObjectView: Subject<boolean> = new Subject<boolean>();
  public url = 'assets/data/';
  titleName: Subject<string>

  public Menu: Subject<IMenuNode[]>;
  public Data = new Data(
    [], // menuList

  )

  constructor(
    public http: HttpClient,
    // public menu: IMenuNode[],
  ) {
    this.titleName = new Subject<string>();
    this.Menu = new Subject<IMenuNode[]>();
  }

  public getMenu(): Observable<IMenuNode[]> {
    return this.http.get<IMenuNode[]>(this.url + 'menu.json');
  }


}
