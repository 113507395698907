import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  Inject,
  OnDestroy
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AlertsService, CLToastType } from '@clavisco/alerts';
import { CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown } from '@clavisco/linker';
import { OverlayService } from '@clavisco/overlay';
import { MapDisplayColumns, MappedColumns } from '@clavisco/table';
import {catchError, finalize, Subscription, tap} from 'rxjs';
import { IPermission } from 'src/app/models/interfaces/i-permissions';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ModalAddPermissionsComponent } from './modal-add-permissions/modal-add-permissions.component';
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;


@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.sass'],
})
export class PermissionComponent implements OnInit, OnChanges, OnDestroy {
  allSuscription: Subscription= new Subscription()
  ELEMENT_DATA!: IPermission[]
  dataSource: IPermission[] = [];
  filter: FormControl = new FormControl('');

  @Input() ActualTabIndex!: number
  @Output() OpenAddModal: EventEmitter<Function> = new EventEmitter<Function>()

  StartPos: number = 0;
  RowsReturned: number = 10;
  tableId = 'tablePermissions'
  displayedColumns!: MappedColumns;
  scrollHeight: string = '425px';
  pageSizeOptions: number[] = [10, 20];
  itemsPeerPage: number = 10;
  recordsCount: number = 0;
  hasPaginator: boolean = true;
  shouldPaginateRequest: boolean = false;
  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };
  Buttons: any = [
    {
      Title: `Acción`,
      Action: Structures.Enums.CL_ACTIONS.UPDATE,
      Icon: `edit`,
      Color: `primary`
    },
  ]
  constructor(
    public dialog: MatDialog,
    private permissionService: PermissionsService,
    private alertService: AlertsService,
    private clBlockUI: OverlayService,
    @Inject('LinkerService') private linkerService: LinkerService
  ) {
    this.displayedColumns = MapDisplayColumns<IPermission, null>({
      dataSource: [],
      renameColumns: {
        Name: 'Código',
        Description: 'Descripción',
        State: 'Estado'
      },
      ignoreColumns: ['MaxQtyRowsFetch', 'Id', 'PermissionType', 'CreatedBy' ,'CreatedDate', 'UpdateDate', 'UpdatedBy', 'IsActive']
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.ActualTabIndex === 2) {
      this.OpenAddModal.emit(() => {
        this.OpenDialog()
      })
    }
  }

  ngOnDestroy(): void{
    this.allSuscription.unsubscribe();
  }
  ngOnInit(): void {
    Register<CL_CHANNEL>(this.tableId, CL_CHANNEL.INPUT, this.GetElementsRecords, this.callbacks);
    Register<CL_CHANNEL>(this.tableId, CL_CHANNEL.OUTPUT, this.SelectedPermission, this.callbacks);

    this.allSuscription.add(this.linkerService.Flow()
      ?.pipe(StepDown<CL_CHANNEL>(this.callbacks))
      .subscribe({
        next: (callback) =>
          Run(callback.Target, callback, this.callbacks.Callbacks),
        error: (error) => console.log(`mi error`, error),
      }));

    this.GetPermissionData();
  }

  GetElementsRecords = (_event: ICLEvent): void => {
    if (_event) {
      const INCOMMING_TABLE_STATE = JSON.parse(_event.Data);
      this.RowsReturned = INCOMMING_TABLE_STATE.ItemsPeerPage;
      this.StartPos = INCOMMING_TABLE_STATE.CurrentPage;
      this.GetPermissionData();
    }
  };

  SelectedPermission = (_event: ICLEvent) => {
    const event = JSON.parse(_event.Data)
    const permission: IPermission = JSON.parse(event.Data)
    this.OpenEditDialog(permission)
  };


  GetPermissionData(): void {
    this.clBlockUI.OnPost("Cargando permisos...")
    this.permissionService.GetPermissions(this.filter.value, this.StartPos, this.RowsReturned).pipe(finalize(() => { this.clBlockUI.Drop(); }),
    tap((data: ICLResponse<IPermission[]>)=>{
          if (data.Data) {
            this.dataSource = data.Data;
            this.dataSource.map(x => {
              x.State = x.IsActive ? 'Activo' : 'Inactivo'
            })
            this.recordsCount = data.Data.length > 0 ? data.Data[0].MaxQtyRowsFetch : 0;
          } else {
            this.alertService.Toast({ message: data.Message, type: CLToastType.ERROR })
          }
          this.loadTableData();
        }),catchError((err) => {

          return [];
        }
      )).subscribe();
  }

  loadTableData(): void {
    const CURRENT_TABLE_STATE = {
      CurrentPage: 1,
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.dataSource,
      RecordsCount: this.recordsCount
    };
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.tableId,
      Data: JSON.stringify(CURRENT_TABLE_STATE)
    } as ICLEvent);
  }

  OpenDialog(): void {

    const dialogRef = this.dialog.open(ModalAddPermissionsComponent, {
      width: '450px',
      maxHeight: `85vh`,
    });
    dialogRef.afterClosed().subscribe(result => {
      this.GetPermissionData()
    })

  }

  OpenEditDialog(permission: IPermission): void {
    const dialogRef = this.dialog.open(ModalAddPermissionsComponent, {
      width: '450px',
      maxHeight: `85vh`,
      data: permission,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result)  this.GetPermissionData()
    })
  }
}
