<div>
  <h2 style="text-align: center">Valores Válidos</h2>
  <hr>
  <mat-card class="mb-1 mat-elevation-z0 card">
    <cl-table [Id]="tableId"
              [MappedColumns]="displayedColumns"
              [Records]="validValuesSAP"
              [RecordsCount]="recordsCount"
              [HasPaginator]="true"
              [HasItemsSelection]="false"
              [ShouldPaginateRequest]="false">
    </cl-table>
  </mat-card>

  <div>
    <button mat-flat-button color="primary" style="float: right"  [mat-dialog-close]="null">Aceptar</button>
  </div>
</div>
