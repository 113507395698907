import {CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown} from "@clavisco/linker";
import {AlertsService, CLToastType, ModalService} from "@clavisco/alerts";
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MapDisplayColumns, MappedColumns} from "@clavisco/table";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ICLTableButton} from "@clavisco/table/lib/table.space";
import {filter, finalize, map, Subscription} from "rxjs";
import {OverlayService} from "@clavisco/overlay";
import {FormControl} from "@angular/forms";
import {Structures} from "@clavisco/core";

import {ISapObjectResStructure} from "../../../../models/interfaces/sap-object/i-sap-object-res-structure";
import {ModalSummaryResponseComponent} from "../modal-summary-response/modal-summary-response.component";
import {UdoConnectionsModalComponent} from "../udo-connections-modal/udo-connections-modal.component";
import {IUserDefinedField} from "../../../../models/interfaces/sap-object/i-user-defined-field";
import {IUserDefinedTable} from "../../../../models/interfaces/sap-object/i-user-defined-table";
import {ISapUdoHandler} from "../../../../models/interfaces/sap-object/i-sap-udo-handler";
import {IUdoConnection} from "../../../../models/interfaces/sap-object/i-udo-connection";
import {IApplication} from "../../../../models/interfaces/sap-object/i-application";
import {PermissionCode, UdoHistoryAction} from "../../../../common/constants";
import {ModalAddUdtComponent} from "../modal-add-udt/modal-add-udt.component";
import {ModalAddUdfComponent} from "../modal-add-udf/modal-add-udf.component";
import {ApplicationService} from "../../../../services/application.service";
import {ConnectionService} from "../../../../services/connection.service";
import {SAPObjectService} from "../../../../services/sap-object.service";
import {SessionService} from "../../../../services/session.service";


@Component({
  selector: 'app-user-defined-objects',
  templateUrl: './user-defined-objects.component.html',
  styleUrls: ['./user-defined-fields.component.sass']
})

export class UserDefinedObjectsComponent implements OnInit, OnDestroy {

  // #region Properties
  subscription: Subscription = new Subscription();
  listConnections: IUdoConnection[] = [];
  itemsPeerPage: number = 4;
  applications: IApplication[];

  controlSelectedApplication: FormControl = new FormControl('');
  selectedApplication?: IApplication;
  // #endregion

  // #region Table UDFs
  userDefinedFieldsTableId: string = "table-user-defined-fields";
  displayedColumnsUDF: MappedColumns;
  UDFs: IUserDefinedField[] = [];
  recordsCountUDFTable: number = this.UDFs.length;
  pageSizeOptionsUDFTable: number[] = [10, 25, 50];
  itemsPeerPageUDFTable: number = this.pageSizeOptionsUDFTable[0];
  udfTableCurrentPage: number = 1;
  shouldPaginateRequestUDFTable: boolean = false;
  scrollHeightUDFTable: string = '380px';
  // #endregion

  // #region Table UDTs
  userDefinedTablesTableId: string = "table-user-defined-tables";
  displayedColumnsUDT: MappedColumns;
  UDTs: IUserDefinedTable[] = [];
  recordsCountUDTTable: number = this.UDTs.length;
  pageSizeOptionsUDTTable: number[] = [10, 25, 50];
  itemsPeerPageUDTTable: number = this.pageSizeOptionsUDTTable[0];
  udtTableCurrentPage: number = 1;
  shouldPaginateRequestUDTTable: boolean = false;
  scrollHeightUDTTable: string = '380px';
  // #endregion

  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };

  buttonsUDOSTable: ICLTableButton[] = [
    {
      Title: "Eliminar",
      Action: Structures.Enums.CL_ACTIONS.DELETE,
      Icon: "delete",
      Color: "primary"
    },
    {
      Title: "Editar",
      Action: Structures.Enums.CL_ACTIONS.UPDATE,
      Icon: "edit",
      Color: "primary"
    }
  ];

  // #region Permissions
  permissionAddUDT: boolean = false;
  permissionAddUDF: boolean = false;
  // £endregion

  constructor(
    public dialog: MatDialog,
    public alertService: AlertsService,
    public modalService: ModalService,
    private connectionService: ConnectionService,
    private overlayService: OverlayService,
    private sapObjectService: SAPObjectService,
    private applicationService: ApplicationService,
    private sessionService: SessionService,
    @Inject('LinkerService') private linkerService: LinkerService
  ) {
    this.displayedColumnsUDF = MapDisplayColumns({
      dataSource: [],
      renameColumns: {Name: 'Nombre', Description: 'Descripción', Type: 'Tipo', TableName: 'Nombre de la Tabla'},
      ignoreColumns: ['DefaultValue', 'SubType', 'Size', 'EditSize', 'FieldID', 'Mandatory', 'ValidValuesMD']
    });
    this.displayedColumnsUDT = MapDisplayColumns({
      dataSource: [],
      renameColumns: {
        TableName: 'Nombre',
        TableDescription: 'Descripción',
        TableType: 'Tipo',
        Archivable: 'Archivable'
      },
      ignoreColumns: ['ArchiveDateField']
    });
  };

  ngOnInit(): void {
    Register<CL_CHANNEL>(this.userDefinedFieldsTableId, CL_CHANNEL.OUTPUT, this.OptionsUDFSTable, this.callbacks);
    Register<CL_CHANNEL>(this.userDefinedTablesTableId, CL_CHANNEL.OUTPUT, this.OptionsUDTSTable, this.callbacks);

    this.subscription.add(this.linkerService.Flow()
      ?.pipe(StepDown<CL_CHANNEL>(this.callbacks))
      .subscribe({
        next: (callback) =>
          Run(callback.Target, callback, this.callbacks.Callbacks),
        error: (error) => this.alertService.ShowAlert({HttpErrorResponse: error})
      })
    );
    this.connectionService.connection$.subscribe((connections: IUdoConnection[]) => this.listConnections = connections);
    this.permissionAddUDT = this.sessionService.GetPermissionCodeFromToken().includes(PermissionCode.AddUDT);
    this.permissionAddUDF = this.sessionService.GetPermissionCodeFromToken().includes(PermissionCode.AddUDF);
  };

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.connectionService.ClearConnections();
  };

  LoadTableDataUDF(): void {
    this.recordsCountUDFTable = this.UDFs.length > 0 ? this.UDFs.length : 0;
    const CURRENT_TABLE_STATE_UDF = {
      CurrentPage: this.udfTableCurrentPage,
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.UDFs,
      RecordsCount: this.recordsCountUDFTable,
    };
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.userDefinedFieldsTableId,
      Data: JSON.stringify(CURRENT_TABLE_STATE_UDF)
    } as ICLEvent);
  };

  LoadTableDataUDT(): void {
    this.recordsCountUDTTable = this.UDTs.length > 0 ? this.UDTs.length : 0;
    const CURRENT_TABLE_STATE_UDT = {
      CurrentPage: this.udtTableCurrentPage,
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.UDTs,
      RecordsCount: this.recordsCountUDTTable,
    };
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.userDefinedTablesTableId,
      Data: JSON.stringify(CURRENT_TABLE_STATE_UDT)
    } as ICLEvent);
  }


  OptionsUDFSTable = (_event: ICLEvent): void => {
    const event = JSON.parse(_event.Data);
    const userDefinedField: IUserDefinedField = JSON.parse(event.Data);

    switch (event.Action) {
      case Structures.Enums.CL_ACTIONS.DELETE:
        const updatedUDFS: IUserDefinedField[] = this.UDFs.filter((UDF: IUserDefinedField) =>
          !(UDF.Name === userDefinedField.Name && UDF.TableName === userDefinedField.TableName));
        this.UDFs = updatedUDFS;
        this.LoadTableDataUDF();
        this.alertService.Toast({
          type: CLToastType.SUCCESS,
          message: "UDF eliminado exitosamente"
        });
        break;
      case Structures.Enums.CL_ACTIONS.UPDATE:
        this.OpenModalAddUdf(userDefinedField);
        break;
    }
  };

  OptionsUDTSTable = (_event: ICLEvent): void => {
    const event = JSON.parse(_event.Data);
    const userDefinedTable: IUserDefinedTable = JSON.parse(event.Data);
    switch (event.Action) {
      case Structures.Enums.CL_ACTIONS.DELETE:
        let index = this.UDTs.findIndex(udt => udt.TableName === userDefinedTable.TableName);
        if (index === -1)
          return this.alertService.Toast({ type: CLToastType.ERROR, message: "No se encontró el UDT" });
        this.UDTs.splice(index, 1);
        this.alertService.Toast({ type: CLToastType.SUCCESS, message: "UDT eliminado exitosamente"
        });
        break;

      case Structures.Enums.CL_ACTIONS.UPDATE:
        this.OpenModalAddUdt(userDefinedTable);
        break;
    }

    this.LoadTableDataUDT();
  }

  OpenModalConnections = () => {
    this.dialog.open(UdoConnectionsModalComponent, {
      minWidth: '80%',
      height: `85vh`,
      autoFocus: 'first-header',
      data: {}
    });
  };

  OpenModalAddUdf(udfToEdit?: IUserDefinedField): void {
    let joinTablesCodes: string[] = [];
    if (this.UDTs)
      joinTablesCodes.push(...this.UDTs.map(u => `@${u.TableName}`));

    if (this.UDFs)
      joinTablesCodes.push(...this.UDFs.map(u => u.TableName));

    const dialogRef: MatDialogRef<ModalAddUdfComponent> = this.dialog.open(ModalAddUdfComponent, {
      minWidth: '80%',
      height: `85vh`,
      autoFocus: 'first-header',
      disableClose: true,
      data: { listUDFS: this.UDFs, dbCodes: new Set(joinTablesCodes), udfToEdit }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.length) {
        this.UDFs = result;
        this.LoadTableDataUDF();
      }
    });
  }

  OpenModalAddUdt(udtToEdit?: IUserDefinedTable): void {
    const dialogRef: MatDialogRef<ModalAddUdtComponent> = this.dialog.open(ModalAddUdtComponent, {
      minWidth: '60%',
      height: `70vh`,
      autoFocus: 'first-header',
      data: { listUDTS: this.UDTs, udtToEdit }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.length) {
        this.UDTs = result;
        this.LoadTableDataUDT();
      }
    });
  }

  CreateUdo(): void {
    if (!this.UDFs.length && !this.UDTs.length)
      return this.alertService.Toast({message: 'No se encontraron UDOs a crear', type: CLToastType.ERROR})

    if (!this.listConnections.length) {
      return this.alertService.Toast({ message: 'No existen conexiones configuradas para los UDFs', type: CLToastType.ERROR })
    }

    const objectsToCreate: ISapUdoHandler = {
      Connections: this.listConnections,
      UserDefinedFields: this.UDFs,
      UserDefinedTables: this.UDTs,
      ApplicationId: this.selectedApplication?.Id || null,
      Action: UdoHistoryAction.Create,
    };

    this.modalService.Question({
      title: 'Creación de UDs',
      subtitle: `Se crearán ${ this.UDFs.length } UDFs y ${ this.UDTs.length } UDTs para ${ this.listConnections.length } conexiones`,
      disableClose: false
    }).pipe(filter(result => result)).subscribe(res => {

      this.overlayService.OnPost();
      this.sapObjectService.Create(objectsToCreate).pipe(
        finalize(() => this.overlayService.Drop()),
        filter(result => result.Data !== null),
        map(result => result.Data)
      ).subscribe(apiResult => {

        const sapObjectRes: ISapObjectResStructure[] = apiResult;
        const dialogRef: MatDialogRef<ModalSummaryResponseComponent> = this.dialog.open(ModalSummaryResponseComponent, {
          minWidth: '60%',
          height: `75vh`,
          autoFocus: 'first-header',
          disableClose: true,
          data: sapObjectRes
        });

        dialogRef.afterClosed().subscribe((action: string): void => {
          switch (action){
            case 'save':
              this.alertService.Toast({ type: CLToastType.SUCCESS, message: 'La información se ha guardado correctamente' });
              break;
              // clear
            default:
              this.UDFs = [];
              this.UDTs = [];
              this.LoadTableDataUDF();
              this.LoadTableDataUDT();
              this.ClearApplicationSelection();
              this.connectionService.ClearConnections();
              this.alertService.Toast({
                type: CLToastType.SUCCESS,
                message: 'La información se ha limpiado correctamente'
              });
              break;
          }
        });
      });
    });
  }

  GetFilteredApplication() {
    this.overlayService.OnGet('Cargando Aplicaciones...')
    this.applications = [];
    this.applicationService.Get().pipe(
      finalize(() => {
        this.overlayService.Drop()
      }),
      map(apps => apps.Data)
    ).subscribe((data: IApplication[]) => {
      this.applications = data;
    });
  }

  HandleApplicationSelection(app: IApplication): void {
    if(app){
      this.selectedApplication = { ...app };
    }
  }

  ClearApplicationSelection(): void {
    this.controlSelectedApplication.reset();
    this.selectedApplication = undefined;
  }

}
