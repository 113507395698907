import {Component, OnInit, Inject, AfterViewInit, OnDestroy} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {catchError, finalize, map, Observable, startWith, Subscription, tap} from 'rxjs';
import { IServersRecollectionHistories } from 'src/app/models/interfaces/i-servers-recollection-histories';
import { IServer } from 'src/app/models/interfaces/i-server';
import { LogService } from 'src/app/services/log.service';
import { LogsDetailComponent } from 'src/app/shared/components/logs-detail/logs-detail.component';
import { ShepherdService } from 'angular-shepherd';
import { AlertsService, CLToastType } from '@clavisco/alerts';
import { MapDisplayColumns, MappedColumns } from '@clavisco/table';
import { CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown } from '@clavisco/linker';
import {OverlayService} from "@clavisco/overlay";
import {Structures} from "@clavisco/core";
import {ServersService} from "../../../services/servers.service";
import {HttpErrorResponse} from "@angular/common/http";
import ICLResponse = Structures.Interfaces.ICLResponse;

const today: Date = new Date();
const actuallymonth = today.getMonth();
const actuallyear: number = today.getFullYear();
const currentdate: any = new Date();
const firstDayMonth: any = new Date(currentdate.getFullYear(), actuallymonth, 1);
const numberOfDays: number = Math.floor((currentdate - firstDayMonth) / (24 * 60 * 60 * 1000));
const actuallyWeek: number = Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);
const firstDayWeek: Date = new Date(actuallyear, actuallymonth, (actuallyWeek - 1) * 7 - 2);
@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.sass']
})
export class LogsComponent implements OnInit, OnDestroy {
  allSuscription: Subscription= new Subscription()
  logs!: IServersRecollectionHistories[];
  servers: IServer[] = [];
  serverId: number;
  selectControl = new FormControl('');
  filteredServer!: Observable<IServer[]>;

  StartPos: number = 0;
  RowsReturned: number = 10;
  tableId = 'tableLogs'
  displayedColumns!: MappedColumns;
  scrollHeight: string = '425px';
  pageSizeOptions: number[] = [10, 20];
  itemsPeerPage: number = 10;
  recordsCount: number = 0;
  hasPaginator: boolean = true;
  loading:boolean = true;
  shouldPaginateRequest: boolean = false;
  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };
  Buttons: any = [
    {
      Title: ``,
      Action: Structures.Enums.CL_ACTIONS.UPDATE,
      Icon: `open_in_new`,
      Color: `primary`
    },
  ]

  datesControl = new FormGroup({
    initDate: new FormControl(firstDayWeek),
    endDate: new FormControl(new Date()),
  });

  constructor(
    private logService: LogService,
    private serversService: ServersService,
    private modalService: MatDialog,
    private alertService: AlertsService,
    private shepherdService: ShepherdService,
    private clBlockUI: OverlayService,
    @Inject('LinkerService') private linkerService: LinkerService
  ) {
    this.displayedColumns = MapDisplayColumns<IServersRecollectionHistories, null>({
      dataSource: [],
      renameColumns: {
        Server: 'Servidor',
        CreatedDate: 'Fecha de Registro'
      },
      ignoreColumns: ['MaxQtyRowsFetch', 'JsonObject', 'Id', 'PermissionType', 'CreatedBy', 'UpdateDate', 'UpdatedBy', 'IsActive'],
      tooltipsPosition: {rows: 'left', cells: 'left'}
    });
  }

  ngOnDestroy(): void{
    this.allSuscription.unsubscribe();
  }
  ngOnInit(): void {
    Register<CL_CHANNEL>(this.tableId, CL_CHANNEL.INPUT, this.GetElementsRecords, this.callbacks);
    Register<CL_CHANNEL>(this.tableId, CL_CHANNEL.OUTPUT, this.SelectedLog, this.callbacks);

    this.allSuscription.add(this.linkerService.Flow()
      ?.pipe(StepDown<CL_CHANNEL>(this.callbacks))
      .subscribe({
        next: (callback) =>
          Run(callback.Target, callback, this.callbacks.Callbacks),
        error: (error) => console.log(`mi error`, error),
      }));

    this.GetServers();
    this.GetServersRecHistories();
    this.getFilteredServers();
  }

  GetElementsRecords = (_event: ICLEvent): void => {
    if (_event) {
      const INCOMMING_TABLE_STATE = JSON.parse(_event.Data);
      this.RowsReturned = INCOMMING_TABLE_STATE.ItemsPeerPage;
      this.StartPos = INCOMMING_TABLE_STATE.CurrentPage;
      this.GetServersRecHistories();
    }
  };

  SelectedLog = (_event: ICLEvent) => {
    const event = JSON.parse(_event.Data)
    const log: IServersRecollectionHistories = JSON.parse(event.Data)
    this.ShowLogsDetail(log.JsonObject)
  };


  getFilteredServers() {
    this.filteredServer = this.selectControl.valueChanges.pipe(
      startWith(''),
      map((f: string | null) => (f ? this._filterServer(f) : this.servers.slice())),
    );
  }

  private _filterServer(value: string): IServer[] {
    const filterValue = value.toLowerCase();
    return this.servers.filter(state => state.Name.toLowerCase().includes(filterValue));
  }

  GetServersRecHistories(): void {
    if (!this.datesControl.value.initDate || !this.datesControl.value.endDate) return this.alertService.Toast({ message: 'La fecha de inicio y de fecha final son obligatorias', type: CLToastType.WARNING })

    this.clBlockUI.OnGet('Cargando logs...')
    this.logService.GetServersRecHistories(
      this.StartPos,
      this.RowsReturned,
      this.selectControl.value || '',
      this.datesControl.value.initDate!,
      this.datesControl.value.endDate!
    ).pipe(finalize(()=>{
      this.clBlockUI.Drop();
      this.loading = false;
    }),
      tap((response: ICLResponse<IServersRecollectionHistories[]>)=>{
        if (response.Data) {
          this.logs = response.Data
          this.recordsCount = response.Data.length > 0 ? response.Data[0].MaxQtyRowsFetch : 0;
          this.logs.map(x=>{
            x.CreatedDate = this.getFormatDate(new Date(x.CreatedDate))
          })
          this.loadTableData();
        }
      }),catchError((err) => {
        return [];
      })
    ).subscribe()

  }

  getFormatDate(date: Date):string{
    const time = ` - ${date.toTimeString().split(('GMT'))[0]}`;
    return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${time}`
  }


  loadTableData(): void {
    const CURRENT_TABLE_STATE = {
      CurrentPage: 1,
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.logs.map(x => ({...x, CellsMessages: {Options: "Ver Detalle"}})),
      RecordsCount: this.recordsCount
    };
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.tableId,
      Data: JSON.stringify(CURRENT_TABLE_STATE)
    } as ICLEvent);
  }

  GetServers(): void {
    this.serversService.GetServersByRecollectionHistories()
      .pipe(finalize(() => { this.clBlockUI.Drop(); }),
        tap((data: ICLResponse<IServer[]>)=>{
          if (data.Data) {
            this.servers = data.Data;
          }
        }),catchError((err) => {

            return [];
          }
        )).subscribe();
  }

  ShowLogsDetail(_jsonObject: string): void {
    this.modalService.open(LogsDetailComponent, {
      data: {
        jsonObject: _jsonObject,
      }
    });
  }

  selectedServer(server: IServer) {
    this.serverId = server.Id;
    this.GetServersRecHistories();
  }

  ngAfterViewInit() {
  }

}
