<div class="align-right"><mat-icon class="icon-close" (click)="CloseDialog(false)">close</mat-icon></div>
<div class="user-add-header">
    <h1>{{this.data.projectStatus.NameStatus ? "Editar Estado": "Agregar Estado"}}</h1>
</div>
<mat-divider></mat-divider>
<br>
<div class="user-add-content">
    <form [formGroup]="projectStatusForm">
        <mat-form-field class="mat-form-field" appearance="outline">
            <mat-label>Nombre</mat-label>
            <input matInput type="text" name="NameStatus" formControlName="NameStatus">
        </mat-form-field>
        <br>
        <mat-form-field class="mat-form-field" appearance="outline">
            <mat-label>Descripción</mat-label>
            <input matInput type="text" name="Description" formControlName="Description">
        </mat-form-field>
        <br>
        <mat-checkbox class="example-margin" formControlName="IsActive">Activo</mat-checkbox>
        <br>
    </form>
    <div class="user-add-footer">
        <button mat-stroked-button (click)="CloseDialog(false)"><mat-icon>close</mat-icon> Cerrar </button>
        <button mat-stroked-button *ngIf="!data.projectStatus.NameStatus"
            (click)="ActionProjectStatusSave()"><mat-icon>save</mat-icon>
            Guardar</button>
        <button mat-stroked-button color="primary" *ngIf="data.projectStatus.NameStatus"
            (click)="ActionProjectStatusEdit()"><mat-icon>edit</mat-icon> Editar</button>
    </div>
</div>
