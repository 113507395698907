import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import {IProject, IProjectComplete} from '../models/interfaces/i-proyects';
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {IAppLink} from "../models/interfaces/i-appLink";
import {IResponse} from "../models/i-response";

@Injectable({
  providedIn: 'root'
})
export class ProyectService {
  private readonly CONTROLLER = '/api/Project'


  @Output() EventChecked: EventEmitter<Boolean> = new EventEmitter();

  constructor(
    private http: HttpClient) { }

  Get(_name:string, _status:string, _startPos: number, _rowsReturned: number): Observable<ICLResponse<IProject[]>> {
    return this.http.get<ICLResponse<IProject[]>>(`${this.CONTROLLER}`, {
      params: new HttpParams()
        .set('name', _name)
        .set('startPos', _startPos.toString())
        .set('status', _status)
        .set('rowsReturned', _rowsReturned.toString())
    });
  }

  GetAllProjects(){
    return this.http.get<ICLResponse<IProject[]>>(`${this.CONTROLLER}/GetAllProjects`)
  }

  GetLinkByProject(id: number): Observable<ICLResponse<IAppLink[]>> {
    const param = new HttpParams().set('id', id);
    return this.http.get<ICLResponse<IAppLink[]>>(`${this.CONTROLLER}/GetLinkByProject`, {params: param})
  }

  GetProyectById(id: string): Observable<ICLResponse<IProjectComplete>> {
    return this.http.get<ICLResponse<IProjectComplete>>(`${this.CONTROLLER}/GetInfoProjectById`, {
      params: new HttpParams().set("id", id)
    });
  }

  PostProyect(data: IProjectComplete): Observable<ICLResponse<number>> {
    return this.http.post<ICLResponse<number>>(`${this.CONTROLLER}`, data)
  }

  PatchProyect(data: IProjectComplete): Observable<ICLResponse<number>> {
    return this.http.patch<ICLResponse<number>>(`${this.CONTROLLER}`, data)
  }


  // GetProjectsByNameOrCode(_startPos: number, _rowsReturned: number, _value: string): Observable<IResponse<IProject[]>> {
  //   const params = new HttpParams()
  //     .set("_startPos", _startPos.toString())
  //     .set("_rowsReturned", _rowsReturned.toString())
  //     .set("_value", _value)
  //   return this.http.get<IResponse<IProject[]>>(`${environment.ApiUrl}api/Project/GetProjectByNameOrCode`, { params })
  // }

}

