<!-- <div class="sidebar ">
    <div *ngFor="let menu of menuItems" class="menu-item">
        <a *ngIf="menu.Url && (menu.SubMenu.length === 0) && menu.ParentId === 0 && menu.HavePerm" mat-button
            fxLayout="row" fxLayoutAlign="start center" (click)="onClick(menu)" [id]="'menu-item-'+menu.Id"
            class="text-left">
            <mat-icon class="p-2 m-3">{{menu.IconClass}}</mat-icon>
            <span class="p-2 menu-title">{{menu.Name}}</span>
        </a>
        <a *ngIf="!menu.Url && (menu.SubMenu.length === 0) && menu.ParentId === 0 && menu.HavePerm" mat-button
            fxLayout="row" fxLayoutAlign="start center" (click)="onClick(menu)" [id]="'menu-item-'+menu.Id"
            class="text-left">
            <mat-icon class="m-3">{{menu.IconClass}}</mat-icon>
            <span class="p-2 menu-title">{{menu.Name}}</span>
        </a>
        <a *ngIf="(menu.SubMenu.length > 0) && menu.ParentId === 0 && menu.HavePerm" mat-button fxLayout="row"
            fxLayoutAlign="start center" (click)="onClick(menu)" [id]="'menu-item-'+menu.Id" class="text-left">
            <mat-icon class="m-1">{{menu.IconClass}}</mat-icon>
            <span class="menu-title">{{menu.Name}}</span>
            <mat-icon class="p-2 menu-expand-icon transition-2">expand_more</mat-icon>
        </a>
        <div *ngIf="(menu.SubMenu.length > 0)" class="sub-menu" [id]="'sub-menu-'+menu.Id">
            <app-sidenav-menu [menuItems]="menu.SubMenu" [menuParentId]="menu.Id"></app-sidenav-menu>
        </div>
        <a *ngIf="(menu.SubMenu.length === 0) && menu.ParentId > 0 && menu.HavePerm" mat-button fxLayout="row"
            fxLayoutAlign="start center" (click)="onClick(menu)" [id]="'menu-item-'+menu.Id" class="text-left">
            <mat-icon class="mr-1 size-icon">{{menu.IconClass}}</mat-icon>
            <span class="menu-title">{{menu.Name}}</span>
        </a>
    </div>
</div> -->

<cl-menu
  [Id]="MenuId"
  [User]="User"
  [Logo]="Logo"
  [Nodes]="MenuNodes">
</cl-menu>
