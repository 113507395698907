<section class="container-modal-add">
  <div class="header-container">
    <div class="header-container" mat-dialog-title>
      <h1>Crear Campo Definido por el Usuario</h1>
    </div>
  </div>
  <div class="body-container">
    <form id="form-add-udf" [formGroup]="formToAddUDF" (ngSubmit)="AddUDF()">
      <mat-form-field appearance="outline">
        <mat-label>Nombre del Campo</mat-label>
        <input matInput type="text" required [formControlName]="'Name'" placeholder="Nombre del campo..."/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Valor por Defecto</mat-label>
        <input matInput type="text" [formControlName]="'DefaultValue'" placeholder="Valor por defecto..."/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Tipo de Campo</mat-label>
        <mat-select formControlName="Type" (valueChange)="SelectedType($event)" required>
          <mat-option *ngFor="let fieldType of listFieldTypes | async" [value]="fieldType.Type">
            <span>{{ fieldType.Type }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>SubTipo de Campo</mat-label>
        <mat-select [formControlName]="'SubType'" [disabled]="!isSelectedType">
          <mat-option *ngFor="let fieldSubType of listFieldSubTypes | async" [value]='fieldSubType'>
            <span>{{ fieldSubType }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Tamaño del Campo</mat-label>
        <input matInput type="number" [formControlName]="'Size'" placeholder="Tamaño del campo..."/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Tamaño de Edicion</mat-label>
        <input matInput type="number" [formControlName]="'EditSize'" placeholder="Tamaño de edición..."/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Nombre de la Tabla</mat-label>
        <input required type="text" placeholder="Nombre de la tabla..." aria-label="Nombre de la tabla"
               matInput [matAutocomplete]="auto" [formControlName]="'TableName'"/>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let fieldTable of dbCodes" [value]="fieldTable">
            <span>{{ fieldTable }}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-checkbox [formControlName]="'Mandatory'">Obligatorio</mat-checkbox>
      <mat-form-field appearance="outline" class="full-space">
        <mat-label>Descripción del Campo</mat-label>
        <input matInput [formControlName]="'Description'" [maxLength]="80" placeholder="Descripción del Campo"/>
      </mat-form-field>
    </form>
    <mat-accordion>
      <mat-expansion-panel  class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>Valores validos del campo de usuario</mat-panel-title>
        </mat-expansion-panel-header>
        <form id="form-user-field-valid-value" [formGroup]="formUserFieldValidValue" (ngSubmit)="AddFieldValidValues()">
          <div class="fields-container">
            <mat-form-field appearance="outline">
              <mat-label>Valor</mat-label>
              <input matInput type="text" [formControlName]="'Value'" placeholder="Valor...">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Descripción</mat-label>
              <input matInput type="text" [formControlName]="'Description'" placeholder="Descripción...">
            </mat-form-field>
          </div>
          <div class="action-container">
            <button type="button" class="text-btn" (click)="ClearTableValidValues()" matTooltip="Limpiar tabla de valores validos" mat-stroked-button>
              <mat-icon>clear</mat-icon>
             Limpiar
            </button>
            <button class="text-btn" form="form-user-field-valid-value" type="submit" matTooltip="Agregar valor" mat-stroked-button>
              <mat-icon>add</mat-icon>Agregar
            </button>
          </div>
        </form>
        <mat-card class="mat-elevation-z0" s>
          <cl-table
            [Id]="validValueTableId"
            [MappedColumns]="displayedColumns"
            [Records]="userFieldValidValues"
            [PageSizeOptions]="pageSizeOptions"
            [ItemsPeerPage]="itemsPeerPage"
            [ShouldPaginateRequest]="shouldPaginateRequest"
            [Buttons]="validValuesTableButtons"
            [ScrollHeight]="scrollHeight"
            [PaginationType]="'dba'"
          ></cl-table>
        </mat-card>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="footer-container" mat-dialog-actions>
    <button class="close-btn" mat-stroked-button matTooltip="Configuración terminada" (click)="HandleModalClose()">
      <span class="text-btn"><mat-icon>close</mat-icon>Finalizar</span>
    </button>
    <button form="form-add-udf" type="submit" mat-stroked-button>
      <span class="text-btn" matBadge="{{listUDFs.length}}" matTooltip="UDFs agregados {{listUDFs.length}}">
        <mat-icon>add</mat-icon>{{ udfToEdit ? 'Guardar Cambios' : 'Agregar' }}
      </span>
    </button>
  </div>
</section>
