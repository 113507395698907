import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const exclude: string[] = ["Reports", "assets", "Parameters", "ChangePassword", "ChangeRecoveryPassword", "RecoveryPassword"]
    if(exclude.some(val => request.url.includes(val))){
      return next.handle(request);
    }
    if(request.url.includes(environment.ApiUrl))
    {
      return next.handle(request);
    }
    request = request.clone({url: `${environment.ApiUrl}${request.url}`});

    return next.handle(request);
  }
}
