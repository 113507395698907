<div class="container">

    <mat-tab-group (selectedTabChange)="tabActive($event)" [selectedIndex]="selectedIndex">
        <mat-tab label="Servidores Aplicación">
            <div class="filters-inputs w-100 mt-1 flex">
                <mat-form-field class="w-40 align-items" appearance="outline">
                    <mat-icon matPrefix>search</mat-icon>
                    <mat-label>Buscar Servidor</mat-label>
                    <input matInput [formControl]="filterApp" (keyup.enter)="GetServers(filterApp.value, 'APP')"
                        autocomplete="off">
                </mat-form-field>
              <button mat-stroked-button color="primary" (click)="GetServers(filterApp.value, 'APP')">
                <mat-icon>search</mat-icon>Buscar
              </button>
            </div>
            <mat-card class="mat-elevation-z0">
                <cl-table [Id]="tableId" [MappedColumns]="displayedColumns" [Records]="serversApp"
                    [PageSizeOptions]="pageSizeOptions" [ItemsPeerPage]="itemsPeerPage" [RecordsCount]="recordsCount"
                    [HasPaginator]="hasPaginator" [ScrollHeight]="scrollHeight" [ShouldPaginateRequest]="shouldPaginateRequest">
                </cl-table>
            </mat-card>
        </mat-tab>
        <mat-tab label="Servidores Base de datos">
            <div class="users-header">
                <div class="filters-inputs w-100 flex">
                    <mat-form-field class="w-40 align-items" appearance="outline">
                        <mat-icon matPrefix>search</mat-icon>
                        <mat-label>Buscar Servidor</mat-label>
                        <input matInput [formControl]="filterDB" (keyup.enter)="GetServers(filterDB.value, 'DB')"
                            autocomplete="off">
                    </mat-form-field>
                  <button mat-stroked-button color="primary" (click)="GetServers(filterDB.value, 'DB')">
                    <mat-icon>search</mat-icon>Buscar
                  </button>
                </div>
                <div *ngIf="CanEditServer()">
                    <button class="btn-user" mat-stroked-button color="primary"
                        (click)="openModal(newServerDB)"><mat-icon>add</mat-icon>
                        Agregar </button>
                </div>
            </div>

            <div>
                <mat-card class="mat-elevation-z0">
                    <cl-table [Id]="tableIdDB" [MappedColumns]="displayedColumnsDB" [Records]="serversDB"
                        [PageSizeOptions]="pageSizeOptions" [ItemsPeerPage]="itemsPeerPageDB"
                        [RecordsCount]="recordsCountDB" [HasPaginator]="hasPaginator" [ScrollHeight]="scrollHeight"
                        [Buttons]="Buttons" [ShouldPaginateRequest]="shouldPaginateRequest">
                    </cl-table>
                </mat-card>
            </div>

        </mat-tab>
    </mat-tab-group>



</div>
