import {Component, Input, OnInit, Inject, ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import { CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown } from '@clavisco/linker';
import { IMenuNode } from '@clavisco/menu';
import { PermissionCode } from 'src/app/common/constants';
import menu from 'src/app/common/menu';
import { IFiles } from 'src/app/models/interfaces/i-file';
import { IMenuPath } from 'src/app/models/interfaces/i-menu-paths';
import { FileService } from 'src/app/services/file.service';
import { MenuService } from 'src/app/services/menu.service';


@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss']
})
export class SidenavMenuComponent implements OnInit {

  Toggle: boolean = true;
  MenuNodes: IMenuNode[] = menu
  MenuId = "main-menu"
  Logo = "./../../assets/image/clavis-white.png"
  User = ""
  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: []
  }

  @Input('menuItems') menuItems!: IMenuPath[];
  @Input('menuParentId') menuParentId: any;
  filesBlob: IFiles[] = [];
  submenu!: IMenuPath;

  constructor(
    private router: Router,
    private fileService: FileService,
    @Inject('LinkerService') private linkerervice: LinkerService,
    private menuService: MenuService,
    private cd: ChangeDetectorRef,
  ) {

  }

  ngOnInit(): void {
    this.cd.detectChanges();
  }

  toggleMenuItem(menuId: number) {
    let menuItem = document.getElementById('menu-item-' + menuId);
    let subMenu = document.getElementById('sub-menu-' + menuId);
    if (subMenu) {
      if (subMenu.classList.contains('show')) {
        subMenu.classList.remove('show');
        menuItem?.classList.remove('expanded');
      }
      else {
        subMenu.classList.add('show');
        menuItem?.classList.add('expanded');
      }
    }
  }

}
