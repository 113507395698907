import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AlertsService, CLToastType } from '@clavisco/alerts';
import { IMenuNode } from '@clavisco/menu/lib/menu.space';
import { Observable } from 'rxjs';
import { PermissionCode } from '../common/constants';
import menu from '../common/menu';
import { IMenuPath } from '../models/interfaces/i-menu-paths';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard implements CanActivate {
  currentRoutePermission!: IMenuPath;

  constructor(
    private sessionService: SessionService,
    private alertService: AlertsService,
    private router: Router,
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let accessCondition: boolean = false;
    let perm = this.FindRoutePermission(menu, state.url.substring(1));
    accessCondition = this.checkPerm(perm)
    if (!accessCondition) {
      this.alertService.Toast({ message: 'Acceso denegado. No tienes el permiso para acceder. Si consideras que es un error comunicate con el administrador.', type: CLToastType.ERROR })
      this.router.navigate(['/index'])
      return accessCondition
    }
    return accessCondition;
  }

  AssignPermissions(key: string): string {
    let found: string = '';
    switch (key) {
      case 'index/proyects': return found = PermissionCode.Projects;
      case 'index/proyects/new': return found = PermissionCode.EditProjects
      case 'index/proyects/{id}/edit': return found = PermissionCode.EditProjects
      case 'index/files': return found = PermissionCode.Files;
      case 'index/projectDeliveries': return found = PermissionCode.DeliverableProject;
      case 'index': return found = PermissionCode.NoNecessary;
      case 'index/reports': return found = PermissionCode.Reports;
      case 'index/services': return found = PermissionCode.Services;
      case 'index/servers': return found = PermissionCode.Servers;
      case 'index/logs': return found = PermissionCode.Logs;
      case 'index/users': return found = PermissionCode.Users;
      case 'index/permissions': return found = PermissionCode.Permission;
      case 'index/checklist': return found = PermissionCode.CheckList;
      case 'index/projectStatus': return found = PermissionCode.StatusProject;
      case 'index/documents': return found = PermissionCode.CreateFile;
      case 'index/applications': return found = PermissionCode.Application;
      case 'index/setting': return found = PermissionCode.Configuration;
      case 'index/replicate': return found = PermissionCode.ReplicateSapObj;
      case 'index/userDefinedObjects': return found = PermissionCode.UserDefinedObjects;
      case 'index/historyUserDefinedObjects': return found = PermissionCode.HistorySapObj;
    }
    return found
  }


  public checkPerm(permToCheck: string): boolean {
    const perms = this.GetUserPermissons()
    if (permToCheck === '') return false;
    if (perms && perms.length) {
      return perms.some((perm: string) => perm === permToCheck);
    } else if (permToCheck.length == 0) {
      return true;
    }
    return false;
  }

  FindRoutePermission(menu: IMenuNode[], url: string): string {
    let found = '';
    const paths: string[] = ['index/proyects/new', 'index/proyects/{id}/edit']
    menu.forEach((menuElement) => {
      if (menuElement.Route === url) {
        found = this.AssignPermissions(menuElement.Route)//menuElement.Perm
      }
      if (menuElement.Nodes.length > 0) {
        menuElement.Nodes.forEach((submenuElement) => {
          if (submenuElement.Route === url) {
            found = this.AssignPermissions(submenuElement.Route)
          }
        });
      }
    });
    if(found==''){
      url.split('/').forEach(part => {
        if(parseInt(part)){
          url = url.replace(part,'{id}')
        }
      })
      if(paths.includes(url)) {
        found = this.AssignPermissions(url)
      }
    }
    return found;
  }




  GetUserPermissons(): string[] {
    const permissions: string[] = []
    this.sessionService.GetCompleteToken().Permissions?.forEach(element => {
      permissions.push(element.Name)
    });
    return permissions
  }
}
