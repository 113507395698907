import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OverlayService } from '@clavisco/overlay';
import {catchError, finalize, tap} from 'rxjs';
import {IFiles, IFilesType} from 'src/app/models/interfaces/i-file';
import { FileService } from 'src/app/services/file.service';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import {PermissionCode} from "../../../common/constants";
import {SessionService} from "../../../services/session.service";
import {IServersRecollectionHistories} from "../../../models/interfaces/i-servers-recollection-histories";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.sass']
})
export class FilesComponent implements OnInit {
  listFiles: IFiles[] = []


  constructor(private fileService: FileService,
    public dialog: MatDialog,
    private clBlockUI: OverlayService,
    private sessionService: SessionService,
  ) { }

  ngOnInit(): void {
    this.getFilesBlob();
  }

  getFilesBlob(): void {
    this.clBlockUI.OnGet('Cargando archivos...')
    this.fileService.Get().pipe(finalize(() => { this.clBlockUI.Drop(); }),
      tap((response: ICLResponse<IFiles[]>)=>{
        if (response.Data) {
          if (response.Data.length > 0) {
            this.listFiles = response.Data
            this.listFiles = this.listFiles.sort(function (x, y) {
              if (x.CreatedDate < y.CreatedDate) return -1
              if (x.CreatedDate < y.CreatedDate) return 1
              return 0
            }).reverse();
          }
        }
      }),catchError((err) => {
        console.log(err)
        return [];
      })
    ).subscribe();
  }

  getNameFile(fileName: string): string {
    const urlFile = fileName.split('/');
    return urlFile[urlFile.length - 1];
  }

  OpenDialog() {
    const data: IFilesType = {Files:this.listFiles,Type:1}
    const dialogRef = this.dialog.open(UploadFilesComponent, {
      minWidth: '750px',
      maxHeight: `85vh`,
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getFilesBlob();
    })
  }

  DownloadFileBlob(file: IFiles) {
    this.clBlockUI.OnPost('Descargando Archivo...')
    const urlF = file.Name.split('/');
    const fileUrl = `${file.Url}/${file.Name}`;
    this.fileService.DownloadFileBlob(fileUrl).pipe(finalize(() => { this.clBlockUI.Drop(); }),
      tap((response: ICLResponse<string>)=> {
        let report = new Blob([this.StringToArrayBuffer(atob(response.Data))], {
          type: 'application/octet-stream',
        });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(report);
        link.download = urlF[urlF.length - 1];
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }),catchError((err) => {
        console.log(err)
        return [];
      })
    ).subscribe();
  }

  private StringToArrayBuffer(toConvert: string) {
    let buf = new ArrayBuffer(toConvert.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i != toConvert.length; ++i) {
      view[i] = toConvert.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  getConvertBytes(byte: number): string {
    if (byte < 1048576) {
      return `${(byte / 1024).toFixed(3)} KB`;
    }
    else if (byte >= 1048576 && byte < 1073741824) {
      return `${(byte / 1048576).toFixed(3)} MB`;
    }
    else {
      return `${(byte / 1073741824).toFixed(3)} GB`;
    }
  }

  CanCreateFile():boolean{
    return this.sessionService.GetPermissionCodeFromToken().includes(PermissionCode.CreateFile)
  }

  CanDownloadFile(): boolean {
    return this.sessionService.GetPermissionCodeFromToken().includes(PermissionCode.DownloadFile)
  }
}
