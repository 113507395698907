import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PermissionCode } from 'src/app/common/constants';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.sass']
})
export class PermissionsComponent implements OnInit {

  showAddButton: boolean = false;
  tabIndex!: number
  openModalFunction!: Function

  constructor(
  ) { }

  ngOnInit(): void {
    
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    if (tabChangeEvent.index === 0) {
      this.showAddButton = false;
    } else {
      this.showAddButton = true;
    }
    this.tabIndex = tabChangeEvent.index
  }

  SetFuntionOpenModal(openModalFunction: Function): void {
    this.openModalFunction = openModalFunction
  }

  OpenModal(): void{
    this.openModalFunction()
  }


}
