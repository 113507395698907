import { Component, OnInit, Inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService, CLToastType } from '@clavisco/alerts';
import { OverlayService } from '@clavisco/overlay';
import {catchError, finalize, tap} from 'rxjs';
import {ICheckListRow, ICheckList, ICheckListProject} from 'src/app/models/interfaces/i-checklist';
import { IProjectDeliverables } from 'src/app/models/interfaces/i-proyects';
import { CheckListService } from 'src/app/services/checklist-details.service';
import { ProyectDeliverableService } from 'src/app/services/project-deliverables.service';
import { ProyectService } from 'src/app/services/proyect.service';
import { SessionService } from 'src/app/services/session.service';
import { UserService } from 'src/app/services/user.service';
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {IUsers} from "../../../../models/interfaces/i-users";

export interface formGDynamic {
  Id: number,
  FormGr: FormGroup,
  checklistD: ICheckListRow[],
  Header: ICheckList
}

@Component({
  selector: 'app-modal-project-deliverables',
  templateUrl: './modal-project-deliverables.component.html',
  styleUrls: ['./modal-project-deliverables.component.sass'],

})

export class ModalProjectDeliverablesComponent implements OnInit {
  headers: ICheckList[] = [];

  checklistDetails: ICheckListRow[] = [];
  formDynamic: formGDynamic[] = []

  isloading = true;


  constructor(private deliverablesService: ProyectDeliverableService,
    private checkListService: CheckListService,
    private projectService: ProyectService,
    private sessionService: SessionService,
    @Inject(MAT_DIALOG_DATA) public idProject: number,
    private userService: UserService,
    private alertService: AlertsService,
    private dialogRef: MatDialogRef<ModalProjectDeliverablesComponent>,
    private clBlockUI: OverlayService) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getCheckList();
    }, 0);
  }

  CloseDialog(): void {
    this.dialogRef.close();
  }

  getCheckList() {
    this.clBlockUI.OnGet('Cargando...')
    this.checkListService.GetCheckList().pipe(finalize(() => {
      this.isloading = true;
      this.getProjectDetails();
    }),
      tap((response:ICLResponse<ICheckList[]>)=>{
        this.headers = response.Data
        response.Data.forEach((element, index) => {
          this.formDynamic = [...this.formDynamic, {
            checklistD: [], Header: element,
            Id: element.Id, FormGr: new FormGroup({
            })
          }]
          this.checkListService.GetCheckListRowsByHeader(element.Id).pipe(finalize(() => {}),
          tap((response:ICLResponse<ICheckListRow[]>)=>{
            this.checklistDetails = response.Data;
            response.Data.forEach(m => {
              this.formDynamic[index].checklistD = response.Data;
              this.formDynamic[index].FormGr.addControl(m.CrystalParamName, new FormControl('', m.IsRequired ? Validators.required : null))
            })
          }),catchError((err) => {
            console.log(err)
            return [];
          })).subscribe()
        });
      }),catchError((err) => {
        console.log(err)
        return [];
      })).subscribe()
  }

  getUserManagerById(id: number, form: FormGroup) {
    this.userService.GetUserById(id).pipe(
      finalize(() => { }),
      tap((response:ICLResponse<IUsers>)=> {
        form.get('PManag')!.setValue(response.Data.Name)
      }),
      catchError((err) => {
        console.log(err)
        return [];
      })
    ).subscribe();
  }

  getProjectDetails() {
    const user = this.sessionService.GetUserName();

    this.checkListService.getCheckListByProject(this.idProject).pipe(finalize(() => { this.clBlockUI.Drop(); }),
      tap((response:ICLResponse<ICheckListProject>)=> {
        this.formDynamic.forEach(x => {
          if (x.Header.Name === 'DetallesGenerales') {
            this.getUserManagerById(response.Data.Project.UserManagerId, x.FormGr);
            x.FormGr.patchValue({
              'PProject': response.Data.Project.Name,
              'PClient': response.Data.Project.Client,
              'PDate': new Date().toLocaleDateString(),
              'PUsersInv': response.Data.ListDevelopers?.map(x => x.Name).toString(),
              'PDB': response.Data.ListDataBase?.map(x => x.Name).toString(),
              'PServ': response.Data.ListServers?.map(x => x.Name).toString(),
              'PUserC': user.toString()
            })
          } else if (x.Header.Name === 'Aplicacion') {
            x.FormGr.patchValue({
              'PTS': response.Data.ListTasks?.map(x => x.Name).toString(),
              'PSW': response.Data.ListServices?.map(x => x.ServiceName).toString()
            })
          }
        })
      }),
      catchError((err) => {
        console.log(err)
        return [];
      })
      ).subscribe();
  }

  SaveCheckListDetails() {
    const userId = this.sessionService.getUserId();
    let obj: any[] = []
    let isvalid = true;
    this.formDynamic.map(x => {
      //if (x.FormGr.get('PPPLiber')?.value)
      if (!x.FormGr.valid) {
        isvalid = false;
        x.FormGr.markAllAsTouched();
        return;
      }
      let listDet: any = [];
      x.checklistD.map(m => {
        let value = x.FormGr.get(m.CrystalParamName.toString())?.value
        listDet = [...listDet, { Name: m.CrystalParamName.toString(), Value: value ? value : 'N/A' }];

      })

      obj = [...obj,
      {
        'Header': { Name: x.Header.CrystalParamName, Value: x.Header.DisplayName },
        'Details': listDet
      }
      ]
    })
    if (!isvalid) {
      return this.alertService.Toast({ message: `El formulario es inválido, los campos con (*) son requeridos`, type: CLToastType.ERROR })
    } else {
      var jsonString = JSON.stringify(obj);
      let type
      this.formDynamic.filter(x => x.Header.Name === 'DetallesGenerales').map(x => {
        type = x.FormGr.get('PType')?.value
      })
      const link: string = this.formDynamic.filter(x => x.FormGr.get('PPPLiber')).map(x => { return x.FormGr.get('PPPLiber')?.value }).toString();
      const deliv: IProjectDeliverables = {
        LinkVideo: link ? link : '', ProjectId: this.idProject, JsonObject: jsonString,
        Type: type ? type : '', CreatedBy:'', UpdatedBy:'', IsActive:true, CreatedDate:new Date, UpdateDate:new Date(), User: userId, Id: 0
      }
      this.clBlockUI.OnPost("Guardando Entrega...");
      this.deliverablesService.SaveProyectDeliverable(deliv).pipe(finalize(() => { this.clBlockUI.Drop() }),
        tap((response)=>{
          this.alertService.Toast({ message: `Entrega de proyecto guardada correctamente`, type: CLToastType.SUCCESS });
          this.dialogRef.close();
        }),
        catchError((err) => {
          this.alertService.Toast({ message: 'Error al guardar la entrega', type: CLToastType.ERROR });
          return [];
        }),
        ).subscribe();
    }
  }

}
