import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService, CLToastType } from '@clavisco/alerts';
import { OverlayService } from '@clavisco/overlay';
import {catchError, finalize, tap} from 'rxjs';
import { IPermission } from 'src/app/models/interfaces/i-permissions';
import { PermissionsService } from 'src/app/services/permissions.service';
import {PermissionType} from "../../../../../common/constants";

@Component({
  selector: 'app-modal-add-permissions',
  templateUrl: './modal-add-permissions.component.html',
  styleUrls: ['./modal-add-permissions.component.sass']
})
export class ModalAddPermissionsComponent implements OnInit {

  //permissions: PermissionType[] = Object.values(PermissionType)
  permissionForm!: FormGroup
  getPermissionsType() {
    return Object.values(PermissionType);
  }
  constructor(
    private dialogRef: MatDialogRef<ModalAddPermissionsComponent>,
    private permissionService: PermissionsService,
    private alertService: AlertsService,
    @Inject(MAT_DIALOG_DATA) public permissionData: IPermission,
    private clBlockUI: OverlayService
  ) { }

  ngOnInit(): void {
    this.onLoad();
  }
  onLoad() {
    this.permissionForm = this.createPermissionForm();
    if (this.permissionData) {
      this.permissionForm = new FormGroup({
        Description: new FormControl(this.permissionData.Description, Validators.required),
        Name: new FormControl({ value: this.permissionData.Name, disabled: true }, Validators.required),
        IsActive: new FormControl(this.permissionData.IsActive, Validators.required),
        Id: new FormControl(this.permissionData.Id),
        PermissionType: new FormControl(this.permissionData.PermissionType, Validators.required),
        CreatedBy: new FormControl(""),
        UpdatedBy: new FormControl(""),
      });
    }
  }

  SavePermission(): void {
    if (this.permissionForm.invalid) {
      this.permissionForm.markAllAsTouched()
      this.permissionForm.updateValueAndValidity()
      this.alertService.Toast({ message: `Campos Requeridos`, type: CLToastType.ERROR })
      return
    }
    this.clBlockUI.OnPost('Guardando permiso...')
    if (this.permissionData) {
      this.permissionService.PatchPermission((this.permissionForm.getRawValue()) as IPermission)
        .pipe(finalize ( () => { this.clBlockUI.Drop() }),
          tap((data)=>{
            this.alertService.Toast({ message:`Permiso actualizado` , type: CLToastType.SUCCESS })
            this.CloseDialog(true)
          }),catchError((err) => {
            console.log(err)
            return [];
          })).subscribe();
    } else {
      this.permissionService.PostPermission((this.permissionForm.value) as IPermission)
        .pipe(finalize ( () => { this.clBlockUI.Drop() }),
          tap((data)=>{
            this.alertService.Toast({ message:`Permiso Guardado` , type: CLToastType.SUCCESS })
            this.CloseDialog(true)
          }),catchError((err) => {
            console.log(err)
            return [];
          })).subscribe();
    }

  }

  private createPermissionForm(): FormGroup {
    return new FormGroup({
      Description: new FormControl('', Validators.required),
      Name: new FormControl('', Validators.required),
      IsActive: new FormControl(false, Validators.required),
      Id: new FormControl(0),
      PermissionType: new FormControl(PermissionType.Update, Validators.required),
      CreatedBy: new FormControl(""),
      UpdatedBy: new FormControl(""),
    }, Validators.required);
  }

  CloseDialog(result: boolean): void {
    this.dialogRef.close(result)
  }

}
