
import { IAppSetting } from 'src/app/models/i-appsetting';

export class AppSettingModel<T> implements IAppSetting<T>{
  Key!: string;
  JsonObject!: string;
  Json!: T

  constructor(response: IAppSetting<T>){
    this.Key = response.Key
    this.Json = JSON.parse(response.JsonObject)
  }

  ToExport(): IAppSetting<T>{
    return {
      Key: this.Key,
      JsonObject: JSON.stringify(this.Json)
    }
  }
}
