<div class="align-right"><mat-icon class="icon-close" (click)="CloseDialog()">close</mat-icon></div>
<div class="add-role-header">
  <h1>Agregar Rol</h1>
</div>
<mat-divider></mat-divider>
<div class="add-role-content" [formGroup]="roleForm">
  <div class="imput-field">
    <mat-form-field appearance="outline" class="input-credential">
      <mat-label>Codigo</mat-label>
      <input type="text" matInput placeholder="Codigo" formControlName="Name" />
    </mat-form-field>
  </div>
  <div class="imput-field">
    <mat-form-field appearance="outline" class="input-credential">
      <mat-label>Nombre</mat-label>
      <input type="text" matInput placeholder="Nombre" formControlName="Description" />
    </mat-form-field>
  </div>
  <mat-checkbox class="ckActive" formControlName="IsActive">Activo</mat-checkbox>
</div>
<mat-divider></mat-divider>
<div class="add-role-footer">
  <button mat-stroked-button color="warn" (click)="CloseDialog()">
    <mat-icon>close</mat-icon> Cerrar
  </button>
  <button mat-stroked-button color="primary" (click)="SaveRole()">
    <mat-icon>save</mat-icon> Guardar</button>
</div>
