import {Injectable} from "@angular/core";
import {IResponse} from "../models/i-response";
import {Observable} from "rxjs";
import {
  IFileTemplateHeader,
  IProjectTemplate,
  IProjectTemplateHeader
} from "../models/interfaces/i-project-template";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {IFiles, IFileTemplate} from "../models/interfaces/i-file";
import {IServersRecollectionHistories} from "../models/interfaces/i-servers-recollection-histories";
import {SessionService} from "./session.service";

@Injectable({
  providedIn: 'root'
})
export class ProjectTemplateService {

  currentTime: Date = new Date();
  constructor(private http: HttpClient, private sessionService: SessionService) {}

  GetProjectTemplateHeader(_startPos: number,_rowsReturned: number, _initDate: Date | null, _endDate: Date | null, status: boolean):Observable<IResponse<IProjectTemplateHeader[]>>{
    let initDate = '';
    let endDate = '';
    if( _initDate && _endDate){
      initDate = `${_initDate.getFullYear()}-${_initDate.getMonth() + 1}-${_initDate.getDate()} ${_initDate.getHours()}:${_initDate.getMinutes()}:${_initDate.getSeconds()}`;
      endDate = `${_endDate.getFullYear()}-${_endDate.getMonth() + 1}-${_endDate.getDate()} ${this.currentTime.getHours()}:${this.currentTime.getMinutes()}:${this.currentTime.getSeconds()}`;
    }

    return this.http.get<IResponse<IProjectTemplateHeader[]>>(`${environment.ApiUrl}/api/ProjectTemplate/GetProjectTemplate`, {
      params: new HttpParams()
        .set("startPos", _startPos.toString())
        .set("rowsReturned", _rowsReturned.toString())
        .set("initDate", initDate)
        .set("endDate", endDate)
        .set("status", status ? '1' : '0')
    });
  }

  GetTemplatesByHeader(header:number):Observable<IResponse<IProjectTemplate[]>>{
    return this.http.get<IResponse<IProjectTemplate[]>>(`${environment.ApiUrl}/api/ProjectTemplate/GetTemplatesByHeader`, {
      params: new HttpParams()
        .set("header", header)
    });
  }

  ReviewTemplate(template: IFiles):Observable<IResponse<IFileTemplateHeader>>{
    return this.http.post<IResponse<IFileTemplateHeader>>(`${environment.ApiUrl}/api/ProjectTemplate/ReviewTemplate`, template)
  }

  SaveProjectTemplate(template: IFileTemplate): Observable<IResponse<string>> {
    template.ProjectTemplatesList.map(template => template.CreatedBy = template.UpdatedBy = this.sessionService.GetUserName())
    return this.http.post<IResponse<string>>(`${environment.ApiUrl}/api/ProjectTemplate/SaveProjectTemplate`, template)
  }

}
