<div>
  <h1 mat-dialog-title>{{this.isUpdated ? "Actualizar Configuracion" : "Crear Configuracion"}}</h1>
</div>
<mat-dialog-content>
  <div class="content">
  <form [formGroup]="settingForm">
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Codigo</mat-label>
        <input matInput placeholder="Codigo" formControlName="Code">
        <mat-error *ngIf="settingForm.controls['Code'].invalid">{{GetErrorMessageRequired("Code", "El codigo es requerido")}}</mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" style="display: flex;">
        <mat-label>Descripcion</mat-label>
        <input matInput placeholder="Descripcion" formControlName="Description">
        <mat-error *ngIf="settingForm.controls['Description'].invalid">{{GetErrorMessageRequired("Description", "La descripcion es requerida")}}</mat-error>
      </mat-form-field>
    </div>
    <div>
      <json-viewer (JsonStringModel)="RefreshJson($event)" [jsonString]="settingData?.Json??'{}'"></json-viewer>
    </div>
    <mat-checkbox class="example-margin" formControlName="IsActive">Activo</mat-checkbox>
  </form>
</div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="setting-buttons-footer">
  <button mat-stroked-button  (click)="CloseDialog()"><mat-icon>close</mat-icon> Cerrar </button>
  <button mat-flat-button  (click)="OnSubmit()"><mat-icon>save</mat-icon>
    {{this.isUpdated ? "Actualizar" : "Guardar"}} </button>
</div>

</mat-dialog-actions>
