import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncrDecrService {

  // Declare this key and iv values in declaration
  private key = CryptoJS.enc.Utf8.parse('C1Av!sC0)!$02021');
  private iv = CryptoJS.enc.Utf8.parse('$$C1Av!sC0)!$100');

  constructor() { }

  // Methods for the encrypt and decrypt Using AES
  encryptUsingAES256(_value: string): string {
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(JSON.stringify(_value)), this.key, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }

  decryptUsingAES256(decString: any): string {
    var decrypted = CryptoJS.AES.decrypt(decString, this.key, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8)
    return decrypted;
  }

}
