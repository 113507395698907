import {Component, Inject, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {columnsToIgnore, IProject} from 'src/app/models/interfaces/i-proyects';
import { ProyectService } from 'src/app/services/proyect.service';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import {FormControl, FormGroup} from '@angular/forms';
import { IFiles } from 'src/app/models/interfaces/i-file';
import { BdcWalkService } from 'bdc-walkthrough';
import { ShepherdService } from 'angular-shepherd';
import { AlertsService, CLToastType } from '@clavisco/alerts';
import { OverlayService } from '@clavisco/overlay';
import {catchError, finalize, Subscription, tap} from 'rxjs';
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {AppLinksModalComponent} from "./app-links-modal/app-links-modal.component";
import {IAppLink} from "../../../models/interfaces/i-appLink";
import {IProjectStatus} from "../../../../core/interfaces/i-project-status";
import {ProjectStatusService} from "../../../services/projectStatus.service";
import {MapDisplayColumns, MappedColumns} from "@clavisco/table";
import {CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown} from "@clavisco/linker";
import {ICLTableButton} from "@clavisco/table/lib/table.space";
import {ISetting} from "../../../models/i-appsetting";

@Component({
  selector: 'app-proyects',
  templateUrl: './proyects.component.html',
  styleUrls: ['./proyects.component.sass']
})
export class ProyectsComponent implements OnInit {

  //Configuracion de tabla
  tableId: string = 'ProjectTableId'
  allSuscription: Subscription= new Subscription()
  displayedColumn!: MappedColumns
  projectList: IProject[] = []
  readonly pageSizeOptions: number[] = [5, 15, 30]
  itemsPeerPage: number = this.pageSizeOptions[0]
  recordsCount: number = 0
  hasPaginator: boolean = true
  scrollHeight: string = '400px'
  shouldPaginateRequest: boolean = false;
  readonly columnsToIgnore: string[] = [...columnsToIgnore]
  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };
  Buttons: ICLTableButton[] = [{
    Title: 'Ver links de interes',
    Action: Structures.Enums.CL_ACTIONS.UPDATE,
    Icon: `link`,
    Color: `primary`
  },{
    Title: 'Previsualizar proyecto',
    Action: Structures.Enums.CL_ACTIONS.READ,
    Icon: `ballot`,
    Color: `primary`
  }]

  //Fin de Configuracion


  displayedColumns: string[] = ['Id', 'Name', 'Code', 'Status', 'Options'];
  dataSource!: MatTableDataSource<IProject>;
  @ViewChild('paginatorProyects') paginatorProyects!: MatPaginator;
  files: IFiles[] = [];
  projectStatus: IProjectStatus[] = [];
  filterForm: FormGroup;



  //pagination
  pageEvent!: PageEvent;
  pageSize: number = 10;
  pageIndex: number = 0;
  lengthPaginator: number = 0;

  //Datos para consumir base de datos
  projects!: IProject[]

  isFilter!: Boolean;

  constructor(
    private proyectService: ProyectService,
    private projectStatusService: ProjectStatusService,
    private router: Router,
    private alertService: AlertsService,
    public matPaginatorIntl: MatPaginatorIntl,
    private bdcWalkService: BdcWalkService,
    private shepherdService: ShepherdService,
    private dialog: MatDialog,
    private clBlockUI: OverlayService,
    @Inject('LinkerService') private linkerService: LinkerService
  ) {
    this.OnLoadTable()
    this.filterForm = new FormGroup({
      ProjectName: new FormControl(''),
      ProjectStatus: new FormControl('')
    })
  }

  ngOnDestroy() {
    this.allSuscription.unsubscribe();
  }
  ngOnInit(): void {
    try{
      Register<CL_CHANNEL>(this.tableId, CL_CHANNEL.INPUT, this.GetElementsRecords, this.callbacks);
      Register<CL_CHANNEL>(this.tableId, CL_CHANNEL.OUTPUT, this.SelectedProjectOptions, this.callbacks);
      this.allSuscription.add(this.linkerService.Flow()
        ?.pipe(StepDown<CL_CHANNEL>(this.callbacks))
        .subscribe({
          next: (callback) =>
            Run(callback.Target, callback, this.callbacks.Callbacks),
          error: (error) => console.log(`mi error`, error),
        }));
    }catch(e){
      console.warn(e)
    }
    this.GetProyect();
    this.GetProjectStatus();
    this.matPaginatorIntl.itemsPerPageLabel = 'Items por página';
  }

  OnLoadTable(): void {
    this.displayedColumn = MapDisplayColumns<IProject, null>({
      dataSource: this.projectList,
      renameColumns:{
        "Id": "N°",
        "Name": "Nombre del Proyecto",
        "Code": "Codigo de Proyecto",
        "Status": "Estado",
      },
      ignoreColumns: [...this.columnsToIgnore]
    })
  }

  GetElementsRecords = (_event: ICLEvent): void => {
    if (_event) {
      const INCOMMING_TABLE_STATE = JSON.parse(_event.Data);
      this.GetProyect();
      this.GetProjectStatus();
    }
  };

  private loadTableData(): void{
    const CURRENT_TABLE_STATE = {
      CurrentPage: 1,
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.projectList,
      RecordsCount: this.recordsCount
    };
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.tableId,
      Data: JSON.stringify(CURRENT_TABLE_STATE)
    } as ICLEvent);
  }

  GetProjectStatus(): void {
    this.projectStatusService.GetProyectStatus().pipe(
      finalize(() => { }),
      tap((callback: ICLResponse<IProjectStatus[]>) => {
        if (callback) {
          this.projectStatus = [...callback.Data]
        }
      }), catchError((err) => {
        console.log(err);
        return [];
      })
    ).subscribe()
  }
  GetProyect(): void {
    this.isFilter = false;
    this.clBlockUI.OnGet('Cargando Proyectos...')
    this.proyectService.Get(this.filterForm.get("ProjectName")?.value, this.filterForm.get('ProjectStatus')?.value?? '',this.pageIndex, this.pageSize).pipe(finalize(() => { this.clBlockUI.Drop(); }),
      tap((data: ICLResponse<IProject[]>)=>{
        if (data?.Data) {
          this.projectList = data.Data
          this.dataSource = new MatTableDataSource(data.Data);
          this.recordsCount = data.Data.length > 0 ? data.Data[0].MaxQtyRowsFetch : 0;
          this.loadTableData()
        }
      }),catchError((err) => {
          this.alertService.Toast({ message: err, type: CLToastType.ERROR })
          return [];
        }
      )).subscribe();
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.lengthPaginator = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.GetProyect();
  }

  OpenModal(project: IProject): void {
    this.clBlockUI.OnGet('Cargando links...')
    this.proyectService.GetLinkByProject(project.Id).pipe(finalize(() => { this.clBlockUI.Drop(); }),
      tap((data: ICLResponse<IAppLink[]>)=>{
        if (data.Data.length === 0) {
          return this.alertService.Toast({message: 'El proyecto no cuenta con links', type: CLToastType.INFO})
        }else {
          this.dialog.open(AppLinksModalComponent, {
            data: data.Data,
            width: '700px',
            maxHeight: '440px'
          })
        }
      }),catchError((err) => {
          return [];
        }
      )).subscribe();
  }

  ViewProject(id: number): void {
    this.router.navigateByUrl(`index/proyects/${id}/edit`)
  }

  CreateProject(): void {
    this.router.navigateByUrl('index/proyects/new')
  }

  reset() {
    this.bdcWalkService.reset('step1');
  }

  SelectedProjectOptions = (_event:ICLEvent) : void => {

    if (_event.Data) {
      const BUTTON_EVENT = JSON.parse(_event.Data);
      const ELEMENT = JSON.parse(BUTTON_EVENT.Data);
      switch (BUTTON_EVENT.Action) {
        case Structures.Enums.CL_ACTIONS.READ:
          this.ViewProject(ELEMENT.Id)
          break;
        case Structures.Enums.CL_ACTIONS.UPDATE:
          this.OpenModal(ELEMENT);
          break;
      }
    }

  }

}
