import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AlertsService, CLToastType} from "@clavisco/alerts";
import {OverlayService} from "@clavisco/overlay";
import {ApplicationService} from "../../../../services/application.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {catchError, finalize, tap} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-modal-application',
  templateUrl: './modal-application.component.html',
  styleUrls: ['./modal-application.component.sass']
})
export class ModalApplicationComponent {

  constructor(
    private dialogRef: MatDialogRef<ModalApplicationComponent>,
    private alertService: AlertsService,
    private applicationService: ApplicationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private clBlockUI: OverlayService
  ) { }
  applicationForm: FormGroup = new FormGroup({
    Id: new FormControl(this.data.Application?.Id??0, Validators.required),
    Code: new FormControl(this.data.Application?.Code??"", Validators.required),
    Name: new FormControl(this.data.Application?.Name??"", Validators.required),
    Description: new FormControl(this.data.Application?.Description??"", Validators.required),
    IsActive: new FormControl(this.data.Application?.IsActive??true, Validators.required),
    CreatedBy: new FormControl(""),
    UpdatedBy: new FormControl(""),
  })
  CloseDialog(): void {
    this.dialogRef.close();
  }

  SaveApplication(): void {
    if (this.applicationForm.invalid) {
      return this.alertService.Toast({ message: 'El formulario es inválido, los campos son requeridos', type: CLToastType.ERROR });
    }
    if(this.data.Edit){
      this.clBlockUI.OnPost('Actualizando Aplicación...')
      this.applicationService.Patch(this.applicationForm.value)
        .pipe(finalize ( () => { this.clBlockUI.Drop() }),
          tap((data)=>{
            this.alertService.Toast({ message: 'Aplicación actualizada correctamente', type: CLToastType.SUCCESS })
            this.dialogRef.close();
          }),catchError(async (error) => this.alertService.ShowAlert({ HttpErrorResponse: error })),
        ).subscribe();
    }else{
      this.clBlockUI.OnPost('Guardando Aplicacion...')
      this.applicationService.Post(this.applicationForm.value)
        .pipe(finalize ( () => { this.clBlockUI.Drop() }),
          tap((data)=>{
            this.dialogRef.close();
            this.alertService.Toast({ message: 'Aplicación guardada correctamente', type: CLToastType.SUCCESS })
          }),catchError(async (error) => this.alertService.ShowAlert({ HttpErrorResponse: error })),
        ).subscribe();
    }

  }
}
