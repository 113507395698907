import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppMaterialModule } from 'src/app/app-material.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { DocumentsComponent } from './documents.component';
import {ProjectTemplateModalComponent} from "./project-template-modal/project-template-modal";
import {TableModule} from "@clavisco/table";
import {LinkerService} from "@clavisco/linker";
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    declarations: [
      DocumentsComponent,
      ProjectTemplateModalComponent
    ],
    exports: [
        DocumentsComponent
    ],
  imports: [
    CommonModule,
    AppMaterialModule,
    AppRoutingModule,
    TableModule,
    FlexLayoutModule
  ],
  providers: [
    { provide: 'LinkerService', useClass: LinkerService }
  ],
})
export class DocumentsModule { }
