<div class="container">
  <div *ngIf="!filters" class="mb-1 mat-elevation-z0">
    <div class="filter-container">
      <div class="search-container">

        <mat-form-field class="filter-input w-40 search-input" appearance="outline" style="margin-top: 18px;">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Buscar Aplicación</mat-label>
          <input matInput [formControl]="filter" (keyup.enter)="GetApplications()" autocomplete="off">
        </mat-form-field>

        <mat-form-field class="filter-input w-40 search-input" appearance="outline" style="margin-top: 18px;">
          <!--        <mat-icon matPrefix>search</mat-icon>-->
          <mat-label>Estado</mat-label>
          <mat-select [formControl]="filterStatus" (selectionChange)="GetApplications()">
            <mat-option value="">Ambos</mat-option>
            <mat-option value="true">Activo</mat-option>
            <mat-option value="false">Inactivo</mat-option>
          </mat-select>

        </mat-form-field>


      </div>

      <div class="actions-buttons button-container">
        <button mat-stroked-button color="primary" (click)="GetApplications()">
          Buscar
        </button>
        <button mat-stroked-button color="primary" (click)="openModal(false)">
          <mat-icon>
            add
          </mat-icon>
          Agregar </button>
      </div>
    </div>
  </div>
  <mat-card class="mat-elevation-z0">
    <cl-table [Id]="tableId" [MappedColumns]="displayedColumns" [Records]="applicationsList"
      [PageSizeOptions]="pageSizeOptions" [ItemsPeerPage]="itemsPeerPage" [RecordsCount]="recordsCount"
      [HasPaginator]="hasPaginator" [ScrollHeight]="scrollHeight" [ShouldPaginateRequest]="false" [Buttons]="Buttons">
    </cl-table>
  </mat-card>
</div>
