import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {OverlayService} from '@clavisco/overlay';
import {catchError, finalize, tap} from 'rxjs';
import {ICheckListRow, ICheckList} from 'src/app/models/interfaces/i-checklist';
import {CheckListService} from 'src/app/services/checklist-details.service';
import {ModalCheckListDetailsComponent} from './modal-check-list-details/modal-check-list-details.component';
import {ModalCheckListHeaderComponent} from './modal-check-list-header/modal-check-list-header.component';
import {CLToastType} from "@clavisco/alerts";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;

export interface formDynamic {
  Id: number,
  Table: MatTableDataSource<ICheckListRow>;

}

@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.sass']
})
export class CheckListComponent implements OnInit {
  headers: ICheckList[] = [];
  checklistDetails: ICheckListRow[] = [];
  formDynamic: formDynamic[] = []

  displayedColumns: string[] = ['DisplayName', 'Type', 'IsRequired', 'Action'];

  constructor(
    private checkListService: CheckListService,
    public dialog: MatDialog,
    private clBlockUI: OverlayService
  ) {
  }

  ngOnInit(): void {
    this.getCheckList();
  }

  getCheckList() {
    this.clBlockUI.OnGet("Cargando...")
    this.formDynamic = [];
    this.checkListService.GetCheckList().pipe(finalize(() => {
        this.headers.map(x => this.checkListService.GetCheckListRowsByHeader(x.Id).pipe(
          finalize(() => {
            this.clBlockUI.Drop();
          }),
          tap((response: ICLResponse<ICheckListRow[]>) => {
            this.formDynamic.push({Id: x.Id, Table: new MatTableDataSource(response.Data)})
          }), catchError((err) => {
            console.log(err, "error")
            return [];
          })
        ).subscribe())
      }),
      tap((response: ICLResponse<ICheckList[]>) => {
        this.headers = response.Data
      }), catchError((err) => {
        console.log(err)
        return [];
      })
    ).subscribe()
  }

  openModalHeader(edit: boolean, header?: ICheckList) {
    const dialogRef = this.dialog.open(ModalCheckListHeaderComponent, {
      width: '450px',
      maxHeight: `85vh`,
      data: edit ? header : null
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) this.getCheckList();
    })
  }

  OpenModalDetails(edit: boolean, headersId: number, detail?: ICheckListRow) {
    if (edit) {
      const dialogRef = this.dialog.open(ModalCheckListDetailsComponent, {
        width: '450px',
        maxHeight: `85vh`,
        data: {detail: detail, idHeader: headersId, edit: true}
      });
      dialogRef.afterClosed().subscribe(result => {
        if (headersId)
          this.checkListService.GetCheckListRowsByHeader(headersId)
            .pipe(
              finalize(() => {
                this.clBlockUI.Drop();
              }),
              tap((response: ICLResponse<ICheckListRow[]>) => {
                this.formDynamic.filter(x => x.Id === headersId).map(x => x.Table = new MatTableDataSource(response.Data))

              }), catchError((err) => {
                console.log(err)
                return [];
              })
            ).subscribe()
      })
    } else {
      const dialogRef = this.dialog.open(ModalCheckListDetailsComponent, {
        width: '450px',
        maxHeight: `85vh`,
        data: {detail: null, idHeader: headersId, edit: false}
      });
      dialogRef.afterClosed().subscribe(result => {
        if (headersId)
          this.checkListService.GetCheckListRowsByHeader(headersId).pipe(
            finalize(() => {
              this.clBlockUI.Drop();
            }),
            tap((response: ICLResponse<ICheckListRow[]>) => {
              this.formDynamic.filter(x => x.Id === headersId).map(x => x.Table = new MatTableDataSource(response.Data))
            }), catchError((err) => {
              console.log(err)
              return [];
            })
          ).subscribe()
      })
    }
  }

}
