import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService, CLToastType } from '@clavisco/alerts';
import { ICheckList } from 'src/app/models/interfaces/i-checklist';
import { CheckListService } from 'src/app/services/checklist-details.service';
import {OverlayService} from "@clavisco/overlay";
import {catchError, finalize, tap} from "rxjs";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Component({
  selector: 'app-modal-check-list-header',
  templateUrl: './modal-check-list-header.component.html',
  styleUrls: ['./modal-check-list-header.component.sass']
})
export class ModalCheckListHeaderComponent implements OnInit {

  formHeader: FormGroup;

  constructor(private alertService: AlertsService,
    @Inject(MAT_DIALOG_DATA) public data: ICheckList,
    private dialogRef: MatDialogRef<ModalCheckListHeaderComponent>,
    private checkListService: CheckListService, private clBlockUI: OverlayService
  ) {
    this.formHeader = new FormGroup({
      Id: new FormControl(0, Validators.required),
      DisplayName: new FormControl('', Validators.required),
      Description: new FormControl(''),
      Name: new FormControl(''),
      IsActive: new FormControl(true),
      CrystalParamName: new FormControl('P', Validators.required),
      CreatedBy: new FormControl(""),
      UpdatedBy: new FormControl("")
    })
  }

  ngOnInit(): void {
    if (this.data) {
      this.formHeader.patchValue({
        Id: this.data.Id,
        DisplayName: this.data.DisplayName,
        Description: this.data.Description,
        Name: this.data.Name,
        IsActive: this.data.IsActive,
        CrystalParamName: this.data.CrystalParamName,
        CreatedBy: '',
        UpdatedBy: '',
      })
    }
  }

  CloseDialog(): void {
    this.dialogRef.close(false);
  }

  Save() {
    if (!this.formHeader.valid) {
      return this.alertService.Toast({ message: `El formulario es inválido, los campos son requeridos`, type: CLToastType.ERROR })
    }
    if (!this.data) {
      const name = this.formHeader.get('DisplayName')?.value
      this.formHeader.get('Name')?.setValue(name)
    }
    this.clBlockUI.OnPost('Guardando...')
    if(this.data != null) {
      this.checkListService.PatchCheckList(this.formHeader.value).pipe(finalize(()=>{this.clBlockUI.Drop();}),
        tap((response: ICLResponse<ICheckList>) => {
          this.formHeader.get('Id')?.setValue(response.Data.Id)
          this.alertService.Toast({ message: `Guardado Correctamente`, type: CLToastType.SUCCESS })
          this.dialogRef.close(true);
        })
        ,catchError((err) => {
          console.log(err)
          this.dialogRef.close(true);
          return [];
        })
        ).subscribe()
    }
    else{
      this.checkListService.PostCheckList(this.formHeader.value).pipe(finalize(()=>{this.clBlockUI.Drop();}),
        tap((response: ICLResponse<ICheckList>) => {
          this.formHeader.get('Id')?.setValue(response.Data.Id)
          this.alertService.Toast({ message: `Guardado Correctamente`, type: CLToastType.SUCCESS })
          this.dialogRef.close(true);
        })
        ,catchError((err) => {
          console.log(err)
          this.dialogRef.close(true);
          return [];
        })).subscribe()
    }
  }
}
