import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService, CLToastType } from '@clavisco/alerts';
import { OverlayService } from '@clavisco/overlay';
import {catchError, finalize, tap} from 'rxjs';
import { ServersService } from 'src/app/services/servers.service';

@Component({
  selector: 'app-modal-server',
  templateUrl: './modal-server.component.html',
  styleUrls: ['./modal-server.component.sass']
})
export class ModalServerComponent {

  StatusList: string[] = [
    'Activo',
    'Inactivo',
    // 'Finalizado',
    // 'En proceso',
    // 'Suspendido',
  ];

  constructor(
    private dialogRef: MatDialogRef<ModalServerComponent>,
    private alertService: AlertsService,
    private serverService: ServersService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private clBlockUI: OverlayService
  ) { }

  serverForm: FormGroup = new FormGroup({
    Id: new FormControl(this.data.Id, Validators.required),
    Type: new FormControl('DB'),
    Name: new FormControl(this.data.Name, Validators.compose([Validators.required, Validators.maxLength(30)])),
    Status: new FormControl(this.data.Status, Validators.required),
    Description: new FormControl(this.data.Description, Validators.required),
    IsActive: new FormControl(this.data.IsActive),
    CreatedBy: new FormControl(""),
    UpdatedBy: new FormControl(""),
  })

  CloseDialog(): void {
    this.dialogRef.close();
  }

  SaveServer(): void {
    if (this.serverForm.invalid) {
      return this.alertService.Toast({ message: 'El formulario es inválido, los campos son requeridos', type: CLToastType.ERROR });
    }
    this.clBlockUI.OnPost('Guardando servidor...')
    this.serverForm.controls['IsActive'].setValue(this.serverForm.controls['Status'].value === 'Activo');
    this.serverService.StoreServer(this.serverForm.value)
      .pipe(finalize ( () => { this.clBlockUI.Drop() }),
        tap((data)=>{
            this.dialogRef.close();
            this.alertService.Toast({ message: 'Servidor de base de datos guardado correctamente', type: CLToastType.SUCCESS })
        }),catchError(async (error) => this.alertService.ShowAlert({ HttpErrorResponse: error })),
      ).subscribe();
  }

}
