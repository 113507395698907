<router-outlet>
  <div class="container">
   
      <div id="filter-container">
        <form [formGroup]="formControl" fxLayout="row wrap" fxLayoutAlign="space-between">


          <div fxflex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="49" fxFlex.gt-md="49" class="flex">

            <mat-form-field class="input-date second-element" appearance="outline">
              <mat-label>Rango de fechas</mat-label>
              <mat-date-range-input [rangePicker]="campaignOnePicker">
                <input matStartDate placeholder="Start date" formControlName="initDate">
                <input matEndDate placeholder="End date" formControlName="endDate">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
              <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-input  ml-2">
              <mat-label>Seleccione un estado</mat-label>
              <mat-select formControlName="status">
                <mat-option [value]="true">Activo</mat-option>
                <mat-option [value]="false">Inactivo</mat-option>
              </mat-select>
            </mat-form-field>

            <button mat-stroked-button color="primary" (click)="GetProjectTemplates()" class="search-button ml-2">
              <mat-icon>
                search
              </mat-icon>
              Buscar
            </button>

          </div>

          <div fxflex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="49" fxFlex.gt-md="49">
            <button style="float: right" mat-stroked-button color="primary"
              (click)="OpenDialog()"><mat-icon>cloud_upload</mat-icon>
              Cargar Archivos
            </button>
          </div>





        </form>
      </div>
      <mat-card class="mat-elevation-z0">

      <div class="example-container mat-elevation-z0">
        <cl-table [Id]="tableId" [MappedColumns]="displayedColumns" [Records]="projectTemplatesHeaders"
          [PageSizeOptions]="pageSizeOptions" [ItemsPeerPage]="itemsPeerPage" [RecordsCount]="recordsCount"
          [HasPaginator]="hasPaginator" [ScrollHeight]="scrollHeight" [Buttons]="Buttons">
        </cl-table>
      </div>

    </mat-card>

  </div>




</router-outlet>