import {Structures} from "@clavisco/core";
import IBaseEntity = Structures.Interfaces.IBaseEntity;

export interface IProjectTemplate// extends IBaseEntity
{
  Id:number;
  Project: string;
  Client: string;
  DevelopmentManager: string;
  Communication: string;
  PO: string;
  StartDate: string;
  EndDate: string;
  NextDate:string;
  Type:string;
  Comments: string;
  ProjectCode: string;
  TemplateHeaderId: number;
  State: string;
//   Remover apenas nuget este actualizado
  CreatedDate: Date;
  CreatedBy: string;
  UpdatedDate?: Date;
  UpdatedBy?: string;
  IsActive: boolean;

}

export interface IProjectTemplateHeader extends IBaseEntity{
  Id:number;
  Url:string;
  MaxQtyRowsFetch: number;
  Week: number;
  Name: string;
  State: string;
  DateFormat: string;
}

export interface IFileTemplateHeader{
  ProjectTemplatesList: IProjectTemplate[];
  NameHeader: string;
  ValidTemplate?: boolean;
  RemplaceHeader: number;
}
