import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IPermission } from '../models/interfaces/i-permissions';
import { IToken } from '../models/interfaces/i-token';
import { StorageService } from './storage.service';
import {OverlayService} from "@clavisco/overlay";

@Injectable({
  providedIn: 'root',
})
export class SessionService {

  auxiliarSession?: string;
  token!: IToken;

  constructor(
    private storageService: StorageService,
    private router: Router,
    private clBlockUI: OverlayService
  ) { }

  SetSession(session: IToken): void {
    this.storageService.SetStorageData('session',  btoa(JSON.stringify(session)));
  }

  AnyActiveSession(): boolean {
    const session = atob(this.storageService.GetStorageData('session'));
    this.auxiliarSession = session;
    if (!this.auxiliarSession) return false;
    this.token = JSON.parse(this.auxiliarSession);
    return new Date(this.token.expires) > new Date();
  }

  GetToken(): string {
      const session = atob(this.storageService.GetStorageData('session'));
      this.token = <IToken>(
        JSON.parse(session)
      );
    return this.token.access_token;
  }

  GetUserName(): string {
    const session = atob(this.storageService.GetStorageData('session'));
    this.token = <IToken>(
      JSON.parse(session))
    return this.token.name
  }

  getUserId(): string {
    const session = atob(this.storageService.GetStorageData('session'));
    this.token = <IToken>(
      JSON.parse(session))
    return this.token.id;
  }


  SetPermissions(_perms: IPermission[]) {
    let currentSession = this.GetCompleteToken();
    currentSession.Permissions = _perms

    this.SetSession(currentSession);

  }

  GetUserId(): number {
    const session = atob(this.storageService.GetStorageData('session'));
    this.token = <IToken>(
      JSON.parse(session))
    return +this.token.id;
  }

  GetPermissionCodeFromToken(): string[] {
    let permissionsCode: string[] = []
    this.GetCompleteToken()?.Permissions.forEach(permission => {
      permissionsCode.push(permission.Name)
    })
    return permissionsCode
  }

  GetCompleteToken(): IToken {
    const session = atob(this.storageService.GetStorageData('session'));
    return <IToken>JSON.parse(session);
  }

  RemoveSession(): void {
    this.storageService.RemoveStorage('session');
  }

  Logout(): void {
    this.RemoveSession();
    this.clBlockUI.Drop();
    this.router.navigateByUrl('/login');
  }

}
