import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {OverlayService} from "@clavisco/overlay";

@Component({
  selector: 'app-xml-detail',
  templateUrl: './xml-detail.component.html',
  styleUrls: ['./xml-detail.component.sass']
})
export class XmlDetailComponent {

  webConfig: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<XmlDetailComponent>,
    private clBlockUI: OverlayService
  ) { }

  ngOnInit(): void {
    this.webConfig = atob(this.data.xml)
  }

  private StringToArrayBuffer(toConvert: string) {
    let buf = new ArrayBuffer(toConvert.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i != toConvert.length; ++i) {
      view[i] = toConvert.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  DownloadFile() {
    let report = new Blob([this.StringToArrayBuffer(atob(this.data.xml))], {
      //let report = new Blob([this.data.xml], {
      type: 'application/octet-stream',
    });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(report);
    link.download = 'config.xml';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  CloseDialog(): void {
    this.dialogRef.close();
  }

}
