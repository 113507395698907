import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/app-material.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { LinkerService } from '@clavisco/linker';
import { TableModule } from '@clavisco/table';
import { ProjectStatusComponent } from './project-status.component';
import { CreateEditProjectStatusComponent } from './create-edit-project-status/create-edit-project-status.component';

@NgModule({
    declarations: [
        ProjectStatusComponent,
        CreateEditProjectStatusComponent
    ],
    imports: [
        CommonModule,
        AppMaterialModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserModule,
        TableModule
    ],
    exports: [
        ProjectStatusComponent
    ],
    providers: [
        { provide: 'LinkerService', useClass: LinkerService }
    ],
})
export class ProjectStatusModule { }
