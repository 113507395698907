import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {IResponse} from '../models/i-response';
import {Observable} from 'rxjs';
import {ICheckListRow, ICheckList, ICheckListProject} from '../models/interfaces/i-checklist';
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Injectable({
  providedIn: 'root'
})

export class CheckListService {
  private readonly CONTROLLER = '/api/CheckList'

  constructor(
    private http: HttpClient) {
  }
  GetCheckList(): Observable<ICLResponse<ICheckList[]>> {
    return this.http.get<ICLResponse<ICheckList[]>>(`${this.CONTROLLER}`)
  }
  GetCheckListRowsByHeader(id: number): Observable<ICLResponse<ICheckListRow[]>> {
    return this.http.get<ICLResponse<ICheckListRow[]>>(`${this.CONTROLLER}/GetCheckListRowsByHeader`, {
      params: new HttpParams()
        .set('id', id)
    })
  }
  PostCheckList(checkList: ICheckList): Observable<ICLResponse<ICheckList>> {
    return this.http.post<ICLResponse<ICheckList>>(`${this.CONTROLLER}`, checkList)
  }
  PatchCheckList(checkList: ICheckList): Observable<ICLResponse<ICheckList>> {
    return this.http.patch<ICLResponse<ICheckList>>(`${this.CONTROLLER}`, checkList)
  }
  PostCheckListRow(checkListRow: ICheckListRow): Observable<ICLResponse<ICheckListRow>> {
    return this.http.post<ICLResponse<ICheckListRow>>(`${this.CONTROLLER}/PostCheckListRow`, checkListRow)
  }
  PatchCheckListRow(checkListRow: ICheckListRow): Observable<ICLResponse<ICheckListRow>> {
    return this.http.patch<ICLResponse<ICheckListRow>>(`${this.CONTROLLER}/PatchCheckListRow`, checkListRow)
  }
  getCheckListByProject(projectId: number): Observable<ICLResponse<ICheckListProject>> {
    return this.http.get<ICLResponse<ICheckListProject>>(`${this.CONTROLLER}/GetCheckListRowsByProject`, {
      params: new HttpParams()
        .set('projectId', projectId)
    })

  }
}
