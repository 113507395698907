<section class="container-user-defined-fields">
  <mat-card class="mat-elevation-z0">
    <mat-tab-group dynamicHeight>
      <mat-tab label="Creación de UDTs">
        <div class="container-content-tab">
          <div class="creation-tab-header">
            <button class="text-btn" *ngIf="permissionAddUDT" mat-stroked-button matTooltip="Agregar un UDT"
                    (click)="OpenModalAddUdt()">
              <mat-icon>add</mat-icon>
              Agregar UDT
            </button>
          </div>
          <div class="creation-tab-body">
            <mat-divider></mat-divider>
            <cl-table
              [Id]="userDefinedTablesTableId"
              [MappedColumns]="displayedColumnsUDT"
              [Records]="UDTs"
              [PageSizeOptions]="pageSizeOptionsUDTTable"
              [ItemsPeerPage]="itemsPeerPageUDTTable"
              [ShouldPaginateRequest]="shouldPaginateRequestUDTTable"
              [Buttons]="buttonsUDOSTable"
              [ScrollHeight]="scrollHeightUDTTable"
              [PaginationType]="'dba'"
            ></cl-table>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Creación de UDFs">
        <div class="container-content-tab">
          <div class="creation-tab-header">
            <button class="text-btn" *ngIf="permissionAddUDF" mat-stroked-button matTooltip="Agregar un UDF"
                    (click)="OpenModalAddUdf()">
              <mat-icon>add</mat-icon>
              Agregar UDF
            </button>
          </div>
          <div class="creation-tab-body">
            <mat-divider></mat-divider>
            <cl-table
              [Id]="userDefinedFieldsTableId"
              [MappedColumns]="displayedColumnsUDF"
              [Records]="UDFs"
              [PageSizeOptions]="pageSizeOptionsUDFTable"
              [ItemsPeerPage]="itemsPeerPageUDFTable"
              [ShouldPaginateRequest]="shouldPaginateRequestUDFTable"
              [Buttons]="buttonsUDOSTable"
              [ScrollHeight]="scrollHeightUDFTable"
              [PaginationType]="'dba'"
            ></cl-table>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
    <div class="footer-container">
      <mat-form-field appearance="outline" class="application-autocomplete">
        <mat-label>Aplicacion</mat-label>
        <div class="container-application-input">
          <input matInput type="text" [matAutocomplete]="auto" (click)="GetFilteredApplication()"
                 [formControl]="controlSelectedApplication"/>
          <mat-icon matTooltip="Cancelar" class="cancel-icon" (click)="ClearApplicationSelection()">close</mat-icon>
        </div>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let app of applications"
                      (click)="HandleApplicationSelection(app)" [value]="app.Name"
                      [matTooltip]="app.Code + ':  ' + app.Description" [matTooltipPosition]="'right'">
            <span>{{ app.Name }}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div class="container-action-btns">
        <button class="text-btn" mat-stroked-button matBadge="{{listConnections?.length}}"
                matTooltip="Conexiones agregadas {{listConnections.length}}" (click)="OpenModalConnections()">
          <mat-icon>
            linear_scale
          </mat-icon>
          Conexiones
        </button>
        <button mat-stroked-button class="btn-filter" matTooltip="Guardar información" (click)="CreateUdo()">
          <mat-icon>
            search
          </mat-icon>
          <span>Guardar</span>
        </button>
      </div>
    </div>
  </mat-card>
</section>
