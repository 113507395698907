import {Component, Inject, OnInit} from '@angular/core';
import {IUserFieldValidValue} from "../../../../models/interfaces/sap-object/i-user-field-valid-value";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {MapDisplayColumns, MappedColumns} from "@clavisco/table";
import {CL_CHANNEL, ICLCallbacksInterface} from "@clavisco/linker";

@Component({
  selector: 'app-modal-valid-values-md',
  templateUrl: './modal-valid-values-md.component.html',
  styleUrls: ['./modal-valid-values-md.component.sass']
})
export class ModalValidValuesMDComponent implements OnInit {

  validValuesSAP: IUserFieldValidValue[] = [];

  tableId: string = 'tableValidValues';
  displayedColumns!: MappedColumns;
  recordsCount: number = 0;

  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IUserFieldValidValue[]
  ) {
    this.validValuesSAP = data;
    this.displayedColumns = MapDisplayColumns<IUserFieldValidValue, null>({
      dataSource: this.validValuesSAP,
      renameColumns: {
        "Value": "Valor",
        "Description": "Descripción",
      },
      ignoreColumns: []
    })
  }

  ngOnInit(): void {
  }

}
