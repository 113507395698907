import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { IBaseResponse, IResponse } from '../models/i-response';
import {
  IPermission,
  IPermissionsByRole
} from '../models/interfaces/i-permissions';
import {
  IRole,
  IRoleByUser
} from '../models/interfaces/i-role';
import { SessionService } from './session.service';
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Injectable({
  providedIn: 'root',
})
export class RolesService
{
  private readonly CONTROLLER = '/api/Roles'
  constructor(
    private http: HttpClient,
  ) { }

  GetRoles(): Observable<ICLResponse<IRole[]>> {
    return this.http.get<ICLResponse<IRole[]>>(`${this.CONTROLLER}`);
  }

  Post(role: IRole): Observable<ICLResponse<IRole>> {
    return this.http.post<ICLResponse<IRole>>(
      `${this.CONTROLLER}`,
      role
    );
  }
  GetRoleByUser(userId: number): Observable<ICLResponse<IRole[]>> {
    const query = new HttpParams().set('id', userId);
    return this.http.get<ICLResponse<IRole[]>>(
      `${this.CONTROLLER}/GetRolesByUser`,
      { params: query }
    );
  }

  StoreRoleByUser(roleAssociates: IRoleByUser): Observable<IResponse<IRole>> {
    return this.http.post<IResponse<IRole>>(
      `${this.CONTROLLER}/PostRolesByUser`,
      roleAssociates
    );
  }
}
