<div>
    <div class="align-right"><mat-icon class="icon-close" (click)="CloseDialog(false)">close</mat-icon></div>
    <h1 class="title">Cargar Archivos</h1>
    <div class="dropzone" style="cursor: pointer;">
        <input  type="file" #fileDropRef id="fileDropRef" [multiple]="data.Type===1" (change)="onSelectFiles($event)" />
        <img id="file" mat-card-image src="../../../../../assets/image/cloudUpload.png" alt="Subir Archivo">
        <h2>Arrastra y Suelta</h2>
        <h2>o</h2>
        <label for="FileDropRef">Selecciona un Archivo</label>
    </div>
    <div class="content-button">
        <button mat-stroked-button (click)="uploadFiles()">
            <mat-icon>save</mat-icon> Guardar Archivos</button>
    </div>
    <div>
        <mat-list *ngIf="listFiles.length > 0" class="content">
            <mat-list-item *ngFor="let file of listFiles; let i=index">
                <mat-icon style="border-radius: 0%" matListAvatar>description</mat-icon>
                <mat-icon style="border-radius: 0%; cursor: pointer;" (click)="DeleteFile(i)">close</mat-icon>
                <h3 matLine> {{file}} </h3>
                <mat-divider></mat-divider>
            </mat-list-item>
        </mat-list>
    </div>
</div>
