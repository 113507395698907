import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {AlertsService, CLToastType, ModalService} from '@clavisco/alerts';
import {catchError, finalize, tap} from 'rxjs';
import {IFiles, IFilesType, IFileTemplate} from 'src/app/models/interfaces/i-file';
import { FileService } from 'src/app/services/file.service';
import {ProjectTemplateService} from "../../../../services/project-template.service";
import {OverlayService} from "@clavisco/overlay";
import {
  IFileTemplateHeader,
  IProjectTemplate,
  IProjectTemplateHeader
} from "../../../../models/interfaces/i-project-template";
import {ProjectTemplateModalComponent} from "../../documents/project-template-modal/project-template-modal";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.sass']
})

export class UploadFilesComponent implements OnInit {

  files: IFiles[] = [];
  listFiles: string[] = [];
  listExtensions: string[] = [];

  @ViewChild('fileDropRef', { static: false }) fileInputRef: ElementRef;

  constructor(private fileService: FileService, private alertService: AlertsService,
    private modalService: ModalService,
    private templateService: ProjectTemplateService,
    private dialogRef: MatDialogRef<UploadFilesComponent>,
    private clBlockUI: OverlayService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: IFilesType) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.GetFileExtensions();
    }, 0);
  }

  GetFileExtensions() {
    this.fileService.GetFileExtensions().pipe(finalize(() => { }),
      tap((response: ICLResponse<string[]>) => {
        this.listExtensions = response.Data;
      }), catchError((err) => {
        console.log(err)
        return [];
      })
    ).subscribe();
  }

  fileToBase64 = (file: File | Blob): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };

      reader.readAsDataURL(file);
      reader.onerror = reject;
    });

  ValidateExtensions(type:string): boolean{
    if(this.data.Type == 1){
      if (this.listExtensions.filter(x => x === type).length === 0) {
         this.alertService.Toast({ message: 'No se permite la extensión ' + type, type: CLToastType.ERROR })
        return false;
      }
    }else{
      if(type!= "xlsx"){
        this.alertService.Toast({ message: `No se permite la extensión ${type}, solo la XLSX(Excel)`, type: CLToastType.ERROR })
        return false;
      }
    }
    return true;
  }
  onSelectFiles = async (e: Event) => {
    if(this.data.Type === 2 && this.listFiles.length>=1){
      return this.alertService.Toast({ message: 'Solo se puede cargar una plantilla', type: CLToastType.ERROR });
    }
    const target = e.target as HTMLInputElement;
    let b64;
    const files: FileList = (target.files as FileList);

    await Promise.all(
      [].map.call(target.files, async (file: File) => {
        const type = file.name.split('.');
        if(!this.ValidateExtensions(type[type.length-1])) return
        if (this.listFiles.filter(x => x == file.name).length > 0) return this.alertService.Toast({ message: 'El archivo ya existe', type: CLToastType.ERROR });

        b64 = (await this.fileToBase64(file)).split(',');
        this.listFiles.push(file.name)
        this.files.push({
          Name: file.name,
          Base64: b64[b64.length - 1],
          Url: '',
          CreatedDate: new Date()
        });
      })
    );
    if (this.fileInputRef && this.fileInputRef.nativeElement) {
      this.fileInputRef.nativeElement.value = '';
    }
  }


  uploadFiles() {
    if (this.files.length === 0) {
      return this.alertService.Toast({ message: 'Debe seleccionar un archivo', type: CLToastType.WARNING });
    }
    const xFiles = this.data.Files.map(x => x.Name.split('/'))
    this.files.forEach(x => {
      if (xFiles.filter(m => m[m.length - 1] === x.Name).length > 0) return this.alertService.Toast({ message: `Ya existe un archivo con el mismo nombre: ${x.Name}`, type: CLToastType.WARNING });
    })
    this.files.length > 1 ? this.clBlockUI.OnPost("Subiendo archivos") : this.clBlockUI.OnPost("Subiendo archivo");

    if(this.data.Type===1){
      this.fileService.UploadFile(this.files).pipe(finalize(() => { this.clBlockUI.Drop(); }),
        tap((response:ICLResponse<string>)=>{
            this.files.length > 1 ? this.alertService.Toast({ message: 'Archivos subidos correctamente', type: CLToastType.SUCCESS }) : this.alertService.Toast({ message: 'Archivo subido correctamente', type: CLToastType.SUCCESS })
            this.listFiles = [];
            this.CloseDialog(true);
        }),catchError((err) => {
          this.alertService.Toast({ message: 'Ocurrió un problema al guardar el archivo, ' + err, type: CLToastType.ERROR });
          return [];
        })
      ).subscribe();
    }else{  //excel
      const today = new Date();
      const formattedDate = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()} ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
      const fileNameWithDate = this.files[0].Name + '_' + formattedDate;
      this.files[0].Name = fileNameWithDate;
      this.templateService.ReviewTemplate(this.files[0]).pipe(finalize(()=>{ this.clBlockUI.Drop(); })).subscribe({
        next: resp => {
          if (resp.Data) {
            this.OpenReviewTemplate(resp.Data);
          }
        },
        error: err => console.log(err)
      });
    }
  }

  OpenReviewTemplate(template: IFileTemplateHeader){
    const dialogTemplate = this.dialog.open(ProjectTemplateModalComponent,
      {
        width: '1200px',
        maxHeight: `85vh`,
        data: {Data:template.ProjectTemplatesList, Type: 1, NameHeader: template.NameHeader}
      })
    dialogTemplate.afterClosed().subscribe(result=>{
      if(result){
        const templateRes :IFileTemplateHeader = {ProjectTemplatesList: result, NameHeader: template.NameHeader, RemplaceHeader: template.RemplaceHeader}
        if(!template.ValidTemplate){
          this.modalService
            .Question({ title: 'Ya existe este archivo', subtitle: '¿Seguro que desea reemplazarlo?', disableClose: false })
            .subscribe(res => {
              if (res) {
                this.UploadTemplate(templateRes);
              }
            });
        } else {
          this.UploadTemplate(templateRes);
        }
      }
    })
  }

  UploadTemplate(templates: IFileTemplateHeader){
    this.clBlockUI.OnGet('Subiendo archivo...')
    const file: IFileTemplate= {ProjectTemplatesList :templates.ProjectTemplatesList, File: this.files[0],RemplaceHeaderTemplate: templates.RemplaceHeader, NameHeader: templates.NameHeader };
    this.templateService.SaveProjectTemplate(file).pipe(finalize(() => { this.clBlockUI.Drop(); })).subscribe({
      next: callback => {
        if (callback.Data) {
          this.alertService.Toast({ message: 'Archivo subido correctamente', type: CLToastType.SUCCESS })
          this.CloseDialog(true);
        }
      },
      error: err => this.alertService.Toast({ message: 'Ocurrió un problema al guardar el archivo, ' + err, type: CLToastType.ERROR })
    })
  }

  CloseDialog(result:boolean): void {
    this.dialogRef.close(result);
  }

  DeleteFile(index: number) {
    this.listFiles.splice(index, 1)
    this.files.splice(index, 1)
  }

}
