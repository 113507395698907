<router-outlet>
  <div class="container">
    <div class="div_primary">
      <div class="content-header">
        <div class="filters-inputs ">
          <form [formGroup]="filterForm">

            <mat-form-field class="w-40 no-bottom-field" appearance="outline">
              <mat-icon matPrefix>search</mat-icon>
              <mat-label>Buscar proyecto</mat-label>
              <!--Step 1-->
              <div>
                <input matInput formControlName="ProjectName" (keyup.enter)="GetProyect()" autocomplete="off">
              </div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-40 w-input">
              <mat-label>Estado</mat-label>
              <mat-select formControlName="ProjectStatus" (selectionChange)="GetProyect()">
                <mat-option value="" >Todos</mat-option>
                <mat-option *ngFor="let status of projectStatus"
                  [value]="status.NameStatus">{{status.NameStatus}}</mat-option>
              </mat-select>
            </mat-form-field>
          </form>

        </div>
        <div>
          <button mat-stroked-button color="primary" class="actions-buttons" (click)="GetProyect()">
            <mat-icon>search</mat-icon>
            Buscar
          </button>
          <!--Step 2-->

          <button mat-stroked-button (click)="CreateProject()" class=" actions-buttons">
            <mat-icon>add</mat-icon>
            Agregar
          </button>

        </div>




      </div>
      <mat-card class="mat-elevation-z0 no-margin">
        <div class="example-container ">
          <cl-table [Id]="tableId" [MappedColumns]="displayedColumn" [Records]="projectList"
            [PageSizeOptions]="pageSizeOptions" [ItemsPeerPage]="itemsPeerPage" [RecordsCount]="recordsCount"
            [HasPaginator]="hasPaginator" [ScrollHeight]="scrollHeight" [ShouldPaginateRequest]="shouldPaginateRequest"
            [Buttons]="Buttons">
          </cl-table>
        </div>

      </mat-card>

      <!-- <button class="mt-1 vidibility-hiden" style="margin-right: 15px;" mat-stroked-button
        (click)="startTour()">Shepherd Tour</button> -->

      <!--Comienzan los steps BDC-Walktrought-->
      <bdc-walk-popup class="pop-up" #step1Step1 name="step1" header="Buscador" xPosition="after" class="warning"
        [showButton]="true" buttonText="Siguiente" sideNoteText="1/4">
        Ingrese el código del proyecto que desea buscar.
      </bdc-walk-popup>

      <bdc-walk-popup #step1Step2 name="step1Step2" header="Agregar proyecto" yPosition="below" [showButton]="true"
        buttonText="Siguiente" [mustCompleted]="{step1: true}" [onButtonCompleteTask]="{step1Next: 2}"
        sideNoteText="2/4">
        Agregue un nuevo proyecto desde esta sección.
      </bdc-walk-popup>

      <bdc-walk-popup #step1Step3 name="step1Step3" header="Tabla" xPosition="after" [showButton]="true"
        buttonText="Siguiente" [mustCompleted]="{step1Step2: true}" [onButtonCompleteTask]="{step1Next: 3}"
        sideNoteText="3/4" class="pop-up">
        La información se cargará en la tabla para que pueda visualizarla.
      </bdc-walk-popup>

      <bdc-walk-popup #step1Step4 name="step1Step4" header="Editar" xPosition="after" [showButton]="true"
        buttonText="Completar" [mustCompleted]="{step1Step3: true}" [onButtonCompleteTask]="{step1Next: 4}"
        sideNoteText="4/4">
        Puede editar el proyecto al dar click en esta sección.
      </bdc-walk-popup>


      <!--Boton inicia tour, visible en la UI-->
      <button class="mt-1 vidibility-hiden" (click)="reset()" mat-stroked-button>BDC-Walktrought tour</button>
      <!--Terminan los steps BDC-Walktrought-->

    </div>
  </div>

</router-outlet>
