import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/app-material.module';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { ModalProjectDeliverablesComponent } from './modal-project-deliverables/modal-project-deliverables.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { ProjectDeliveriesComponent } from './project-deliveries.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { CustomTooltipDirective } from 'src/app/directives/custom-tooltip.directive';

@NgModule({
  declarations: [
    ProjectDeliveriesComponent,
    ModalProjectDeliverablesComponent,
    CustomTooltipDirective
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    HttpClientModule,
  ],
  exports: [
    ProjectDeliveriesComponent,
  ],

})
export class ProjectDeliveriesModule { }
