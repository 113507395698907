import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AlertsService, CLToastType} from '@clavisco/alerts';
import {IFilesType} from 'src/app/models/interfaces/i-file';
import {catchError, finalize, Subscription, tap} from "rxjs";
import {SessionService} from "../../../services/session.service";
import {MapDisplayColumns, MappedColumns} from "@clavisco/table";
import {CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown} from "@clavisco/linker";
import {Structures} from "@clavisco/core";
import {IProjectTemplateHeader} from "../../../models/interfaces/i-project-template";
import {ProjectTemplateService} from "../../../services/project-template.service";
import {FormControl, FormGroup} from "@angular/forms";
import {UploadFilesComponent} from "../files/upload-files/upload-files.component";
import {MatDialog} from "@angular/material/dialog";
import {ProjectTemplateModalComponent} from "./project-template-modal/project-template-modal";
import {FileService} from "../../../services/file.service";
import {OverlayService} from "@clavisco/overlay";
import {IResponse} from "../../../models/i-response";
import ICLResponse = Structures.Interfaces.ICLResponse;

const today: Date = new Date();
const actuallymonth = today.getMonth();
const actuallyear: number = today.getFullYear();
const currentdate: any = new Date();
const firstDayMonth: any = new Date(currentdate.getFullYear(), actuallymonth, 1);
const numberOfDays: number = Math.floor((currentdate - firstDayMonth) / (24 * 60 * 60 * 1000));
const actuallyWeek: number = Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);
const firstDayWeek: Date = new Date(actuallyear, actuallymonth, (actuallyWeek - 1) * 7 - 2);

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.sass']
})
export class DocumentsComponent implements OnInit, OnDestroy {
  allSuscription: Subscription = new Subscription()
  StartPos: number = 0;
  RowsReturned: number = 10;
  tableId = 'tableTemplates'
  displayedColumns!: MappedColumns;
  scrollHeight: string = '400px';
  pageSizeOptions: number[] = [10, 50];
  itemsPeerPage: number = 10;
  recordsCount: number = 0;
  hasPaginator: boolean = true;
  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };
  Buttons: any = [
    {
      Title: `Ver detalles`,
      Action: Structures.Enums.CL_ACTIONS.UPDATE,
      Icon: `info`,
      Color: `primary`
    },
    {
      Title: `Descargar`,
      Action: Structures.Enums.CL_ACTIONS.CONTINUE,
      Icon: `download`,
      Color: `primary`
    },
  ]

  projectTemplatesHeaders:IProjectTemplateHeader[] = []
  formControl = new FormGroup({
    initDate: new FormControl(firstDayWeek),
    endDate: new FormControl(new Date(today.setDate(today.getDate()+1))),
    status: new FormControl(true)
  });

  constructor(private templateService: ProjectTemplateService, private alertService: AlertsService,
    private clBlockUI: OverlayService,private sessionService: SessionService,
    private templatesService: ProjectTemplateService,
    private fileService: FileService,
    public dialog: MatDialog,
    @Inject('LinkerService') private linkerService: LinkerService
  ) {
    this.displayedColumns = MapDisplayColumns<IProjectTemplateHeader,null>({
      dataSource: [],
      renameColumns: {
        Name: 'Nombre',
        DateFormat: 'Fecha de Registro',
        Week: 'Número de Semana',
        State: 'Estado',
        User: 'Usuario',
      },
      ignoreColumns: ['MaxQtyRowsFetch', 'Id', 'Url', 'IsActive', 'UpdatedBy', 'UpdateDate', 'CreatedDate', 'CreatedBy']
    });
  }

  ngOnDestroy(): void{
    this.allSuscription.unsubscribe();
  }

  ngOnInit(): void {
    Register<CL_CHANNEL>(this.tableId, CL_CHANNEL.INPUT, this.GetElementsRecords, this.callbacks);
    Register<CL_CHANNEL>(this.tableId, CL_CHANNEL.OUTPUT, this.SelectedTemplate, this.callbacks);

    this.allSuscription.add(this.linkerService.Flow()
      ?.pipe(StepDown<CL_CHANNEL>(this.callbacks))
      .subscribe({
        next: (callback) =>
          Run(callback.Target, callback, this.callbacks.Callbacks),
        error: (error) => console.log(`error`, error),
      }));
    const fecha = new Date()
    const fechaAnt = new Date()
    fechaAnt.setDate(fecha.getDate() - 7)
    this.GetProjectTemplatesHeader(0,this.itemsPeerPage, fechaAnt, fecha, true);
  }

  GetElementsRecords = (_event: ICLEvent): void => {
    if (_event) {
      const INCOMMING_TABLE_STATE = JSON.parse(_event.Data);
      this.RowsReturned = INCOMMING_TABLE_STATE.ItemsPeerPage;
      this.StartPos = INCOMMING_TABLE_STATE.CurrentPage;
      this.GetProjectTemplates()
    }
  };

  SelectedTemplate = (_event:ICLEvent) => {
    const event = JSON.parse(_event.Data);
    const template: IProjectTemplateHeader = JSON.parse(event.Data)

    switch (event.Action) {
      case Structures.Enums.CL_ACTIONS.UPDATE:
        this.dialog.open(
          ProjectTemplateModalComponent,{
            width: '1200px',
            maxHeight: `85vh`,
            data: {Data:template?.Id, Type: 0}
          });
        break;
      case Structures.Enums.CL_ACTIONS.CONTINUE:
        this.DownloadFile(template)
        break;
    }
  }

  OpenDialog() {
    const data: IFilesType = {Files:[],Type:2}
    const dialogRef = this.dialog.open(UploadFilesComponent, {
      minWidth: '750px',
      maxHeight: `85vh`,
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result)  this.GetProjectTemplates()
    })
  }

  GetProjectTemplates(){
    if (!this.formControl.value.initDate || !this.formControl.value.endDate) return this.alertService.Toast({ message: 'La fecha de inicio y de fecha final son obligatorias', type: CLToastType.WARNING })
    this.GetProjectTemplatesHeader(this.StartPos,this.RowsReturned,this.formControl.value.initDate!,
      this.formControl.value.endDate!, this.formControl.value.status!);
  }

  GetProjectTemplatesHeader(startPost:number, rowsR: number, iniDate: Date | null, endDate:Date | null, status:boolean){
    this.clBlockUI.OnGet('Cargando...')
    this.templatesService.GetProjectTemplateHeader(startPost,rowsR,iniDate,endDate, status).pipe(finalize(()=>this.clBlockUI.Drop())).subscribe({
      next:value => {
        if(value.Data){
          this.projectTemplatesHeaders = value.Data;
          this.projectTemplatesHeaders.map(x=>{
            x.Week = this.getWeek(x.CreatedDate)
            x.State = x.IsActive ? 'Activo' : 'Inactivo'
            x.DateFormat = this.getFormatDate(new Date(x.CreatedDate))
          })
          this.loadTableData();
        }
      }, error: (error) => console.log(error)
    });
  }

  getFormatDate(date: Date):string{
    const time = ` - ${date.toTimeString().split(('GMT'))[0]}`;
    return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${time}`
  }

  getWeek(date: Date): number {
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 4 - (date.getDay() || 7));
    const yearStart = new Date(date.getFullYear(), 0, 1);
    const weekNo = Math.ceil(((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);

    return weekNo;
  }

  loadTableData(): void {
    const CURRENT_TABLE_STATE = {
      CurrentPage: 1,
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.projectTemplatesHeaders,
      RecordsCount: this.recordsCount
    };
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.tableId,
      Data: JSON.stringify(CURRENT_TABLE_STATE)
    } as ICLEvent);
  }

  DownloadFile(file: IProjectTemplateHeader) {
    this.clBlockUI.OnPost('Descargando Archivo...')
    this.fileService.DownloadFileBlob(file.Url).pipe(finalize(() => { this.clBlockUI.Drop(); }),
      tap((response:ICLResponse<string>)=>{
        const urlF = file.Url.split('/');
        let report = new Blob([this.StringToArrayBuffer(atob(response.Data))], {
          type: 'application/octet-stream',
        });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(report);
        link.download = urlF[urlF.length - 1];
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      ,catchError((error: Error) => {
        this.alertService.Toast({message:error.message, type:CLToastType.ERROR})
        return [];
      })
    ).subscribe();
  }

  private StringToArrayBuffer(toConvert: string) {
    let buf = new ArrayBuffer(toConvert.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i != toConvert.length; ++i) {
      view[i] = toConvert.charCodeAt(i) & 0xff;
    }
    return buf;
  }

}
