import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppMaterialModule } from '../../../app-material.module';
import { ApplicationComponent } from './application.component';
import { ModalApplicationComponent } from './modal-application/modal-application.component';
import { LinkerService } from '@clavisco/linker';
import { TableModule } from '@clavisco/table';
import {AppRoutingModule} from "../../../app-routing.module";



@NgModule({
  declarations: [
    ApplicationComponent,
    ModalApplicationComponent
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    AppRoutingModule,
    TableModule
  ],
  exports: [
    ApplicationComponent
  ],
  providers: [
    { provide: 'LinkerService', useClass: LinkerService }
  ],
})
export class ApplicationModule { }
