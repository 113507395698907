import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AlertsService, CLToastType } from '@clavisco/alerts';
import { IAppLink } from 'src/app/models/interfaces/i-appLink';
import { ProyectService } from 'src/app/services/proyect.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-app-links',
  templateUrl: './app-links.component.html',
  styleUrls: ['./app-links.component.sass']
})
export class AppLinksComponent implements OnInit {

  @Input() AppLinkList: IAppLink[];
  AppLinkTable: MatTableDataSource<IAppLink> = new MatTableDataSource();
  displayedColumns: string[] = ['Link', 'CreatedDate', 'Id'];

  @Input() checked: boolean;
  @Input() edit: boolean;



  @Output() AppLinkEvent: EventEmitter<IAppLink[]> = new EventEmitter<IAppLink[]>()

  reg = /^((http|https):\/\/)?(([a-zA-Z0-9$\-_.+!*'(),;:&=]|%[0-9a-fA-F]{2})+@)?(((25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9]|[1-9][0-9]|[0-9])(\.(25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9]|[1-9][0-9]|[0-9])){3})|localhost|([a-zA-Z0-9\-\u00C0-\u017F]+\.)+([a-zA-Z]{2,}))(:[0-9]+)?(\/(([a-zA-Z0-9$\-_.+!*'(),;:@&=]|%[0-9a-fA-F]{2})*(\/([a-zA-Z0-9$\-_.+!*'(),;:@&=]|%[0-9a-fA-F]{2})*)*)?(\?([a-zA-Z0-9$\-_.+!*'(),;:@&=\/?]|%[0-9a-fA-F]{2})*)?(\#([a-zA-Z0-9$\-_.+!*'(),;:@&=\/?]|%[0-9a-fA-F]{2})*)?)?$/;

    Link: FormControl = new FormControl('', [Validators.required, Validators.pattern(this.reg)]);


  private _isChecked: boolean;

  @Input()
  set isChecked(value: boolean) {
    this._isChecked = value;
    this.onCheckedChange();
  }

  get isChecked(): boolean {
    return this._isChecked;
  }


  constructor(
    private alertService: AlertsService,
    private sessionService: SessionService,
    private projectService: ProyectService
  ) { }

  ngOnInit(): void {
    this.AppLinkTable.data = this.AppLinkList;
   /* this.projectService.EventChecked.subscribe(res => {


      if (!res) {
        this.displayedColumns.pop()
      } else {
        this.displayedColumns.push('Id')
      }
    })
*/
  }

  onCheckedChange(): void {
    // Tu lógica aquí, este método se ejecutará cada vez que isChecked cambie
    console.log('El valor de isChecked ha cambiado en el componente padre:', this.isChecked);


    if (this.isChecked && !this.displayedColumns.includes('Id'))  {
      this.displayedColumns.push('Id')

    } else if (!this.isChecked && this.displayedColumns.includes('Id')) {
      this.displayedColumns.pop()
    }
  }

  validateLinks(link: IAppLink) {
    const res = this.AppLinkTable.data.filter(x => x.Link === link.Link)
    if (res.length > 0) return false;
    return true;
  }

  RemoveLink(index: number): void {
    this.AppLinkTable.data.splice(index, 1);
    this.AppLinkTable.data = [...this.AppLinkTable.data]
    this.AppLinkList = this.AppLinkTable.data;
    this.AppLinkEvent.emit(this.AppLinkList)
  }

  AddLink() {
    if (!this.Link.valid) {
      return this.alertService.Toast({ message: 'Error en el formulario, el link es requerido o el link ingresado no es válido', type: CLToastType.ERROR })
    } else {
      const date = new Date()
      const initDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

      const link: IAppLink = {
        Id: 0,
        UserName: "",
        Link: this.Link.value, ProjectId: 0, UserId: Number(this.sessionService.getUserId()), CreatedDate: new Date(), CreatedBy: '', UpdateDate: new Date(), UpdatedBy: '', IsActive: true
      }

      if (!this.validateLinks(link)) {
        return this.alertService.Toast({ message: 'El link ya existe', type: CLToastType.WARNING })
      } else {
        if (this.AppLinkList.length === 0) {
          this.AppLinkTable.data.push(link)
        } else {
          this.AppLinkTable.data = [... this.AppLinkTable.data, link]
        }
        this.AppLinkList = this.AppLinkTable.data;
        this.AppLinkEvent.emit(this.AppLinkList)
      }
      this.Link.setValue('')
    }
  }

}
