import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevelopersComponent } from './developers.component';
import { AppMaterialModule } from 'src/app/app-material.module';
import { ModalAddDevelopersComponent } from './modal-add-developers/modal-add-developers.component';




@NgModule({
  declarations: [
    DevelopersComponent,
    ModalAddDevelopersComponent,  
  ],
  imports: [
    CommonModule,
    AppMaterialModule
   
  ],
  exports: [
    DevelopersComponent
  ]
})
export class DevelopersModule { }
