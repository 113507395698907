
<div class="flex-container mt-3">
  <div cdkDropListGroup class="w-100" fxLayout="row wrap" fxLayoutAlign="space-between">
    <div  fxFlex="50" class="px-1">
      <h2>Usuarios</h2>
      <mat-form-field appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label>Busqueda Usuario</mat-label>
        <input matInput [(ngModel)]="userFilter" (keyup.enter)="GetUser()" >
      </mat-form-field>
      <mat-selection-list [multiple]="false"  class="cdk-list">
        <mat-list-option *ngFor="let user of userList" [value]="user.Name" class="cdk-role-box" [matTooltip]="GetFullName(user)" matTooltipPosition="right" [ngClass]="{ 'selected': user.Id == selectedUser?.Id}"  (click)="ChargeUser(user)">
          {{ user.Email }}
        </mat-list-option>
      </mat-selection-list>
    </div>
    <div fxFlex="50" >
      <h2>Roles asignados y disponibles </h2>
      <div class="bar-permission" >

        <mat-form-field appearance="outline" >
          <mat-label>Rol</mat-label>
          <mat-icon matPrefix class="my-icon">search</mat-icon>
          <input matInput type="text" placeholder="Rol" [formControl]="roleFilter" (keydown.enter)="getRolesFilter()" >
        </mat-form-field>
        <mat-slide-toggle [disabled]="!isSelected()" (click)="checkedUser()" [ngModel]="allSelected" (change)="setAllRoles($event)">Seleccionar todos</mat-slide-toggle>
      </div>
      <mat-selection-list [multiple]="false" class="cdk-list">
        <mat-list-option *ngFor="let role of rolesFiltered" [value]="role.Id" class="cdk-role-box" >
          <div class="bar-permission">
            {{role.Description}}
            <mat-slide-toggle [disabled]="!isSelected()" (click)="checkedUser()" [ngModel]="role.IsAssigned" (change)="SelectedRole($event,role)"></mat-slide-toggle>
          </div>
        </mat-list-option>
      </mat-selection-list>

    </div>
  </div>
</div>
