import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

import { IUdoConnection } from "../models/interfaces/sap-object/i-udo-connection";

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  private connectionsSource = new BehaviorSubject<IUdoConnection[]>([]);
  connection$ = this.connectionsSource.asObservable();

  GetConnections() : IUdoConnection[] {
    return this.connectionsSource.value
  };

  ConnectionExist(dataBaseCode: string, serverUrl: string): boolean {
    return this.connectionsSource.value.some(
      conn => (conn.DataBaseCode === dataBaseCode && conn.ServerUrl === serverUrl)
    );
  };

  AddConnection(connection: IUdoConnection): boolean {
    if(this.ConnectionExist(connection.DataBaseCode, connection.ServerUrl)){
      return false; // The connection already exists
    }
    const currentConnections = this.connectionsSource.value;
    this.connectionsSource.next([ ...currentConnections, connection]);
    return true; // Connection added successfully
  };

  DeleteConnection(connection: IUdoConnection) {
    const currentConnections = this.connectionsSource.value;
    const updatedConnections  = currentConnections.filter((c: IUdoConnection) =>
      !(c.DataBaseCode === connection.DataBaseCode && c.ServerUrl === connection.ServerUrl));
    this.connectionsSource.next(updatedConnections);
  };

  ClearConnections() {
    this.connectionsSource.next([]);
  };

  constructor() { }
}
