<section class="container-modal-add">
  <div class="header-container">
    <div class="title-container" mat-dialog-title>
      <h1>Crear Campo Definido por el Usuario</h1>
    </div>
  </div>
  <div class="body-container">
    <form id="form-add-udt" [formGroup]="userDefinedTableForm" (ngSubmit)="AddUdt()">
      <mat-form-field appearance="outline" class="w-input">
        <mat-label>Nombre de la Tabla</mat-label>
        <input matInput placeholder="Nombre de la Tabla" [maxLength]="19" formControlName="TableName">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Tipo de la Tabla</mat-label>
        <input required type="text" placeholder="Tipo de la tabla" aria-label="Nombre de la tabla"
               matInput [matAutocomplete]="auto" [formControlName]="'TableType'"/>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let fieldTable of listTableTypes" [value]="fieldTable">
            <span>{{ fieldTable }}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-input">
        <mat-label>Descripción de la Tabla</mat-label>
        <textarea matInput placeholder="Descripción de la Tabla" [maxLength]="30"
                  formControlName="TableDescription">
        </textarea>
      </mat-form-field>
      <mat-checkbox class="m-10" formControlName="Archivable">Archivable</mat-checkbox>
    </form>
  </div>
  <div class="footer-container" mat-dialog-actions>
    <button class="close-btn" mat-stroked-button matTooltip="Configuración terminada" (click)="HandleModalClose()">
      <span class="text-btn"><mat-icon>close</mat-icon>Finalizar</span>
    </button>
    <button form="form-add-udt" type="submit" mat-stroked-button>
      <span class="text-btn" matBadge="{{listUDTs.length}}" matTooltip="UDTs agregados {{listUDTs.length}}">
        <mat-icon>add</mat-icon>{{ udtToEdit ? 'Guardar Cambios' : 'Agregar' }}
      </span>
    </button>
  </div>
</section>
