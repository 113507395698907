import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { LinkerService } from "@clavisco/linker";
import { RouterModule } from "@angular/router";
import { CommonModule } from '@angular/common';
import { TableModule } from "@clavisco/table";
import { FormsModule } from "@angular/forms";

import { UdoConnectionsModalComponent } from './udo-connections-modal/udo-connections-modal.component';
import { UserDefinedObjectsComponent } from './user-defined-objects/user-defined-objects.component';
import { ModalAddUdfComponent } from './modal-add-udf/modal-add-udf.component';
import { AppMaterialModule } from "../../../app-material.module";
import { ModalSummaryResponseComponent } from './modal-summary-response/modal-summary-response.component';
import {MatBadgeModule} from "@angular/material/badge";
import { ModalAddUdtComponent } from './modal-add-udt/modal-add-udt.component';
import { ReplicateObjectsComponent } from './replicate-objects/replicate-objects.component';
import { ModalValidValuesMDComponent } from './modal-valid-values-md/modal-valid-values-md.component';
import { HistoryUdosComponent } from './history-udos/history-udos.component';
import { ViewUdfsModalComponent } from './view-udfs-modal/view-udfs-modal.component';
@NgModule({
  declarations: [
    UserDefinedObjectsComponent,
    UdoConnectionsModalComponent,
    ModalAddUdfComponent,
    ModalSummaryResponseComponent,
    ModalAddUdtComponent,
    ReplicateObjectsComponent,
    HistoryUdosComponent,
    ModalValidValuesMDComponent,
    ViewUdfsModalComponent,
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    FontAwesomeModule,
    RouterModule,
    TableModule,
    FormsModule,
    MatBadgeModule
  ],
  providers: [
    { provide: 'LinkerService', useClass: LinkerService }
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class SAPObjectModule { }
