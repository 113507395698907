<div>
    <div *ngIf="edit ? checked : !checked">
        <div class="form-input">
            <mat-form-field appearance="legacy" class="w-input">
                <mat-label>Link</mat-label>
                <input matInput placeholder="Link" [formControl]="Link" />
            </mat-form-field>

            <button mat-icon-button color="primary" style="margin-left: 15px;" (click)="AddLink()">Agregar
                <mat-icon>add</mat-icon>
            </button>
        </div>

    </div>

    <div id="table-container" class="example-container">
        <table mat-table [dataSource]="AppLinkTable" id="tableLinks" *ngIf="AppLinkTable.data.length > 0">
            <ng-container matColumnDef="Link">
                <th mat-header-cell *matHeaderCellDef> Link </th>
                <td mat-cell *matCellDef="let element"> {{ element.Link }} </td>
            </ng-container>
            <ng-container matColumnDef="CreatedDate">
                <th mat-header-cell *matHeaderCellDef> Fecha </th>
                <td mat-cell *matCellDef="let element"> {{ element.CreatedDate | date: 'short'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="Id">
                <th mat-header-cell *matHeaderCellDef>Acción</th>
                <td mat-cell *matCellDef="let element; let i = index">
                    <div class="btn-actions">
                        <button *ngIf="checked" mat-stroked-button (click)='RemoveLink(i)' matTooltip="Eliminar Link">
                            <fa-icon [icon]="['fas', 'close']" style="cursor: pointer;">
                            </fa-icon>
                        </button>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>