<div>

  <div class="align-right"><mat-icon class="icon-close" (click)="CloseDialog()">close</mat-icon></div>
  
  <app-windows-services [edit]="true" [checked]="false" [listTask]="taskList"
                        [listSites]="siteList" [listServices]="wServiceList" [listPools]="poolList" [filters]="true" [pageSizeOptions]="pageSizeOptions" [itemsPeerPage]="itemsPeerPage"></app-windows-services>

  <div>
    <h3 *ngIf="serviceVersion">Versión: {{serviceVersion}}</h3>
  </div>
</div>
