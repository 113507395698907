import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {IServiceLayerConnection} from "../models/interfaces/i-service-layer-connection";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {ISAPDefinitionSettings} from "../models/interfaces/i-sap-definition-settings";
import {ISetting} from "../models/i-appsetting";

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  private readonly CONTROLLER = '/api/Settings';

  constructor(private http: HttpClient) { }

  Post(setting: ISetting): Observable<ICLResponse<ISetting>>{
      return this.http.post<ICLResponse<ISetting>>(`${this.CONTROLLER}/Post`, setting)
  }

  Patch(setting: ISetting): Observable<ICLResponse<ISetting>>{
    return this.http.patch<ICLResponse<ISetting>>(`${this.CONTROLLER}/Patch`, setting)
  }

  Get(): Observable<ICLResponse<ISetting[]>> {
    return this.http.get<ICLResponse<ISetting[]>>(`${this.CONTROLLER}/Get`)
  }

  GetServiceLayerConnections():Observable<ICLResponse<IServiceLayerConnection[]>> {
    return this.http.get<ICLResponse<IServiceLayerConnection[]>>(`${this.CONTROLLER}/GetServiceLayerConnections`);
  }

  GetSAPDefinitions():Observable<ICLResponse<ISAPDefinitionSettings>> {
    return this.http.get<ICLResponse<ISAPDefinitionSettings>>(`${this.CONTROLLER}/GetSAPDefinitions`);
  }

}
