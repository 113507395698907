import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/app-material.module';
import { BitbucketIntegrationComponent } from './bitbucket-integration.component';
import {TableModule} from "@clavisco/table";

@NgModule({
  declarations: [
    BitbucketIntegrationComponent,
  ],
    imports: [
        CommonModule,
        AppMaterialModule,
        TableModule

    ],
  exports: [
    BitbucketIntegrationComponent
  ]
})
export class BitbucketIntegrationModule {
}
