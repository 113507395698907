import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IBaseResponse, IResponse } from '../models/i-response';
import { IProject } from '../models/interfaces/i-proyects';
import { IServer } from '../models/interfaces/i-server';
import { SessionService } from './session.service';
import {IServersRecollectionHistories, ILogs} from '../models/interfaces/i-servers-recollection-histories';
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {LogTypes} from "../common/constants";

@Injectable({
    providedIn: 'root'
})
export class LogService {
    private readonly CONTROLLER = '/api/Logs';
    currentTime: Date = new Date()

    constructor(private http: HttpClient) { }

  GetServersRecHistories(_startPos: number, _rowsReturned: number, _server: string, _initDate: Date, _endDate: Date): Observable<ICLResponse<IServersRecollectionHistories[]>> {

        const initDate = `${_initDate.getFullYear()}-${_initDate.getMonth() + 1}-${_initDate.getDate()} ${_initDate.getHours()}:${_initDate.getMinutes()}:${_initDate.getSeconds()}`;

        const endDate = `${_endDate.getFullYear()}-${_endDate.getMonth() + 1}-${_endDate.getDate()} ${this.currentTime.getHours()}:${this.currentTime.getMinutes()}:${this.currentTime.getSeconds()}`;

        return this.http.get<ICLResponse<IServersRecollectionHistories[]>>(`${this.CONTROLLER}/ServersRecollectionHistories`, {
            params: new HttpParams()
                .set("startPos", _startPos.toString())
                .set("rowsReturned", _rowsReturned.toString())
                .set("server", _server)
                .set("initDate", initDate)
                .set("endDate", endDate)
        });
    }
  GetLogs(_startPos: number, _rowsReturned: number, _createdBy: string, _initDate: Date, _endDate: Date, _type: LogTypes): Observable<ICLResponse<ILogs[]>> {

    const initDate = `${_initDate.getFullYear()}-${_initDate.getMonth() + 1}-${_initDate.getDate()} ${_initDate.getHours()}:${_initDate.getMinutes()}:${_initDate.getSeconds()}`;

    const endDate = `${_endDate.getFullYear()}-${_endDate.getMonth() + 1}-${_endDate.getDate() } ${this.currentTime.getHours()}:${this.currentTime.getMinutes()}:${this.currentTime.getSeconds()}`;

    return this.http.get<ICLResponse<ILogs[]>>(`${this.CONTROLLER}/Filter`, {
      params: new HttpParams()
        .set('startPos', _startPos.toString())
        .set('rowsReturned', _rowsReturned.toString())
        .set("createdBy", _createdBy)
        .set("initDate", initDate)
        .set("endDate", endDate)
        .set("type", _type.toString() != '0' ? _type.toString() : "")
    });
  }
}

