import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { WindowsService } from '../common/constants';
import { IResponse } from '../models/i-response';
import { FilteredServiceData, TypeServices, IWindowService } from '../models/interfaces/i-windowsservice';
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Injectable({
  providedIn: 'root'
})
export class WindowsServicesService {

  @Output() event: EventEmitter<TypeServices> = new EventEmitter();
  private readonly CONTROLLER = '/api/Services'

  constructor(
    private http: HttpClient,
  ) { }

  GetWindowsServices<T>(service: WindowsService): Observable<ICLResponse<T[]>> {
    const query = new HttpParams()
      .set('service', service)
    return this.http.get<ICLResponse<T[]>>(`${this.CONTROLLER}/GetAllWindowsInformation`, { params: query });
  }

  GetWindowsServicesByProjectName<T>(service: WindowsService, projectName: string, startPos: number = 0, rowsReturned: number = 10 ): Observable<ICLResponse<T[]>> {
    const query = new HttpParams()
      .set('service', service).set('startPos', startPos).set('rowsReturned', rowsReturned).set('projectName', projectName)
    return this.http.get<ICLResponse<T[]>>(`${this.CONTROLLER}/GetWindowsInformationByProject`, { params: query });
  }

  GetWindowsInformation<T>(service: WindowsService, startPos: number, rowsReturned: number, name: string, server: string, ambiente: string, status: string): Observable<ICLResponse<T>> {
    const query = new HttpParams()
      .set('service', service)
      .set('startPos', startPos.toString())
      .set('rowsReturned', rowsReturned.toString())
      .set('name', name)
      .set('server', server)
      .set('environment', ambiente)
      .set('status', status)
    return this.http.get<ICLResponse<T>>(`${this.CONTROLLER}/GetWindowsInformation`, { params: query });
  }

  GetPoolByName<T>(_name: string): Observable<ICLResponse<T>> {
    const query = new HttpParams().set('name', _name);
    return this.http.get<ICLResponse<T>>(`${this.CONTROLLER}/GetPoolByName`, { params: query });
  }

}
