import {EventEmitter, Injectable, Output} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {SessionService} from "./session.service";
import {Observable} from "rxjs";
import {IServer} from "../models/interfaces/i-server";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {IApplication} from "../models/interfaces/i_applications";

@Injectable({
  providedIn: 'root'
})
export class ApplicationService{
  @Output() event: EventEmitter<IApplication[]> = new EventEmitter();
  private readonly CONTROLLER = '/api/Application';

  constructor(
    private http: HttpClient) { }

  Get(): Observable<ICLResponse<IApplication[]>> {
    return this.http.get<ICLResponse<IApplication[]>>(`${this.CONTROLLER}`)
  }

  GetApplications(_name: string, _status: string, _startPos: number, _rowsReturned: number): Observable<ICLResponse<IApplication[]>> {
    return this.http.get<ICLResponse<IApplication[]>>(`${this.CONTROLLER}/Filter`, {
      params: new HttpParams()
        .set('name', _name)
        .set('status', _status)
        .set('startPos', _startPos.toString())
        .set('rowsReturned', _rowsReturned.toString())
    })
  }

  Post(data: IApplication): Observable<ICLResponse<IApplication>> {
    return this.http.post<ICLResponse<IApplication>>(`${this.CONTROLLER}`, data)
  }

  Patch(data: IApplication): Observable<ICLResponse<IApplication>> {
    return this.http.patch<ICLResponse<IApplication>>(`${this.CONTROLLER}`, data)
  }
}
