import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from '../common/constants';
import { IDeveloper  } from '../models/interfaces/i-developers';
import { IBaseResponse, IResponse } from '../models/i-response';
import { SessionService } from './session.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DevelopersService {

  constructor(private http:HttpClient) { }

    GetDevelopers():Observable<IResponse<IDeveloper[]>>{
      return this.http.get<IResponse<IDeveloper[]>>(`${environment.ApiUrl}/api/Developer/GetDevelopers`);
    }

    SaveDeveloper(data: IDeveloper) : Observable<IResponse<Number>>{
      return this.http.post<IResponse<Number>>(`${environment.ApiUrl}/api/Developer/StoreDeveloper`, data)
    }

    GetDevelopersByEmailOrName(value: string): Observable<IResponse<IDeveloper[]>>{
      const params = new HttpParams().set("value", value)
      return this.http.get<IResponse<IDeveloper[]>>(`${environment.ApiUrl}/api/Developer/GetDevelopersByEmailOrName`, {params})
    }
}
