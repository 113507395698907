<div class="flex">
    <h2>Links de intereses</h2>
    <div class="align-right">
        <mat-icon class="icon-close" (click)="CloseDialog()">
            close
        </mat-icon>
    </div>
</div>

<mat-divider></mat-divider>
<table mat-table [dataSource]="ListLinks" id="tableLinks" class="content">
    <ng-container matColumnDef="UserName">
        <th mat-header-cell *matHeaderCellDef> Usuario </th>
        <td style="padding-left: 10px;" mat-cell *matCellDef="let element"> {{ element.UserName || 'N/A'}}
        </td>
    </ng-container>
    <ng-container matColumnDef="Link">
        <th mat-header-cell *matHeaderCellDef> Link </th>
        <td class="clickTable" mat-cell *matCellDef="let element" (click)='OpenLink(element.Link)'
            style="cursor: pointer; font-weight: bold;">
            <div style="justify-content: left !important;">
                <p class="fourth-element">{{element.Link || 'N/A'}}</p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="CreatedDate">
        <th mat-header-cell *matHeaderCellDef> Fecha </th>
        <td style="padding-left: 10px;" mat-cell *matCellDef="let element"> {{ element.CreatedDate |
            date:'short'}} </td>
    </ng-container>
    <tr class="item" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
