import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { SessionService } from 'src/app/services/session.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private AUTH_HEADER = 'Authorization';

  constructor(
    private authService: AuthService,
    private sessionService: SessionService
  ) { }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // si se pide token se retorna el request de una vez
    const include: string[] = [ "token", "ChangePassword", "ChangeRecoveryPassword", "RecoveryPassword"]
    include.map<boolean>(value => req.url.includes(value))
    if (include.some(value => req.url.includes(value))) {
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')

      });

      return next.handle(req);
    }

    // se agrega el content type 'application/json' al request
    if (!req.headers.has('Content-Type')) {
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
      });
    }

    // se verifica que use autenticacion y de ser asi se evalua su estado y se agrega
    req = this.addAuthenticationToken(req);

    return next.handle(req).pipe(map((event: HttpEvent<any>) => {
      return event;
    }),
      catchError((error: HttpErrorResponse) => {
        const started = Date.now();
        const elapsed = Date.now() - started;
        console.log(`Request for ${req.urlWithParams} failed after ${elapsed} ms.`);
        return throwError(() => error);
      })
    );

  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    // If we do not have a token yet then we should not set the header.
    // Here we could first retrieve the token from where we store it.
    const TOKEN = this.sessionService.GetToken()
    if (!TOKEN) {
      return request;
    }

    if (!this.authService) {
      // this.authService.logoutForToken();
      this.sessionService.Logout();
      //throw new Error('No esta autenticado');
      throwError(() => new Error('Token invalido'));
    }
    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, `Bearer ${TOKEN}`)
    });
  }

}
