import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import {Repository, Structures} from '@clavisco/core';
import {Login} from "@clavisco/login";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit{
  title = 'AppManager';
  ApiUrl=environment.ApiUrl;


  ngOnInit(): void {
    Repository.Behavior.SetTokenConfiguration({
      token: 'Shared',
      setting: 'apiUrl',
      value: this.ApiUrl
    })

    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.LOGN,
      setting: 'overlaysConfiguration',
      value: JSON.stringify({
        loginMessage: 'Iniciando Sesion',
        changePasswordMessage: 'Cambiando Contraseña',
        sendRecoverEmailMessage: 'Enviando Correo de Recuperacion',
        recoverPasswordMessage: 'Actualizando Contraseña'
      } as Login.Structures.IOverlaysGlobalConfiguration)
    });

    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.LOGN,
      setting: 'changePassword',
      value: {
        endpointToRequest: `${environment.ApiUrl}/api/Users/ChangePassword`
      } as Login.Structures.IChangePasswordConfig
    })

    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.LOGN,
      setting: 'recoverPassword',
      value: {
        endpointToRequest: `${environment.ApiUrl}/api/Users/ChangeRecoveryPassword`
      } as Login.Structures.IChangePasswordConfig
    })

    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.LOGN,
      setting: 'sendRecoverPasswordEmail',
      value: {
        urlVariableToReplaceWithEmail: '#EMAIL#',
        endpointToRequest: `${environment.ApiUrl}/api/Users/#EMAIL#/RecoveryPassword`
      }
    })
  }
}

