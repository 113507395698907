import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProyectsComponent } from './proyects.component';
import { AppMaterialModule } from 'src/app/app-material.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { ModalEditRepositoryComponent } from './modal-edit-repository/modal-edit-repository.component';
import { CreateEditProyectComponent } from './create-edit-project/create-edit-project.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { BdcWalkModule } from 'bdc-walkthrough';
import { AppLinksComponent } from './app-links/app-links.component';
import { WindowsServicesModule } from '../windows-services/windows-services.module';
import { AppLinksModalComponent } from './app-links-modal/app-links-modal.component';
import {ApplicationModule} from "../applications/application.module";
import {TableModule} from "@clavisco/table";

@NgModule({
  declarations: [
    ProyectsComponent,
    ModalEditRepositoryComponent,
    CreateEditProyectComponent,
    AppLinksComponent,
    AppLinksModalComponent
  ],
    imports: [
        CommonModule,
        AppMaterialModule,
        HttpClientModule,
        AppRoutingModule,
        MatExpansionModule,
        BrowserModule,
        MatListModule,
        BdcWalkModule,
        WindowsServicesModule,
        ApplicationModule,
        TableModule
    ],
  exports: [
    ProyectsComponent,
  ]
})
export class ProyectsModule { }
