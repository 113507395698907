import { IAppLink } from "./i-appLink";
import { IDataBaseProject } from "./i-database";
import {IUsers} from "./i-users";
import { IISPool, IISSite, TaskSchedule, WindowServerService } from "./i-windowsservice";
import {Structures} from "@clavisco/core";
import IBaseEntity = Structures.Interfaces.IBaseEntity;
import {IApplication} from "./i_applications";

export interface IProject extends IBaseEntity {
  MaxQtyRowsFetch: number;
  Name: string;
  Code: string;
  JiraUrl: string;
  Description: string;
  UserManagerId: number;
  ProductOwnerId: number;
  Client: string;
  Status?: string;
  CreatedBy: string;
}

export interface IProjectComplete extends IProject{
  ListDataBase?: IDataBaseProject[],
  ListDevelopers: IUsers[],
  ListTasks?: TaskSchedule[],
  ListServices?: WindowServerService[],
  ListPools?: IISPool[],
  ListSites?: IISSite[],
  ListLinks?: IAppLink[]
  ListApplications?: IApplication[]
}


export interface IProjectDeliverables extends IBaseEntity{
  Id: number,
  User: string
  Type: string
  LinkChekList?: string
  LinkVideo?: string
  ProjectId: number,
  CreatedBy: string;
  JsonObject: string
}

const ignoreIProjectComplete: string[] = ['ListApplications','ListLinks','ListSites','ListPools','ListServices','ListTasks','ListDevelopers', 'ListDataBase']
export const columnsToIgnore:string[] = ['MaxQtyRowsFetch', 'IsActive', 'UpdatedBy', 'UpdateDate', 'CreateBy', 'CreatedBy', 'CreatedDate', 'JiraUrl', 'Description', 'UserManagerId', 'ProductOwnerId', 'Client', ...ignoreIProjectComplete]
